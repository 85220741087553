/**
 * @file   src\containers\Login.tsx
 * @brief  Login page.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col } from 'react-bootstrap';
import icnEye from '../assets/img/icnEye.svg';
import eyeOff from '../assets/img/eye-off.svg';
import Input from '../components/MAInput';
import Checkbox from '../components/MACheck';
import TeacherHomeLayout from '../components/TeacherHomeLayout';
import { IAuthRequest } from '../interfaces/AuthInterface';
import { validateForm } from '../utils/formValidation';
import { SIGNIN_SCHEMA } from '../validations/authSchema';
import { MessageToaster } from '../utils/ToastUtil';
import { loginUser } from '../store/actions/authActions';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';
import Loader from '../components/Loader';
import { setItemLocalStorage, removeItemLocalStorage, useIntlMessages } from '../utils/helper';
import { RoleTypeIds } from '../utils/enums';

// Toast object creation.
const toast = new MessageToaster();

const Teacherlog = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Login action
  const loginWithCredentials = () =>
    dispatch(
      loginUser({
        Email: loginForm.username.trim(),
        Password: loginForm.password,
      }),
    );
  // Access redux state variables
  const { loginApiData, loginApiLoading, loginApiSuccess, responseCode, responseMessage } = useAppSelector((state: RootState) => state.authentication);
  // Ref object creation.
  const buttonRef = useRef<any>();
  const inputRef = useRef<any>();
  const containerRef = useRef<any>();
  // Initialize component state variables.
  const [loginForm, setLoginForm] = useState<IAuthRequest>({
    username: '',
    password: '',
  });
  const [errorFields, setErrorFields] = useState<any>({});
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // Set remember me
  useEffect(() => {
    try {
      const storedUsername = localStorage.getItem('username');
      if (storedUsername) {
        const updateForm = { ...loginForm, username: storedUsername, password: '' };
        setLoginForm(updateForm);
        setRememberMe(true);
      }
      const listener = (event: any) => {
        if (event.code === 'Enter' || event.code === 'NumpadEnter') {
          event.preventDefault();
          buttonRef?.current?.focus();
        }
      };
      const currentContainer = containerRef?.current;
      if (currentContainer) {
        currentContainer.addEventListener('keydown', listener);
      }
      return () => {
        if (currentContainer) {
          currentContainer.removeEventListener('keydown', listener);
        }
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // Add event listener for Enter key press
  useEffect(() => {
    try {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
          // Check if all required fields are filled
          if (isFormValid()) {
            event.preventDefault();
            onSubmit();
          }
        }
      };
      const isFormValid = () => {
        return Object.values(errorFields).every((error) => !error);
      };
      const currentContainer = containerRef?.current;
      if (currentContainer) {
        currentContainer.addEventListener('keydown', handleKeyPress);
      }
      return () => {
        if (currentContainer) {
          currentContainer.removeEventListener('keydown', handleKeyPress);
        }
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [errorFields]);
  // Handle login action
  useEffect(() => {
    try {
      if (responseCode === 200 && loginApiSuccess) {
        setItemLocalStorage('MI_USR_DATA', loginApiData);
        if (loginApiData.RoleTypeId === RoleTypeIds.SYSTEM_ADMIN) {
          if (loginApiData.IsPasswordChanged == true && loginApiData.IsProfileCreated == true) navigate('/home');
          else if (loginApiData.IsPasswordChanged == false) navigate('/setuppassword');
          else if (loginApiData.IsProfileCreated == false) navigate('/profilecreate');
          else navigate('/changepassword');
        } else if ([RoleTypeIds.APP_USER_MANAGER, RoleTypeIds.ORGANIZATION_ADMIN].includes(loginApiData.RoleTypeId)) {
          if (loginApiData.IsProfileCreated == false) navigate('/profilecreate');
          else navigate('/vieworganization');
        } else if (loginApiData.RoleTypeId === RoleTypeIds.SUPER_ADMIN) {
          navigate('/home');
        } else {
          // organization manager
          if (loginApiData.IsProfileCreated == false) navigate('/profilecreate');
          else navigate('/vieworganization');
        }
      }
      if (responseCode > 200 && responseMessage?.length > 0) {
        toast.toastError(responseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [loginApiLoading]);

  // handle form fields validation
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      setLoginForm((info) => ({
        ...info,
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      const errorresult = await validateForm(validateObj, SIGNIN_SCHEMA, errorFields);
      setErrorFields(errorresult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Login form submit event.
  const onSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    try {
      if (event) {
        event.preventDefault();
      }
      const errorresult = await validateForm(loginForm, SIGNIN_SCHEMA, errorFields);
      if (Object.keys(errorresult).length > 0) {
        setErrorFields(errorresult);
        // If there are validation errors, focus on the input fiel
        const firstErrorField = Object.keys(errorresult)[0];
        if (firstErrorField && inputRef?.current) {
          inputRef?.current?.focus();
        }
      } else {
        loginWithCredentials();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Show/hide password text.
  const togglePassword = () => {
    try {
      setShowPassword(!showPassword);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Function to handle "Remember Me" checkbox state change
  const handleRememberMeChange = (event: any) => {
    try {
      setRememberMe(event.target.checked);
      if (!event.target.checked) {
        removeItemLocalStorage('username');
      } else {
        localStorage.setItem('username', loginForm.username);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Function to handle forgot password on enter key press
  const handleForgotPasswordKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    try {
      if (event.key === 'Enter') {
        navigate('/forgotpassword');
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <TeacherHomeLayout>
      <div className="login-main" ref={containerRef}>
        <h1>{useIntlMessages('Label.WelcomeBack')}</h1>
        <h2>
          <FormattedMessage id="Hd.Signin" />
        </h2>
        <form name="loginform" onSubmit={onSubmit}>
          <Col>
            <Input
              autoFocus
              ref={inputRef}
              label={useIntlMessages('Label.EmailUsername')}
              id="username"
              name="username"
              type="text"
              placeholder={useIntlMessages('PH.EmailUsername')}
              maxLength={200}
              errorMessage={errorFields?.username}
              onChange={onInputHandleChange}
              value={loginForm.username}
            />
          </Col>
          <Col className="position-relative">
            <Input
              label={useIntlMessages('Label.Password')}
              id="password"
              name="password"
              type={!showPassword ? 'password' : 'text'}
              placeholder={useIntlMessages('PH.Password')}
              maxLength={200}
              errorMessage={errorFields?.password}
              onChange={onInputHandleChange}
              value={loginForm.password}
            />
            <Button variant="outline-secondary" className="btn-eye" onClick={togglePassword}>
              <img src={!showPassword ? icnEye : eyeOff} alt="" />
            </Button>
          </Col>
          <Col className="mb-4">
            <Checkbox type="Checkbox" label={useIntlMessages('Label.RememberMe')} onChange={handleRememberMeChange} checked={rememberMe} />
          </Col>
          <Button
            className="w-100 mb-4"
            variant="primary"
            type="submit"
            ref={buttonRef}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSubmit();
              }
            }}
          >
            <FormattedMessage id="Button.Signin" />
          </Button>
          <div className="text-center">
            <Link to="/forgotpassword" className="text-center" onKeyDown={handleForgotPasswordKeyDown}>
              <FormattedMessage id="Link.ForgorPassword" />
            </Link>
          </div>
        </form>
      </div>
      {loginApiLoading && <Loader />}
    </TeacherHomeLayout>
  );
};
export default Teacherlog;
