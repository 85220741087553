/**
 * @file   src\containers\subscriptions\Add.tsx
 * @brief  Subscription plans add page.
 * @date    July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React, { useState, useEffect } from 'react';
import '../../assets/css/Home.scss';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages, useIntlActionMessages } from '../../utils/helper';
import { Button, Col, Row, Breadcrumb } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { RootState } from '../../store';
import Input from '../../components/MAInput';
import Select from '../../components/MASelect';
import { NumberValues } from '../../utils/enums';
import { ISelectOptionsNumber, ISelectOptionsString } from '../../interfaces/GeneralInterface';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getSubscriptionPlanValidity, addSubscriptionPlan, getSubscriptionPlanDetails, editSubscriptionPlan } from '../../store/actions/subscriptionPlanActions';
import { ISubscriptionPlanForm, IViewSubscriptionPlanApiParams } from '../../interfaces/SubscriptionPlanInterface';
import { MessageToaster } from '../../utils/ToastUtil';
import Loader from '../../components/Loader';
import Checkbox from '../../components/MACheck';
import { validateForm } from '../../utils/formValidation';
import { SUBSCRIPTION_SCHEMA, SUBSCRIPTION_GENERAL_SCHEMA } from '../../validations/subscriptionPlanSchema';
import { resetSubscriptionPlanAdd, resetSubscriptionPlanDetails, resetSubscriptionPlanUpdate } from '../../store/slices/subscriptionPlanSlice';
import { getOrganizationTypeDropDownList, getOrganizationNameByOrgType } from '../../store/actions/organizationTypeActions';
// Declare default params
const savedefaultRequestParams: ISubscriptionPlanForm = {
  Amount: 0,
  AppUserCount: 0,
  OrganizationTypeId: 0,
  Organizations: ['0'],
  Title: '',
  Validity: 0,
  WebUserCount: 0,
  IsGeneral: false,
  Discount: 0,
};
const SubscriptionPlans = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Location object
  const location = useLocation();
  // Toast object creation.
  const toast = new MessageToaster();

  // Access redux state variables
  const {
    subscriptionPlanValidityListData,
    subscriptionPlanValidityListApiSuccess,
    subscriptionPlanValidityListApiLoading,
    subscriptionPlanDetailApiData,
    subscriptionPlanDetailApiSuccess,
    subscriptionPlanDetailApiLoading,
    addSubscriptionPlanApiLoading,
    addSubscriptionPlanApiSuccess,
    addSubscriptionPlanApiResponseCode,
    addSubscriptionPlanApiResponseMessage,
    subscriptionPlanUpdateApiLoading,
    subscriptionPlanUpdateApiSuccess,
    subscriptionPlanUpdateApiResponseCode,
    subscriptionPlanUpdateApiResponseMessage,
  } = useAppSelector((state: RootState) => state.subscriptionPlan);

  const { organizationTypeDropDownData, organizationTypeDropDownSuccess, organizationTypeDropDownLoading, orgnameByOrgTypeData, orgnameByOrgTypeLoading } = useAppSelector(
    (state: RootState) => state.organizationType,
  );
  // Initialize component state variables.
  const isEditSubscriptionPlan = location.pathname === '/editsubscriptionplan';
  const [errorFields, setErrorFields] = useState<any>({});
  const [validityOptions, setValidityOptions] = useState<Array<any>>([]);
  const [validityValue, setValidity] = useState<ISelectOptionsNumber>();
  const [subscriptionPlanForm, setSubscriptionPlanForm] = useState<ISubscriptionPlanForm>(savedefaultRequestParams);
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<Array<any>>([]);
  const [organizationTypeValue, setOrganizationTypeValue] = useState<ISelectOptionsNumber>();
  const [orgName, setOrgName] = useState<ISelectOptionsString[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<ISelectOptionsString[]>([]);
  const allOption = { label: 'All', value: -1 };
  const [IsGeneral, setIsGeneral] = useState(false);
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // Show message after form submit, success/failure
  useEffect(() => {
    try {
      if (addSubscriptionPlanApiResponseCode > 0 && addSubscriptionPlanApiSuccess) {
        toast.toastSuccess(addSubscriptionPlanApiResponseMessage);
        navigate('/subscriptionplans');
      } else if (addSubscriptionPlanApiResponseCode > 0 && addSubscriptionPlanApiResponseMessage && !addSubscriptionPlanApiSuccess) {
        toast.toastError(addSubscriptionPlanApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [addSubscriptionPlanApiLoading, addSubscriptionPlanApiResponseCode, addSubscriptionPlanApiSuccess, addSubscriptionPlanApiResponseMessage]);
  // Reset the form after creating plan
  useEffect(() => {
    try {
      if (location?.state?.subscriptionPlanID) {
        const viewOrganizationInfoParams: IViewSubscriptionPlanApiParams = {
          PlanID: location?.state?.subscriptionPlanID,
        };
        dispatch(getSubscriptionPlanDetails(viewOrganizationInfoParams));
      }
      return () => {
        dispatch(resetSubscriptionPlanAdd());
        dispatch(resetSubscriptionPlanDetails());
        dispatch(resetSubscriptionPlanUpdate());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // set data for plan edit
  useEffect(() => {
    try {
      if (subscriptionPlanDetailApiSuccess && subscriptionPlanDetailApiData) {
        if (isEditSubscriptionPlan) {
          setIsGeneral(subscriptionPlanDetailApiData?.IsGeneral);
          setValidity({
            label: subscriptionPlanDetailApiData?.DurationLabel,
            value: subscriptionPlanDetailApiData?.ValidityID,
          });
          if (subscriptionPlanDetailApiData?.IsGeneral) {
            setSelectedOrganization([]);
          } else {
            setOrganizationTypeValue({
              label: subscriptionPlanDetailApiData.OrganizationTypeName,
              value: subscriptionPlanDetailApiData.OrganizationTypeId,
            });
            setSelectedOrganization([
              {
                label: subscriptionPlanDetailApiData.OrganizationName,
                value: subscriptionPlanDetailApiData.OrganizationID,
              },
            ]);
          }
          const organisationsForSelection = subscriptionPlanDetailApiData?.OrgList?.map((organisations: any) => ({
            label: organisations.organizationname,
            value: organisations.org_id,
          }));
          setSelectedOrganization(organisationsForSelection);
          // Fetch organizations based on the selected organization type on edit mode
          dispatch(
            getOrganizationNameByOrgType({
              OrganizationTypeId: subscriptionPlanDetailApiData?.OrganizationTypeID,
            }),
          ).then(() => {
            setSelectedOrganization(organisationsForSelection);
          });

          const organisationList = subscriptionPlanDetailApiData?.OrgList?.map((organisations: any) => organisations.org_id);
          setSubscriptionPlanForm((prevPlanForm) => ({
            ...prevPlanForm,
            Title: subscriptionPlanDetailApiData?.PlanName,
            Amount: subscriptionPlanDetailApiData?.Amount,
            AppUserCount: subscriptionPlanDetailApiData?.NoOfMobAppUsers,
            WebUserCount: subscriptionPlanDetailApiData?.NoOfWebUsers,
            SubscriptionPlanID: location?.state?.subscriptionPlanID,
            OrganizationTypeId: subscriptionPlanDetailApiData?.OrganizationTypeID,
            Validity: subscriptionPlanDetailApiData?.ValidityID,
            Organizations: organisationList,
            IsGeneral: subscriptionPlanDetailApiData?.IsGeneral,
            Discount: subscriptionPlanDetailApiData?.Discount,
          }));
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [subscriptionPlanDetailApiLoading, subscriptionPlanDetailApiSuccess, subscriptionPlanDetailApiData]);
  // Prevent enter key press
  useEffect(() => {
    try {
      dispatch(getSubscriptionPlanValidity({}));
      dispatch(getOrganizationTypeDropDownList({}));
      const listener = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
          event.preventDefault();
        }
      };
      document.addEventListener('keydown', listener);
      return () => {
        document.removeEventListener('keydown', listener);
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // Get plan validities
  useEffect(() => {
    try {
      if (subscriptionPlanValidityListApiSuccess && subscriptionPlanValidityListData?.length > 0) {
        const setValidityOptionsVal = subscriptionPlanValidityListData.map(
          (planValidity: any): ISelectOptionsNumber => ({
            label: planValidity.Label,
            value: planValidity.ValidityId,
          }),
        );
        setValidityOptions([...setValidityOptionsVal]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [subscriptionPlanValidityListApiLoading]);
  // Get organization details by organization type
  useEffect(() => {
    try {
      if (organizationTypeValue) {
        if (orgnameByOrgTypeData && orgnameByOrgTypeData.length > 0) {
          const organizationData = orgnameByOrgTypeData.map(
            (organization: any): ISelectOptionsNumber => ({
              label: organization.Name,
              value: organization.ID,
            }),
          );
          setOrgName([allOption, ...organizationData]);
          setSelectedOrganization([]);
        } else {
          // If ResponseData is null, update orgName state with null
          setOrgName([]);
          setSelectedOrganization([]);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [orgnameByOrgTypeLoading]);
  // Get organization type
  useEffect(() => {
    try {
      if (organizationTypeDropDownSuccess && organizationTypeDropDownData?.length > 0) {
        const orgTypeOptions = organizationTypeDropDownData.map(
          (orgtype: any): ISelectOptionsNumber => ({
            label: orgtype.orgTypeName,
            value: orgtype.orgTypeId,
          }),
        );
        setOrganizationTypeOptions([...orgTypeOptions]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationTypeDropDownLoading]);
  // Add event listener for Enter key press
  useEffect(() => {
    try {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
          // Check if all required fields are filled
          if (isFormValid()) {
            event.preventDefault();
            onSubmit();
          }
        }
      };
      const isFormValid = () => {
        return Object.values(errorFields).every((error) => !error);
      };
      document.addEventListener('keydown', handleKeyPress);
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [errorFields]);
  // Handle Org Type Update Success/Failure.
  useEffect(() => {
    try {
      if (subscriptionPlanUpdateApiSuccess && subscriptionPlanUpdateApiResponseCode > 0) {
        navigate('/subscriptionplans');
        toast.toastSuccess(subscriptionPlanUpdateApiResponseMessage);
      } else if (!subscriptionPlanUpdateApiSuccess && subscriptionPlanUpdateApiResponseCode > 0) {
        toast.toastError(subscriptionPlanUpdateApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [subscriptionPlanUpdateApiLoading]);
  // Function to set validity of plan
  const handleValidityChange = async (event: any) => {
    try {
      const selectedValidity = parseInt(event.value);
      setSubscriptionPlanForm((prevSubscriptionPlanForm) => ({
        ...prevSubscriptionPlanForm,
        Validity: selectedValidity,
      }));
      setValidity(event);
      const validateObj = { Validity: selectedValidity };
      const errorResult = await validateForm(validateObj, SUBSCRIPTION_SCHEMA, errorFields);
      setErrorFields(errorResult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle form submit
  const onSubmit = async () => {
    try {
      let errorResult = {};
      if (!IsGeneral) {
        errorResult = await validateForm(subscriptionPlanForm, SUBSCRIPTION_SCHEMA, errorFields);
      } else {
        errorResult = await validateForm(subscriptionPlanForm, SUBSCRIPTION_GENERAL_SCHEMA, errorFields);
      }
      if (Object.keys(errorResult).length === 0) {
        if (location?.state?.subscriptionPlanID) {
          dispatch(editSubscriptionPlan(subscriptionPlanForm));
        } else {
          dispatch(addSubscriptionPlan(subscriptionPlanForm));
        }
      } else {
        setErrorFields(errorResult);
        // If there are validation errors, focus on the input field
        const firstErrorField = Object.keys(errorResult)[0];
        const inputFields = document.getElementsByName(firstErrorField);
        if (inputFields.length > 0 && inputFields[0] instanceof HTMLInputElement) {
          inputFields[0].focus();
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle input field
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      let parsedValue: any = value;
      if (['Amount', 'AppUserCount', 'WebUserCount', 'Discount'].includes(name)) {
        parsedValue = Number(value);
      }
      setSubscriptionPlanForm((info: any) => ({
        ...info,
        [name]: parsedValue,
      }));
      // Validate other fields
      const validateObj = { [name]: parsedValue };
      const errorResult = await validateForm(validateObj, SUBSCRIPTION_SCHEMA, errorFields);
      setErrorFields(errorResult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Get organization name on organization type change
  const handleOrgTypeChange = async (event: any) => {
    try {
      const selectedOrgTypeId = parseInt(event.value);
      setSubscriptionPlanForm((prevSubscriptionPlanForm) => ({
        ...prevSubscriptionPlanForm,
        OrganizationTypeId: selectedOrgTypeId,
        Organizations: ['0'],
      }));
      setOrganizationTypeValue(event);
      dispatch(
        getOrganizationNameByOrgType({
          OrganizationTypeId: selectedOrgTypeId,
        }),
      );
      const validateObj = { OrganizationTypeId: selectedOrgTypeId };
      const errorResult = await validateForm(validateObj, SUBSCRIPTION_SCHEMA, errorFields);
      setErrorFields(errorResult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle selection change
  const handleSelectionChange = async (event: any) => {
    try {
      const allValue = -1; //  -1 to represent 'all'
      if (event) {
        let orgArray = event.map((subcategory: any) => subcategory.value);
        let updatedEvent = event;
        if (orgArray.includes(allValue)) {
          orgArray = orgName.map((org) => org.value).filter((value) => value !== allValue);
          updatedEvent = orgName.filter((org) => org.value !== allValue);
        }
        setSelectedOrganization(updatedEvent);
        setSubscriptionPlanForm((prevPlanForm: any) => ({
          ...prevPlanForm,
          Organizations: orgArray,
        }));
        const validateObj = {
          ...subscriptionPlanForm,
          Organizations: orgArray.length > 0 ? orgArray : undefined,
        };
        const errorResult = await validateForm(validateObj, SUBSCRIPTION_SCHEMA, errorFields);
        setErrorFields(errorResult);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle key press
  const handleKeyPress = (event: any) => {
    try {
      if (event.which < 48 || event.which > 57) {
        event.preventDefault();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle key press for amount and discount
  const handleKeyPressAmount = (event: any) => {
    try {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode !== 46 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
      }
      if (charCode === 46 && event.target.value.indexOf('.') !== -1) {
        event.preventDefault();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    try {
      setIsGeneral(!IsGeneral);
      // Reset selected options when checkbox is checked
      if (!IsGeneral) {
        setSubscriptionPlanForm((prevSubscriptionPlanForm) => ({
          ...prevSubscriptionPlanForm,
          IsGeneral: true,
          OrganizationTypeId: 0,
          Organizations: [],
        }));
        setSelectedOrganization([]);
      } else {
        setSubscriptionPlanForm((prevSubscriptionPlanForm) => ({
          ...prevSubscriptionPlanForm,
          IsGeneral: false,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>{isEditSubscriptionPlan ? <FormattedMessage id="Button.EditSubscriptionPlan" /> : <FormattedMessage id="Hd.AddPlan" />}</h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/subscriptionplans')}>
              <FormattedMessage id="Hd.ManageSubscriptionPlans" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{isEditSubscriptionPlan ? <FormattedMessage id="Button.EditSubscriptionPlan" /> : <FormattedMessage id="Hd.AddPlan" />}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="content-sub">
        <div className="content-area-padding d-flex justify-content-center py-5">
          <Col xl={10} lg={12}>
            <Row>
              <Col lg={6} md={6}>
                <Input
                  autoFocus
                  label={useIntlMessages('Label.SubscriptionPlanName')}
                  id="Title"
                  name="Title"
                  type="text"
                  placeholder={useIntlMessages('PH.EnterName')}
                  maxLength={NumberValues.NUM_100}
                  errorMessage={errorFields?.Title}
                  value={subscriptionPlanForm.Title}
                  onChange={onInputHandleChange}
                />
              </Col>
              <Col lg={6} md={6}>
                <Select
                  label={useIntlMessages('Label.SubscriptionValidity')}
                  name="Validity"
                  value={validityValue}
                  options={validityOptions}
                  placeholder={useIntlMessages('PH.Select')}
                  error={errorFields?.Validity}
                  onChange={(e: any) => handleValidityChange(e)}
                  isDisabled={isEditSubscriptionPlan}
                />
              </Col>
              <Col lg={6} md={6}>
                <Input
                  label={useIntlMessages('Label.SubscriptionAmount')}
                  id="Amount"
                  name="Amount"
                  type="text"
                  placeholder={useIntlMessages('Label.EnterAmount')}
                  maxLength={NumberValues.NUM_6}
                  value={subscriptionPlanForm.Amount > 0 ? subscriptionPlanForm.Amount : ''}
                  onChange={isEditSubscriptionPlan ? undefined : onInputHandleChange}
                  errorMessage={errorFields?.Amount}
                  onKeyPress={handleKeyPressAmount}
                  disabled={isEditSubscriptionPlan}
                />
              </Col>
              <Col lg={6} md={6}>
                <Input
                  label={useIntlMessages('Label.Discount')}
                  id="Discount"
                  name="Discount"
                  type="text"
                  placeholder={useIntlMessages('PH.EnterDiscount')}
                  maxLength={NumberValues.NUM_3}
                  value={subscriptionPlanForm.Discount > 0 ? subscriptionPlanForm.Discount : ''}
                  onChange={onInputHandleChange}
                  onKeyPress={handleKeyPressAmount}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <h5 className="mb-4">
                <FormattedMessage id="Hd.SubscriptionADD-ONs" />
              </h5>
              <Col lg={6} md={6}>
                <Input
                  label={useIntlMessages('Label.NumberofWebUsers')}
                  id="WebUserCount"
                  name="WebUserCount"
                  type="text"
                  value={subscriptionPlanForm.WebUserCount > 0 ? subscriptionPlanForm.WebUserCount : ''}
                  placeholder={useIntlMessages('PH.EnterNumber')}
                  maxLength={NumberValues.NUM_6}
                  onChange={onInputHandleChange}
                  errorMessage={errorFields?.WebUserCount}
                  onKeyPress={handleKeyPress}
                />
              </Col>
              <Col lg={6} md={6}>
                <Input
                  label={useIntlMessages('Label.NumofApp')}
                  id="AppUserCount"
                  name="AppUserCount"
                  type="text"
                  placeholder={useIntlMessages('PH.EnterNumber')}
                  maxLength={NumberValues.NUM_6}
                  onChange={onInputHandleChange}
                  value={subscriptionPlanForm.AppUserCount > 0 ? subscriptionPlanForm.AppUserCount : ''}
                  errorMessage={errorFields?.AppUserCount}
                  onKeyPress={handleKeyPress}
                />
              </Col>
            </Row>
            <Row className="mt-4 mb-4">
              <h5 className="mb-4">
                <FormattedMessage id="Hd.SubscriptionPlanVisibility" />
              </h5>
              <div className="check-sm">
                <Checkbox
                  type="Checkbox"
                  id="IsGeneral"
                  name="IsGeneral"
                  label={useIntlActionMessages('Label.Checkbox.GeneralPlaneAdmin')}
                  onChange={handleCheckboxChange}
                  value={subscriptionPlanForm?.IsGeneral}
                  checked={subscriptionPlanForm.IsGeneral}
                />
              </div>
              {!IsGeneral && (
                <>
                  <Col lg={6} md={6}>
                    <Select
                      label={useIntlMessages('label.SelectOrganizationsType')}
                      options={organizationTypeOptions}
                      value={organizationTypeValue}
                      name="OrganizationTypeId"
                      placeholder={useIntlMessages('PH.Select')}
                      onChange={(e: any) => handleOrgTypeChange(e)}
                      error={errorFields?.OrganizationTypeId}
                    />
                  </Col>
                  <Col lg={6} md={6}>
                    <Select
                      label={useIntlMessages('label.SelectOrganizations')}
                      options={orgName}
                      value={selectedOrganization}
                      name="Organizations"
                      id="Organizations"
                      placeholder={useIntlMessages('PH.Select')}
                      onChange={(e: any) => handleSelectionChange(e)}
                      isMulti
                      error={errorFields?.Organizations}
                    />
                  </Col>
                </>
              )}
            </Row>
            <div className="btn-container btn-min-width d-flex justify-content-end">
              <Button variant="outline-primary" onClick={() => navigate('/subscriptionplans')}>
                <FormattedMessage id="Button.Cancel" />
              </Button>
              <Button
                variant="primary"
                onClick={onSubmit}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
              >
                <FormattedMessage id="Button.Save" />
              </Button>
            </div>
          </Col>
        </div>
      </div>
      {(orgnameByOrgTypeLoading || addSubscriptionPlanApiLoading || subscriptionPlanDetailApiLoading) && <Loader />}
    </>
  );
};
export default SubscriptionPlans;
