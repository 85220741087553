/**
 * @file   src\components\ManageAppSetting.tsx
 * @brief  404 page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../../assets/css/manageSettings.scss';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row, Accordion, Form } from 'react-bootstrap';
import Select from '../../components/MASelect';
import ListCard from '../../components/OrgListCard';
import { slide as Filter } from 'react-burger-menu';
import Orgimg from '../../assets/img/Org-image.png';
import Close from '../../assets/img/Close.svg';
import { useNavigate } from 'react-router-dom';
import { useIntlMessages, useIntlActionMessages } from '../../utils/helper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getOrganizationNameByOrgType, getOrganizationTypeDropDownList, getUserRolesByOrgType } from '../../store/actions/organizationTypeActions';
import { RootState } from '../../store';
import { IOrgTypeResponse } from '../../interfaces/OrganizationTypeInterface';
import { ISelectOption } from '../../interfaces/GeneralInterface';
import { ISaveSettingsRequest } from '../../interfaces/SettingsInterface';
import { getOnboardingProcessColumns, getSettings, getUserRolePermissions, saveSettings } from '../../store/actions/settingsAction';
import RadioGroup from '../../components/RadioGroup';
import { resetGetSettings, resetSaveSettings } from '../../store/slices/settingsSlice';
import { MessageToaster } from '../../utils/ToastUtil';
import Loader from '../../components/Loader';
import { resetOrgTypeNameRoles } from '../../store/slices/organizationTypeSlice';
import { BUCKET_URL } from '../../utils/constants';

// Onboarding columns radio options
const radioOptionsOnboardingColumns = [
  { label: 'Mandatory', value: 1 },
  { label: 'Optional', value: 2 },
  { label: 'Hidden', value: 3 },
];

// Permissions radio options
const radioOptionsPermissions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

// Save Settings API Form
const defaultSettingsForm = {
  OrganizationTypeId: 0,
  RoleId: 0,
  OrganizationID: '',
  FieldInfo: [],
  Permission: [],
};

// Get Settings API request
const getSettingsDefaultRequest = {
  RoleId: 0,
  OrganizationID: '',
};

const ManageappSettings = () => {
  // Navigation object
  const navigate = useNavigate();
  // Action dispatch object
  const dispatch = useAppDispatch();
  // Toast object creation.
  const toast = new MessageToaster();
  // Accessing redux state variables
  const {
    organizationTypeDropDownData,
    organizationTypeDropDownResponseCode,
    organizationTypeDropDownSuccess,
    organizationTypeDropDownLoading,
    rolesByOrgTypeData,
    rolesByOrgTypeLoading,
    rolesByOrgTypeSuccess,
    rolesByOrgTypeResponseCode,
    orgnameByOrgTypeData,
    orgnameByOrgTypeLoading,
    orgnameByOrgTypeSuccess,
    orgnameByOrgTypeResponseCode,
  } = useAppSelector((state: RootState) => state.organizationType);
  const {
    getOnboardingProcessColumnsApiLoading,
    getOnboardingProcessColumnsApiSuccess,
    getOnboardingProcessColumnsApiResponseCode,
    getOnboardingProcessColumnsApiData,
    getUserRolePermissionsApiLoading,
    getUserRolePermissionsApiSuccess,
    getUserRolePermissionsApiResponseCode,
    getUserRolePermissionsApiData,
    getSettingsApiLoading,
    getSettingsApiSuccess,
    getSettingsApiResponseCode,
    getSettingsApiData,
    saveSettingsApiLoading,
    saveSettingsApiResponseCode,
    saveSettingsApiSuccess,
    saveSettingsApiResponseMessage,
  } = useAppSelector((state: RootState) => state.settings);
  // Component state variables
  const [orgTypeList, setOrgTypeList] = useState<any>([]);
  const [selectedOrgType, setSelectedOrgType] = useState<ISelectOption>({ label: 'Select', value: '0' });
  const [organizationList, setOrganizationList] = useState<any>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<any>(null);
  const [userRoleList, setUserRoleList] = useState<any>([]);
  const [selectedUserRole, setSelectedUserRole] = useState<string>('');
  const [numCols] = useState<number>(2);
  const [itemsPerCol, setItemsPerCol] = useState<number>(0);
  const [onboardingColumns, setOnBoardingColumns] = useState<any>([]);
  const [permissions, setPermissions] = useState<any>([]);
  const [settingsForm, setSettingsForm] = useState<ISaveSettingsRequest>(defaultSettingsForm);
  const [getSettingsRequest, setGetSettingsRequest] = useState<any>(getSettingsDefaultRequest);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isUserRoleLoaded, setIsUserRoleLoaded] = useState<boolean>(false);
  const [isOrgLoaded, setIsOrgLoaded] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [isPermissionsLoaded, setIsPermissionsLoaded] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const NodataText = useIntlActionMessages('Label.Nodata');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // Reset Slice.
  useEffect(() => {
    try {
      return () => {
        dispatch(resetOrgTypeNameRoles());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // works on initial rendering
  useEffect(() => {
    try {
      dispatch(getOrganizationTypeDropDownList({}));
      dispatch(getOnboardingProcessColumns({}));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // Handle Organization Type API success/failure
  useEffect(() => {
    try {
      if (organizationTypeDropDownSuccess && organizationTypeDropDownResponseCode > 0) {
        const orgTypes: any = [];
        organizationTypeDropDownData?.forEach((orgType: IOrgTypeResponse) =>
          orgTypes.push({
            label: orgType.orgTypeName,
            value: orgType.orgTypeId,
          }),
        );
        setOrgTypeList(orgTypes);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationTypeDropDownLoading]);

  // get user roles and organization names by organization type
  useEffect(() => {
    try {
      if (selectedOrgType?.value !== '0') {
        dispatch(
          getUserRolesByOrgType({
            OrganizationTypeId: selectedOrgType?.value,
          }),
        );
        dispatch(
          getOrganizationNameByOrgType({
            OrganizationTypeId: selectedOrgType?.value,
          }),
        );
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [selectedOrgType]);

  // Handle roles by or type API success/failure
  useEffect(() => {
    try {
      if (rolesByOrgTypeSuccess && rolesByOrgTypeResponseCode > 0) {
        const roles = rolesByOrgTypeData
          .filter((userRoles: any) => userRoles.RoleId !== 0)
          .map(
            (userRoles: any): ISelectOption => ({
              label: userRoles.Name,
              value: userRoles.RoleId + '_' + userRoles.RoleTypeID,
            }),
          );
        setUserRoleList(roles);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [rolesByOrgTypeLoading]);

  // Handle Organization by org type API success/failure
  useEffect(() => {
    try {
      if (orgnameByOrgTypeSuccess && orgnameByOrgTypeResponseCode > 0) {
        if (orgnameByOrgTypeData && orgnameByOrgTypeData?.length > 0) {
          const organizations: any = [];
          orgnameByOrgTypeData?.forEach((org: any) =>
            organizations.push({
              id: org.ID,
              name: org.Name,
              logo: org.Logo,
              address: org.Address,
            }),
          );
          setOrganizationList(organizations);
        } else {
          setOrganizationList([]);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [orgnameByOrgTypeLoading]);

  // get saved settings if user roles and organizations are loaded
  useEffect(() => {
    try {
      if (isOrgLoaded && isUserRoleLoaded && isPermissionsLoaded) {
        dispatch(getSettings(getSettingsRequest));
        setShowSettings(true);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getSettingsRequest, isPermissionsLoaded]);

  // Handle get onboarding columns API success/failure
  useEffect(() => {
    try {
      if (getOnboardingProcessColumnsApiSuccess && getOnboardingProcessColumnsApiResponseCode > 0 && getOnboardingProcessColumnsApiData?.length > 0) {
        // Calculate the number of items per column
        const items = Math.ceil(getOnboardingProcessColumnsApiData.length / numCols);
        setItemsPerCol(items);
        const columns = getOnboardingProcessColumnsApiData.map((field: any) => ({
          ColumnId: field.ColumnId,
          ColumnName: field.ColumnName,
          OnBoardingStatusId: 1,
        }));
        setOnBoardingColumns(columns);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getOnboardingProcessColumnsApiLoading]);

  // Handle get permissions API success/failure
  useEffect(() => {
    try {
      if (getUserRolePermissionsApiSuccess && getUserRolePermissionsApiResponseCode > 0 && getUserRolePermissionsApiData?.length > 0) {
        const permissions = getUserRolePermissionsApiData.map((field: any) => ({
          PermissionId: field.PermissionId,
          Permission: field.Permission,
          PermissionStatusId: 1,
        }));
        setPermissions(permissions);
        setIsPermissionsLoaded(true);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getUserRolePermissionsApiLoading]);

  // Handle get Settings API success/failure
  useEffect(() => {
    try {
      if (getSettingsApiSuccess && getSettingsApiResponseCode > 0 && getSettingsApiData != null) {
        const updatedColumns = onboardingColumns.map((column: any) => {
          const apiEntry = getSettingsApiData[0]?.OnBoardingInfo?.find((data: any) => data.columnid === column.ColumnId);
          if (apiEntry) {
            return {
              ...column,
              OnBoardingStatusId: apiEntry.onboardingstatusid,
            };
          }
          return column;
        });
        setOnBoardingColumns(updatedColumns);
        const updatedPermissions = permissions.map((permisssion: any) => {
          const apiEntry = getSettingsApiData[0]?.PermissionInfo?.find((data: any) => data.permissionid === permisssion.PermissionId);
          if (apiEntry) {
            return {
              ...permisssion,
              PermissionStatusId: apiEntry.permission_status,
            };
          }
          return permisssion;
        });
        setPermissions(updatedPermissions);
        dispatch(resetGetSettings());
      } else {
        const updatedColumns = onboardingColumns.map((column: any) => {
          return {
            ...column,
            OnBoardingStatusId: 1,
          };
        });
        setOnBoardingColumns(updatedColumns);
        const updatedPermissions = permissions.map((permission: any) => {
          return {
            ...permission,
            PermissionStatusId: 1,
          };
        });
        setPermissions(updatedPermissions);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getSettingsApiLoading]);

  // Handle Settings Save
  useEffect(() => {
    try {
      if (isSubmit) {
        if (settingsForm.OrganizationID != '') dispatch(saveSettings(settingsForm));
        setIsSubmit(false);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [settingsForm]);

  // Handle Save Settings API success/failure
  useEffect(() => {
    try {
      if (saveSettingsApiSuccess && saveSettingsApiResponseCode > 0) {
        toast.toastSuccess(saveSettingsApiResponseMessage);
        dispatch(resetSaveSettings());
        dispatch(resetOrgTypeNameRoles());
        // ResetToBase();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [saveSettingsApiLoading]);

  // Handle Org Type Change
  const handleOrgTypeChange = (event: any) => {
    try {
      setSelectedOrgType(event);
      setSettingsForm((info: any) => ({
        ...info,
        OrganizationTypeId: event.value,
      }));
      setIsOrgLoaded(false);
      setIsUserRoleLoaded(false);
      setIsPermissionsLoaded(false);
      setOrganizationList([]);
      setSelectedOrganization(null);
      setUserRoleList([]);
      setSelectedUserRole('');
      setShowSettings(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Role Change
  const handleUserRoleChange = (event: any) => {
    try {
      const temp = event.value.split('_');
      const roleId = parseInt(temp[0]);
      const roleTypeId = parseInt(temp[1]);
      setSelectedUserRole(event);
      setSettingsForm((info: any) => ({
        ...info,
        RoleId: roleId,
      }));
      setGetSettingsRequest((info: any) => ({
        ...info,
        RoleId: roleId,
      }));
      setIsUserRoleLoaded(true);
      setIsPermissionsLoaded(false);
      dispatch(getUserRolePermissions({ RoleId: roleId, RoleTypeId: roleTypeId }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Organization Select
  const handleOrganisationSelect = (index: number) => {
    try {
      const prevSelectedIndex = organizationList.findIndex((item: any) => item.selected);
      if (prevSelectedIndex >= 0) {
        const prevSelectedtOrg = organizationList[prevSelectedIndex];
        prevSelectedtOrg.selected = false;
      }
      const currentSelectedOrg = organizationList[index];
      currentSelectedOrg.selected = true;
      setSelectedOrganization(currentSelectedOrg);
      setSettingsForm((info: any) => ({
        ...info,
        OrganizationID: currentSelectedOrg?.id,
      }));
      setGetSettingsRequest((info: any) => ({
        ...info,
        OrganizationID: currentSelectedOrg?.id,
      }));
      setIsOrgLoaded(true);
      setOpen(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Radio Onboarding columns radio group change
  const handleRadioGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      const updatedFields = onboardingColumns.map((field: any) => (field.ColumnId === parseInt(name) ? { ...field, OnBoardingStatusId: parseInt(value) } : field));
      setOnBoardingColumns(updatedFields);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Submit handler to Save Settings.
  const handleSaveSettings = async () => {
    try {
      if (settingsForm.OrganizationID != '') {
        const fieldInfo = onboardingColumns.map((field: any) => ({
          ColumnId: field.ColumnId,
          OnBoardingStatusId: field.OnBoardingStatusId,
        }));
        const permissionInfo = permissions.map((field: any) => ({
          PermissionId: field.PermissionId,
          PermissionStatusId: field.PermissionStatusId,
        }));
        setSettingsForm((info: any) => ({
          ...info,
          FieldInfo: fieldInfo,
          Permission: permissionInfo,
        }));
        setIsSubmit(true);
      } else {
        toast.toastError('No organization has been added to this organization type.');
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Permission Radio Group change
  const handlePermissionRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      const updatedFields = permissions.map((field: any) => (field.PermissionId === parseInt(name) ? { ...field, PermissionStatusId: parseInt(value) } : field));
      setPermissions(updatedFields);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  return (
    <>
      <div className="manageSetting">
        <div className="page-title orgAdd-page-title d-flex justify-content-between align-items-center">
          <h3>
            <FormattedMessage id="HD.ManageAppSettings" />
          </h3>
        </div>
      </div>
      <Row>
        <Col md="4">
          <Select
            label={useIntlActionMessages('Label.OrgType')}
            options={orgTypeList}
            value={selectedOrgType}
            onChange={(e: any) => handleOrgTypeChange(e)}
            placeholder={useIntlMessages('PH.Select')}
          />
        </Col>
      </Row>
      <Row className="d-flex mb-4">
        <Col md="4" className="me-auto">
          <Select
            label={useIntlActionMessages('Label.User')}
            value={selectedUserRole}
            options={userRoleList}
            onChange={(e: any) => handleUserRoleChange(e)}
            placeholder={useIntlMessages('PH.Select')}
          />
        </Col>
        <Col md="auto" className="d-flex align-items-center">
          <div className="custom-berger-menu me-3">
            <Filter
              isOpen={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => setOpen(false)}
              width={450}
              right
              pageWrapId={'filter-wrapper'}
              outerContainerId={'outer-container'}
              customCrossIcon={<img src={Close} />}
              customBurgerIcon={
                <div className="org-selected">
                  {selectedOrganization?.logo && (
                    <img className="thumbnail-image" src={selectedOrganization?.logo ? BUCKET_URL + selectedOrganization?.logo : Orgimg} alt="user pic" />
                  )}
                  <div className="org-details">
                    <h6>{selectedOrganization?.name}</h6>
                    <small>{selectedOrganization?.address}</small>
                  </div>
                </div>
              }
            >
              <div id="filter-wrapper" className="filter-main">
                <h4 className="mb-5">
                  <FormattedMessage id="Hd.Organization" />
                </h4>
                {organizationList?.length > 0 ? (
                  organizationList.map((org: any, index: number) => (
                    <ListCard image={org.logo} label={org.name} sublabel={org.address} selected={org.selected} handleClick={() => handleOrganisationSelect(index)} />
                  ))
                ) : (
                  <h6 className="text-center">{NodataText}</h6>
                )}
              </div>
            </Filter>
          </div>
        </Col>
      </Row>
      {showSettings && (
        <>
          <Row>
            <h4>{useIntlMessages('Label.OnboardingProcess')} </h4>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="border-bottom">
                  <h6>
                    <FormattedMessage id="Students" />
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <Row className="d-flex r-rep-contIssue">
                    {Array.from({ length: 2 }).map((_, colIndex) => (
                      <Col md={6}>
                        <div>
                          {onboardingColumns?.slice(colIndex * itemsPerCol, (colIndex + 1) * itemsPerCol).map((column: any) => (
                            <RadioGroup
                              options={radioOptionsOnboardingColumns}
                              name={column.ColumnId}
                              label={column.ColumnName}
                              value={column.OnBoardingStatusId}
                              onChange={handleRadioGroupChange}
                            ></RadioGroup>
                          ))}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="border-bottom">
                  <h6>
                    <FormattedMessage id="Permissions" />
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  {permissions?.map((item: any) => (
                    <Row className="d-flex r-rep-contIssue">
                      <Col md={10} className="flex-grow-1 ">
                        <Form className="permision-from-check mb-4">
                          {['radio'].map((type) => (
                            <div key={`inline-${type}`} className="d-flex mb-3">
                              <Row>
                                <Col md={12} className="d-flex">
                                  <h6>{item.Permission}</h6>
                                  {radioOptionsPermissions.map((option) => (
                                    <Form.Check
                                      label={option.label}
                                      name={item.PermissionId}
                                      type="radio"
                                      id={`inline-${type}-${item.PermissionId}-1`}
                                      value={option.value}
                                      checked={item.PermissionStatusId === option.value}
                                      onChange={handlePermissionRadioChange}
                                    />
                                  ))}
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </Form>
                      </Col>
                    </Row>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
          <Row>
            <Col lg={12} className="d-flex justify-content-end mt-4 ">
              <Button className="mb-4 btn-cancel" variant="outline-primary" onClick={() => navigate('/home')}>
                <FormattedMessage id="Button.Cancel" />
              </Button>
              <Button className="mb-4 btn-Done" variant="primary" onClick={handleSaveSettings}>
                <FormattedMessage id="Button.Save" />
              </Button>
            </Col>
          </Row>
        </>
      )}
      {!showSettings && (
        <div className="content-sub content-area-padding border-top text-center p-4">
          <h6>Select Organization Type, User Role and Organization to view results.</h6>
        </div>
      )}
      {(getSettingsApiLoading || getUserRolePermissionsApiLoading) && <Loader />}
    </>
  );
};
export default ManageappSettings;
