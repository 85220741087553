/**
 * @file   src\containers\organizations\MyOrganization.tsx
 * @brief  MY Organization page.
 * @date   Feb, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useEffect, useState } from 'react';
import '../../assets/css/org.scss';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Breadcrumb, Nav, NavDropdown, Button, Table } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import More from '../../assets/img/More.svg';
import { end } from '@popperjs/core';
import { useNavigate } from 'react-router-dom';
import Delete from '../../assets/img/icon/Delete';
import PlanList from './TeacherPlanListCard';
import CreditCard from '../../assets/img/CreditCard.svg';
import { RootState } from '../../store';
import Loader from '../../components/Loader';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getOrganizationInfo } from '../../store/actions/organizationActions';
import { getFromLocalStorage, isOrgAdmin, isOrgAdminManager, getCurrentOrgDetails } from '../../utils/helper';
import { BUCKET_URL, DATE_FORMAT } from '../../utils/constants';
import Default from '../../assets/img/default.jpg';
import { getCardsList, getBillingHistory, listSubscriptionPlans, cancelSubscriptionPlan, deletePaymentMethod } from '../../store/actions/profileActions';
import moment from 'moment';
import { IBillingInfo, ICardInfo } from '../../interfaces/ProfileInterface';
import { getSubscriptionPlanDetails } from '../../store/actions/subscriptionPlanActions';
import { IPlanInfo } from '../../interfaces/ProfileInterface';
import {
  resetSubscriptionPayment,
  resetCardsBillingHistory,
  resetSubscriptionList,
  resetAddPaymentMethod,
  resetDeletePaymentCancelSubscription,
} from '../../store/slices/profileSlice';
import { MessageToaster } from '../../utils/ToastUtil';
import PaymentPopUp from './PaymentPopUp';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import DeleteConfirmation from '../../components/DeleteConfirmation';
import { RoleTypeIds } from '../../utils/enums';
import { useIntlMessages } from '../../utils/helper';
import { Scrollbar } from 'react-scrollbars-custom';
// stripe key
const stripePromise = loadStripe('pk_test_51PL5J12MewYdLBpvyz9pNUA7jmNkuzBHD77nw0xcAqfV2govTcf0ju857qo8rs5mMTWmlkM5tNNtn08xRp8sbhuw00p9ISbzoN');

const MyOrganization = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Action dispatch object creation
  const dispatch = useAppDispatch();
  // Message toaster object creation
  const toastObj = new MessageToaster();
  const userData = getFromLocalStorage('MI_USR_DATA');

  // Access redux state variables
  const { organizationDetailApiData, organizationDetailApiLoading, organizationDetailApiSuccess } = useAppSelector((state: RootState) => state.organization);
  const {
    getCardsListApiData,
    getCardsListApiLoading,
    getCardsListApiSuccess,
    getBillingHistoryApiData,
    getBillingHistoryApiLoading,
    getBillingHistoryApiSuccess,
    getSubscriptionPlansData,
    getSubscriptionPlansLoading,
    getSubscriptionPlansSuccess,
    addSubscriptionPaymentApiLoading,
    addSubscriptionPaymentApiResponseMessage,
    addSubscriptionPaymentApiSuccess,
    addPaymentMethodApiLoading,
    addPaymentMethodApiSuccess,
    addPaymentMethodApiResponseMessage,
    cancelSubscriptionApiLoading,
    cancelSubscriptionApiResponseMessage,
    cancelSubscriptionApiSuccess,
    deletePaymentMethodApiLoading,
    deletePaymentMethodApiSuccess,
    deletePaymentMethodApiResponseMessage,
  } = useAppSelector((state: RootState) => state.profile);
  const { subscriptionPlanDetailApiData, subscriptionPlanDetailApiLoading } = useAppSelector((state: RootState) => state.subscriptionPlan);

  const [show, setShow] = useState<boolean>(false);
  const [cancelPopUp, setCancelPopUp] = useState<boolean>(false);
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // Intial loading - call to fetch required details
  useEffect(() => {
    try {
      dispatch(getOrganizationInfo({ OrganizationId: userData.OrganizationId }));
      if (isOrgAdminManager()) {
        dispatch(getBillingHistory({ Page: 0 }));
      }
      window.scrollTo(0, 0);
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // fetch current subscription plan details of organization
  useEffect(() => {
    try {
      if (organizationDetailApiSuccess && organizationDetailApiData.SubscriptionPlanID !== null && isOrgAdmin()) {
        dispatch(
          getSubscriptionPlanDetails({
            PlanID: organizationDetailApiData.SubscriptionPlanID,
          }),
        );
        dispatch(getCardsList({ OrganizationId: userData.OrganizationId }));
      } else if (organizationDetailApiSuccess && organizationDetailApiData.SubscriptionPlanID === null && isOrgAdmin()) {
        dispatch(listSubscriptionPlans({ OrganizationId: userData.OrganizationId, Page: 0 }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationDetailApiLoading]);
  // reset data on unmounting
  useEffect(() => {
    try {
      return () => {
        dispatch(resetSubscriptionPayment());
        dispatch(resetCardsBillingHistory());
        dispatch(resetSubscriptionList());
        dispatch(resetAddPaymentMethod());
        dispatch(resetDeletePaymentCancelSubscription());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // handle subscription plan payment success.
  useEffect(() => {
    try {
      if (!addSubscriptionPaymentApiLoading && addSubscriptionPaymentApiSuccess) {
        toastObj.toastSuccess(addSubscriptionPaymentApiResponseMessage);
        navigate('/successfullysubscribed');
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [addSubscriptionPaymentApiLoading]);
  // handle add payment method success
  useEffect(() => {
    try {
      if (addPaymentMethodApiSuccess) {
        toastObj.toastSuccess(addPaymentMethodApiResponseMessage);
        dispatch(getCardsList({ OrganizationId: userData.OrganizationId }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [addPaymentMethodApiLoading]);
  // handle cancel subscription success and error response
  useEffect(() => {
    try {
      if (cancelSubscriptionApiSuccess && userData.RoleTypeId === RoleTypeIds.ORGANIZATION_MANGER) {
        toastObj.toastSuccess(cancelSubscriptionApiResponseMessage);
        dispatch(getOrganizationInfo({ OrganizationId: userData.OrganizationId }));
      } else if (cancelSubscriptionApiSuccess && userData.RoleTypeId === RoleTypeIds.ORGANIZATION_ADMIN) {
        toastObj.toastSuccess(cancelSubscriptionApiResponseMessage);
        dispatch(getOrganizationInfo({ OrganizationId: getCurrentOrgDetails().OrganizationID }));
      } else if (!cancelSubscriptionApiLoading && !cancelSubscriptionApiSuccess && cancelSubscriptionApiResponseMessage.length > 0) {
        toastObj.toastError(cancelSubscriptionApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [cancelSubscriptionApiLoading]);

  useEffect(() => {
    try {
      if (deletePaymentMethodApiSuccess && userData.RoleTypeId === RoleTypeIds.ORGANIZATION_MANGER) {
        toastObj.toastSuccess(deletePaymentMethodApiResponseMessage);
        dispatch(getCardsList({ OrganizationId: userData.OrganizationId }));
      } else if (deletePaymentMethodApiSuccess && userData.RoleTypeId === RoleTypeIds.ORGANIZATION_ADMIN) {
        toastObj.toastSuccess(deletePaymentMethodApiResponseMessage);
        dispatch(getCardsList({ OrganizationId: getCurrentOrgDetails().OrganizationID }));
      } else if (!deletePaymentMethodApiLoading && !deletePaymentMethodApiSuccess && deletePaymentMethodApiResponseMessage.length > 0) {
        toastObj.toastError(deletePaymentMethodApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [deletePaymentMethodApiLoading]);
  // show add payment method popup
  const showAddPaymentPopup = () => {
    try {
      setShow(true);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // show cancel subscription popup
  const showCancelSubPopUp = () => {
    try {
      setCancelPopUp(true);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // call to cancel subscription api
  const confirmCancelSubscription = () => {
    try {
      setCancelPopUp(false);
      if (userData.RoleTypeId === RoleTypeIds.ORGANIZATION_MANGER) {
        dispatch(
          cancelSubscriptionPlan({
            SubscriptionPlanID: subscriptionPlanDetailApiData.SubscriptionPlanID,
            OrganizationID: userData.OrganizationId,
          }),
        );
      } else {
        dispatch(
          cancelSubscriptionPlan({
            SubscriptionPlanID: subscriptionPlanDetailApiData.SubscriptionPlanID,
            OrganizationID: getCurrentOrgDetails().OrganizationID,
          }),
        );
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // show delete payment method popup
  const showDeletePopUp = (id: number) => {
    try {
      setDeletePopUp(true);
      setDeleteId(id);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // call delete payment method api
  const confirmDeletePaymentMethod = () => {
    try {
      setDeletePopUp(false);
      dispatch(
        deletePaymentMethod({
          ID: deleteId,
        }),
      );
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // redirect to edit organization page
  const handleEditOrganization = () => {
    try {
      if (userData.RoleTypeId === RoleTypeIds.ORGANIZATION_MANGER) {
        navigate('/editorganization', { state: { organizationId: userData.OrganizationId } });
      } else if (userData.RoleTypeId === RoleTypeIds.ORGANIZATION_ADMIN) {
        navigate('/editorganization', { state: { organizationId: getCurrentOrgDetails().OrganizationID } });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <>
      {(organizationDetailApiLoading ||
        getCardsListApiLoading ||
        getSubscriptionPlansLoading ||
        addSubscriptionPaymentApiLoading ||
        getBillingHistoryApiLoading ||
        deletePaymentMethodApiLoading ||
        subscriptionPlanDetailApiLoading) && <Loader />}
      <DeleteConfirmation
        show={cancelPopUp}
        onCancel={() => setCancelPopUp(false)}
        onOkay={confirmCancelSubscription}
        handleClose={() => setCancelPopUp(false)}
        title="Cancel Subscription"
        content="After cancelling the subscription, you will not be able to add new users to this organization. Consider subscribing to a new plan to add users. Are you sure you want to cancel the current subscription plan?"
      />
      <DeleteConfirmation
        show={deletePopUp}
        onCancel={() => setDeletePopUp(false)}
        onOkay={confirmDeletePaymentMethod}
        handleClose={() => setDeletePopUp(false)}
        title="Delete Payment Method"
        content="Are you sure you want to delete the payment method?"
      />
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Hd.MyOrganization" />
        </h3>
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item active>
            <FormattedMessage id="Hd.MyOrganization" />
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-sub">
        <div className="content-sub-header d-flex justify-content-between align-items-center">
          <div>
            <h4>
              <FormattedMessage id="Hd.OrganizationInfo" />
            </h4>
          </div>
          <Col xs="auto">
            {userData.RoleTypeId === RoleTypeIds.APP_USER_MANAGER ? null : (
              <Nav>
                <NavDropdown align={end} className="icon-btn-more" title={<img src={More} alt="more" />}>
                  <NavDropdown.Item onClick={handleEditOrganization}>
                    <FormattedMessage id="Link.EditOrganizationInfo" />
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            )}
          </Col>
        </div>
        <div className="content-area-padding d-flex justify-content-between align-items-center">
          <Row className="d-flex align-items-center">
            <Col className="org-img">
              <img
                src={organizationDetailApiData && organizationDetailApiData?.ImageURL ? BUCKET_URL + organizationDetailApiData?.ImageURL : Default}
                alt="more"
                className="w-100"
              />
            </Col>
            <Col className="org-details">
              <h5 className="mb-2">{organizationDetailApiData && organizationDetailApiData.Name && organizationDetailApiData.Name} </h5>
              <div className="phone">
                {organizationDetailApiData && organizationDetailApiData.CountryCode && organizationDetailApiData.CountryCode}{' '}
                {organizationDetailApiData && organizationDetailApiData.PhoneNo && organizationDetailApiData.PhoneNo}
              </div>
              <div className="phone">{organizationDetailApiData && organizationDetailApiData.Address && organizationDetailApiData.Address}</div>
            </Col>
          </Row>
        </div>
        <div className="content-area-padding border-top">
          <Row className="label-with-content">
            <Col xl lg={3} md={4}>
              <label>
                <FormattedMessage id="Label.address" />
              </label>
              <p>{organizationDetailApiData && organizationDetailApiData.Address && organizationDetailApiData.Address}</p>
            </Col>
            <Col xl lg={3} md={4}>
              <label>
                <FormattedMessage id="Label.City" />
              </label>
              <p>{organizationDetailApiData && organizationDetailApiData.City && organizationDetailApiData.City}</p>
            </Col>
            <Col xl lg={3} md={4}>
              <label>
                <FormattedMessage id="Label.State" />
              </label>
              <p>{organizationDetailApiData && organizationDetailApiData.State && organizationDetailApiData.State}</p>
            </Col>
            <Col xl lg={3} md={4}>
              <label>
                <FormattedMessage id="Label.Zip" />
              </label>
              <p>{organizationDetailApiData && organizationDetailApiData.Zip && organizationDetailApiData.Zip}</p>
            </Col>
            <Col xl lg={3} md={4}>
              <label>
                <FormattedMessage id="Label.Status" />
              </label>
              <p>{organizationDetailApiData && organizationDetailApiData.Status && organizationDetailApiData.Status}</p>
            </Col>
            <Col xl lg={3} md={4}>
              <label>
                <FormattedMessage id="Label.SubscriptionPlan" />
              </label>
              <p>
                {organizationDetailApiData && organizationDetailApiData.SubscriptionPlan && organizationDetailApiData.SubscriptionPlan} (
                {organizationDetailApiData && organizationDetailApiData.SubscriptionPlanStatus && organizationDetailApiData.SubscriptionPlanStatus})
              </p>
            </Col>
          </Row>
        </div>
      </div>
      {organizationDetailApiData && organizationDetailApiData.SubscriptionPlanID && organizationDetailApiData.SubscriptionPlanID !== null && isOrgAdminManager() && (
        <Row>
          <Col>
            <div className="content-sub">
              <div className="content-sub-header">
                <h4>Subscription Details</h4>
              </div>
              <div className="content-area-padding planlist-myorganization">
                <PlanList
                  save="60"
                  duration={subscriptionPlanDetailApiData && subscriptionPlanDetailApiData.DurationLabel && subscriptionPlanDetailApiData.DurationLabel}
                  price={subscriptionPlanDetailApiData && subscriptionPlanDetailApiData.Amount && subscriptionPlanDetailApiData.Amount}
                  bills="$50 Billed Monthly"
                  plantype={subscriptionPlanDetailApiData && subscriptionPlanDetailApiData.DurationLabel && subscriptionPlanDetailApiData.DurationLabel}
                  discount={subscriptionPlanDetailApiData && subscriptionPlanDetailApiData.Discount && subscriptionPlanDetailApiData.Discount}
                  context="Cancel"
                  webusersno={subscriptionPlanDetailApiData && subscriptionPlanDetailApiData.NoOfWebUsers && subscriptionPlanDetailApiData.NoOfWebUsers}
                  appusersno={subscriptionPlanDetailApiData && subscriptionPlanDetailApiData.NoOfMobAppUsers && subscriptionPlanDetailApiData.NoOfMobAppUsers}
                  onCancelSub={showCancelSubPopUp}
                  isCancelled={organizationDetailApiData.IsSubscriptionCancelled}
                />
              </div>
            </div>
          </Col>
          <Col>
            <div className="content-sub payment-method-main">
              <div className="content-sub-header d-flex justify-content-between align-items-center">
                <h4>Payment Method</h4>
                <Button variant="primary" size="sm" onClick={showAddPaymentPopup}>
                  <FormattedMessage id="Button.AddNew" />
                </Button>
              </div>
              <div className="content-area-padding">
                <Scrollbar className="h-227px">
                  {getCardsListApiSuccess &&
                    getCardsListApiData &&
                    getCardsListApiData.List.map((card: ICardInfo) => (
                      <div className="content-area-padding payment-methods p-2 mb-2">
                        <Row className="align-items-center" key={card.Id}>
                          <Col sm="auto">{card.CardName}</Col>
                          <Col>
                            <img src={CreditCard} /> XXXX XXXX XXXX {card.CardNumber}
                          </Col>
                          <Col sm="auto">
                            <Button variant="dark" size="sm" className="icon-btn" onClick={() => showDeletePopUp(card.Id)}>
                              <Delete />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))}
                </Scrollbar>
              </div>
            </div>
          </Col>
        </Row>
      )}
      <Col>
        {getSubscriptionPlansSuccess && organizationDetailApiData.SubscriptionPlanID === null && getSubscriptionPlansData?.SubscriptionPlans && (
          <div className="content-sub">
            <div className="content-sub-header">
              <h4>Subscription Details</h4>
            </div>
            <div className="content-area-padding">
              <Row className="justify-content-center">
                {getSubscriptionPlansData.SubscriptionPlans.map((plan: IPlanInfo) => (
                  <Col xl={3} lg={4} md={4}>
                    <PlanList
                      duration={plan.PlanType}
                      price={plan.Amount}
                      plantype={plan.PlanType}
                      appusersno={plan.NoOfMobAppUsers}
                      webusersno={plan.NoOfWebUsers}
                      planid={plan.PlanID}
                      discount={plan.Discount}
                      context="Subscribe"
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        )}
      </Col>
      {organizationDetailApiData && organizationDetailApiData.SubscriptionPlanID && organizationDetailApiData.SubscriptionPlanID !== null && isOrgAdminManager() && (
        <div className="content-sub">
          <div className="content-sub-header d-flex justify-content-between align-items-center">
            <div>
              <h4>Billing History</h4>
            </div>
          </div>
          <div className="content-area-padding">
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="Label.Date" />
                  </th>
                  <th>
                    <FormattedMessage id="Label.Details" />
                  </th>
                  <th>
                    <FormattedMessage id="Label.NextBillingDate" />
                  </th>
                  <th>
                    <FormattedMessage id="Label.PaymentStatus" />
                  </th>
                  <th>
                    <FormattedMessage id="Label.Amount" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {getBillingHistoryApiSuccess &&
                  getBillingHistoryApiData &&
                  getBillingHistoryApiData.List.map((billing: IBillingInfo) => (
                    <tr key={billing.PaymentId}>
                      <td>{moment(billing.PaymentDate).local().format(DATE_FORMAT)}</td>
                      <td>
                        {billing.PlanName} - {moment(billing.PaymentDate).local().format(DATE_FORMAT)} - {moment(billing.NextBilliingDate).local().format(DATE_FORMAT)}
                      </td>
                      <td>{moment(billing.NextBilliingDate).local().format(DATE_FORMAT)}</td>
                      <td>{billing.PaymentStatus}</td>
                      <td>
                        <span className="text-teal">${billing.Amount}</span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}
      <Elements stripe={stripePromise}>
        <PaymentPopUp show={show} setShow={setShow} context="add" />
      </Elements>
    </>
  );
};
export default MyOrganization;
