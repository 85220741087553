/**
 * @file   src\containers\organizations\SurveyStep2.tsx
 * @brief  Survey add page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Upload from '../../assets/img/icon/Upload';
import Download from '../../assets/img/Download.svg';
import AddIcon from '../../assets/img/icon/Add';
import SurveyThumb from '../../assets/img/icon/SurveyThumb';
import AttachmentCard from '../../components/AttachmentCard';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { getSurveyFormList, deleteSurveyForm, finishSurvey, uploadSurveyForm } from '../../store/actions/surveyAction';
import Loader from '../../components/Loader';
import { MessageToaster } from '../../utils/ToastUtil';
import DeleteConfirmPopup from '../../components/DeleteConfirmation';
import { useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import { resetDeleteForm } from '../../store/slices/surveySlice';
import { uploadFile } from '../../store/actions/organizationActions';
import { resetUploadFile } from '../../store/slices/organizationSlice';
import { BUCKET_URL } from '../../utils/constants';
import { RoleTypeIds } from '../../utils/enums';

const SurveyStep2 = (props: any) => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Message toaster object creation
  const toastObj = new MessageToaster();

  const surveyFormError = useIntlActionMessages('Survey.Form.Error');

  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Access redux state variables.
  const {
    surveyFormListApiData,
    surveyFormListApiLoading,
    surveyFormListApiSuccess,
    deleteSurveyFormApiLoading,
    deleteSurveyFormApiResponseMessage,
    deleteSurveyFormApiSuccess,
    finishSurveyApiLoading,
    finishSurveyApiResponseCode,
    finishSurveyApiResponseMessage,
    finishSurveyApiSuccess,
    uploadTemplateApiLoading,
    uploadTemplateApiSuccess,
    uploadTemplateApiResponseCode,
  } = useAppSelector((state: RootState) => state.survey);

  const { uploadFileApiLoading, uploadFileResponseCode, uploadFileApiSuccess, uploadFileApiData } = useAppSelector((state: RootState) => state.organization);
  // Accessing redux state variables
  const { rolesByOrgTypeData } = useAppSelector((state: RootState) => state.organizationType);

  const [isShowDeleteConfirmPopup, setIsShowDeleteConfirmPopup] = useState<boolean>(false);
  const [selectedFormId, setSelectedFormId] = useState<number | null>(null);
  const [completeSurvey, setCompleteSurvey] = useState<boolean>(false);
  const [upTemplate, setUpTemplate] = useState<string | null>(null);
  const [upTemplateDetails, setUpTemplateDetails] = useState<any>({});
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');

  useEffect(() => {
    try {
      window.scrollTo(0, 0);

      // Get category list based on organization
      dispatch(getSurveyFormList({ SurveyId: +props.surveyId }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [deleteSurveyFormApiSuccess]);

  // show  delete form api success message and error message
  useEffect(() => {
    try {
      if (deleteSurveyFormApiSuccess) {
        setIsShowDeleteConfirmPopup(false);
        dispatch(resetDeleteForm());
        toastObj.toastSuccess(deleteSurveyFormApiResponseMessage);
      } else if (!deleteSurveyFormApiSuccess && deleteSurveyFormApiResponseMessage.length > 0) {
        toastObj.toastError(deleteSurveyFormApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [deleteSurveyFormApiLoading]);

  // handle finish survey api response
  useEffect(() => {
    try {
      if (completeSurvey && finishSurveyApiResponseCode > 0 && finishSurveyApiSuccess) {
        // Redirect to survey listing
        setCompleteSurvey(false);
        toastObj.toastSuccess(finishSurveyApiResponseMessage);
        navigate('/activities', { state: { tabId: 2 } });
      } else if (!finishSurveyApiSuccess && completeSurvey && finishSurveyApiResponseMessage.length > 0) {
        toastObj.toastError(finishSurveyApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [finishSurveyApiResponseCode, finishSurveyApiSuccess]);

  useEffect(() => {
    try {
      if (uploadFileResponseCode > 0 && uploadFileApiSuccess) {
        uploadTemplateToS3();
        dispatch(resetUploadFile());
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFileApiLoading]);

  useEffect(() => {
    try {
      if (uploadTemplateApiResponseCode > 0 && uploadTemplateApiSuccess) {
        dispatch(getSurveyFormList({ SurveyId: props.surveyId }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadTemplateApiLoading]);

  // handle delete survey form
  const deleteSurveyForms = () => {
    try {
      const apiParam = { FormId: selectedFormId, SurveyId: +props.surveyId };
      dispatch(deleteSurveyForm(apiParam));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle delete confirmation
  const ShowDeleteConfirmPopup = (formId: number) => {
    try {
      setIsShowDeleteConfirmPopup(true);
      setSelectedFormId(formId);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle survey finish step 2
  const handleFinishSurvey = async () => {
    try {
      if (surveyFormListApiSuccess && surveyFormListApiData?.TotalCount > 0) {
        setCompleteSurvey(true);
        const finishParams = { SurveyId: +props.surveyId };
        dispatch(finishSurvey(finishParams));
      } else {
        toastObj.toastError(surveyFormError);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // set file selected
  const uploadTemplate = (e: any) => {
    try {
      const file = e.target.files[0];
      setUpTemplate(file);
      const imgInfo = {
        name: file.name,
        type: file.type,
      };
      setUpTemplateDetails(imgInfo);
      const formData = new FormData();
      formData.append('UploadExtension', file.type);
      formData.append('UploadObjectKey', file.name);
      formData.append('UploadType', 'bulksurveyform_upload');
      dispatch(uploadFile(formData));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Put file to bucket after getting the path
  const uploadTemplateToS3 = async () => {
    try {
      const uploadURL = uploadFileApiData;
      if (uploadURL) {
        // PUT request: upload file to S3
        const result = await fetch(uploadURL, {
          method: 'PUT',
          body: upTemplate,
        });
        if (result.status == 200) {
          const uploadTemplateData = { SurveyId: props.surveyId, S3_FileName: upTemplateDetails.name };
          dispatch(uploadSurveyForm(uploadTemplateData));
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // get the label for tabs
  const getAppUserLabel = (userLevel: number) => {
    let tabLabel = '';
    try {
      if (rolesByOrgTypeData.length > 0) {
        const element = rolesByOrgTypeData.filter((role: any) => userLevel === role.RoleTypeID);
        tabLabel = element.length === 1 ? element[0].Name : '';
      } else {
        tabLabel = '';
      }
    } catch (error) {
      console.log('Error: ', error);
    }
    return tabLabel;
  };
  return (
    <>
      <div className="content-sub">
        <div className="content-area-padding">
          <div className="d-flex justify-content-center mb-3">
            <Col xl={6} lg={7}>
              <h3 className="text-center">
                <FormattedMessage id="Hd.AddForm" />
              </h3>
              <p className="text-center">
                <FormattedMessage id="SubHd.DownloadtemplateDesc1" />
                {getAppUserLabel(RoleTypeIds.APP_USER)}
                <FormattedMessage id="SubHd.DownloadtemplateDesc2" />
              </p>
            </Col>
          </div>
          <div className="d-flex justify-content-center mb-4">
            <Col xl={6} lg={7}>
              <Row className="justify-content-center">
                <Col xl={4}>
                  <div className="fileupload-sec fileupload-survey userbulk-label dropzone  mb-3">
                    <input
                      type="file"
                      name="file-3[]"
                      id="file-3"
                      className="inputfile inputfile-3"
                      data-multiple-caption="{count} files selected"
                      multiple={false}
                      accept=".xls, .xlsx, .csv, .ods, .xlsm"
                      onChange={(e: any) => uploadTemplate(e)}
                    />
                    <label htmlFor="file-3">
                      <Upload />
                      <span>{useIntlActionMessages('Label.Upload.Template')}</span>
                    </label>
                  </div>
                </Col>
                <Col xl={4}>
                  <Button variant="secondary" className="btn-icon-top w-100" onClick={() => navigate('/surveyform/' + props.surveyId)}>
                    <span>
                      <AddIcon />
                    </span>
                    <FormattedMessage id="Button.AddNew" />
                  </Button>
                </Col>
              </Row>
              <Row className="d-flex align-items-center mt-2">
                <Col md="auto">
                  <a href={`${BUCKET_URL}surveyform_bulkupload/sample.xlsx`} className="download-icn" target="_blank" rel="noopener noreferrer">
                    <img src={Download} alt="Download" />
                    {useIntlActionMessages('Label.Download.Template')}
                  </a>
                </Col>
                <Col className="border-start mt-2">
                  <small className="d-inline-block">
                    <FormattedMessage id="Label.DownloadContent" />
                  </small>
                </Col>
              </Row>
            </Col>
          </div>
          <div>
            {surveyFormListApiSuccess && surveyFormListApiData.List.length > 0 && (
              <>
                <h6 className="text-center">
                  <FormattedMessage id="Label.Attachments" />{' '}
                </h6>
                <div className="attachment-list d-flex justify-content-center mt-3">
                  <Col xl={10}>
                    <Row className="d-flex justify-content-center">
                      <Col xl={6}>
                        {surveyFormListApiData.List.map((form: any) => (
                          <AttachmentCard
                            fileType={<SurveyThumb />}
                            filename={form.FormTitle}
                            editOption
                            deleteOption
                            onDelete={() => ShowDeleteConfirmPopup(form.FormId)}
                            onEdit={() => navigate('/surveyform/' + props.surveyId + '/' + form.FormId)}
                          />
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </div>
              </>
            )}
          </div>
          <div className="attachment-list d-flex justify-content-center mt-3">
            <Col xl={10}>
              <div className="btn-container d-flex justify-content-end my-4">
                <Button variant="outline-primary" onClick={() => navigate('/activities', { state: { tabId: 2 } })}>
                  <FormattedMessage id="Button.Cancel" />
                </Button>
                <Button variant="primary" onClick={handleFinishSurvey}>
                  <FormattedMessage id="Button.Save" />
                </Button>
              </div>
            </Col>
          </div>
        </div>
        <DeleteConfirmPopup
          show={isShowDeleteConfirmPopup}
          handleClose={() => setIsShowDeleteConfirmPopup(false)}
          onCancel={() => setIsShowDeleteConfirmPopup(false)}
          onOkay={() => deleteSurveyForms()}
          title={useIntlActionMessages('Text.DeleteConfirmation.SurveyForm')}
          content={useIntlActionMessages('Text.DeleteConfirmation.SurveyForm.Desc')}
        />
        {(surveyFormListApiLoading || deleteSurveyFormApiLoading || finishSurveyApiLoading) && <Loader />}
      </div>
    </>
  );
};
export default SurveyStep2;
