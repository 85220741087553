/**
 * @file   src\containers\organizations\TaskDetails.tsx
 * @brief  Task details page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { FormattedMessage } from 'react-intl';
import { Col, Row, Button } from 'react-bootstrap';
import { slide as Filter } from 'react-burger-menu';
import Close from '../../assets/img/Close.svg';
import Select from '../../components/MASelect';

const CategoryFilter = (props: any) => {
  return (
    <>
      <Filter
        onOpen={props.handleFilterClick}
        onClose={props.handleFilterClose}
        isOpen={props.isOpenFilter}
        width={450}
        right
        pageWrapId={'filter-wrapper'}
        outerContainerId={'outer-container'}
        customCrossIcon={<img src={Close} />}
        burgerButtonClassName={props.isFilterApplied ? 'active' : ''}
      >
        <div id="filter-wrapper" className="filter-main">
          <h4 className="mb-5">
            <FormattedMessage id="Hd.Filter" />
          </h4>
          <Row>
            <Col>
              <Select label="Category" options={props.categoryList} value={props.selectedCategory} placeholder="Select" onChange={(e: any) => props.handleCategoryChange(e)} />
            </Col>
            <Col>
              <Select
                label="Sub Category"
                id="subCategory"
                name="subCategory"
                options={props.subCategoryList}
                value={props.selectedSubCategory}
                placeholder="Select"
                onChange={(e: any) => props.handleSubCategoryChange(e)}
                isMulti
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Button variant="outline-primary" className="w-100" onClick={props.handleFilterReset}>
                <FormattedMessage id="Button.ClearFilter" />
              </Button>
            </Col>
            <Col>
              <Button variant="primary" className="w-100" onClick={props.handleApplyFilter}>
                <FormattedMessage id="Button.ApplyFilters" />
              </Button>
            </Col>
          </Row>
        </div>
      </Filter>
    </>
  );
};
export default CategoryFilter;
