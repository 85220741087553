/**
 * @file   src\utils\apiUrls.ts
 * @brief  This file is for defining api methods.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { AUTH_API_URL, ADMIN_API_URL, APP_USER_URL } from './constants';

const authApiUrl = AUTH_API_URL;
const adminApiUrl = ADMIN_API_URL;
const appUserUrl = APP_USER_URL;

// Dashboard api urls
export enum DashboardApi {
  ORGANIZATIONTYPE_LIST = 'https://fakestoreapi.com/products',
  // ORGANIZATIONTYPE_LIST = '/admin/organizationtypelist',
  USERS_COUNT_LIST = adminApiUrl + '/admin/get-org-type-wise-user-summary',
  OVERALL_PERFORMANCE = adminApiUrl + '/admin/get-organizations-overall-performance',
  CONTACT_ADMIN = adminApiUrl + '/admin/contact-admin',
  CATEGORY_WISE_OVERALL_PERFORMANCE = adminApiUrl + '/admin/get-organizations-subcategories-wise-performance',
  GUEST_CONTACT_ADMIN = adminApiUrl + '/admin/guest-contact-admin',
}
export enum AuthApis {
  LOGIN = authApiUrl + '/auth/login',
  REFRESH_TOKEN = authApiUrl + '/auth/refreshtoken',
  CHANGE_PASSWORD = authApiUrl + '/auth/changepassword',
  FORGOT_PAASSWORD = authApiUrl + '/auth/forgotpassword',
  RESET_PASSWORD = authApiUrl + '/auth/verify-email-and-change-password',
}
export enum CommonApis {
  STATES = authApiUrl + '/common/getallstates',
  CITIES = authApiUrl + '/common/getcities',
  FILE_UPLOAD = authApiUrl + '/common/v2/generateS3PresignedUrl',
  FILE_UPLOAD_PATH_UPDATE = authApiUrl + '/common/v2/update-asset-path',
  CATEGORY_LIST = authApiUrl + '/common/category-details-by-organization',
  FILE_UPLOAD_PATH_UPDATE_MULTIPLE = authApiUrl + '/common/multiple-assets-update',
  SUB_CATEGORY_LIST = authApiUrl + '/common/list-subcategories',
}
// User api urls
export enum UserApis {
  LIST_USERS = adminApiUrl + '/admin/listuser',
  ADD_SINGLE_USER = adminApiUrl + '/admin/addsingleuser',
  VIEW_USERS = adminApiUrl + '/admin/userdetails',
  ADD_SYSTEM_ADMIN = authApiUrl + '/auth/add-system-admin',
  GET_PROFILE_SETTINGS = adminApiUrl + '/admin/get-settings',
  USER_BULK_UPLOAD = adminApiUrl + '/admin/add-user-bulk-upload',
  ACC_PARTNERS_FOR_STUDENTS = adminApiUrl + '/admin/admin-view-of-mentor-allotted-to-user',
  ASSIGN_ORGS = adminApiUrl + '/admin/assign-org-to-orgAdmin',
  ORG_LIST_FOR_ADMINS = adminApiUrl + '/admin/list-organizations-assigned-to-orgAdmin',
  STUDENTS_FOR_ACPARTNERS = adminApiUrl + '/admin/admin-view-of-list-users-under-mentors',
  STUDENT_TAGS = adminApiUrl + '/admin/get-user-tags',
  ADD_KUDOS_POINTS = adminApiUrl + '/admin/add-kudos',
  LIST_KUDOS_POINTS = authApiUrl + '/common/list-kudos-points',
  SHOW_KUDOS_POINTS = adminApiUrl + '/admin/get-kudos-points-by-student-id',
  LIST_SURVEYS_APP_USER = adminApiUrl + '/admin/get-survey-list-for-appuser',
  LIST_GOALS_APP_USER = adminApiUrl + '/admin/list-all-goals-assigned-to-appuser',
  LIST_TASK_EVENTS_APPUSER = adminApiUrl + '/admin/get-task-and-event-list-of-appuser',
  GET_PROOF_COUNT = adminApiUrl + '/admin/get-proof-count',
  LIST_PROOFS = adminApiUrl + '/admin/get-proof-list',
  LIST_KUDOS_LEVELS = authApiUrl + '/common/list-user-badge-level-specifics',
  USER_COUNT_FOR_SUBSCRIPTION = adminApiUrl + '/admin/available-user-limit',
}

export enum OrganizationApi {
  SAVE_ORGANIZATION = adminApiUrl + '/admin/v2/saveorganization',
  UPDATE_ORGANIZATION = adminApiUrl + '/admin/editorganization',
  LIST_ORGANIZATIONS = adminApiUrl + '/admin/listorganization',
  DELETE_ORGANIZATION = adminApiUrl + '/admin/deleteorganization',
  DELETE_CATEGORY = adminApiUrl + '/admin/deletecategories',
  ORGANIZATION_INFO = adminApiUrl + '/admin/vieworganization',
  SUBSCRIPTION_PLANS = adminApiUrl + '/admin/subscription-plans',
  STUDENT_PRODUCTIVITY = adminApiUrl + '/admin/get-organization-productivity',
  STUDENT_PRODUCTIVE_TIME = adminApiUrl + '/admin/get-organization-productive-time',
}
export enum OrganizationTypeApis {
  LIST_ORGANIZATIONTYPE = '/admin/listorganizationtypes',
  LIST_ORGANIZATIONTYPE_DROPDOWN = '/admin/getOrganizationTypes',
  ADD_ORGANIZATIONTYPE = '/admin/saveorganizationtype',
  UPDATE_ORGANIZATIONTYPE = '/admin/edit-organization-type',
  ROLES_BY_ORG_TYPE = '/admin/roles-by-organization-type',
  VIEW_ORGANIZATIONTYPE = '/admin/viewOrganizationTypeDetials',
  ORGANIZATION_NAME_BY_ORG_TYPE = '/admin/organizations-by-type',
  CATEGORY_DETAILS_BY_ORG_ID = '/admin/category-details-by-organization',
  LIST_ORGANIZATIONS_BY_ORG_TYPE = '/admin/vieworganizationinfo-by-organization-type',
  TAGS_BY_ORG_TYPE = '/admin/get-user-tags',
}
// Resource api urls
export enum ResourceApis {
  ADD_RESOURCE = authApiUrl + '/common/add-resources',
  EDIT_RESOURCE = authApiUrl + '/common/edit-resources',
  VIEW_RESOURCE = authApiUrl + '/common/resource-details',
  LIST_MOTIVATIONALRESOURCE = authApiUrl + '/common/list-motivational',
  LIST_INCENTIVE_RESOURCE = authApiUrl + '/common/list-incentives',
  LIST_GOAL_INCENTIVE_RESOURCE = authApiUrl + '/common/search-incentives-for-link-incentives',
  LIST_REPORTED_RESOURCE = authApiUrl + '/common/list-reported-contents',
  VIEW_ALL_MOTIVATIONAL_RESOURCE = authApiUrl + '/common/viewall-motivational-resources',
  RESOLVE_RESOURCE_REPORT = authApiUrl + '/common/resolve-resource-report',
  LINK_RESOURCE = authApiUrl + '/common/link-incentives',
  DELETE_RESOURCE = authApiUrl + '/common/delete-resource',
  DELETE_RESOURCE_ATTACHMENT = authApiUrl + '/common/delete-resource-attachment',
  LIST_REPORT_REASONS = authApiUrl + '/common/list-report-reasons',
  ADD_RESOURCE_REPORT = authApiUrl + '/common/report-resource',
  LIST_GROUP_BY_TYPE = adminApiUrl + '/admin/list-groups-by-type',
}

export enum GoalApis {
  ADD_GOAL_EVENT = authApiUrl + '/common/add-events',
  ADD_GOAL = authApiUrl + '/common/v2/add-goal',
  ADD_GOAL_TASK = authApiUrl + '/common/add-task',
  GET_GOAL_LIST = authApiUrl + '/common/list-goals',
  LIST_EVENTS_TASK = authApiUrl + '/common/list-events-tasks',
  DELETE_EVENT_TASK = authApiUrl + '/common/delete-goal-event-task',
  ADD_GOAL_FINISH = authApiUrl + '/common/add-kudos-and-activate-goal',
  LIST_GOAL_RESOURCE = authApiUrl + '/common/list-goal-resources',
  DELETE_GOAL_RESOURCE = authApiUrl + '/common/delete-resource-from-goal',
  GET_GOAL_DETAIL = authApiUrl + '/common/goal-details',
  UPDATE_GOAL = authApiUrl + '/common/edit-goal',
  GET_USERS_BY_GOAL_ID = authApiUrl + '/common/list-users-associated-to-goal-id',
  GET_ASSIGNMENT_STUDENTS_LIST = authApiUrl + '/common/get-event-task-user-list',
  GET_ASSIGNMENT_PROOFS = authApiUrl + '/common/list-proofs-and-notes-added-by-user',
  GET_EVENT_DETAILS = authApiUrl + '/common/get-event-basic-details',
  GET_TASK_DETAILS = authApiUrl + '/common/get-taskDetails',
  GET_ASSIGNMENT_STATUS_COUNT = authApiUrl + '/common/get-event-task-user-list-count',
  APPROVE_ASSIGNMENT = authApiUrl + '/common/approve-task-event',
  REJECT_ASSIGNMENT = authApiUrl + '/common/event-or-task-details-reject',
  EDIT_TASK = authApiUrl + '/common/edit-task',
  EDIT_EVENT = authApiUrl + '/common/edit-event',
  DELETE_GOAL = authApiUrl + '/common/delete-goal',
  LIST_GOAL_COMPETENCIES = authApiUrl + '/common/list-competencies-under-goal',
  LIST_GOAL_TASKS_EVENTS = authApiUrl + '/common/goal-tasks-events-list',
  ASSIGN_GOAL_TO_USERS = authApiUrl + '/common/assign-goal-to-users',
  SEND_ASSIGNMENT_MESSAGE = authApiUrl + '/common/add-message-for-task-or-event',
  LIST_USERS_WITH_SURVEY_GOAL_ASSIGNMENT_DETAILS = adminApiUrl + '/admin/list-users-with-survey-goal-assignment-details',
}
// Api urls for teacher profile creation
export enum ProfileApis {
  CREATE_PROFILE = adminApiUrl + '/admin/edit-profile',
  CREATE_ACCOUNT = authApiUrl + '/auth/web-user-signup',
  VERIFY_OTP = authApiUrl + '/auth/verify-phone-otp',
  VALIDATE_TOKEN = appUserUrl + '/api/get-data-by-token',
  GET_AVATARS_LIST = authApiUrl + '/common/get-avatars',
  SEND_PHONE_OTP = authApiUrl + '/auth/phoneotp',
  SUBSCRIPTION_PLANS_BYORGID = adminApiUrl + '/admin/subscription-plans-by-orgid',
  SUBSCRIPTION_PAYMENT = adminApiUrl + '/admin/subscribe-to-plan',
  LIST_PAYMENT_CARDS = adminApiUrl + '/admin/payment/list-cards',
  LIST_BILLING_HISTORY = adminApiUrl + '/admin/payment/get-billing-history',
  ADD_PAYMENT_METHOD = adminApiUrl + '/admin/payment/add-payment-method',
  CANCEL_SUBSCRIPTION = adminApiUrl + '/admin/cancel-subscription',
  DELETE_PAYMENT_METHOD = adminApiUrl + '/admin/payment/delete-payment-method',
}
// Competency api urls
export enum CompetencyApis {
  ADD_COMPETENCY = adminApiUrl + '/admin/add-competencies',
  LIST_COMPETENCIES = adminApiUrl + '/admin/list-competencies',
  VIEW_COMPETENCY = adminApiUrl + '/admin/competency-details',
  UPDATE_COMPETENCY = adminApiUrl + '/admin/edit-competency',
  LIST_TOP_COMPETENCIES = adminApiUrl + '/admin/list-top-competencies',
  DELETE_COMPETENCY = adminApiUrl + '/admin/delete-competency',
  LIST_GROUP_BY_TYPE = adminApiUrl + '/admin/list-groups-by-type',
  SAVE_GROUP_BY_TYPE = adminApiUrl + '/admin/add-or-edit-group',
  DELETE_GROUP_BY_TYPE = adminApiUrl + '/admin/delete-group',
  LIST_TAGS = adminApiUrl + '/admin/get-competency-tags',
}

// Subscription plan api urls
export enum SubscriptionPlanApis {
  LIST_SUBSCRIPTION_PLAN_VALIDITY = adminApiUrl + '/admin/subscription-plan-validities',
  ADD_SUBSCRIPTION_PLAN = adminApiUrl + '/admin/add-subscription',
  LIST_SUBSCRIPTION_PLANS = adminApiUrl + '/admin/manage-subscriptions',
  VIEW_SUBSCRIPTION_PLANS = adminApiUrl + '/admin/subscription-plan-details',
  DELETE_SUBSCRIPTION_PLAN = adminApiUrl + '/admin/delete-subscription-plan',
  LIST_SUBSCRIBED_ORGANIZATIONS = adminApiUrl + '/admin/get-subscribed-organizations',
  EDIT_SUBSCRIPTION_PLAN = adminApiUrl + '/admin/edit-subscription-plan',
}
// Notification api urls
export enum NotificationApis {
  LIST_NOTIFICATION = adminApiUrl + '/admin/listnotifications',
  DELETE_NOTIFICATIONS = authApiUrl + '/common/delete-notifications',
}
// Survey api urls
export enum SurveyApis {
  CREATE_SURVEY = adminApiUrl + '/admin/tz/create-survey',
  LIST_SURVEY = adminApiUrl + '/admin/list-survey',
  LIST_ANSWER_TYPES = authApiUrl + '/common/get-answer-types',
  ADD_SURVEY_FORM = adminApiUrl + '/admin/add-survey-form',
  LIST_SURVEY_FORMS = ADMIN_API_URL + '/admin/list-survey-form',
  DELETE_SURVEY_FORMS = ADMIN_API_URL + '/admin/delete-survey-form',
  FINISH_SURVEY = ADMIN_API_URL + '/admin/complete-survey',
  GET_SURVEY_DETAILS = ADMIN_API_URL + '/admin/survey-details',
  SURVEY_FORM_DETAILS = ADMIN_API_URL + '/admin/get-survey-form',
  UPDATE_SURVEY_FORM = adminApiUrl + '/admin/update-survey-form',
  GET_USERS_BY_SURVEY_ID = authApiUrl + '/common/list-users-associated-to-goal-id',
  GET_ASSIGNMENT_STUDENTS_LIST = ADMIN_API_URL + '/admin/get-survey-user-list',
  GET_ASSIGNMENT_STATUS_COUNT = ADMIN_API_URL + '/admin/get-survey-user-list-count',
  GET_SURVEY_QUESTION_RESPONSE_LIST = ADMIN_API_URL + '/admin/get-survey-questions-response',
  UPDATE_SURVEY = adminApiUrl + '/admin/update-survey',
  UPLOAD_SURVEY_FORM = adminApiUrl + '/admin/upload-survey-form',
  DELETE_SURVEY_FORM_ATTACHMENT = adminApiUrl + '/admin/delete-survey-form-attachments',
  DELETE_SURVEY = adminApiUrl + '/admin/delete-survey',
  LIST_USERS_ASSOCIATED_TO_SURVEY = '/admin/list-users-associated-to-survey',
}
// Settings api urls
export enum SettingsApis {
  MANAGE_SETTINGS = adminApiUrl + '/admin/manage-settings',
  GET_ONBOARDING_PROCESS_COLUMNS = adminApiUrl + '/admin/get-all-onboarding-process-columns',
  GET_USERROLE_PERMISSIONS = adminApiUrl + '/admin/get-userrole-permissions',
  GET_SETTINGS = adminApiUrl + '/admin/get-settings',
  CREATE_OR_UPDATE_SYSTEM_ADMIN_PERMISSIONS = adminApiUrl + '/admin/create-or-update-sys-admin-permissions',
  GET_SYSTEM_ADMIN_PERMISSIONS = adminApiUrl + '/admin/get-sys-admin-permission-settings',
}
// Overview api urls
export enum OverviewApis {
  LIST_USERS_WITH_TODO = adminApiUrl + '/admin/list-users-with-todo-items',
  GET_USER_GOALS_SURVEYS_OVERVIEW = adminApiUrl + '/admin/get-user-goals-and-surveys-overview',
}
