/**
 * @file   src\containers\subscriptions\Details.tsx
 * @brief  Subscription Plan Details page.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row, Breadcrumb, Table, Nav, NavDropdown } from 'react-bootstrap';
import { end } from '@popperjs/core';
import { useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import More from '../../assets/img/More.svg';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import SubscriptionDetailCard from '../../components/SubscriptionDetailCard';
import ViewIcon from '../../assets/img/icon/View';
import Sort from '../../assets/img/icon/Sort';
import Pagination from '../../components/Pagination';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getSubscriptionPlanDetails, deleteSubscriptionPlan, getSubscribedOrganizations } from '../../store/actions/subscriptionPlanActions';
import { IViewSubscriptionPlanApiParams, IDeleteSubscriptionPlanApiParams } from '../../interfaces/SubscriptionPlanInterface';
import { resetDeleteSubscriptionPlan, resetSubscriptionPlanOrganizationList } from '../../store/slices/subscriptionPlanSlice';
import Loader from '../../components/Loader';
import DeleteConfirmPopup from '../../components/DeleteConfirmation';
import { MessageToaster } from '../../utils/ToastUtil';
import { SubscriptionPlanSortField, SortOrder } from '../../utils/enums';
import { DEFAULT_PAGE_INDEX, PAGE_SIZE } from '../../utils/constants';
import moment from 'moment';
// Declare default params
const organizationListAPIDefaultParams = {
  PlanID: 0,
  Page: DEFAULT_PAGE_INDEX,
  SortColumnName: SubscriptionPlanSortField.ORG_NAME,
  SortOrder: SortOrder.ASC,
};

const SubscriptionPlanDetails = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Location object
  const location = useLocation();
  // Message toaster object creation
  const toastObj = new MessageToaster();
  // Access redux state variables
  const {
    subscriptionPlanDetailApiData,
    subscriptionPlanDetailApiSuccess,
    subscriptionPlanDetailApiLoading,
    deletesubscriptionPlanApiLoading,
    deletesubscriptionPlanApiResponseMessage,
    deletesubscriptionPlanApiSuccess,
    organizationListApiData,
    organizationListApiSuccess,
    organizationListApiLoading,
  } = useAppSelector((state: RootState) => state.subscriptionPlan);
  // Initialize component state variables.
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_INDEX);
  const [isShowDeleteConfirmPopup, setIsShowDeleteConfirmPopup] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<string>(SortOrder.ASC);
  const [sortField, setSortField] = useState<string>(SubscriptionPlanSortField.ORG_NAME);
  const [organizationListApiParams, setOrganizationListApiParams] = useState<any>(organizationListAPIDefaultParams);
  // Initialize language variables.
  const NillText = useIntlActionMessages('Label.Nill');
  const labelNoData = useIntlActionMessages('Label.Nodata');
  const DeletePlanError = useIntlActionMessages('Text.DeletePlanError');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // Resetting plan details  works on unmounting
  useEffect(() => {
    try {
      return () => {
        dispatch(resetDeleteSubscriptionPlan());
        dispatch(resetSubscriptionPlanOrganizationList());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // Call subscribed organization list api on initial loading.
  useEffect(() => {
    try {
      if (subscriptionPlanDetailApiSuccess && subscriptionPlanDetailApiData) {
        const params = {
          ...organizationListApiParams,
          Page: currentPage,
          PlanID: subscriptionPlanDetailApiData?.SubscriptionPlanID,
          SortColumnName: sortField,
          SortOrder: sortOrder,
        };
        dispatch(getSubscribedOrganizations(params));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [subscriptionPlanDetailApiSuccess, subscriptionPlanDetailApiData, currentPage, sortField, sortOrder]);
  // Get plan details
  useEffect(() => {
    try {
      window.scrollTo(0, 0);
      if (location?.state?.PlanID) {
        const viewOrganizationInfoParams: IViewSubscriptionPlanApiParams = {
          PlanID: location?.state?.PlanID,
        };
        dispatch(getSubscriptionPlanDetails(viewOrganizationInfoParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [dispatch, location?.state?.PlanID]);
  // show  delete subscription plan api success message and error message
  useEffect(() => {
    try {
      if (deletesubscriptionPlanApiSuccess) {
        toastObj.toastSuccess(deletesubscriptionPlanApiResponseMessage);
        navigate('/subscriptionplans');
      } else if (!deletesubscriptionPlanApiSuccess && deletesubscriptionPlanApiResponseMessage.length > 0) {
        toastObj.toastError(deletesubscriptionPlanApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [deletesubscriptionPlanApiLoading]);
  // Set API params on sort change.
  useEffect(() => {
    try {
      setOrganizationListApiParams((apiParams: any) => ({
        ...apiParams,
        Page: currentPage,
        SortColumnName: sortField,
        SortOrder: sortOrder,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [currentPage, sortField, sortOrder]);
  // Sort header change event.
  const changeSortField = (field: string) => {
    try {
      let newSortOrder = SortOrder.ASC;
      if (field === sortField) {
        newSortOrder = sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
      }
      setSortField(field);
      setSortOrder(newSortOrder);
      setOrganizationListApiParams((prevParams: any) => ({
        ...prevParams,
        SortColumnName: field,
        SortOrder: newSortOrder,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle delete confirmation
  const ShowDeleteConfirmPopup = () => {
    try {
      if (organizationListApiData?.OrganizationList?.length > 0) {
        toastObj.toastInfo(DeletePlanError);
      } else {
        setIsShowDeleteConfirmPopup(true);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Api call to delete subscription plan
  const handleDeleteSubscriptionPlanClick = () => {
    try {
      if (location?.state?.PlanID) {
        const deleteParams: IDeleteSubscriptionPlanApiParams = {
          PlanID: location?.state?.PlanID,
        };
        dispatch(deleteSubscriptionPlan(deleteParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle edit subscription plan
  const editSubscriptionPlan = () => {
    try {
      if (location?.state?.PlanID) {
        const planID = location?.state?.PlanID;
        navigate('/editsubscriptionplan', { state: { subscriptionPlanID: planID } });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Hd.SubscriptionPlanDetails" />
        </h3>
        <Col md="auto">
          {location?.state?.parentPage == 'organizationview' && (
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>
                <FormattedMessage id="Hd.Organization" />
              </Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => navigate('/vieworganization', { state: { organizationId: location.state.organizationId } })}>
                <FormattedMessage id="Hd.ViewOrganization" />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <FormattedMessage id="Hd.SubscriptionPlanDetails" />
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
          {location?.state?.parentPage == 'subscription plan' && (
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item onClick={() => navigate('/subscriptionplans')}>
                <FormattedMessage id="Hd.ManageSubscriptionPlans" />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <FormattedMessage id="Hd.SubscriptionPlanDetails" />
              </Breadcrumb.Item>
            </Breadcrumb>
          )}
        </Col>
      </div>
      <div className="content-sub">
        <div className="content-area-padding">
          {location?.state?.parentPage == 'subscription plan' && (
            <div className="d-flex justify-content-end mb-2">
              <Nav className="more-dropdown">
                <NavDropdown align={end} className="icon-btn-more" title={<img src={More} alt="more" />}>
                  <NavDropdown.Item onClick={editSubscriptionPlan}>
                    <FormattedMessage id="Button.Edit" />
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => ShowDeleteConfirmPopup()}>
                    <FormattedMessage id="Button.Delete" />
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </div>
          )}
          <Row className="mb-4">
            <Col xl={12} className="subscription-details">
              <SubscriptionDetailCard
                price={subscriptionPlanDetailApiData?.Amount}
                duration={subscriptionPlanDetailApiData?.DurationLabel}
                name={subscriptionPlanDetailApiData?.PlanName}
                websiteuser={subscriptionPlanDetailApiData?.NoOfWebUsers}
                mobileuser={subscriptionPlanDetailApiData?.NoOfMobAppUsers}
                limited={subscriptionPlanDetailApiData?.Visibility}
              />
            </Col>
          </Row>
        </div>
      </div>
      {location?.state?.parentPage != 'organizationview' && (
        <>
          <h4>
            <FormattedMessage id="Hd.Organization" />
          </h4>
          <div className="content-sub mt-3">
            <div className="content-area-padding">
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage id="Label.OrgName" />
                      <Link to="" className="ms-1" onClick={() => changeSortField(SubscriptionPlanSortField.ORG_NAME)}>
                        <Sort />
                      </Link>
                    </th>
                    <th>
                      <FormattedMessage id="Label.OrgType" />
                      <Link to="" className="ms-1" onClick={() => changeSortField(SubscriptionPlanSortField.TYPE_NAME)}>
                        <Sort />
                      </Link>
                    </th>
                    <th>
                      <FormattedMessage id="Label.SubscriptionStartDate" />
                      <Link to="" className="ms-1" onClick={() => changeSortField(SubscriptionPlanSortField.PLAN_START_DATE)}>
                        <Sort />
                      </Link>
                    </th>
                    <th>
                      <FormattedMessage id="Label.SubscriptionEndDate" />
                      <Link to="" className="ms-1" onClick={() => changeSortField(SubscriptionPlanSortField.PLAN_END_DATE)}>
                        <Sort />
                      </Link>
                    </th>
                    <th className="w-50px"></th>
                  </tr>
                </thead>
                <tbody>
                  {organizationListApiData &&
                    organizationListApiData.OrganizationList &&
                    organizationListApiData.OrganizationList.length > 0 &&
                    organizationListApiData.OrganizationList.map((organization: any) => (
                      <tr key={organization?.OrganizationID}>
                        <td className="text-capitalize">
                          {organization?.OrganizationName}
                          {organization?.SubscriptionStatus ? (
                            <span className="subscribed-list ms-2">
                              <span> {useIntlActionMessages('Text.Subscribed')}</span>
                            </span>
                          ) : (
                            <span className="nonsubscriber-list ms-2">
                              <span>{useIntlActionMessages('Text.NotSubscribed')}</span>
                            </span>
                          )}
                        </td>
                        <td>{organization?.OrganizationType}</td>
                        <td>{organization && organization?.SubscriptionStartDate ? moment(organization.SubscriptionStartDate).local().format('MM/DD/YYYY') : NillText}</td>
                        <td>{organization && organization?.SubscriptionEndDate ? moment(organization.SubscriptionEndDate).local().format('MM/DD/YYYY') : NillText}</td>
                        <td>
                          <Button
                            variant="secondary"
                            size="sm"
                            className="icon-btn"
                            onClick={() => navigate('/vieworganization', { state: { organizationId: organization?.OrganizationID } })}
                          >
                            <ViewIcon />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  {!organizationListApiData ||
                    (organizationListApiData && !organizationListApiData.OrganizationList && (
                      <tr>
                        <td colSpan={5}>
                          <div className="content-sub content-area-padding border-top text-center">
                            <h6>{labelNoData}</h6>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {organizationListApiSuccess && organizationListApiData?.OrganizationList?.length > 0 && organizationListApiData.TotalCount > PAGE_SIZE && (
                <Pagination
                  pageSize={PAGE_SIZE}
                  totalitems={organizationListApiData?.TotalCount}
                  pageLimit={PAGE_SIZE}
                  setCurrentPage={(page: number) => setCurrentPage(page)}
                  currentPage={currentPage - 1}
                  prevPage={-1}
                  itemsDisplayed={organizationListApiData?.OrganizationList?.length}
                />
              )}
            </div>
          </div>
        </>
      )}
      <DeleteConfirmPopup
        show={isShowDeleteConfirmPopup}
        handleClose={() => setIsShowDeleteConfirmPopup(false)}
        onCancel={() => setIsShowDeleteConfirmPopup(false)}
        onOkay={() => handleDeleteSubscriptionPlanClick()}
        title={useIntlActionMessages('Text.DeleteConfirmation.SubscriptionPlan')}
        content={useIntlActionMessages('Text.DeleteConfirmation.SubscriptionPlan.Desc')}
      />
      {(subscriptionPlanDetailApiLoading || deletesubscriptionPlanApiLoading || organizationListApiLoading) && <Loader />}
    </>
  );
};
export default SubscriptionPlanDetails;
