/**
 * @file   src\containers\organizations\OrgTtypeDetails.tsx
 * @brief  Manage Permission.
 * @date   Dec, 2023
 * @author ZCO
 * @copyright (c) 2023, ZCO */

import React, { useEffect, useState } from 'react';
import '../../assets/css/myprof.scss';
import '../../assets/css/org.scss';
import '../../assets/css/mngPermission.scss';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Breadcrumb, Button, Accordion } from 'react-bootstrap';
import Select from '../../components/MASelect';
import mngNamIcn from '../../assets/img/icn-org.svg';
import Checkbox from '../../components/MACheck';
import { useNavigate, useParams } from 'react-router-dom';
import { getOrganizationNameByOrgType, getOrganizationTypeDropDownList, getUserRolesByOrgType } from '../../store/actions/organizationTypeActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { IOrgTypeResponse } from '../../interfaces/OrganizationTypeInterface';
import { ISelectOption, ISelectOptionsNumber } from '../../interfaces/GeneralInterface';
import { ISystemAdminPermissionsForm } from '../../interfaces/SettingsInterface';
import { getFromLocalStorage, useIntlMessages } from '../../utils/helper';
import { RoleTypeIds } from '../../utils/enums';
import { createOrUpdateSystemAdminPermissions, getSystemAdminPermissions } from '../../store/actions/settingsAction';
import { resetOrgTypeNameRoles } from '../../store/slices/organizationTypeSlice';
import { MessageToaster } from '../../utils/ToastUtil';
import { resetCreateOrUpdateSystemAdminPermissions } from '../../store/slices/settingsSlice';
import Loader from '../../components/Loader';

const defaultSystemAdminPermissionsParams = {
  AddedBy: 0,
  AssignedRoleIds: [],
  HasPermissionToAddNewCompetency: true,
  HasPermissionToAddNewResource: true,
  OrganizationIds: [],
  OrganizationTypeId: 0,
  SysAdminID: 0,
};
const ManagePermission = () => {
  // Navigation object
  const navigate = useNavigate();
  // Create route param object to access the route parameters.
  const params = useParams();
  // Action dispatch object
  const dispatch = useAppDispatch();
  // Toast object creation.
  const toast = new MessageToaster();
  const userData = getFromLocalStorage('MI_USR_DATA');
  const {
    organizationTypeDropDownData,
    organizationTypeDropDownSuccess,
    organizationTypeDropDownLoading,
    rolesByOrgTypeData,
    rolesByOrgTypeLoading,
    rolesByOrgTypeSuccess,
    rolesByOrgTypeResponseCode,
    orgnameByOrgTypeData,
    orgnameByOrgTypeLoading,
  } = useAppSelector((state: RootState) => state.organizationType);
  const {
    createOrUpdateSystemAdminPermissionsApiLoading,
    createOrUpdateSystemAdminPermissionsApiResponseMessage,
    createOrUpdateSystemAdminPermissionsApiResponseCode,
    createOrUpdateSystemAdminPermissionsApiSuccess,
    getSystemAdminPermissionsApiLoading,
    getSystemAdminPermissionsApiSuccess,
    getSystemAdminPermissionsApiData,
  } = useAppSelector((state: RootState) => state.settings);

  // Component state variables
  const [orgTypeList, setOrgTypeList] = useState<ISelectOptionsNumber[]>([]);
  const [selectedOrgType, setselectedOrgType] = useState<ISelectOption[]>([]);
  const [selectedOrgTypeId, setSelectedOrgTypeId] = useState<number>(0);
  const [rolesByOrgType, setRolesByOrgType] = useState<any>([]);
  const [organizationsByOrgType, setOrganizationsByOrgType] = useState<any>([]);
  const [resourcePermission, setResourcePermission] = useState<boolean>(true);
  const [competencyPermission, setCompetencyPermission] = useState<boolean>(true);
  const [systemAdminPermissionsForm, setSystemAdminPermissionsForm] = useState<ISystemAdminPermissionsForm>(defaultSystemAdminPermissionsParams);
  const [selectAllOrganization, setSelectAllOrganization] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [isOrgLoaded, setIsOrgLoaded] = useState<boolean>(false);
  const [isUserRoleLoaded, setIsUserRoleLoaded] = useState<boolean>(false);
  const [showPermissions, setShowPermissions] = useState<boolean>(false);
  const itemsPerRow = 3;
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');

  // Reset Slice.
  useEffect(() => {
    try {
      return () => {
        dispatch(resetOrgTypeNameRoles());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // works on initial rendering
  useEffect(() => {
    try {
      dispatch(getOrganizationTypeDropDownList({}));
      setSystemAdminPermissionsForm((info: any) => ({
        ...info,
        AddedBy: userData.UserId,
        SysAdminID: params.userId,
      }));
      dispatch(
        getSystemAdminPermissions({
          OrgTypeID: null,
          SysAdminID: params.userId,
        }),
      );
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // set org type dropdown
  useEffect(() => {
    try {
      if (organizationTypeDropDownSuccess) {
        const orgTypes: any = [];
        organizationTypeDropDownData.forEach((orgType: IOrgTypeResponse) =>
          orgTypes.push({
            label: orgType.orgTypeName,
            value: orgType.orgTypeId,
          }),
        );
        setOrgTypeList(orgTypes);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationTypeDropDownLoading]);

  // get user roles and organization names by organization type
  useEffect(() => {
    try {
      if (selectedOrgTypeId !== 0) {
        dispatch(
          getUserRolesByOrgType({
            OrganizationTypeId: selectedOrgTypeId,
          }),
        );
        dispatch(
          getOrganizationNameByOrgType({
            OrganizationTypeId: selectedOrgTypeId,
          }),
        );
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [selectedOrgTypeId]);

  // sets the RoleId
  useEffect(() => {
    try {
      if (rolesByOrgTypeSuccess && rolesByOrgTypeResponseCode > 0 && rolesByOrgTypeData.length > 0) {
        const filteredRoles = rolesByOrgTypeData
          .filter((role: any) => role.RoleTypeID !== RoleTypeIds.SYSTEM_ADMIN)
          .map((field: any) => ({
            RoleId: field.RoleId,
            Name: field.Name,
            RoleType: field.RoleType,
            RoleTypeID: field.RoleTypeID,
            PermissionStatus: true,
          }));
        setRolesByOrgType(filteredRoles);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [rolesByOrgTypeLoading]);

  // setorganization name
  useEffect(() => {
    try {
      if (orgnameByOrgTypeData && orgnameByOrgTypeData.length > 0) {
        const organizationData = orgnameByOrgTypeData.map((organization: any) => ({
          Name: organization.Name,
          Id: organization.ID,
          PermissionStatus: false,
        }));
        setOrganizationsByOrgType(organizationData);
      } else if (orgnameByOrgTypeData && orgnameByOrgTypeData.length === 0) {
        setOrganizationsByOrgType([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [orgnameByOrgTypeLoading]);

  useEffect(() => {
    try {
      if (organizationsByOrgType.length > 0) {
        setIsOrgLoaded(true);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationsByOrgType]);

  useEffect(() => {
    try {
      if (rolesByOrgType.length > 0) {
        setIsUserRoleLoaded(true);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [rolesByOrgType]);

  // get saved settings if user roles and organizations are loaded
  useEffect(() => {
    try {
      if (isOrgLoaded && isUserRoleLoaded) {
        dispatch(
          getSystemAdminPermissions({
            OrgTypeID: selectedOrgTypeId,
            SysAdminID: params.userId,
          }),
        );
        setShowPermissions(true);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [isOrgLoaded, isUserRoleLoaded]);

  // setorganization names
  useEffect(() => {
    try {
      if (getSystemAdminPermissionsApiSuccess && getSystemAdminPermissionsApiData.length > 0) {
        const updatedOrgs = organizationsByOrgType.map((org: any) => {
          const apiEntry = getSystemAdminPermissionsApiData[0]?.permission_details[0]?.managing_org_details?.find((mangorg: any) => org.Id === mangorg.organizationid);
          if (apiEntry) {
            return {
              ...org,
              PermissionStatus: true,
            };
          }
          return org;
        });
        setOrganizationsByOrgType(updatedOrgs);
        const updatedRoles = rolesByOrgType.map((role: any) => {
          const apiEntry = getSystemAdminPermissionsApiData[0]?.permission_details[0]?.managing_roles?.find((mangrole: any) => role.RoleId === mangrole.roleid);
          if (apiEntry) {
            return {
              ...role,
              PermissionStatus: true,
            };
          } else {
            return {
              ...role,
              PermissionStatus: false,
            };
          }
          return role;
        });
        setRolesByOrgType(updatedRoles);
        setCompetencyPermission(getSystemAdminPermissionsApiData[0]?.has_permission_to_add_new_competencies);
        setResourcePermission(getSystemAdminPermissionsApiData[0]?.has_permission_to_add_new_resource);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getSystemAdminPermissionsApiLoading]);

  // Handle Permissions Save
  useEffect(() => {
    try {
      if (isSubmit) {
        dispatch(createOrUpdateSystemAdminPermissions(systemAdminPermissionsForm));
        setIsSubmit(false);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [systemAdminPermissionsForm]);

  useEffect(() => {
    try {
      if (createOrUpdateSystemAdminPermissionsApiResponseCode > 0 && createOrUpdateSystemAdminPermissionsApiSuccess) {
        toast.toastSuccess(createOrUpdateSystemAdminPermissionsApiResponseMessage);
        dispatch(resetCreateOrUpdateSystemAdminPermissions());
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [createOrUpdateSystemAdminPermissionsApiLoading]);

  const handleOrganizationTypeChange = (event: any) => {
    try {
      setselectedOrgType([event]);
      setSelectedOrgTypeId(event.value);
      setSystemAdminPermissionsForm((info: any) => ({
        ...info,
        OrganizationTypeId: event.value,
      }));
      setRolesByOrgType([]);
      setOrganizationsByOrgType([]);
      setIsUserRoleLoaded(false);
      setIsOrgLoaded(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  const handleOrganizationPermissionClick = (id: any) => {
    try {
      const updatedOrganizations = organizationsByOrgType.map((item: any) => {
        if (item.Id === id) {
          return { ...item, PermissionStatus: !item.PermissionStatus }; // Toggle permission status
        }
        return item;
      });
      setOrganizationsByOrgType(updatedOrganizations);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Select All checkbox click
  const handleSelectAllCheckboxClick = (event: any) => {
    try {
      setSelectAllOrganization(!selectAllOrganization);
      const updatedOrganizations = organizationsByOrgType.map((item: any) => ({
        ...item,
        PermissionStatus: event.target.checked ? true : false, // Set PermissionStatus
      }));
      setOrganizationsByOrgType(updatedOrganizations);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const handleUserRolePermissionClick = (index: number) => {
    try {
      const updatedRoles = rolesByOrgType.map((item: any, subIndex: any) => {
        if (subIndex === index) {
          return { ...item, PermissionStatus: !item.PermissionStatus }; // Toggle permission status
        }
        return item;
      });
      setRolesByOrgType(updatedRoles);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle General Click
  const handleResourcePermissionClick = (event: any) => {
    try {
      let resourcePerm = false;
      if (event.target.checked) {
        resourcePerm = true;
      }
      setResourcePermission(resourcePerm);
      setSystemAdminPermissionsForm((info: any) => ({
        ...info,
        HasPermissionToAddNewResource: resourcePerm,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle General Click
  const handleCompetencyPermissionClick = (event: any) => {
    try {
      let competencyPerm = false;
      if (event.target.checked) {
        competencyPerm = true;
      }
      setCompetencyPermission(competencyPerm);
      setSystemAdminPermissionsForm((info: any) => ({
        ...info,
        HasPermissionToAddNewCompetency: competencyPerm,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  const handleSavePermissions = () => {
    try {
      setIsSubmit(true);
      const organizations = organizationsByOrgType.filter((org: any) => org.PermissionStatus == true).map((field: any) => field.Id);
      const roles = rolesByOrgType.filter((role: any) => role.PermissionStatus == true).map((field: any) => field.RoleId);
      setSystemAdminPermissionsForm((info: any) => ({
        ...info,
        OrganizationIds: organizations,
        AssignedRoleIds: roles,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Label.MngPermissions" />
        </h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageuser')}>
              <FormattedMessage id="Hd.ManageUsers" />
            </Breadcrumb.Item>
            <Breadcrumb.Item
              onClick={() =>
                navigate(`/userDetails/${params.userId}/${RoleTypeIds.SYSTEM_ADMIN}`, {
                  state: {
                    tabName: 'System Admin',
                  },
                })
              }
            >
              <FormattedMessage id="Hd.OrgManager Details" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Label.MngPermissions" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div>
        <Col xl={4} lg={6} md={4}>
          <Select label="Organization Type" options={orgTypeList} value={selectedOrgType} placeholder="Select" onChange={(e: any) => handleOrganizationTypeChange(e)} />
        </Col>
      </div>
      {showPermissions && (
        <>
          <div className="mng-org-per">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <h6>
                    <FormattedMessage id="Hd.ManageOrganizations" />
                  </h6>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="org-name-sec">
                    <Row>
                      <div className="check-sm d-flex flex-row-reverse">
                        <Checkbox type="Checkbox" label="Select All Organizations" checked={selectAllOrganization} onChange={handleSelectAllCheckboxClick} />
                      </div>
                    </Row>
                    {organizationsByOrgType.map((organization: any, index: any) => {
                      // Start a new row every 'itemsPerRow' items
                      if (index % itemsPerRow === 0) {
                        return (
                          <Row className="mb-3" key={index}>
                            {organizationsByOrgType.slice(index, index + itemsPerRow).map((item: any, subIndex: any) => (
                              <Col lg={4} key={subIndex}>
                                <div className="user-wrap d-flex org-name-b">
                                  <div className="org-name-icn">
                                    <img src={mngNamIcn} alt="Organization Icon" />
                                  </div>
                                  <div className="usr-name">
                                    <p>{item.Name}</p>
                                    <span className="usr-status-admin small-text">Active</span>
                                  </div>
                                  <div className="check-sm">
                                    <Checkbox type="Checkbox" checked={item.PermissionStatus} onChange={() => handleOrganizationPermissionClick(item.Id)} />
                                  </div>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        );
                      }
                      return null; // Skip rendering the element if it's already processed
                    })}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div className="content-sub">
            <div className="content-sub-header d-flex justify-content-between align-items-center">
              <div>
                <h4>
                  <FormattedMessage id="Hd.ManageUsers" />
                </h4>
                <span className="small-text">Permission to Add New Users to the Selected Roles</span>
              </div>
            </div>
            <div className="content-area-padding">
              <Row>
                {rolesByOrgType &&
                  rolesByOrgType?.length > 0 &&
                  rolesByOrgType.map((role: any, index: any) => (
                    <Col lg={3}>
                      <div className="check-sm">
                        <Checkbox type="Checkbox" label={role.Name} checked={role.PermissionStatus} onChange={() => handleUserRolePermissionClick(index)} />
                      </div>
                    </Col>
                  ))}
              </Row>
            </div>
          </div>
        </>
      )}
      <div className="content-sub">
        <div className="content-sub-header d-flex justify-content-between align-items-center">
          <div>
            <h4>
              <FormattedMessage id="Hd.ManageResources" />
            </h4>
          </div>
        </div>
        <div className="content-area-padding">
          <Row>
            <Col lg={3}>
              <div className="check-sm">
                <Checkbox type="Checkbox" label="Permission to Add New Resources" checked={resourcePermission} onChange={handleResourcePermissionClick} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="content-sub">
        <div className="content-sub-header d-flex justify-content-between align-items-center">
          <div>
            <h4>
              <FormattedMessage id="Hd.ManageCompetencies" />
            </h4>
          </div>
        </div>
        <div className="content-area-padding">
          <Row>
            <Col lg={4}>
              <div className="check-sm">
                <Checkbox type="Checkbox" label="Permission to Add New Competencies" checked={competencyPermission} onChange={handleCompetencyPermissionClick} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Row>
        <Col lg={12} md={6} className="btn-btm d-flex justify-content-lg-end mt-4">
          <Button className="mb-4 btn-cancel btn-sec" variant="outline-primary" onClick={() => navigate('/manageuser')}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button className="mb-4 btn-Done" variant="primary" onClick={handleSavePermissions}>
            <FormattedMessage id="Button.save" />
          </Button>
        </Col>
      </Row>
      {getSystemAdminPermissionsApiLoading && <Loader />}
    </>
  );
};
export default ManagePermission;
