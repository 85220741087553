/**
 * @file   src\components\About.js
 * @brief  404 page.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useEffect, useState } from 'react';
import '../../assets/css/addOrgType.scss';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages } from '../../utils/helper';
import { Button, Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Input from '../../components/MAInput';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation, useNavigate } from 'react-router-dom';
import { IOrganisationTypeForm, IOrganizationTypeViewAPIRequest } from '../../interfaces/OrganizationTypeInterface';
import { ORGANISATION_TYPE_SCHEMA } from '../../validations/organizationTypeSchema';
import { validateForm } from '../../utils/formValidation';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { addOrganizationType, viewOrganizationType, updateOrganizationType } from '../../store/actions/organizationTypeActions';
import { RootState } from '../../store';
import { MessageToaster } from '../../utils/ToastUtil';
import { resetOrganizationTypeAdd, resetOrganizationTypeUpdate } from '../../store/slices/organizationTypeSlice';
import { NumberValues, RoleTypeIds } from '../../utils/enums';

const AddOrganizationType = () => {
  // Navigation object.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Toast object creation.
  const toast = new MessageToaster();
  // Location object
  const location = useLocation();
  const {
    organizationTypeAddApiLoading,
    organizationTypeAddApiSuccess,
    organizationTypeAddApiResponseCode,
    organizationTypeAddApiResponseMessage,
    organizationTypeDetail,
    organizationTypeViewApiLoading,
    organizationTypeViewApiSuccess,
    organizationTypeUpdateApiLoading,
    organizationTypeUpdateApiSuccess,
    organizationTypeUpdateApiResponseCode,
    organizationTypeUpdateApiResponseMessage,
  } = useAppSelector((state: RootState) => state.organizationType);
  // Component state values.
  const [organizationTypeForm, setOrganisationTypeForm] = useState<IOrganisationTypeForm>({
    OrganizationTypeId: 0,
    OrganizationTypeName: '',
    OrganizationTypeAdmin: '',
    OrganizationTypeManager: '',
    OrganizationTypeAppUserManager: '',
    OrganizationTypeAppUser: '',
    OrganizationTypeAccountabilityPartner: '',
  });
  const [errorFields, setErrorFields] = useState<any>({});
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  useEffect(() => {
    try {
      if (location?.state?.organizationTypeId) {
        const organizationTypeViewAPIRequest: IOrganizationTypeViewAPIRequest = {
          OrganizationTypeId: location?.state?.organizationTypeId,
        };
        dispatch(viewOrganizationType(organizationTypeViewAPIRequest));
      }
      // Prevent enterkey press.
      const listener = (event: any) => {
        if (event.keyCode === NumberValues.NUM_13) {
          event.preventDefault();
        }
      };
      document.addEventListener('keydown', listener);
      return () => {
        document.removeEventListener('keydown', listener);
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // Reset Org Type add/edit.
  useEffect(() => {
    try {
      return () => {
        dispatch(resetOrganizationTypeAdd());
        dispatch(resetOrganizationTypeUpdate());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // set data for organization type roles
  useEffect(() => {
    try {
      if (organizationTypeViewApiSuccess && organizationTypeDetail) {
        if (location.pathname === '/editorganizationtype') {
          setOrganisationTypeForm((organizationTypeForm: any) => ({
            ...organizationTypeForm,
            OrganizationTypeId: organizationTypeDetail.organizationTypeInfo[0].OrganizationTypeId,
            OrganizationTypeName: organizationTypeDetail.organizationTypeInfo[0].OrganizationTypeName,
            OrganizationTypeAdmin: organizationTypeDetail.organizationTypeInfo[0].OrganizationTypeWebsiteUsers.find(
              (option: any) => option.roletypeid == RoleTypeIds.ORGANIZATION_ADMIN,
            )?.rolename,
            OrganizationTypeManager: organizationTypeDetail.organizationTypeInfo[0].OrganizationTypeWebsiteUsers.find(
              (option: any) => option.roletypeid == RoleTypeIds.ORGANIZATION_MANGER,
            )?.rolename,
            OrganizationTypeAppUserManager: organizationTypeDetail.organizationTypeInfo[0].OrganizationTypeWebsiteUsers.find(
              (option: any) => option.roletypeid == RoleTypeIds.APP_USER_MANAGER,
            )?.rolename,
            OrganizationTypeAppUser: organizationTypeDetail.organizationTypeInfo[0].OrganizationTypeMobileUsers.find((option: any) => option.roletypeid == RoleTypeIds.APP_USER)
              ?.rolename,
            OrganizationTypeAccountabilityPartner: organizationTypeDetail.organizationTypeInfo[0].OrganizationTypeMobileUsers.find(
              (option: any) => option.roletypeid == RoleTypeIds.ACCOUNTABILITY_PARTNER,
            )?.rolename,
          }));
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationTypeViewApiLoading]);

  // Handle Org Type Add Success/Failure.
  useEffect(() => {
    try {
      if (organizationTypeAddApiSuccess && organizationTypeAddApiResponseCode === 4020) {
        navigate('/listorganizationtypes');
        toast.toastSuccess(organizationTypeAddApiResponseMessage);
      } else if (!organizationTypeAddApiSuccess && organizationTypeAddApiResponseCode === 4019) {
        toast.toastError(organizationTypeAddApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationTypeAddApiLoading]);

  // Handle Org Type Update Success/Failure.
  useEffect(() => {
    try {
      if (organizationTypeUpdateApiSuccess && organizationTypeUpdateApiResponseCode > 0) {
        navigate('/listorganizationtypes');
        toast.toastSuccess(organizationTypeUpdateApiResponseMessage);
      } else if (!organizationTypeUpdateApiSuccess && organizationTypeAddApiResponseCode > 0) {
        toast.toastError(organizationTypeUpdateApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationTypeUpdateApiLoading]);

  // Add event listener for Enter key press.
  useEffect(() => {
    try {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
          // Check if all required fields are filled
          if (isFormValid()) {
            event.preventDefault();
            onSubmit();
          }
        }
      };
      const isFormValid = () => {
        return Object.values(errorFields).every((error) => !error);
      };
      document.addEventListener('keydown', handleKeyPress);
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [errorFields]);
  // handle form fields validtaion.
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      setOrganisationTypeForm((info: any) => ({
        ...info,
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      const errorresult = await validateForm(validateObj, ORGANISATION_TYPE_SCHEMA, errorFields);
      setErrorFields(errorresult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Submit handler to add a new organization type/edit an organization type.
  const onSubmit = async () => {
    try {
      const errorresult = await validateForm(organizationTypeForm, ORGANISATION_TYPE_SCHEMA, errorFields);
      setErrorFields(errorresult);
      if (Object.keys(errorresult).length === 0) {
        if (location?.state?.organizationTypeId) {
          dispatch(updateOrganizationType(organizationTypeForm));
        } else {
          dispatch(addOrganizationType(organizationTypeForm));
        }
      } else {
        setErrorFields(errorresult);
        // If there are validation errors, focus on the input field
        const firstErrorField = Object.keys(errorresult)[0];
        const inputFields = document.getElementsByName(firstErrorField);
        if (inputFields.length > 0 && inputFields[0] instanceof HTMLInputElement) {
          inputFields[0].focus();
        }
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Function to handle cancel on tab key press.
  const handleCancel: React.KeyboardEventHandler<HTMLButtonElement> = (event) => {
    try {
      if (event.key === 'Enter') {
        navigate('/listorganizationtypes');
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  return (
    <>
      <div className="page-title orgAddType-page-title d-flex justify-content-between align-items-center">
        <h2>{location.pathname === '/editorganizationtype' ? <FormattedMessage id="Hd.EditOrgType" /> : <FormattedMessage id="Hd.AddOrgType" />}</h2>
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>
            <FormattedMessage id="Hd.ManageOrganizations" />
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate('/listorganizationtypes')}>
            <FormattedMessage id="Label.ViewOrgType" />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {location.pathname === '/editorganizationtype' ? <FormattedMessage id="Hd.EditOrgType" /> : <FormattedMessage id="Hd.AddOrgType" />}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-sub content-sub-org add-Org-type">
        <Row className="d-flex justify-content-lg-center mb-4">
          <Col lg={6}>
            <Input
              autoFocus
              label={useIntlMessages('Label.OrgType')}
              id="organizationType"
              name="OrganizationTypeName"
              type="text"
              value={organizationTypeForm.OrganizationTypeName}
              onChange={onInputHandleChange}
              placeholder={useIntlMessages('PH.OrgTypeAdd')}
              maxLength={NumberValues.NUM_100}
              errorMessage={errorFields?.OrganizationTypeName}
            />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <h4>
              <FormattedMessage id="Hd.User-role" />
            </h4>
            <p className="small-text">
              <FormattedMessage id="Label.UserRoles" />
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-3">
            <div className="add-Org-type-L">
              <div className="Hd-tit-type text-center">
                <h4>
                  <FormattedMessage id="Hd.tit-type" />
                </h4>
              </div>
              <div className="add-Org-cnt-wr">
                <h6>
                  <FormattedMessage id="Label.UserRole1" />
                </h6>
                <p className="small-text">
                  <FormattedMessage id="Label.UserRole1Desc" />
                </p>
                <Input
                  label={useIntlMessages('Label.CustomUsernameOrgType')}
                  id="organizationAdmin"
                  name="OrganizationTypeAdmin"
                  type="text"
                  value={organizationTypeForm.OrganizationTypeAdmin}
                  onChange={onInputHandleChange}
                  placeholder={useIntlMessages('PH.CustomUsernameOrgType')}
                  maxLength={NumberValues.NUM_100}
                  errorMessage={errorFields?.OrganizationTypeAdmin}
                />
              </div>
              <hr className="solid mt-0 mb-0"></hr>
              <div className="add-Org-cnt-wr">
                <h6>
                  <FormattedMessage id="Label.UserRole2" />
                </h6>
                <p className="small-text">
                  <FormattedMessage id="Label.UserRole2Desc" />
                </p>
                <Input
                  label={useIntlMessages('Label.CustomUsernameOrgType')}
                  id="organizationManager"
                  name="OrganizationTypeManager"
                  type="text"
                  value={organizationTypeForm.OrganizationTypeManager}
                  onChange={onInputHandleChange}
                  placeholder={useIntlMessages('PH.CustomUsernameOrgType')}
                  maxLength={NumberValues.NUM_100}
                  errorMessage={errorFields?.OrganizationTypeManager}
                />
              </div>
              <hr className="solid mt-0 mb-0"></hr>
              <div className="add-Org-cnt-wr">
                <h6>
                  <FormattedMessage id="Label.UserRole3" />
                </h6>
                <p className="small-text">
                  <FormattedMessage id="Label.UserRole3Desc" />
                </p>
                <Input
                  label={useIntlMessages('Label.CustomUsernameOrgType')}
                  id="appUserManager"
                  name="OrganizationTypeAppUserManager"
                  type="text"
                  value={organizationTypeForm.OrganizationTypeAppUserManager}
                  onChange={onInputHandleChange}
                  placeholder={useIntlMessages('PH.CustomUsernameOrgType')}
                  maxLength={NumberValues.NUM_100}
                  errorMessage={errorFields?.OrganizationTypeAppUserManager}
                />
              </div>
            </div>
          </Col>
          <Col lg={6} className="mb-3">
            <div className="add-Org-type-L">
              <div className="Hd-tit-type text-center">
                <h4>
                  <FormattedMessage id="Hd.MobileApp" />
                </h4>
              </div>
              <div className="add-Org-cnt-wr">
                <h6>
                  <FormattedMessage id="Label.MobileAppUserRole1" />
                </h6>
                <p className="small-text">
                  <FormattedMessage id="Label.MobileAppUserRole1Desc" />
                </p>
                <Input
                  label={useIntlMessages('Label.CustomUsernameOrgType')}
                  id="appUser"
                  name="OrganizationTypeAppUser"
                  type="text"
                  value={organizationTypeForm.OrganizationTypeAppUser}
                  onChange={onInputHandleChange}
                  placeholder={useIntlMessages('PH.CustomUsernameOrgType')}
                  maxLength={NumberValues.NUM_100}
                  errorMessage={errorFields?.OrganizationTypeAppUser}
                />
              </div>
              <hr className="solid mt-0 mb-0"></hr>
              <div className="add-Org-cnt-wr">
                <h6>
                  <FormattedMessage id="Label.MobileAppUserRole2" />
                </h6>
                <p className="small-text">
                  <FormattedMessage id="Label.MobileAppUserRole2Desc" />
                </p>
                <Input
                  label={useIntlMessages('Label.CustomUsernameOrgType')}
                  id="accountabilityPartner"
                  name="OrganizationTypeAccountabilityPartner"
                  type="text"
                  value={organizationTypeForm.OrganizationTypeAccountabilityPartner}
                  onChange={onInputHandleChange}
                  placeholder={useIntlMessages('PH.CustomUsernameOrgType')}
                  maxLength={NumberValues.NUM_100}
                  errorMessage={errorFields?.OrganizationTypeAccountabilityPartner}
                />
              </div>
              <hr className="solid mt-0 mb-0"></hr>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={6} className="btn-btm d-flex justify-content-lg-end mt-4">
            <Button className="mb-4 btn-cancel btn-sec" variant="outline-primary" onClick={() => navigate('/listorganizationtypes')} onKeyDown={handleCancel}>
              <FormattedMessage id="Button.Cancel" />
            </Button>
            <Button
              className="mb-4 btn-Done"
              variant="primary"
              onClick={onSubmit}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSubmit();
                }
              }}
            >
              <FormattedMessage id="Button.save" />
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default AddOrganizationType;
