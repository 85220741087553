/**
 * @file   src\containers\manageusers\UserDetails.tsx
 * @brief  User  Details.
 * @date   Dec, 2023
 * @author ZCO
 * @copyright (c) 2023, ZCO */

import { useEffect, useState } from 'react';
import '../../assets/css/myprof.scss';
import '../../assets/css/org.scss';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Breadcrumb, Button, Table, ProgressBar } from 'react-bootstrap';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { userdetails, usersHierarchyStudents, usersHierarchyAcPartners, listSurveysForAppUser, listGoalsForAppUser } from '../../store/actions/userActions';
import { setItemLocalStorage, useIntlMessages } from '../../utils/helper';
import moment from 'moment';
import Loader from '../../components/Loader';
import { BUCKET_URL, DATE_TIME_FORMAT_DISPLAY, DATE_FORMAT, PAGE_SIZE, DEFAULT_PAGE_INDEX } from '../../utils/constants';
import UserImg from '../../assets/img/Userimg.jpg';
import { resetUserDetails, resetUserHierarchyAcPartners, resetUserHierarchyStudents } from '../../store/slices/userSlice';
import UserHierarchyList from './UserHierarchyList';
import { RoleTypeIds } from '../../utils/enums';
import { IUserHierarchyStudentsRequest, IUserHierarchyAcPartnersRequest, IUserHierarchyMapData } from '../../interfaces/UserInterface';
import { slide as Filter } from 'react-burger-menu';
import Select from '../../components/MASelect';
import Search from '../../components/MASearch';
import Sort from '../../assets/img/icon/Sort';
import ViewIcon from '../../assets/img/icon/View';
import Close from '../../assets/img/Close.svg';
import { ISurveyListRequest, IGoalListRequest } from '../../interfaces/UserInterface';
import Pagination from '../../components/Pagination';

const organizationType = [
  { label: 'Schools', value: '1' },
  { label: 'Manufacturing companies', value: '2' },
  { label: 'University', value: '3' },
];

const UserDetails = () => {
  // Navigation object
  const navigate = useNavigate();
  // Location object
  const location = useLocation();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Create route param object to access the route parameters.
  const params = useParams();
  // Access redux state variables
  const {
    userDetail,
    userDetailApiLoading,
    userHierarchyStudentsApiData,
    userHierarchyStudentsApiLoading,
    userDetailViewApiSuccess,
    userHierarchyStudentsApiSuccess,
    userHierarchyAcPartnersApiData,
    userHierarchyAcPartnersApiLoading,
    userHierarchyAcPartnersApiSuccess,
    listGoalsApiData,
    listGoalsApiSuccess,
    listSurveysApiData,
    listSurveysApiSuccess,
  } = useAppSelector((state: RootState) => state.user);
  // Initialize component state variables.
  const [currentPageGoal, setCurrentPageGoal] = useState<number>(1);
  const [currentPageSurvey, setCurrentPageSurvey] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [userList, setUserList] = useState<IUserHierarchyMapData[]>([]);
  const [userListStudents, setUserListStudents] = useState<IUserHierarchyMapData[]>([]);
  const [hierarchyStudentsApiParams, setHierarchyStudentsApiParams] = useState<IUserHierarchyStudentsRequest>({
    AppUserID: '',
    OrganizationID: '',
    Page: currentPage,
  });
  const [hierarchyAcPartnersApiParams, setHierarchyAcPartnersApiParams] = useState<IUserHierarchyAcPartnersRequest>({
    AccPartnerUserId: '',
    Category: [],
    Page: currentPage,
    Search: '',
    SubCategories: [],
  });
  const [listSurveyRequest, setListSurveyRequest] = useState<ISurveyListRequest>({
    OrganizationID: '3364176822722887975',
    Page: 0,
    PageSize: 0,
    Status: 0,
    UserID: '3476506330586415197',
    Search: '',
  });
  const [listGoalsRequest, setListGoalsRequest] = useState<IGoalListRequest>({
    OrganizationID: '3364176822722887975',
    Page: 1,
    SearchText: '',
    Status: '',
    UserId: '3476506330586415197',
  });
  const [searchTextGoal, setSearchTextGoal] = useState<string>('');
  const [searchTextSurvey, setSearchTextSurvey] = useState<string>('');
  // Initialize language variables.
  const NillText = useIntlMessages('Label.Nill');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  const roleId: any = Number(params.roleTypeId);
  // call view api ,user hierarchy apis  on initial loading
  useEffect(() => {
    try {
      const userId: string = params.userId || '0';
      dispatch(
        userdetails({
          UserId: userId,
        }),
      );
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [location]);

  useEffect(() => {
    try {
      if (userDetailViewApiSuccess && userDetail) {
        if (roleId === RoleTypeIds.APP_USER && params.userId !== undefined) {
          const stuApiParams = {
            AppUserID: params.userId || '0',
            OrganizationID: userDetail.OrganizationID,
            Page: currentPage,
          };
          setHierarchyStudentsApiParams(stuApiParams);
          dispatch(usersHierarchyStudents(stuApiParams));
          dispatch(resetUserHierarchyAcPartners());
        } else if (roleId === RoleTypeIds.ACCOUNTABILITY_PARTNER && params.userId !== undefined) {
          const accApiParams = {
            AccPartnerUserId: params.userId || '0',
            Category: [],
            Page: currentPage,
            Search: '',
            SubCategories: [],
          };
          setHierarchyAcPartnersApiParams(accApiParams);
          dispatch(usersHierarchyAcPartners(accApiParams));
          dispatch(resetUserHierarchyStudents());
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [userDetailApiLoading]);
  // scroll to top. reset data on component unmounting
  useEffect(() => {
    try {
      window.scrollTo(0, 0);
      return () => {
        dispatch(resetUserDetails());
        dispatch(resetUserHierarchyAcPartners());
        dispatch(resetUserHierarchyStudents());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // set data for user list - user hierarchy for students
  useEffect(() => {
    try {
      if (userHierarchyStudentsApiSuccess && userHierarchyStudentsApiData?.AccountabilityPartners) {
        const userlist = userHierarchyStudentsApiData.AccountabilityPartners.map((user: any) => ({
          name: user.Name,
          status: user.Status,
          prof_image: user.Image,
          userid: user.Id,
        }));
        setUserList(userlist);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [userHierarchyStudentsApiLoading]);
  // set data for user list - user hierarchy for accountability partners
  useEffect(() => {
    try {
      if (userHierarchyAcPartnersApiSuccess && userHierarchyAcPartnersApiData?.AppUsersUnderMentor) {
        const userlist = userHierarchyAcPartnersApiData.AppUsersUnderMentor.map((user: any) => ({
          name: user.Name,
          status: true,
          prof_image: user.ProfileImage,
          userid: user.UserId,
        }));
        setUserListStudents(userlist);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [userHierarchyAcPartnersApiLoading]);
  // set new page number for pagination
  useEffect(() => {
    try {
      setHierarchyStudentsApiParams((prev: IUserHierarchyStudentsRequest) => ({
        ...prev,
        Page: currentPage,
      }));
      setHierarchyAcPartnersApiParams((prev: IUserHierarchyAcPartnersRequest) => ({
        ...prev,
        Page: currentPage,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [currentPage]);
  // call user hierarchy apis when page changes
  useEffect(() => {
    try {
      if (roleId === RoleTypeIds.APP_USER && userHierarchyStudentsApiSuccess) {
        dispatch(usersHierarchyStudents(hierarchyStudentsApiParams));
      } else if (roleId === RoleTypeIds.ACCOUNTABILITY_PARTNER && userHierarchyAcPartnersApiSuccess) {
        dispatch(usersHierarchyAcPartners(hierarchyAcPartnersApiParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [hierarchyStudentsApiParams.Page, hierarchyAcPartnersApiParams.Page]);

  useEffect(() => {
    try {
      dispatch(listGoalsForAppUser(listGoalsRequest));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listGoalsRequest]);
  useEffect(() => {
    try {
      dispatch(listSurveysForAppUser(listSurveyRequest));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listSurveyRequest]);

  const getSubCategories = (subCategories: any) => {
    let finalString = '';
    try {
      if (subCategories) {
        subCategories.forEach((sub: { classid: number; classname: string }) => (finalString = finalString + sub.classname + ','));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
    return finalString;
  };
  const handleSearchChange = (event: any, context: string) => {
    try {
      if (context === 'goal') {
        setSearchTextGoal(event.target.value);
      } else {
        setSearchTextSurvey(event.target.value);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Search Click.
  const handleSearchClick = (context: string) => {
    try {
      if (context === 'goal') {
        setListGoalsRequest((apiParams: IGoalListRequest) => ({
          ...apiParams,
          SearchText: searchTextGoal,
          Page: DEFAULT_PAGE_INDEX,
        }));
        setCurrentPageGoal(DEFAULT_PAGE_INDEX);
      } else {
        setListSurveyRequest((params: ISurveyListRequest) => ({
          ...params,
          Search: searchTextSurvey,
          Page: DEFAULT_PAGE_INDEX,
        }));
        setCurrentPageSurvey(DEFAULT_PAGE_INDEX);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  return (
    <>
      {(userDetailApiLoading || userHierarchyAcPartnersApiLoading || userHierarchyStudentsApiLoading) && <Loader />}
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          {userDetail && userDetail?.RoleName} <FormattedMessage id="Label.Details" />
        </h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageuser')}>
              <FormattedMessage id="Hd.ManageUsers" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {userDetail && userDetail?.RoleName} <FormattedMessage id="Label.Details" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="myprof-cont ">
        <Row>
          <Col xl={4} lg={5} className="mypro-sec-L ">
            <div>
              <div className="pro-bg "></div>
              <img src={userDetail && userDetail?.LogoImage ? BUCKET_URL + userDetail?.LogoImage : UserImg} className="prof-wrp" alt="" />
              <div className="mng-pro-det">
                <h6>{userDetail && userDetail?.FullName}</h6>
                <span>{userDetail && userDetail?.RoleName}</span>
                {userDetail?.LastLoginTime != null && (
                  <span className="text-center d-block mt-3">
                    {useIntlMessages('Label.LastLogin')}: {moment(userDetail.LastLoginTime).local().format(DATE_TIME_FORMAT_DISPLAY)}
                  </span>
                )}
                <div className="d-flex justify-content-center bt-sec mt-3">
                  {location?.state?.tabName === 'System Admin' && (
                    <Button variant="primary" size="sm" onClick={() => navigate(`/managepermission/${params.userId}`)}>
                      <FormattedMessage id="Button.MngPermissions" />
                    </Button>
                  )}
                  {roleId === RoleTypeIds.ORGANIZATION_ADMIN && (
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => {
                        navigate(`/assignorgs/${userDetail.OrganizationTypeId}/${params.userId}`);
                        setItemLocalStorage('org_admin_org_id', userDetail.OrganizationID);
                      }}
                    >
                      <FormattedMessage id="Button.AddOrganization" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col className="content-sub">
            <div>
              <div className="content-sub-header d-flex justify-content-between align-items-center">
                <div>
                  <h4>
                    <FormattedMessage id="Hd.PersInfo" />
                  </h4>
                </div>
              </div>
              <div className="content-area-padding">
                <Row className="label-with-content mb-3">
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.OrgName" />
                    </label>
                    <p>{userDetail && userDetail?.OrganizationName ? userDetail?.OrganizationName : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.DateofBirth" />
                    </label>
                    <p>{userDetail && userDetail?.Dob ? moment(userDetail.Dob).local().format(DATE_FORMAT) : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.Gender" />
                    </label>
                    <p>{userDetail && userDetail.Gender ? (userDetail.Gender === 'F' ? 'Female' : userDetail.Gender === 'M' ? 'Male' : NillText) : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}></Col>
                </Row>
                <Row className="label-with-content mb-3">
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.Email" />
                    </label>
                    <p>{userDetail && userDetail?.EmailId ? userDetail?.EmailId : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.PhoneNum" />
                    </label>
                    <p>{userDetail && userDetail?.PhoneNumber ? userDetail?.PhoneNumber : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.DateofJoining" />
                    </label>
                    <p>{userDetail && userDetail?.JoiningDate ? moment(userDetail?.JoiningDate).local().format(DATE_FORMAT) : NillText}</p>
                  </Col>
                  {location?.state?.tabName === 'Student' && (
                    <Col xl lg={6} md={3} sm={6}>
                      <label>
                        <FormattedMessage id="Label.Tags" />
                      </label>
                      <p>{userDetail && userDetail?.Tags && userDetail?.Tags.length > 0 ? userDetail.Tags.join(', ') : NillText}</p>
                    </Col>
                  )}
                  <Col xl lg={6} md={3} sm={6}></Col>
                </Row>
                <Row className="label-with-content mb-3">
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.Address" />
                    </label>
                    <p>{userDetail && userDetail?.Address ? userDetail?.Address : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.City" />
                    </label>
                    <p>{userDetail && userDetail?.CityName ? userDetail?.CityName : NillText}</p>
                  </Col>
                  <Col xl lg={6} md={3} sm={6} className="d-flex">
                    <div>
                      <label>
                        <FormattedMessage id="Label.State" />
                      </label>
                      <p>{userDetail && userDetail?.StateName ? userDetail?.StateName : NillText}</p>
                    </div>
                  </Col>
                  <Col xl lg={6} md={3} sm={6} className="d-flex">
                    <div className="zip-col">
                      <label>
                        <FormattedMessage id="Label.Zip" />
                      </label>
                      <p>{userDetail && userDetail?.ZipName ? userDetail?.ZipName : NillText}</p>
                    </div>
                  </Col>
                </Row>
                <Row className="label-with-content mb-3">
                  <Col xl lg={6} md={3} sm={6}>
                    <label>
                      <FormattedMessage id="Label.HighestEducation" />
                    </label>
                    <p>{userDetail && userDetail?.HighestEducation ? userDetail?.HighestEducation : NillText}</p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="content-sub mt-2">
        <div className="content-sub-header d-flex justify-content-between align-items-center">
          <h4>All Goals</h4>
        </div>
        <div className="content-sub-header d-flex justify-content-between align-items-center">
          <Col lg={3} className="form-sm">
            <Search
              onChange={(event: any) => handleSearchChange(event, 'goal')}
              onClick={() => handleSearchClick('goal')}
              placeholder={useIntlMessages('PH.SearchGoal')}
              size="sm"
            />
          </Col>
          <Col md="auto" className="btn-container d-flex">
            <Filter width={450} right pageWrapId={'filter-wrapper'} outerContainerId={'outer-container'} customCrossIcon={<img src={Close} />}>
              <div id="filter-wrapper" className="filter-main">
                <h4 className="mb-5">Filter Organizations</h4>
                <Col className="form-sm">
                  <Select
                    label="Organization Type"
                    options={organizationType}
                    value=""
                    placeholder="Select"
                    onChange={() => {
                      console.log('sss');
                    }}
                  />
                  <Row>
                    <Col>
                      <Select
                        label="Status"
                        options={status}
                        value=""
                        placeholder="Select"
                        onChange={() => {
                          console.log('sss');
                        }}
                      />
                    </Col>
                    <Col>
                      <Select
                        label="Subscription Plan"
                        options=""
                        value=""
                        placeholder="Select"
                        onChange={() => {
                          console.log('sss');
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Row className="mt-4">
                  <Col>
                    <Button variant="outline-primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                      Clear Filters
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                      Apply Filters
                    </Button>
                  </Col>
                </Row>
              </div>
            </Filter>
            <Button variant="primary" size="sm" onClick={() => navigate('/addorganization')}>
              <FormattedMessage id="Button.AssignGoal" />
            </Button>
          </Col>
        </div>
        <div className="content-area-padding">
          <div className="bdr-content-area">
            <Table striped hover responsive>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="Label.GoalName" />
                    <Link to="" className="ms-1" onClick={() => window.alert('Not Implemented')}>
                      <Sort />
                    </Link>
                  </th>
                  <th>
                    <FormattedMessage id="Label.Category" />
                    <Link to="" className="ms-1" onClick={() => window.alert('Not Implemented')}>
                      <Sort />
                    </Link>
                  </th>
                  <th>
                    <FormattedMessage id="Label.SubCategory" />
                    <Link to="" className="ms-1" onClick={() => window.alert('Not Implemented')}>
                      <Sort />
                    </Link>
                  </th>
                  <th>
                    <FormattedMessage id="Label.CreatedBy" />
                    <Link to="" className="ms-1" onClick={() => window.alert('Not Implemented')}>
                      <Sort />
                    </Link>
                  </th>
                  <th>
                    <FormattedMessage id="Label.Status" />
                    <Link to="" className="ms-1" onClick={() => window.alert('Not Implemented')}>
                      <Sort />
                    </Link>
                  </th>
                  <th className="w-50px"></th>
                </tr>
              </thead>
              <tbody>
                {listGoalsApiSuccess &&
                  listGoalsApiData.Goals &&
                  listGoalsApiData.Goals.map((goal: any) => (
                    <tr>
                      <td>{goal.GoalName}</td>
                      <td>{goal.CategoryName}</td>
                      <td>{getSubCategories(goal.GoalClasses)}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="td-user-image">
                            <img src={UserImg} className="w-100" alt="user" />
                          </span>
                          {goal.CreatedBy}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center multiple-user">
                          <ProgressBar now={goal.CompletedPercentage} />{' '}
                          <span className="ps-2">
                            <label className="td-progressbar">
                              {goal.CompletedPercentage}% <span className="inprogress">{goal.UserStatus}</span>
                            </label>
                          </span>
                        </div>
                      </td>
                      <td>
                        <Button
                          variant="dark"
                          size="sm"
                          className="icon-btn"
                          onClick={() =>
                            navigate('/userDetails', {
                              state: {
                                tabName: 'Student',
                              },
                            })
                          }
                        >
                          <ViewIcon />
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {listGoalsApiData && listGoalsApiData.Goals && listGoalsApiData.Goals.length > 0 && listGoalsApiData.TotalCount > PAGE_SIZE && (
              <Pagination
                pageSize={PAGE_SIZE}
                totalitems={listGoalsApiData.TotalCount}
                pageLimit={PAGE_SIZE}
                setCurrentPage={(page: number) => setCurrentPageGoal(page)}
                currentPage={currentPageGoal - 1}
                prevPage={-1}
                itemsDisplayed={listGoalsApiData.Goals.length}
              />
            )}
          </div>
        </div>
      </div>
      <div className="content-sub mt-2">
        <div className="content-sub-header d-flex justify-content-between align-items-center">
          <h4>All Surveys</h4>
        </div>
        <div className="content-sub-header d-flex justify-content-between align-items-center">
          <Col lg={3} className="form-sm">
            <Search
              onChange={(event: any) => handleSearchChange(event, 'survey')}
              onClick={() => handleSearchClick('survey')}
              placeholder={useIntlMessages('PH.SearchGoal')}
              size="sm"
            />
          </Col>
          <Col md="auto" className="btn-container d-flex">
            <Filter width={450} right pageWrapId={'filter-wrapper'} outerContainerId={'outer-container'} customCrossIcon={<img src={Close} />}>
              <div id="filter-wrapper" className="filter-main">
                <h4 className="mb-5">Filter Organizations</h4>
                <Col className="form-sm">
                  <Select
                    label="Organization Type"
                    options={organizationType}
                    value=""
                    placeholder="Select"
                    onChange={() => {
                      console.log('sss');
                    }}
                  />
                  <Row>
                    <Col>
                      <Select
                        label="Status"
                        options={status}
                        value=""
                        placeholder="Select"
                        onChange={() => {
                          console.log('sss');
                        }}
                      />
                    </Col>
                    <Col>
                      <Select
                        label="Subscription Plan"
                        options=""
                        value=""
                        placeholder="Select"
                        onChange={() => {
                          console.log('sss');
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Row className="mt-4">
                  <Col>
                    <Button variant="outline-primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                      Clear Filters
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="primary" className="w-100" onClick={() => window.alert('Not Implemented')}>
                      Apply Filters
                    </Button>
                  </Col>
                </Row>
              </div>
            </Filter>
            <Button variant="primary" size="sm" onClick={() => navigate('/listorganizationtypes')}>
              <FormattedMessage id="Button.AssignSurvey" />
            </Button>
          </Col>
        </div>
        <div className="content-area-padding">
          <Table striped hover responsive>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="Label.SurveyName" />
                  <Link to="" className="ms-1" onClick={() => window.alert('Not Implemented')}>
                    <Sort />
                  </Link>
                </th>
                <th>
                  <FormattedMessage id="Label.Category" />
                  <Link to="" className="ms-1" onClick={() => window.alert('Not Implemented')}>
                    <Sort />
                  </Link>
                </th>
                <th>
                  <FormattedMessage id="Label.SubCategory" />
                  <Link to="" className="ms-1" onClick={() => window.alert('Not Implemented')}>
                    <Sort />
                  </Link>
                </th>
                <th>
                  <FormattedMessage id="Label.CreatedBy" />
                  <Link to="" className="ms-1" onClick={() => window.alert('Not Implemented')}>
                    <Sort />
                  </Link>
                </th>
                <th>
                  <FormattedMessage id="Label.Status" />
                  <Link to="" className="ms-1" onClick={() => window.alert('Not Implemented')}>
                    <Sort />
                  </Link>
                </th>
                <th className="w-50px"></th>
              </tr>
            </thead>
            <tbody>
              {listSurveysApiSuccess &&
                listSurveysApiData.SurveyList &&
                listSurveysApiData.SurveyList.map((survey: any) => (
                  <tr>
                    <td>{survey.SurveyName}</td>
                    <td>{survey.CategoryName}</td>
                    <td>{getSubCategories(survey.SurveyClasses)}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="td-user-image">
                          <img src={UserImg} className="w-100" alt="user" />
                        </span>
                        {survey.CreatedBy}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center multiple-user">
                        <ProgressBar now={60} />{' '}
                        <span className="ps-2">
                          <label className="td-progressbar">
                            <span className="inprogress">{survey.Status}</span>
                          </label>
                        </span>
                      </div>
                    </td>
                    <td>
                      <Button
                        variant="dark"
                        size="sm"
                        className="icon-btn"
                        onClick={() =>
                          navigate('/userDetails', {
                            state: {
                              tabName: 'Student',
                            },
                          })
                        }
                      >
                        <ViewIcon />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {listSurveysApiData && listSurveysApiData.SurveyList && listSurveysApiData.SurveyList.length > 0 && listSurveysApiData.TotalCount > PAGE_SIZE && (
            <Pagination
              pageSize={PAGE_SIZE}
              totalitems={listSurveysApiData.TotalCount}
              pageLimit={PAGE_SIZE}
              setCurrentPage={(page: number) => setCurrentPageSurvey(page)}
              currentPage={currentPageSurvey - 1}
              prevPage={-1}
              itemsDisplayed={listSurveysApiData.SurveyList.length}
            />
          )}
        </div>
      </div>
      <>
        {(roleId === RoleTypeIds.APP_USER || roleId === RoleTypeIds.ACCOUNTABILITY_PARTNER) && (
          <Row>
            <Col>
              <div className="content-sub">
                <div className="content-sub-header d-flex justify-content-between align-items-center">
                  <div>
                    <h4>
                      <FormattedMessage id="Hd.UserHierarchy" />
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            {userHierarchyStudentsApiSuccess && userHierarchyStudentsApiData && (
              <UserHierarchyList
                usersList={userList}
                roleName="Accountability Partners"
                count={userHierarchyStudentsApiSuccess ? userHierarchyStudentsApiData.TotalCount : 0}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={10}
                totalItems={userHierarchyStudentsApiSuccess ? userHierarchyStudentsApiData.TotalCount : 0}
              />
            )}
            {userHierarchyAcPartnersApiSuccess && userHierarchyAcPartnersApiData && (
              <UserHierarchyList
                usersList={userListStudents}
                roleName="Students"
                count={userHierarchyAcPartnersApiSuccess ? userHierarchyAcPartnersApiData.TotalCount : 0}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={10}
                totalItems={userHierarchyAcPartnersApiSuccess ? userHierarchyAcPartnersApiData.TotalCount : 0}
              />
            )}
          </Col>
        </Row>
      </>
    </>
  );
};
export default UserDetails;
