/**
 * @file   src\containers\organizations\Manage.tsx
 * @brief  Organization managing page.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from 'react';
import '../../assets/css/Home.scss';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'react-bootstrap';
import Select from '../../components/MASelect';
import Search from '../../components/MASearch';
import OrganizationCard from '../../components/OrganizationCard';
import Close from '../../assets/img/Close.svg';
import { slide as Filter } from 'react-burger-menu';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ISelectOptionsNumber, ISelectOptionsString } from '../../interfaces/GeneralInterface';
import { getOrganizationList, subscriptionPlans } from '../../store/actions/organizationActions';
import { getOrganizationTypeDropDownList } from '../../store/actions/organizationTypeActions';
import Loader from '../../components/Loader';
import useDebounce from '../../hooks/useDebounce';
import { PAGE_SIZE_9, DEFAULT_PAGE_INDEX } from '../../utils/constants';
import { IListOrganisationForm } from '../../interfaces/OrganisationInterface';
import { useIntlMessages } from '../../utils/helper';
// Values for organization status
const status: Array<any> = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

// Default params for api request
const organizationDefaultParams: IListOrganisationForm = {
  OrganizationTypeId: 0,
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE_9,
  Search: '',
  Status: '',
  SubscriptionPlanId: 0,
};

// Default params for filter
const organizationDefaultFilters = {
  OrganizationTypeId: 0,
  Status: '',
  SubscriptionPlanId: 0,
};

const ManageOrganizations = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();

  // Access redux state variables
  const { organizationTypeDropDownData, organizationTypeDropDownLoading, organizationTypeDropDownSuccess } = useAppSelector((state: RootState) => state.organizationType);
  const { organizationListApiData, organizationListApiLoading, organizationListApiSuccess, subscriptionPlansApiData, subscriptionPlansApiLoading, subscriptionPlansApiSuccess } =
    useAppSelector((state: RootState) => state.organization);

  // Component states
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<Array<any>>([]);
  const [organizationTypeValue, setOrganizationTypeValue] = useState<ISelectOptionsString | []>([]);
  const [organizationApiParams, setOrganizationApiParams] = useState<any>(organizationDefaultParams);
  const [planValue, setPlanValue] = useState<ISelectOptionsNumber | []>([]);
  const [planeOptions, setPlaneOptions] = useState<Array<any>>([]);
  const [statusValue, setStatusValue] = useState<ISelectOptionsString | []>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [organizationFilters, setOrganizationFilters] = useState<any>(organizationDefaultFilters);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  // Invoke debounce component on intervel
  const debouncedSearch = useDebounce(searchText, 500);
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');

  // Initial rendering
  useEffect(() => {
    try {
      dispatch(getOrganizationTypeDropDownList({}));
      dispatch(subscriptionPlans({}));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // params setting after debounce for search
  useEffect(() => {
    try {
      setOrganizationApiParams((apiParams: any) => ({
        ...apiParams,
        Search: searchText,
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPage(1);
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [debouncedSearch]);

  // set data for organization type dropdown
  useEffect(() => {
    try {
      if (organizationTypeDropDownSuccess && organizationTypeDropDownData?.length > 0) {
        const orgTypeOptions = organizationTypeDropDownData.map(
          (orgtype: any): ISelectOptionsNumber => ({
            label: orgtype.orgTypeName,
            value: orgtype.orgTypeId,
          }),
        );
        setOrganizationTypeOptions(orgTypeOptions);
        setOrganizationTypeValue([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationTypeDropDownLoading]);

  // set data for subscription plan dropdown
  useEffect(() => {
    try {
      if (subscriptionPlansApiSuccess && subscriptionPlansApiData?.length > 0) {
        const SubPlanOptions = subscriptionPlansApiData.map(
          (orgtype: any): ISelectOptionsNumber => ({
            label: orgtype.PlanName,
            value: orgtype.PlanID,
          }),
        );
        setPlaneOptions(SubPlanOptions);
        setPlanValue([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [subscriptionPlansApiLoading]);

  // api call to get organization list
  useEffect(() => {
    try {
      dispatch(getOrganizationList(organizationApiParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationApiParams]);

  // handle pagination
  useEffect(() => {
    try {
      setOrganizationApiParams((apiParams: any) => ({
        ...apiParams,
        Page: currentPage,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [currentPage]);

  // handle seach box change
  const handleSearchChange = (event: any) => {
    try {
      setSearchText(event.target.value);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Search Field input key down.
  const handleSearchKeyDown = (event: any) => {
    try {
      if (event.key === 'Enter') {
        event.preventDefault();
        setOrganizationApiParams((apiParams: any) => ({
          ...apiParams,
          Search: searchText,
          Page: DEFAULT_PAGE_INDEX,
        }));
        setCurrentPage(DEFAULT_PAGE_INDEX);
      } else {
        setSearchText(event.target.value);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle seach icon click
  const handleSearchClick = () => {
    try {
      setOrganizationApiParams((apiParams: any) => ({
        ...apiParams,
        Search: searchText,
        Page: 0,
      }));
      setCurrentPage(1);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle organization type change
  const handleOrganizationTypeChange = (event: any) => {
    try {
      setOrganizationFilters((filterParams: any) => ({
        ...filterParams,
        OrganizationTypeId: event.value,
      }));
      setOrganizationTypeValue(event);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle subscription plan change
  const handlePlanValueChange = (event: any) => {
    try {
      setOrganizationFilters((filterParams: any) => ({
        ...filterParams,
        SubscriptionPlanId: event.value,
      }));
      setPlanValue(event);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle organization status change
  const handleStatusValueChange = (event: ISelectOptionsNumber) => {
    try {
      setOrganizationFilters((filterParams: any) => ({
        ...filterParams,
        Status: event.value,
      }));
      setStatusValue(event);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle apply filter
  const handleFilterChange = () => {
    try {
      setIsFilterApplied(true);
      setOrganizationApiParams((apiParams: any) => ({
        ...apiParams,
        OrganizationTypeId: organizationFilters.OrganizationTypeId,
        Status: organizationFilters.Status,
        SubscriptionPlanId: organizationFilters.SubscriptionPlanId,
        Page: 0,
      }));
      setOpen(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle reset filter
  const handleFilterReset = () => {
    try {
      setIsFilterApplied(false);
      setOrganizationApiParams((apiParams: any) => ({
        ...apiParams,
        OrganizationTypeId: 0,
        Status: '',
        SubscriptionPlanId: 0,
      }));
      setPlanValue([]);
      setStatusValue([]);
      setOrganizationTypeValue([]);
      setOpen(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Hd.ManageOrganizations" />
        </h3>
      </div>
      <div className="content-sub">
        <div className="content-sub-header header-light-bg d-flex justify-content-between align-items-center">
          <Col lg={3} className="form-sm">
            <Search
              placeholder="Search with Name"
              size="sm"
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
              onClick={handleSearchClick}
              value={searchText ? searchText : ''}
            />
          </Col>
          <Col md="auto" className="btn-container d-flex">
            <Filter
              isOpen={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => setOpen(false)}
              width={450}
              right
              pageWrapId={'filter-wrapper'}
              outerContainerId={'outer-container'}
              customCrossIcon={<img src={Close} />}
              burgerButtonClassName={isFilterApplied ? 'active' : ''}
            >
              <div id="filter-wrapper" className="filter-main">
                <h4 className="mb-5">Filter Organizations</h4>
                <Col className="form-sm">
                  <Select
                    label="Organization Type"
                    options={organizationTypeOptions}
                    value={organizationTypeValue}
                    placeholder="Select"
                    onChange={(e: any) => handleOrganizationTypeChange(e)}
                  />
                  <Row>
                    <Col>
                      <Select label="Status" options={status} value={statusValue} placeholder="Select" onChange={(e: any) => handleStatusValueChange(e)} />
                    </Col>
                    <Col>
                      <Select label="Subscription Plan" options={planeOptions} value={planValue} placeholder="Select" onChange={(e: any) => handlePlanValueChange(e)} />
                    </Col>
                  </Row>
                </Col>
                <Row className="mt-4">
                  <Col>
                    <Button variant="outline-primary" className="w-100" onClick={handleFilterReset}>
                      Clear Filters
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="primary" className="w-100" onClick={handleFilterChange}>
                      Apply Filters
                    </Button>
                  </Col>
                </Row>
              </div>
            </Filter>
            <Button variant="primary" size="sm" onClick={() => navigate('/addorganization')}>
              <FormattedMessage id="Button.AddOrganization" />
            </Button>
            <Button variant="primary" size="sm" onClick={() => navigate('/listorganizationtypes')}>
              <FormattedMessage id="Button.ViewOrganizationTypes" />
            </Button>
          </Col>
        </div>
        <div className="content-area-padding">
          <Row className="mb-4">
            {organizationListApiSuccess && (organizationListApiData?.Organization?.length == 0 || !organizationListApiData) ? (
              <div className="text-center mt-5">No results found.</div>
            ) : (
              organizationListApiSuccess &&
              organizationListApiData?.Organization?.length > 0 &&
              organizationListApiData?.Organization?.map((organization: any, index: number) => (
                <Col xl={4} lg={6} md={6} key={index}>
                  <OrganizationCard
                    orgImage={organization.LogoImage}
                    organizationname={organization.OrganizationName}
                    organizationId={organization.OrganizationID}
                    category={organization.OrganizationType}
                    phone={organization.PhoneNumber}
                    countrycode={organization.CountryCode}
                    location={organization.Address}
                    active={organization.ActiveStatus}
                    planduration={organization.SubscriptionPlan ? organization.SubscriptionPlan : ''}
                    planstatus={organization.SubplanStatus}
                  />
                </Col>
              ))
            )}
          </Row>
          {organizationListApiSuccess && organizationListApiData?.Organization?.length > 0 && organizationListApiData.TotalCount > PAGE_SIZE_9 && (
            <Pagination
              pageSize={PAGE_SIZE_9}
              totalitems={organizationListApiData.TotalCount}
              pageLimit={PAGE_SIZE_9}
              setCurrentPage={(page: number) => setCurrentPage(page)}
              currentPage={currentPage - 1}
              prevPage={-1}
              itemsDisplayed={organizationListApiData?.Organization?.length}
            />
          )}
        </div>
      </div>
      {(organizationTypeDropDownLoading || organizationListApiLoading || subscriptionPlansApiLoading) && <Loader />}
    </>
  );
};
export default ManageOrganizations;
