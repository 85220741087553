/**
 * @file   src\components\addMotivation.tsx
 * @brief  404 page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import '../../assets/css/org.scss';
import '../../assets/css/style.scss';
import '../../assets/css/Resource.scss';
import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Breadcrumb, Row } from 'react-bootstrap';
import Select from '../../components/MASelect';
import plusicon from '../../assets/img/icon_plus_wt.svg';
import icnDeleteCr from '../../assets/img/icn-delete-clr.svg';
import Input from '../../components/MAInput';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  useIntlActionMessages,
  useIntlMessages,
  checkValidFile,
  getCurrentOrgDetails,
  isAdminTeacherManager,
  getFromLocalStorage,
  bytesToSize,
  getFileTypeNumber,
  isUserAdmin,
} from '../../utils/helper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ResourcetypeID, NumberValues, IMAGE_TYPES_SUPPORTED, UploaderProps, FILE_TYPES_SUPPORTED } from '../../utils/enums';
import { RootState } from '../../store';
import Loader from '../../components/Loader';
import UploadNote from '../../components/UploadNote';
import { addResource, resourcedetails, editResource, deleteResourceAttachment } from '../../store/actions/resourceActions';
import { resetResourceAdd, resetResourceEdit, resetResourceDetail, resetDeleteResourceAttachment } from '../../store/slices/resourceSlice';
import { uploadFile, uploadFilePathUpdate, uploadFilePathUpdateMultiple } from '../../store/actions/organizationActions';
import { INCENTIVE_RESOURCE_SCHEMA } from '../../validations/IncentiveResourceSchema';
import { validateForm } from '../../utils/formValidation';
import { IResourceForm, IGroupRequestParams } from '../../interfaces/ResourceInterface';
import { IUploadFilePathUpdateParams, IuploadFilePathUpdateMultipleParams } from '../../interfaces/OrganisationInterface';
import { MessageToaster } from '../../utils/ToastUtil';
import ImageUpload from '../../components/ImageUpload';
import { resetUploadFile } from '../../store/slices/organizationSlice';
import Image from '../../assets/img/icon/Image';
import Audio from '../../assets/img/icon/Audio';
import Video from '../../assets/img/icon/Video';
import Doc from '../../assets/img/icon/Attachment';
import Checkbox from '../../components/MACheck';
import CreatableSelect from 'react-select/creatable';
import { ISelectOption } from '../../interfaces/GeneralInterface';
import { listGroupByType } from '../../store/actions/competencyActions';

// Declare constants
const fileType = [
  { label: 'Image', value: 'image' },
  { label: 'Video', value: 'video' },
  { label: 'Audio', value: 'audio' },
  { label: 'Document', value: 'document' },
];
// Declare default params
const savedefaultRequestParams: IResourceForm = {
  ResourceName: '',
  ResourcetypeID: ResourcetypeID.INCENTIVE_CONTENT,
  CategoryID: 0,
  ClassID: [],
  CreatedUserRoleTypeID: 0,
  Description: '',
  OrganizationID: '',
  OrganizationTypeId: 0,
  Visibility: [0],
  GoalID: '',
  IsGeneral: false,
  Groups: [],
  IsGeneralGroup: false,
};
const defaultParamsListGroups: IGroupRequestParams = {
  Page: 0,
  PageSize: 0,
  Type: 2,
  SearchText: '',
  PageSource: '',
};

// Call to get  file Icon
const getIcon = (fileType: string) => {
  switch (fileType?.toLowerCase()) {
    case 'audio':
      return <Audio />;
    case 'image':
      return <Image />;
    case 'video':
      return <Video />;
    case 'document':
      return <Doc />;
    default:
      return <Image />;
  }
};

const AddIncentiveResource = () => {
  // Navigation object
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Location object
  const location = useLocation();
  // Toast object creation.
  const toast = new MessageToaster();

  // Ref for  input element on the page
  const fileUploadRef = useRef<any>();
  const resourceInputRef = useRef<any>();
  const imageRef = useRef<any>();

  // Access redux state variables
  const {
    addResourceApiLoading,
    addResourceApiSuccess,
    addResourceApiResponseCode,
    addResourceApiResponseMessage,
    addResourceApiData,
    editResourceApiLoading,
    editResourceApiSuccess,
    editResourceApiResponseCode,
    editResourceApiResponseMessage,
    resourceDetail,
    resourceDetailApiLoading,
    resourceDetailViewApiSuccess,
    resourceDetailViewApiResponseCode,
    deleteResourceAttachmentApiLoading,
  } = useAppSelector((state: RootState) => state.resource);

  const {
    uploadFileApiData,
    uploadFileApiLoading,
    uploadFileApiSuccess,
    uploadFileResponseCode,
    uploadFileResponseMessage,
    uploadFilePathUpdateApiLoading,
    uploadFilePathUpdateApiSuccess,
    uploadFilePathUpdateResponseCode,
    uploadFilePathUpdateResponseMessage,
    uploadFilePathUpdateMultipleApiLoading,
    uploadFilePathUpdateMultipleApiSuccess,
    uploadFilePathUpdateMultipleResponseCode,
    uploadFilePathUpdateMultipleResponseMessage,
  } = useAppSelector((state: RootState) => state.organization);

  const { listGroupData, listGroupApiLoading } = useAppSelector((state: RootState) => state.competency);

  // Initialize component state variables.
  const [resourceForm, setResourceForm] = useState<IResourceForm>(savedefaultRequestParams);
  const [groupParams, setGroupParams] = useState<IGroupRequestParams>(defaultParamsListGroups);
  const [errorFields, setErrorFields] = useState<any>({});
  const [fileTypeValue, setfileTypeValue] = useState<any>(fileType[0]);
  const [fileUploadBegin, setFileUploadBegin] = useState<boolean>(false);
  const [fileUploadError, setFileUploadError] = useState<string>('');
  const [upImageAttachment, setUpImageAttachment] = useState<string | null>(null);
  const [upImageAttachmentDetails, setUpImageAttachmentDetails] = useState<any>({});
  const [upImageThumbnail, setUpImageThumbnail] = useState<string | null>(null);
  const [upImageThumbnailDetails, setUpImageThumbnailDetails] = useState<any>({});
  const [uploadedFileInfo, setUploadedFileInfo] = useState<any>([]);
  const [uploadedFileInfoOld, setUploadedFileInfoOld] = useState<any>([]);
  const [characterCount, setCharacterCount] = useState<number>(0);
  const [uploadThumbnail, setUploadThumbnail] = useState<boolean>(false);
  const [uploadAttachment, setUploadAttachment] = useState<boolean>(false);
  const [beginUpdate, setBeginUpdate] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [startSubmit, setStartSubmit] = useState<boolean>(false);
  const [imgError, setImgError] = useState<string>('');
  const [previewImage, setPreviewImage] = useState<string>('');
  const [deleteList, setDeleteList] = useState<string[]>([]);
  const [isGeneralResource, setIsGeneralResource] = useState(false);
  const [tagOptions, setTagOptions] = useState<ISelectOption[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<ISelectOption[]>([]);

  // Initialize language variables.
  const ImageUploadChooseImage = useIntlActionMessages('Image.Upload.ChooseImage');
  const ImageUploadFileError = useIntlActionMessages('Image.Upload.FileError');
  const ImageUploadChooseAttachment = useIntlActionMessages('Image.Upload.Attachment');
  const AttachmentsText = useIntlActionMessages('Label.Attachments');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  const storageData = getFromLocalStorage('MI_USR_DATA');
  // initial rendering
  useEffect(() => {
    try {
      dispatch(listGroupByType(groupParams));
      dispatch(resetUploadFile());
      dispatch(resetResourceAdd());
      dispatch(resetDeleteResourceAttachment());
      if (location?.state?.resourceId) {
        dispatch(resourcedetails(location?.state?.resourceId));
      } else {
        setResourceForm({
          ...savedefaultRequestParams,
          CreatedUserRoleTypeID: storageData?.RoleTypeId,
        });
        setUploadedFileInfo([]);
        setUpImageAttachmentDetails([]);
        fileTypeChange({ value: 'image' });
        setfileTypeValue(fileType.find((option) => option?.value?.toLowerCase() === 'image'));
      }
      return () => {
        dispatch(resetResourceEdit());
        dispatch(resetResourceDetail());
        dispatch(resetDeleteResourceAttachment());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // Get organization details based roles
  useEffect(() => {
    try {
      const userData = getFromLocalStorage('MI_USR_DATA');
      const OrganizationId = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : userData.OrganizationId;
      if (isAdminTeacherManager()) {
        setResourceForm((prevResourceForm) => ({
          ...prevResourceForm,
          OrganizationTypeId: 0,
          OrganizationID: OrganizationId,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [localStorage.getItem('CURRENT_ORG')]);
  // Handle the upload path api response
  useEffect(() => {
    try {
      const submitUploadsAndSubsequentActions = async () => {
        if (uploadFileResponseCode > 0 && uploadFileApiSuccess && !submit && upImageAttachmentDetails) {
          uploadAttachmentFileToBucket();
          dispatch(resetUploadFile());
        } else if (submit && uploadFileResponseCode > 0 && uploadFileApiSuccess) {
          await uploadThumbnailToBucket();
          dispatch(resetUploadFile());
          if (location?.state?.resourceId) {
            setBeginUpdate(true);
          } else {
            dispatch(addResource(resourceForm));
          }
        }
      };
      submitUploadsAndSubsequentActions();
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFileResponseCode, uploadFileResponseMessage]);

  // api request to the update resource
  useEffect(() => {
    try {
      if (beginUpdate) {
        dispatch(editResource(resourceForm));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [beginUpdate]);

  // Handle add respource api response
  useEffect(() => {
    try {
      if (submit && addResourceApiResponseCode > 0 && addResourceApiResponseMessage && !addResourceApiSuccess) {
        toast.toastError(addResourceApiResponseMessage);
      } else if (submit && addResourceApiResponseCode > 0 && addResourceApiSuccess) {
        setUploadThumbnail(true);
        setSubmit(false);
        // upload thumbnail
        const reqParams: IUploadFilePathUpdateParams = {
          FilePath: upImageThumbnailDetails.name,
          Type: 'resourceimage',
          TypeId: location?.state?.resourceId ? location?.state?.resourceId : addResourceApiData?.ResourceId,
        };
        // Api request to update file info after bucket upload and save organization info
        dispatch(uploadFilePathUpdate(reqParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [addResourceApiResponseCode, addResourceApiResponseMessage]);

  // Handle edit respource api response
  useEffect(() => {
    try {
      if (submit && editResourceApiResponseCode > 0 && editResourceApiResponseMessage && !editResourceApiSuccess) {
        toast.toastError(editResourceApiResponseMessage);
        setStartSubmit(false);
      } else if (submit && editResourceApiResponseCode > 0 && editResourceApiSuccess) {
        setUploadThumbnail(true);
        setSubmit(false);
        // upload thumbnail
        const reqParams: IUploadFilePathUpdateParams = {
          FilePath: upImageThumbnailDetails.name,
          Type: 'resourceimage',
          TypeId: location?.state?.resourceId,
        };
        // Api request to update file info after bucket upload and save organization info
        dispatch(uploadFilePathUpdate(reqParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [editResourceApiResponseCode, editResourceApiResponseMessage]);

  // Show error messages upon save api failure
  useEffect(() => {
    try {
      if (uploadThumbnail && uploadFilePathUpdateResponseCode > 0 && uploadFilePathUpdateResponseMessage && !uploadFilePathUpdateApiSuccess) {
        toast.toastError(uploadFilePathUpdateResponseMessage);
        setStartSubmit(false);
      } else if (uploadThumbnail && uploadFilePathUpdateResponseCode > 0 && uploadFilePathUpdateApiSuccess) {
        setUploadAttachment(true);
        setUploadThumbnail(false);
        const uplodedFileInfo = uploadedFileInfo.map((file: any) => {
          return {
            FileName: file.name,
            DisplayName: file.displayName,
            FileSize: file.size,
            FileType: file.typeNumber.toString(),
          };
        });

        // upload attachments
        const reqParams: IuploadFilePathUpdateMultipleParams = {
          Files: uplodedFileInfo,
          Type: 'resource_attachments',
          TypeId: location?.state?.resourceId ? location?.state?.resourceId : addResourceApiData?.ResourceId,
        };
        // Api request to update file info after bucket upload and save organization info
        dispatch(uploadFilePathUpdateMultiple(reqParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFilePathUpdateResponseCode, uploadFilePathUpdateResponseMessage]);
  // Show error messages upon save api failure
  useEffect(() => {
    try {
      if (uploadAttachment && uploadFilePathUpdateMultipleResponseCode > 0 && uploadFilePathUpdateMultipleApiSuccess) {
        setUploadAttachment(false);
        setStartSubmit(false);
        // // Redirect to org listing after updating file status
        if (location?.state?.resourceId) {
          toast.toastSuccess(editResourceApiResponseMessage);
        } else {
          toast.toastSuccess(addResourceApiResponseMessage);
        }
        navigate('/resources', { state: { tabId: 1 } });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFilePathUpdateMultipleResponseCode, uploadFilePathUpdateMultipleResponseMessage]);
  // handle resource etail api response
  useEffect(() => {
    try {
      if (resourceDetailViewApiResponseCode > 0 && resourceDetailViewApiSuccess) {
        const resourceGroupForSelection = resourceDetail?.ResourceGroup?.map((group: any) => ({
          label: group.Groupname,
          value: group.Groupid,
        }));
        setSelectedGroup(resourceGroupForSelection);
        setCharacterCount(resourceDetail.Description.length);
        setResourceForm((resourceForm) => ({
          ...resourceForm,
          ResourceName: resourceDetail?.ResourceName,
          ResourcetypeID: ResourcetypeID.INCENTIVE_CONTENT,
          CategoryID: 0,
          ClassID: [],
          CreatedUserRoleTypeID: storageData?.RoleTypeId,
          Description: resourceDetail?.Description,
          OrganizationID: '',
          OrganizationTypeId: 0,
          IsGeneral: resourceDetail?.IsGeneral,
          IsGeneralGroup: resourceDetail?.IsGeneralGroup,
          Visibility: [0],
          GoalID: '',
          ResourceId: location?.state?.resourceId,
        }));
        setPreviewImage(resourceDetail?.ResourceThumbnail);
        const fileInfo = resourceDetail?.Attachments?.map((filedetails: any, index: number) => {
          return {
            name: filedetails?.resourcefilepath?.slice(filedetails?.resourcefilepath?.lastIndexOf('/') + 1),
            displayName: filedetails?.resourcefilepath?.slice(filedetails?.resourcefilepath?.lastIndexOf('/') + 1),
            type: filedetails?.resourcefilepath?.slice(filedetails?.resourcefilepath?.lastIndexOf('.') + 1),
            size: filedetails.filesize,
            typeNumber: filedetails.filetypeid,
            resourceID: filedetails.resourcedetailsid,
          };
        });
        const imgInfo = {
          name: resourceDetail?.ResourceThumbnail?.slice(resourceDetail?.ResourceThumbnail?.lastIndexOf('/') + 1),
          type: resourceDetail?.ResourceThumbnail?.slice(resourceDetail?.ResourceThumbnail?.lastIndexOf('.') + 1),
        };
        setPicture(imgInfo);
        setUpImageAttachmentDetails(fileInfo);
        setUploadedFileInfoOld(fileInfo);
        fileTypeChange({ value: 'image' });
        setfileTypeValue(fileType.find((option) => option?.value?.toLowerCase() === 'image'));
        setIsGeneralResource(resourceDetail?.IsGeneralGroup);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [resourceDetailApiLoading, resourceDetailViewApiResponseCode]);

  // Get tags by organization type
  useEffect(() => {
    try {
      if (listGroupData && listGroupData?.Groups?.length > 0) {
        const options = listGroupData?.Groups.map(
          (tag: any): ISelectOption => ({
            value: tag.ID,
            label: tag.Name.charAt(0).toUpperCase() + tag.Name.slice(1),
          }),
        );
        setTagOptions(options);
      } else {
        setTagOptions([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listGroupApiLoading]);

  // Put file to bucket after getting the path
  const uploadAttachmentFileToBucket = async () => {
    try {
      const uploadURL = uploadFileApiData;
      if (uploadURL) {
        // PUT request: upload file to S3
        const result = await fetch(uploadURL, {
          method: 'PUT',
          body: upImageAttachment,
        });
        if (result.status == 200) {
          const fileSize = bytesToSize(upImageAttachmentDetails.size);
          const fileInfo = { ...upImageAttachmentDetails, bucketURL: uploadURL, size: fileSize };
          setUpImageAttachmentDetails(fileInfo);
          setUploadedFileInfo([...uploadedFileInfo, fileInfo]);
          setFileUploadBegin(false);
        } else {
          setFileUploadError('Failed to Upload.');
          setFileUploadBegin(false);
          if (fileUploadRef.current) {
            fileUploadRef.current.value = '';
          }
          setStartSubmit(false);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Put file to bucket after getting the path
  const uploadThumbnailToBucket = async () => {
    try {
      const uploadURL = uploadFileApiData;
      if (uploadURL) {
        // PUT request: upload file to S3
        const result = await fetch(uploadURL, {
          method: 'PUT',
          body: upImageThumbnail,
        });
        if (result.status == 200) {
          const fileSize = bytesToSize(upImageThumbnailDetails.size);
          const fileInfo = { ...upImageThumbnailDetails, bucketURL: uploadURL, size: fileSize };
          setUpImageThumbnailDetails(fileInfo);
        } else {
          setStartSubmit(false);
          setFileUploadError('Failed to Upload.');
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const fileTypeChange = (event: any) => {
    try {
      setfileTypeValue(event);
      const fileType = event.value.toLowerCase();
      const acceptedFileExtensions = FILE_TYPES_SUPPORTED[fileType as keyof typeof FILE_TYPES_SUPPORTED] || [];
      const acceptString = acceptedFileExtensions.map((ext) => `.${ext}`).join(',');
      if (fileUploadRef?.current) {
        fileUploadRef.current.accept = acceptString;
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const uploadResourceFile = async (e: any) => {
    try {
      setFileUploadBegin(true);
      setFileUploadError('');
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const lastDotIndex = file?.name?.lastIndexOf('.');
        const fileNameWithoutExtension = file?.name?.slice(0, lastDotIndex);
        const fileExtension = file?.name?.slice(lastDotIndex + 1);
        const modifiedFileName = fileNameWithoutExtension + new Date().getTime() + '.' + fileExtension;
        // replacing special characters with underscores
        const formattedFilename = modifiedFileName.replace(/[^a-zA-Z0-9.\-_]/g, '_');
        const isValid = checkValidFile(formattedFilename, fileTypeValue.value, file.size);

        if (isValid) {
          setUpImageAttachment(e.target.files[0]);
          const fileInfo = {
            name: formattedFilename,
            displayName: file.name,
            type: fileExtension,
            size: file.size,
            typeNumber: getFileTypeNumber(fileTypeValue),
            fileType: fileTypeValue.value,
          };

          setUpImageAttachmentDetails(fileInfo);
          const formData = new FormData();
          formData.append('UploadExtension', fileInfo.type);
          formData.append('UploadObjectKey', fileInfo.name);
          formData.append('UploadType', 'resource_attachments');
          dispatch(uploadFile(formData));
        } else {
          setFileUploadError(ImageUploadFileError);
          setFileUploadBegin(false);
          if (fileUploadRef.current) {
            fileUploadRef.current.value = '';
          }
        }
      } else {
        setFileUploadBegin(false);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Check validation after form change
  const CheckValidation = async (validateObj: any) => {
    try {
      const errorResult = await validateForm(validateObj, INCENTIVE_RESOURCE_SCHEMA, errorFields);
      setErrorFields(errorResult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle form fields validtaion
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      if (name == 'Description') {
        setCharacterCount(event.target.value.length);
      }
      setResourceForm((info: any) => ({
        ...info,
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      CheckValidation(validateObj);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    try {
      setIsGeneralResource(!isGeneralResource);
      setSelectedGroup([]);
      // Reset selected options when checkbox is checked
      if (!isGeneralResource) {
        setResourceForm((info) => ({
          ...info,
          IsGeneralGroup: true,
          Groups: [],
        }));
      } else {
        setResourceForm((info) => ({
          ...info,
          IsGeneralGroup: false,
          Groups: [],
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle tag changes
  const handleGroupChange = (tags: any) => {
    try {
      setSelectedGroup(tags);
      setResourceForm((info) => ({
        ...info,
        Groups: tags.map((tag: any) => ({
          Id: isNaN(Number(tag?.value)) ? 0 : tag?.value,
          Name: tag?.label,
        })),
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle form submit
  const onSubmit = async () => {
    try {
      const errorResult = await validateForm(resourceForm, INCENTIVE_RESOURCE_SCHEMA, errorFields);
      if (Object.keys(errorResult).length === 0) {
        if (isUserAdmin()) {
          if (!isGeneralResource && (!selectedGroup || selectedGroup.length === 0)) {
            setErrorFields((prev: any) => ({
              ...prev,
              Groups: useIntlMessages('Validation.ResourceGroups.Required'),
            }));
            return;
          }
        }
        if (deleteList.length > 0) {
          const deleteRequestParams = {
            AttachmentIDs: deleteList,
            ResourceID: location?.state?.resourceId,
          };
          dispatch(deleteResourceAttachment(deleteRequestParams));
        }
        if (upImageThumbnail && (uploadedFileInfo?.length > 0 || uploadedFileInfoOld?.length > 0)) {
          setImgError('');
          setFileUploadError('');
          if (location?.state?.resourceId && upImageThumbnailDetails?.name === resourceDetail?.ResourceThumbnail?.slice(resourceDetail?.ResourceThumbnail?.lastIndexOf('/') + 1)) {
            setBeginUpdate(true);
            setSubmit(true);
            setStartSubmit(true); // state to manage loader
          } else {
            const formData = new FormData();
            formData.append('UploadExtension', upImageThumbnailDetails.type);
            formData.append('UploadObjectKey', upImageThumbnailDetails.name);
            formData.append('UploadType', 'resourceimage');
            dispatch(uploadFile(formData));
            setSubmit(true);
            setStartSubmit(true); // state to manage loader
          }
        } else if (upImageThumbnail && uploadedFileInfo.length == 0) {
          setImgError('');
          setFileUploadError(ImageUploadChooseAttachment);
          window.scrollTo(0, 0);
          if (fileUploadRef?.current) {
            fileUploadRef?.current?.focus();
          }
          setStartSubmit(false);
        } else {
          setFileUploadError('');
          setImgError(ImageUploadChooseImage);
          window.scrollTo(0, 0);
          if (imageRef?.current) {
            imageRef?.current?.focus();
          }
          setStartSubmit(false);
        }
      } else {
        setErrorFields(errorResult);
        setStartSubmit(false);
        // If there are validation errors, focus on the input field
        const firstErrorField = Object.keys(errorResult)[0];
        if (firstErrorField && resourceInputRef?.current) {
          resourceInputRef?.current?.focus();
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // set image selected
  const setPicture = (pic: any) => {
    try {
      if (pic) {
        setImgError('');
        setUpImageThumbnail(pic);
        const imgInfo = {
          name: pic.name,
          type: pic?.name?.split('.')[1],
        };
        setUpImageThumbnailDetails(imgInfo);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Remove Attachment from listing
  const deleteAttachment = (deleteIndex: number) => {
    try {
      const newUploadedFileInfo = [...uploadedFileInfo];
      setUploadedFileInfo(newUploadedFileInfo.filter((item: any, index: number) => index != deleteIndex));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Remove Attachment from listing
  const deleteAttachmentOld = (attachmentID: string, deleteIndex: number) => {
    try {
      const newUploadedFileInfo = [...uploadedFileInfoOld];
      setUploadedFileInfoOld(newUploadedFileInfo.filter((item: any, index: number) => index != deleteIndex));
      setDeleteList((prevList) => [...prevList, attachmentID]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  return (
    <>
      <div className="addMotiv-res">
        <div className="page-title orgAdd-page-title d-flex justify-content-between align-items-center">
          <h3>{location?.state?.resourceId ? <FormattedMessage id="Label.EditIncentiveContent" /> : <FormattedMessage id="HD.AddIncentiveContent" />}</h3>
          <Col md="auto">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item onClick={() => navigate('/resources', { state: { tabId: 1 } })}>
                <FormattedMessage id="Hd.Resources" />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {location?.state?.resourceId ? <FormattedMessage id="Label.EditIncentiveContent" /> : <FormattedMessage id="Label.AddIncentiveContent" />}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </div>
        <div className="content-sub">
          <div className="content-area-padding">
            <div className="justify-content-center p-5">
              <div>
                <h3 className="text-center">
                  {location?.state?.resourceId ? <FormattedMessage id="Label.EditIncentiveContent" /> : <FormattedMessage id="HD.AddIncentiveContent" />}
                </h3>
                <p className="text-center">{useIntlActionMessages('SubHd.AddIncentiveContent')}</p>
                <div className="sel-fileType mb-5">
                  <Select
                    label={useIntlActionMessages('Label.SelectFileType')}
                    options={fileType}
                    value={fileTypeValue}
                    placeholder={useIntlMessages('PH.Select')}
                    onChange={(e: any) => fileTypeChange(e)}
                  />
                </div>
              </div>
              <div className="fileupload-sec  mb-3">
                <input
                  ref={fileUploadRef}
                  type="file"
                  name="file-3[]"
                  id="file-3"
                  className="inputfile inputfile-3"
                  data-multiple-caption="{count} files selected"
                  multiple={false}
                  accept="image/*"
                  onChange={(e: any) => uploadResourceFile(e)}
                />
                <label htmlFor="file-3">
                  <img src={plusicon} alt="" />
                  <span>{useIntlActionMessages('Button.AddFile')}</span>
                </label>
              </div>
              <div className="upload-info">
                <UploadNote
                  fileType={Object.entries(FILE_TYPES_SUPPORTED)
                    .map(([key, value]) => `${value.map((ext) => `.${ext}`).join(', ')}`)
                    .join(', ')}
                />
              </div>
              <div className="error text-center pt-2"> {fileUploadError}</div>
              <Row className="text-center">
                {(uploadedFileInfo?.length > 0 || uploadedFileInfoOld?.length > 0) && (
                  <h4>
                    {AttachmentsText}({uploadedFileInfo?.length + (uploadedFileInfoOld?.length ?? 0)})
                  </h4>
                )}
                {uploadedFileInfoOld?.map((filedetails: any, index: number) => (
                  <Col md={6} key={index}>
                    <div className="att-list-b d-flex mb-3">
                      {getIcon(filedetails.fileType)}
                      <div className="text-start text-ar flex-grow-1">
                        <p className="text-white">{filedetails?.displayName}</p>
                        <span className="mb-f">{filedetails?.size}</span>
                      </div>
                      <div className="delete-sec">
                        <Button className="Plus-btn" variant="outline-secondary" id="button-addon2" onClick={() => deleteAttachmentOld(filedetails?.resourceID, index)}>
                          <img src={icnDeleteCr} />
                        </Button>
                      </div>
                    </div>
                  </Col>
                ))}
                {uploadedFileInfo.map((filedetails: any, index: number) => (
                  <Col md={6} key={index}>
                    <div className="att-list-b d-flex mb-3">
                      {getIcon(filedetails.fileType)}
                      <div className="text-start text-ar flex-grow-1">
                        <p className="text-white">{filedetails?.displayName}</p>
                        <span className="mb-f">{filedetails?.size}</span>
                      </div>
                      <div className="delete-sec">
                        <Button className="Plus-btn" variant="outline-secondary" id="button-addon2" onClick={() => deleteAttachment(index)}>
                          <img src={icnDeleteCr} />
                        </Button>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              <Row className="d-flex justify-content-center">
                <div className="sec-addThunmb d-flex flex-wrap align-content-center justify-content-center">
                  <div>
                    <ImageUpload
                      setError={(error: string) => setImgError(error)}
                      imageFile={previewImage ? previewImage : ''}
                      setPicture={setPicture}
                      maxSize={Number(UploaderProps.IMAGE_MAX_SIZE)}
                      acceptedFileTypes={IMAGE_TYPES_SUPPORTED}
                      invalidSizeMessage={useIntlActionMessages('Image.Upload.Size.Message')}
                      invalidTypeMessage={useIntlActionMessages('Image.Upload.AcceptedType.Error')}
                      mode={location?.state?.resourceId ? 'editresource' : 'addresource'}
                    />
                  </div>
                </div>
                <div className="error text-center pt-2" ref={imageRef}>
                  {' '}
                  {imgError}
                </div>
                <div className="upload-info">
                  <UploadNote fileSize="50 MB" />
                </div>
              </Row>
              <Row>
                <div className="p-3">
                  <Input
                    ref={resourceInputRef}
                    label={useIntlActionMessages('Label.NameofResource')}
                    id="ResourceName"
                    name="ResourceName"
                    type="text"
                    placeholder={useIntlActionMessages('Label.IncentiveResource.Name')}
                    maxLength={NumberValues.NUM_100}
                    onChange={onInputHandleChange}
                    value={resourceForm?.ResourceName}
                    errorMessage={errorFields?.ResourceName}
                    autoFocus
                  />
                  <Col lg={12} className="addOrg-textarea">
                    <Input
                      label={useIntlActionMessages('Label.Description')}
                      id="Description"
                      name="Description"
                      type="textarea"
                      placeholder={useIntlActionMessages('Label.IncentiveResource.Description')}
                      maxLength={NumberValues.NUM_1000}
                      as="textarea"
                      rows={3}
                      onChange={onInputHandleChange}
                      value={resourceForm?.Description}
                      errorMessage={errorFields?.Description}
                    />
                    <span className="txt-count">
                      {characterCount}/{NumberValues.NUM_1000}
                    </span>
                  </Col>
                  {isUserAdmin() && (
                    <div className="check-sm mt-4 mb-5">
                      <Checkbox
                        type="Checkbox"
                        id="IsGeneral"
                        name="IsGeneral"
                        label={useIntlActionMessages('Label.Checkbox.GeneralResource')}
                        onChange={handleCheckboxChange}
                        value={resourceForm?.IsGeneralGroup}
                        checked={resourceForm.IsGeneralGroup}
                      />
                      {!isGeneralResource && (
                        <>
                          <label className="form-label">{useIntlActionMessages('Text.AddToGroup')}</label>
                          <CreatableSelect isMulti options={tagOptions} name="Groups" id="Groups" className="tags-selector" onChange={handleGroupChange} value={selectedGroup} />
                          <small>{useIntlActionMessages('Text.HelpTextGroup')}</small>
                          {errorFields?.Groups && (
                            <div className="error-message">
                              <small className="text-danger">{errorFields.Groups}</small>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </Row>
              <Row>
                <Col lg={12} className="d-flex justify-content-end ">
                  <Button className="mb-4 btn-cancel" variant="outline-primary" onClick={() => navigate('/resources', { state: { tabId: 1 } })}>
                    <FormattedMessage id="Button.Cancel" />
                  </Button>
                  <Button
                    className="mb-4 btn-Done"
                    variant="primary"
                    onClick={onSubmit}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onSubmit();
                      }
                    }}
                  >
                    <FormattedMessage id="Button.Save" />
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {(addResourceApiLoading ||
          fileUploadBegin ||
          uploadFileApiLoading ||
          uploadFilePathUpdateApiLoading ||
          uploadFilePathUpdateMultipleApiLoading ||
          startSubmit ||
          resourceDetailApiLoading ||
          deleteResourceAttachmentApiLoading ||
          editResourceApiLoading) && <Loader />}
      </div>
    </>
  );
};
export default AddIncentiveResource;
