import React from 'react';
import { Form } from 'react-bootstrap';

interface RadioOption {
  label: string;
  value: number;
}

interface RadioGroupProps {
  label: string;
  name: any;
  value: number;
  options: RadioOption[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioGroup: React.FC<RadioGroupProps> = ({ label, name, value, options, onChange }) => {
  return (
    <Form className="mb-4 form">
      <h6>{label}</h6>
      {['radio'].map((type) => (
        <div key={`inline-${type}`} className="d-flex mb-3">
          {options.map((option) => (
            <Form.Check  key={option.value} label={option.label} name={name} type="radio" id={`inline-${type}-${name}-1`} value={option.value} checked={value === option.value} onChange={onChange} />
          ))}
        </div>
      ))}
    </Form>
  );
};

export default RadioGroup;
