/**
 * @file   src\components\ManageGroups.tsx
 * @brief  Add Manage Groups
 * @date   Nov, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import Input from '../components/MAInput';
import { NumberValues } from '../utils/enums';
import { useIntlActionMessages, useIntlMessages } from '../utils/helper';
import { Col, Row, Button, Table } from 'react-bootstrap';
import Search from '../components/MASearch';
import Edit from '../assets/img/icon/Edit';
import Delete from '../assets/img/icon/Delete';
import '../assets/css/Input.scss';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';
import { IGroupNameRequestParams } from '../interfaces/ResourceInterface';
import { listGroupByType, saveGroupByType, deleteGroupByType } from '../store/actions/competencyActions';
import DeleteConfirmPopup from '../components/DeleteConfirmation';
import PaginationBS from 'react-bootstrap/Pagination';
import { PAGE_SIZE_6, DEFAULT_PAGE_INDEX } from '../utils/constants';
import { stringFormat } from '../utils/StringBuilder';
import { validateForm } from '../utils/formValidation';
import { IGroupNameForm } from '../interfaces/CompetencyInterface';
import { MessageToaster } from '../utils/ToastUtil';
import { resetGroupByTypeList, resetSaveGroupByType, resetDeleteGroupByType } from '../store/slices/competencySlice';
import Loader from '../components/Loader';
import * as yup from 'yup';

// Interface for Manage Groups
export interface IManageGroupsProps {
  isShow: boolean;
  groupType: number;
}

const ManageGroups: React.FC<IManageGroupsProps> = ({ isShow, groupType }) => {
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Ref for  input element on the page
  const inputRef = useRef<any>();
  // Message toaster object creation
  const toastObj = new MessageToaster();
  // Declare default params
  const savedefaultRequestParams: IGroupNameForm = {
    GroupId: 0,
    GroupName: '',
    Type: groupType,
  };
  const defaultParamsManageGroups: IGroupNameRequestParams = {
    Page: DEFAULT_PAGE_INDEX,
    PageSize: PAGE_SIZE_6,
    SearchText: '',
    Type: groupType,
  };
  // Access redux state variables.
  const [groupNameForm, setGroupNameForm] = useState<IGroupNameForm>(savedefaultRequestParams);
  const [errorFields, setErrorFields] = useState<any>({});
  const {
    listGroupData,
    listGroupApiLoading,
    listGroupApiSuccess,
    saveGroupByTypeApiLoading,
    saveGroupByTypeApiSuccess,
    deleteGroupByTypeApiLoading,
    deleteGroupByTypeApiSuccess,
    deleteGroupByTypeApiResponseMessage,
  } = useAppSelector((state: RootState) => state.competency);

  const [manageGroupsParams, setManageGroupsParams] = useState<IGroupNameRequestParams>(defaultParamsManageGroups);
  const [manageGroupItems, setManageGroupItems] = useState<any>([]);
  const [totalManageGroupItems, setTotalManageGroupItems] = useState<number>(0);
  const [isShowDeleteManageGroupConfirmPopup, setIsShowDeleteManageGroupConfirmPopup] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_INDEX);
  const [searchText, setSearchText] = useState<string>('');
  const [lastPage, setLastPage] = useState<number>(0);
  const [itemsDisplayed, setItemsDisplayed] = useState<number>(0);
  const [deletableGroupId, setDeletableGroupId] = useState<number>(0);
  const [selectedGroupId, setSelectedGroupId] = useState<number>(-1);
  const noData = useIntlMessages('Label.Nodata');
  const searchPlaceHolder = useIntlMessages('PH.SearchGroupName');
  const groupNameSaveSuccessAlert = useIntlActionMessages('Success.Save.GroupName');
  const groupNameDeleteSuccessAlert = useIntlActionMessages('Success.Delete.GroupName');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');

  // Competency Schema
  const GROUP_NAME_SCHEMA = yup.object({
    GroupName: yup.string().trim().required(useIntlActionMessages('Form.GroupName.Required')).max(100, useIntlActionMessages('Form.GroupName.Max')),
  });

  useEffect(() => {
    dispatch(listGroupByType(manageGroupsParams));
    window.scrollTo(0, 0);
    return () => {
      dispatch(resetSaveGroupByType());
      dispatch(resetDeleteGroupByType());
      dispatch(resetGroupByTypeList());
    };
  }, []);

  // Get competency group
  useEffect(() => {
    if (listGroupApiSuccess && listGroupData && listGroupData?.TotalCount > 0) {
      const newItems = listGroupData;
      setItemsDisplayed(listGroupData?.Groups?.length);
      setTotalManageGroupItems(newItems?.TotalCount);
      setLastPage(Math.ceil(newItems.TotalCount / defaultParamsManageGroups.PageSize));
      setManageGroupItems(newItems.Groups);
    } else {
      clearManageGroupList();
    }
  }, [listGroupApiLoading]);

  useEffect(() => {
    setManageGroupsParams((apiParams: any) => ({
      ...apiParams,
      Page: currentPage,
      SearchText: searchText,
    }));
  }, [currentPage]);

  useEffect(() => {
    dispatch(listGroupByType(manageGroupsParams));
  }, [manageGroupsParams]);

  // save group name
  useEffect(() => {
    if (saveGroupByTypeApiSuccess) {
      dispatch(listGroupByType(manageGroupsParams));
      toastObj.toastSuccess(groupNameSaveSuccessAlert);
      resetGroupNameForm();
    }
  }, [saveGroupByTypeApiLoading]);

  // delete selected group
  useEffect(() => {
    if (deleteGroupByTypeApiSuccess) {
      if (itemsDisplayed === 1) {
        setCurrentPage(1);
      } else {
        dispatch(listGroupByType(manageGroupsParams));
      }
      toastObj.toastSuccess(groupNameDeleteSuccessAlert);
    } else if (!deleteGroupByTypeApiSuccess && !deleteGroupByTypeApiLoading && deleteGroupByTypeApiResponseMessage?.length > 0) {
      toastObj.toastError(deleteGroupByTypeApiResponseMessage);
    }
    resetGroupNameForm();
    setSelectedGroupId(-1);
    setIsShowDeleteManageGroupConfirmPopup(false);
  }, [deleteGroupByTypeApiLoading]);

  const clearManageGroupList = () => {
    setItemsDisplayed(0);
    setManageGroupItems([]);
    setTotalManageGroupItems(0);
  };

  // Handle edit confirmation
  const editGroupName = (groupId: number, groupName: string) => {
    setErrorFields({});
    setGroupNameForm((prevForm) => ({
      ...prevForm,
      GroupId: groupId,
      GroupName: groupName,
    }));
    setSelectedGroupId(groupId);
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  };

  // Handle delete confirmation
  const showDeleteGroupConfirmPopup = (groupId: number) => {
    resetGroupNameForm();
    setSelectedGroupId(groupId);
    setDeletableGroupId(groupId);
    setIsShowDeleteManageGroupConfirmPopup(true);
  };

  const handleCancelGroupDeleteClick = () => {
    setSelectedGroupId(-1);
    setIsShowDeleteManageGroupConfirmPopup(false);
  };
  // Api call to delete competency
  const handleDeleteGroupClick = () => {
    dispatch(
      deleteGroupByType({
        GroupId: deletableGroupId,
        Type: groupNameForm.Type,
      }),
    );
  };

  // search text change
  const handleSearchKeyUp = (event: any) => {
    if (selectedGroupId !== -1) {
      resetGroupNameForm();
    }
    setSearchText(event.target.value);
    if (currentPage === 1) {
      setManageGroupsParams((apiParams: any) => ({
        ...apiParams,
        Page: 1,
        SearchText: event.target.value,
      }));
    } else {
      setCurrentPage(1);
    }
  };

  const handleSearchChange = (event: any) => {
    if (selectedGroupId !== -1) {
      resetGroupNameForm();
    }
    if (event.target.value === '') {
      setSearchText('');
      setCurrentPage(1);
      setManageGroupsParams((apiParams: any) => ({
        ...apiParams,
        Page: 1,
        SearchText: event.target.value,
      }));
    }
  };
  const onFirstPageClick = () => {
    setCurrentPage(1);
  };
  const onPreviousPageClick = () => {
    setCurrentPage(currentPage - 1);
  };
  const onNextPageClick = () => {
    setCurrentPage(currentPage + 1);
  };
  const onLastPageClick = () => {
    setCurrentPage(lastPage);
  };

  // Handle input field
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setGroupNameForm((info) => ({
      ...info,
      [name]: value,
    }));
    // Validate other fields
    const validateObj = { [name]: value };
    const errorResult = await validateForm(validateObj, GROUP_NAME_SCHEMA, errorFields);
    setErrorFields(errorResult);
  };

  // Handle form submit
  const onSubmit = async () => {
    try {
      let errorResult = {};
      setErrorFields({});
      errorResult = await validateForm(groupNameForm, GROUP_NAME_SCHEMA, errorFields);
      if (Object.keys(errorResult).length === 0) {
        dispatch(
          saveGroupByType({
            GroupId: groupNameForm.GroupId,
            GroupName: groupNameForm.GroupName.trim(),
            Type: groupNameForm.Type,
          }),
        );
      } else {
        setErrorFields(errorResult);
        // If there are validation errors, focus on the input field
        const errorField = Object.keys(errorResult)[0];
        if (errorField && inputRef?.current) {
          inputRef?.current?.focus();
        }
      }
    } catch (error) {
      toastObj.toastError(unexpectedErrorMessage);
      console.log('Error: ', error);
    }
  };

  // Handle Assign user Select button click.
  const handleGroupNameCancelButtonClick = () => {
    resetGroupNameForm();
  };

  const resetGroupNameForm = async () => {
    setErrorFields({});
    setGroupNameForm((prevForm) => ({
      ...prevForm,
      GroupId: 0,
      GroupName: '',
    }));
    setDeletableGroupId(0);
    setSelectedGroupId(-1);
  };

  return (
    <div id="filter-wrapper" className="filter-main">
      <Col sm={12}>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4 className="mb-0">
            <FormattedMessage id="HD.GroupManagement" />
          </h4>
        </div>
        <Col>
          <div className="custom-calendar mb-4 form-sm">
            <Input
              autoFocus
              ref={inputRef}
              id="GroupName"
              name="GroupName"
              type="text"
              label={useIntlMessages('Label.GroupName')}
              placeholder={useIntlActionMessages('Form.Competencies.groupName.Add')}
              maxLength={NumberValues.NUM_100}
              onChange={onInputHandleChange}
              errorMessage={errorFields?.GroupName}
              value={groupNameForm.GroupName}
            />
          </div>
          <Col>
            <Row className="mt-4 pb-4">
              <Col>
                <Button variant="outline-primary" className="w-100" onClick={() => handleGroupNameCancelButtonClick()}>
                  <FormattedMessage id="Button.Cancel" />
                </Button>
              </Col>
              <Col>
                <Button
                  variant="primary"
                  className="w-100"
                  onClick={onSubmit}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit();
                    }
                  }}
                >
                  <FormattedMessage id="Button.Save" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Col>
        <Search placeholder={searchPlaceHolder} size="sm" onChange={handleSearchChange} onKeyUp={handleSearchKeyUp} />
        <Table striped hover responsive>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Label.GroupName" />
              </th>
              <th className="w-75px"></th>
              <th className="w-75px"></th>
            </tr>
          </thead>
          <tbody>
            {listGroupApiSuccess &&
              manageGroupItems &&
              manageGroupItems.map((group: any) => (
                <tr className={group?.ID === selectedGroupId ? 'selectedRow-bg-color' : ''}>
                  <td>
                    <div className="d-flex align-items-center">{group.Name}</div>
                  </td>
                  <td>
                    <Button variant="dark" size="sm" className="icon-btn edit-btn more-dropdown" onClick={() => editGroupName(group?.ID, group?.Name)}>
                      <Edit />
                    </Button>
                  </td>
                  <td>
                    <Button variant="dark" size="sm" className="icon-btn delete-btn" onClick={() => showDeleteGroupConfirmPopup(group?.ID)}>
                      <Delete />
                    </Button>
                  </td>
                </tr>
              ))}
            {listGroupApiSuccess && itemsDisplayed === 0 && (
              <tr>
                <td colSpan={8} className="text-center">
                  {noData}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {totalManageGroupItems > defaultParamsManageGroups?.PageSize && (
          <div className="d-md-flex justify-content-between align-items-center">
            <p className="m-0">
              {stringFormat(
                useIntlMessages('Label.Pagination'),
                itemsDisplayed > 0 ? (totalManageGroupItems === 1 ? 1 : currentPage - 1) * defaultParamsManageGroups.PageSize + 1 : 0,
                (totalManageGroupItems === 1 ? 1 : currentPage - 1) * defaultParamsManageGroups.PageSize + itemsDisplayed,
                totalManageGroupItems,
              )}
            </p>
            <PaginationBS className="m-grp-pagination">
              <PaginationBS.First
                className={currentPage === DEFAULT_PAGE_INDEX ? 'disable' : ''}
                onClick={onFirstPageClick}
                disabled={currentPage === DEFAULT_PAGE_INDEX ? true : false}
              />
              <PaginationBS.Prev
                className={currentPage === DEFAULT_PAGE_INDEX ? 'disable' : ''}
                onClick={onPreviousPageClick}
                disabled={currentPage === DEFAULT_PAGE_INDEX ? true : false}
              />
              <PaginationBS.Next className={currentPage === lastPage ? 'disable' : ''} onClick={onNextPageClick} disabled={currentPage === lastPage ? true : false} />
              <PaginationBS.Last className={currentPage === lastPage ? 'disable' : ''} onClick={onLastPageClick} disabled={currentPage === lastPage ? true : false} />
            </PaginationBS>
          </div>
        )}
      </Col>
      <DeleteConfirmPopup
        show={isShowDeleteManageGroupConfirmPopup}
        handleClose={() => setIsShowDeleteManageGroupConfirmPopup(false)}
        onCancel={() => handleCancelGroupDeleteClick()}
        onOkay={() => handleDeleteGroupClick()}
        title={useIntlActionMessages('Text.DeleteConfirmation.Group')}
        content={useIntlActionMessages('Text.DeleteConfirmation.Group.Desc')}
      />
      {listGroupApiLoading && <Loader />}
    </div>
  );
};

export default ManageGroups;
