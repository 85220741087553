/**
 * @file   src\containers\manageUsers\ManageUsers.tsx
 * @brief  List users page
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages, isUserAdmin, getFromLocalStorage, getCurrentOrgDetails, isAdminTeacherManager, isUserSystemAdmin } from '../../utils/helper';
import { Button, Col, Breadcrumb, Tab, Tabs, Table, Row } from 'react-bootstrap';
import Search from '../../components/MASearch';
import ViewIcon from '../../assets/img/icon/View';
import Default from '../../assets/img/Userimg.jpg';
import { slide as Filter } from 'react-burger-menu';
import Select from '../../components/MASelect';
import Close from '../../assets/img/Close.svg';
import { Link, useNavigate } from 'react-router-dom';
import Sort from '../../assets/img/icon/Sort';
import DatePicker from 'react-datepicker';
import Checkbox from '../../components/MACheck';
import { fetchUsersList, getStudentTags } from '../../store/actions/userActions';
import { getOrganizationTypeDropDownList, getUserRolesByOrgType, getOrganizationNameByOrgType, getCategoryDetailsById } from '../../store/actions/organizationTypeActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import useDebounce from '../../hooks/useDebounce';
import { ISelectOption, ISelectOptionsNumber, ISelectOptionBoolean } from '../../interfaces/GeneralInterface';
import { IOrganization } from '../../interfaces/OrganisationInterface';
import { IStudentFilters, IUserApiParams, IUserTableRow } from '../../interfaces/UserInterface';
import { IOrgTypeResponse } from '../../interfaces/OrganizationTypeInterface';
import moment from 'moment';
import Pagination from '../../components/Pagination';
import Loader from '../../components/Loader';
import { UsersSortFields, SortOrder, RoleTypeIds } from '../../utils/enums';
import { resetOrgTypeNameRoles } from '../../store/slices/organizationTypeSlice';
import { DATE_FORMAT, BUCKET_URL } from '../../utils/constants';
import { MessageToaster } from '../../utils/ToastUtil';
import { resetUsersList } from '../../store/slices/userSlice';
import { getSystemAdminPermissions } from '../../store/actions/settingsAction';
const availabilityOptions = [
  { label: 'Active', value: true },
  { label: 'Inactive', value: false },
];

const defaultApiParams = {
  Availability: true,
  Category: [],
  Completed: false,
  DOJ: '',
  InProgress: false,
  NotStarted: false,
  OrganizationId: '',
  Overdue: false,
  Page: 0,
  PageSize: 10,
  Rescheduled: false,
  RoleTypeId: 1,
  Search: '',
  SortColumn: UsersSortFields.DOJ,
  SortOrder: SortOrder.DESC,
  SubCategories: [],
  Tags: [],
};

const defaultStudentFilters = {
  Completed: false,
  Rescheduled: false,
  InProgress: false,
  NotStarted: false,
  Overdue: false,
};

const PAGE_SIZE = 10;
const toastObj = new MessageToaster();
// Component that lists different users in the organization
const ManageUsers: React.FC = () => {
  // Navigation object
  const navigate = useNavigate();
  // Action dispatch object
  const dispatch = useAppDispatch();

  // Accessing redux state variables
  const { userListApiData, userListApiLoading, userListApiResponseMessage, userListApiSuccess, getStudentTagsApiData, getStudentTagsApiLoading } = useAppSelector(
    (state: RootState) => state.user,
  );
  const {
    organizationTypeDropDownData,
    organizationTypeDropDownSuccess,
    organizationTypeDropDownLoading,
    rolesByOrgTypeData,
    rolesByOrgTypeLoading,
    rolesByOrgTypeSuccess,
    orgnameByOrgTypeData,
    orgnameByOrgTypeLoading,
    orgnameByOrgTypeSuccess,
    categoryDetailsByOrgIdLoading,
    categoryDetailsByOrgIdData,
  } = useAppSelector((state: RootState) => state.organizationType);
  const { getSystemAdminPermissionsApiLoading, getSystemAdminPermissionsApiSuccess, getSystemAdminPermissionsApiData } = useAppSelector((state: RootState) => state.settings);

  // Component state variables
  const [orgTypesValues, setOrgTypesValues] = useState<ISelectOptionsNumber[]>([]);
  const [organizationTypeValue, setOrganizationTypeValue] = useState<ISelectOption[]>([]);
  const [dateFilter, setDateFilter] = useState<Date | null>(null);
  const [orgTypeId, setOrgTypeId] = useState<string>('');
  const [apiParams, setApiParams] = useState<IUserApiParams>(defaultApiParams);
  const [searchText, setSearchText] = useState<string>('');
  const [organizationNames, setOrganizationNames] = useState<ISelectOption[]>([]);
  const [organizationId, setOrganizationId] = useState<string>('');
  const [categories, setCategories] = useState<ISelectOption[]>([]);
  const [subcategories, setSubCategories] = useState<ISelectOptionsNumber[]>([]);
  const [studentFilters, setStudentFilters] = useState<IStudentFilters>(defaultStudentFilters);
  const [dateString, setDateString] = useState<string>('');
  const [selectedOrganization, setSelectedOrganization] = useState<ISelectOption[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOption[]>([]);
  const [selectedClass, setSelectedClass] = useState<ISelectOptionsNumber[]>([]);
  const [availability, setAvailability] = useState<ISelectOptionBoolean[]>([availabilityOptions[0]]);
  const [previousApiParams, setPreviousApiParams] = useState<IUserApiParams>(defaultApiParams);
  const [selectedStudentTag, setSelectedStudentTag] = useState<string[]>([]);
  const [tagOptions, setTagOptions] = useState<ISelectOption[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [hasAddNewUserPermissionSystemAdmin, setHasAddNewUserPermissionSystemAdmin] = useState<boolean>(false);
  const searchPlaceHolder = useIntlMessages('PH.SearchName');
  const debouncedSearch = useDebounce(searchText, 500);
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');

  useEffect(() => {
    try {
      if (isUserSystemAdmin()) {
        const userData = getFromLocalStorage('MI_USR_DATA');
        dispatch(
          getSystemAdminPermissions({
            OrgTypeID: null,
            SysAdminID: userData.UserId,
          }),
        );
      }
      return () => {
        dispatch(resetOrgTypeNameRoles());
        dispatch(resetUsersList());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // Set system admin permission
  useEffect(() => {
    try {
      if (isUserSystemAdmin()) {
        if (getSystemAdminPermissionsApiSuccess) {
          const hasAddNewUserPermission = getSystemAdminPermissionsApiData[0]?.permission_details?.some((per: any) => per.managing_roles?.length > 0);
          setHasAddNewUserPermissionSystemAdmin(hasAddNewUserPermission);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getSystemAdminPermissionsApiLoading]);

  // Get organization details based roles
  useEffect(() => {
    try {
      const userData = getFromLocalStorage('MI_USR_DATA');
      const organizationId = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : userData.OrganizationId;
      const orgTypeId = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationTypeId : userData.OrganizationTypeId;
      if (isAdminTeacherManager()) {
        setOrganizationId(organizationId);
        const params = apiParams;
        params.OrganizationId = organizationId;
        dispatch(
          getUserRolesByOrgType({
            OrganizationTypeId: orgTypeId,
          }),
        );
        dispatch(
          getOrganizationNameByOrgType({
            OrganizationTypeId: orgTypeId,
          }),
        );
        dispatch(
          getCategoryDetailsById({
            OrganizationId: organizationId,
          }),
        );
        dispatch(fetchUsersList(params));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [localStorage.getItem('CURRENT_ORG')]);
  useEffect(() => {
    try {
      if (isAdminTeacherManager()) {
        const params = apiParams;
        params.Page = currentPage;
        dispatch(fetchUsersList(params));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [localStorage.getItem('CURRENT_ORG'), apiParams.RoleTypeId, apiParams.Search, currentPage, apiParams.SortColumn, apiParams.SortOrder, organizationId]);

  useEffect(() => {
    try {
      if (isUserAdmin()) {
        setOrganizationId('');
        const params = defaultApiParams;
        params.RoleTypeId = RoleTypeIds.SYSTEM_ADMIN;
        params.OrganizationId = '';
        params.Page = 1;
        setPreviousApiParams(params);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // works on initial rendering
  useEffect(() => {
    try {
      dispatch(getOrganizationTypeDropDownList({}));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // works on initial rendering and when RoleTypeId,Search,SortColumn,SortOrder changes
  useEffect(() => {
    try {
      const params = apiParams;
      if (organizationTypeDropDownSuccess && orgnameByOrgTypeSuccess && orgTypeId !== '' && organizationId?.length > 0) {
        const params = apiParams;
        params.Page = currentPage;
        dispatch(fetchUsersList(params));
      } else if (params.RoleTypeId === RoleTypeIds.SYSTEM_ADMIN) {
        params.Page = currentPage;
        dispatch(fetchUsersList(params));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [apiParams.RoleTypeId, apiParams.Search, currentPage, apiParams.SortColumn, apiParams.SortOrder, organizationId, apiParams.Tags]);
  // supports debounced search
  useEffect(() => {
    try {
      setApiParams({ ...apiParams, Search: searchText, Page: 1 });
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [debouncedSearch]);

  // sets the RoleId
  useEffect(() => {
    try {
      if (rolesByOrgTypeData.length > 0) {
        if (isUserAdmin()) {
          setOrganizationId('');
          const element = rolesByOrgTypeData.filter((role: any) => role.RoleTypeID === RoleTypeIds.APP_USER);
          const params = defaultApiParams;
          params.RoleTypeId = element[0].RoleTypeID;
          params.OrganizationId = '';
          setPreviousApiParams(params);
          setApiParams({ ...apiParams, RoleTypeId: RoleTypeIds.SYSTEM_ADMIN });
        } else {
          const element = rolesByOrgTypeData.filter((role: any) => role.RoleTypeID === RoleTypeIds.APP_USER);
          const params = defaultApiParams;
          params.RoleTypeId = element[0].RoleTypeID;
          setPreviousApiParams(params);
          setApiParams({ ...apiParams, RoleTypeId: element[0].RoleTypeID });
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [rolesByOrgTypeLoading]);
  // setting organization type values and getting organizations based on org type id
  useEffect(() => {
    try {
      if (organizationTypeDropDownSuccess) {
        const orgTypes: any = [];
        organizationTypeDropDownData?.forEach((orgType: IOrgTypeResponse) =>
          orgTypes.push({
            label: orgType.orgTypeName,
            value: orgType.orgTypeId,
          }),
        );
        setOrgTypesValues(orgTypes);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationTypeDropDownLoading]);
  // setorganization names
  useEffect(() => {
    try {
      if (orgnameByOrgTypeData && orgnameByOrgTypeData.length > 0) {
        const organizationData = orgnameByOrgTypeData.map(
          (organization: any): ISelectOption => ({
            label: organization.Name,
            value: organization.ID,
          }),
        );
        setOrganizationNames(organizationData);
      } else if (orgnameByOrgTypeData && orgnameByOrgTypeData.length === 0) {
        setOrganizationNames([]);
        setSelectedOrganization([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [orgnameByOrgTypeLoading]);
  // get user roles and organization names by organization type
  useEffect(() => {
    try {
      if (orgTypeId !== '') {
        dispatch(
          getUserRolesByOrgType({
            OrganizationTypeId: orgTypeId,
          }),
        );
        dispatch(
          getOrganizationNameByOrgType({
            OrganizationTypeId: orgTypeId,
          }),
        );
        dispatch(fetchUsersList(apiParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [orgTypeId]);
  // get category details by organization id
  useEffect(() => {
    try {
      if (orgTypeId !== '') {
        dispatch(
          getCategoryDetailsById({
            OrganizationId: organizationId,
          }),
        );
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationId]);
  // set category and subcategory data
  useEffect(() => {
    try {
      if (categoryDetailsByOrgIdData && categoryDetailsByOrgIdData.length > 0) {
        const categoryData = categoryDetailsByOrgIdData.map(
          (category: IOrganization): ISelectOption => ({
            label: category.CategoryName,
            value: category.CategoryId,
          }),
        );
        setCategories(categoryData);
      } else if (categoryDetailsByOrgIdData && categoryDetailsByOrgIdData.length === 0) {
        setCategories([]);
        setSubCategories([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [categoryDetailsByOrgIdLoading]);

  useEffect(() => {
    try {
      if (!userListApiSuccess && userListApiResponseMessage.length > 0) {
        toastObj.toastError(userListApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [userListApiLoading]);

  // Get tags of student
  useEffect(() => {
    try {
      if (getStudentTagsApiData && getStudentTagsApiData.Tags) {
        const options = getStudentTagsApiData.Tags.map(
          (tag: string): ISelectOption => ({
            value: tag,
            label: tag.charAt(0).toUpperCase() + tag.slice(1),
          }),
        );
        setTagOptions(options);
      } else {
        setTagOptions([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getStudentTagsApiLoading, getStudentTagsApiData]);
  // organization type change
  const handleOrganizationTypeChange = (event: ISelectOption) => {
    try {
      setOrganizationTypeValue([event]);
      setOrgTypeId(event.value);
      setSelectedOrganization([]);
      setApiParams({ ...apiParams, RoleTypeId: RoleTypeIds.SYSTEM_ADMIN, OrganizationId: '', SortColumn: defaultApiParams.SortColumn, SortOrder: defaultApiParams.SortOrder });
      dispatch(
        getOrganizationNameByOrgType({
          OrganizationTypeId: event.value,
        }),
      );
      setSelectedCategory([]);
      setSelectedClass([]);
      setSubCategories([]);
      setCategories([]);
      setOrganizationId('');
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // category change . get subcategories based on category
  const handleCategoryChange = (event: ISelectOption) => {
    try {
      const actualCategoryId = event.value;
      setSelectedCategory([event]);
      const category = categoryDetailsByOrgIdData.find((category: any) => category.CategoryId === actualCategoryId);
      if (category) {
        const subcategories = category.Subcategory.map((subcategory: any) => ({
          label: subcategory.classname,
          value: subcategory.classid,
        }));
        setSubCategories(subcategories);
        setSelectedClass([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // get the label for tabs
  const getTabLabel = (userLevel: number) => {
    let tabLabel = '';
    try {
      if (rolesByOrgTypeData.length > 0) {
        const element = rolesByOrgTypeData.filter((role: any) => userLevel === role.RoleTypeID);
        tabLabel = element.length === 1 ? element[0].Name : 'Not found';
      } else {
        tabLabel = 'Not Found';
      }
    } catch (error) {
      console.log('Error: ', error);
    }
    return tabLabel;
  };
  // changes user role type id when tab changes
  const changeUserRoleId = (roleTypeId: string | null) => {
    try {
      setSearchText('');
      setCurrentPage(1);
      setDateFilter(null);
      setFilterApplied(false);
      setSelectedStudentTag([]);
      if (rolesByOrgTypeData.length > 0 && roleTypeId === '1') {
        const id = roleTypeId !== null ? parseInt(roleTypeId) : 0;
        const element = rolesByOrgTypeData.filter((role: any) => id === role.RoleTypeID);
        setApiParams({ ...apiParams, RoleTypeId: element[0].RoleTypeID, Search: '', SortColumn: UsersSortFields.NAME, SortOrder: SortOrder.ASC, Page: 1 });
      } else if (rolesByOrgTypeData.length > 0 && roleTypeId === RoleTypeIds.SYSTEM_ADMIN.toString()) {
        const id = roleTypeId !== null ? parseInt(roleTypeId) : 0;
        const element = rolesByOrgTypeData.filter((role: any) => id === role.RoleTypeID);
        setApiParams({
          ...apiParams,
          RoleTypeId: element[0].RoleTypeID,
          Search: '',
          SortColumn: UsersSortFields.NAME,
          SortOrder: SortOrder.ASC,
          Page: 1,
          OrganizationId: organizationId,
        });
      } else if (rolesByOrgTypeData.length > 0 && roleTypeId !== '1') {
        const id = roleTypeId !== null ? parseInt(roleTypeId) : 0;
        const element = rolesByOrgTypeData.filter((role: any) => id === role.RoleTypeID);
        setApiParams({ ...apiParams, RoleTypeId: element[0].RoleTypeID, Search: '', Page: 1, OrganizationId: organizationId });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // search text change
  const handleSearchChange = (event: any) => {
    try {
      setSearchText(event.target.value);
      setApiParams({ ...apiParams, Page: 1 });
      setCurrentPage(1);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // search button click
  const handleSearchClick = () => {
    try {
      setApiParams({ ...apiParams, Search: searchText });
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // organization change
  const handleOrganizationChange = (event: ISelectOption) => {
    try {
      dispatch(
        getStudentTags({
          OrganizationID: event.value,
          searchText: '',
        }),
      );

      if (organizationId.length > 0) {
        setApiParams({ ...apiParams, OrganizationId: event.value, Page: 1 });
      } else {
        setApiParams({ ...apiParams, RoleTypeId: previousApiParams.RoleTypeId, OrganizationId: event.value, Page: 1 });
      }
      setCurrentPage(1);
      setOrganizationId(event.value);
      setSelectedOrganization([event]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle date picker change
  const handleDateChange = (date: any) => {
    try {
      setDateFilter(date);
      const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}-` : `${date.getMonth() + 1}-`;
      const newDate = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
      const dateFormatted = `${date.getFullYear()}-` + month + newDate;
      setDateString(dateFormatted);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // sub category change in filter
  const handleSubcategoryChange = (event: any) => {
    try {
      setSelectedClass(event);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Tag change for student in filter
  const handleStudentTagChange = (selectedOptions: ISelectOption[]) => {
    try {
      // Format the selected options as an array of strings
      const formattedTags = selectedOptions.map((option) => option.value);
      setSelectedStudentTag(formattedTags);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // availability change in filter
  const handleAvailabilityChange = (event: ISelectOptionBoolean) => {
    try {
      setAvailability([event]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // apply filters for app users
  const applyFiltersStudent = () => {
    try {
      setIsOpen(false);
      setFilterApplied(true);
      const requestParams = apiParams;
      setPreviousApiParams(requestParams);
      requestParams.InProgress = studentFilters.InProgress;
      requestParams.NotStarted = studentFilters.NotStarted;
      requestParams.Completed = studentFilters.Completed;
      requestParams.Overdue = studentFilters.Overdue;
      requestParams.Rescheduled = studentFilters.Rescheduled;
      requestParams.DOJ = dateString;
      requestParams.Availability = availability[0] ? availability[0].value : false;
      requestParams.Tags = selectedStudentTag;
      requestParams.Category = selectedCategory[0] ? [{ categoryid: parseInt(selectedCategory[0].value) }] : [];
      const subcategories: any = [];
      selectedClass.forEach((element) => subcategories.push({ classid: element.value }));
      requestParams.SubCategories = subcategories;
      requestParams.OrganizationId = organizationId;
      dispatch(fetchUsersList(requestParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // apply filter for other users
  const applyFiltersOtherUsers = () => {
    try {
      setIsOpen(false);
      setFilterApplied(true);
      const requestParams = apiParams;
      setPreviousApiParams(requestParams);
      requestParams.DOJ = dateString;
      requestParams.OrganizationId = organizationId;
      requestParams.Availability = availability[0] ? availability[0].value : false;
      requestParams.Tags = [];
      dispatch(fetchUsersList(requestParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // to clear all applied filters
  const clearFilters = () => {
    try {
      setIsOpen(false);
      setFilterApplied(false);
      setSelectedCategory([]);
      setSelectedClass([]);
      setAvailability([availabilityOptions[0]]);
      setDateFilter(new Date());
      setStudentFilters(defaultStudentFilters);
      setSelectedClass([]);
      setSelectedStudentTag([]);
      const params = previousApiParams;
      params.Category = [];
      params.SubCategories = [];
      params.DOJ = '';
      params.Availability = true;
      dispatch(fetchUsersList(params));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle sort field change
  const changeSortField = (field: string) => {
    try {
      let newSortOrder = SortOrder.ASC;
      if (field === apiParams.SortColumn) {
        newSortOrder = apiParams.SortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
      }
      setApiParams({ ...apiParams, SortColumn: field, SortOrder: newSortOrder });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Get table rows for level 6 user - eg.student (app users)
  const getLevel6UsersTableRows = () => {
    try {
      if (userListApiSuccess && userListApiData.length === 0) {
        return (
          <tr>
            <td colSpan={7} className="text-center">
              <FormattedMessage id="NoResults.Found" />
            </td>
          </tr>
        );
      } else if (userListApiSuccess && userListApiData.length > 0) {
        const userListRows = userListApiData.map((user: IUserTableRow) => (
          <tr key={user.UserId}>
            <td>
              <div className="d-flex align-items-center">
                <span className="td-user-image">
                  <img src={user.ProfileImage ? BUCKET_URL + user.ProfileImage : Default} className="w-100" alt="user" />
                </span>
                {user.Name}
              </div>
            </td>
            <td>{user.UserName}</td>
            <td>{user.OrganizationName}</td>
            <td>{user.Categories !== null ? user.Categories.join(', ') : null}</td>
            <td>{user.SubCategories !== null ? user.SubCategories.join(', ') : null}</td>
            <td>{moment(user.DOJ).local().format(DATE_FORMAT)}</td>
            <td>
              <Button
                variant="secondary"
                size="sm"
                className="icon-btn"
                onClick={() =>
                  navigate(`/userDetailsTab/${user.UserId}/${apiParams.RoleTypeId}`, {
                    state: {
                      tabName: 'Student',
                    },
                  })
                }
              >
                <ViewIcon />
              </Button>
            </td>
          </tr>
        ));
        return userListRows;
      }
    } catch (error) {
      console.log('Error: ', error);
      return <></>;
    }
  };
  // Get table rows for level 5 to 3 users
  const getLevelFiveToThreeTableRows = () => {
    try {
      if (userListApiSuccess && userListApiData.length === 0) {
        return (
          <tr>
            <td colSpan={6} className="text-center">
              <FormattedMessage id="NoResults.Found" />
            </td>
          </tr>
        );
      } else if (userListApiSuccess && userListApiData.length > 0) {
        const userListRows = userListApiData.map((user: IUserTableRow) => (
          <tr key={user.UserId}>
            <td>
              <div className="d-flex align-items-center">
                <span className="td-user-image">
                  <img src={user.ProfileImage ? BUCKET_URL + user.ProfileImage : Default} className="w-100" alt="user" />
                </span>
                {user.Name}
              </div>
            </td>
            <td>{user.UserName}</td>
            <td>{user.OrganizationName}</td>
            <td>{user.NoStudentAssigned}</td>
            <td>{moment(user.DOJ).local().format(DATE_FORMAT)}</td>
            <td>
              <Button
                variant="secondary"
                size="sm"
                className="icon-btn"
                onClick={() =>
                  navigate(`/userDetailsTab/${user.UserId}/${apiParams.RoleTypeId}`, {
                    state: {
                      tabName: 'Accountability Partner',
                    },
                  })
                }
              >
                <ViewIcon />
              </Button>
            </td>
          </tr>
        ));
        return userListRows;
      }
    } catch (error) {
      console.log('Error: ', error);
      return <></>;
    }
  };
  const getLevelOneTableRows = () => {
    try {
      if (userListApiSuccess && userListApiData.length === 0) {
        return (
          <tr>
            <td colSpan={5} className="text-center">
              <FormattedMessage id="NoResults.Found" />
            </td>
          </tr>
        );
      } else if (userListApiSuccess && userListApiData.length > 0) {
        const userListRows = userListApiData.map((user: IUserTableRow) => (
          <tr key={user.UserId}>
            <td>
              <div className="d-flex align-items-center">
                <span className="td-user-image">
                  <img src={user.ProfileImage ? BUCKET_URL + user.ProfileImage : Default} className="w-100" alt="user" />
                </span>
                {user.Name}
              </div>
            </td>
            <td>{user.UserName ? user.UserName : user.Email}</td>
            <td>{user.PhoneNumber}</td>
            <td>{moment(user.DOJ).local().format(DATE_FORMAT)}</td>
            <td>
              <Button
                variant="secondary"
                size="sm"
                className="icon-btn"
                onClick={() =>
                  navigate(`/userDetailsTab/${user.UserId}/${apiParams.RoleTypeId}`, {
                    state: {
                      tabName: 'System Admin',
                    },
                  })
                }
              >
                <ViewIcon />
              </Button>
            </td>
          </tr>
        ));
        return userListRows;
      }
    } catch (error) {
      console.log('Error: ', error);
      return <></>;
    }
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Hd.ManageUsers" />
        </h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageuser')}>
              <FormattedMessage id="Hd.ManageUsers" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Hd.AllUsers" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="content-sub">
        <div className="content-sub-header header-light-bg d-flex  align-items-center">
          <Col className="flex-grow-1">
            <h4>
              <FormattedMessage id="Hd.AllUsers" />
            </h4>
          </Col>
          <Col>
            <Row className="align-items-end justify-content-end">
              {isUserAdmin() && (
                <>
                  <Col xl={4} lg={4} md={4} className="form-sm">
                    <Select
                      options={orgTypesValues}
                      value={organizationTypeValue}
                      placeholder="Select"
                      onChange={(e: any) => handleOrganizationTypeChange(e)}
                      label="Organization Type"
                    />
                  </Col>
                  <Col xl={4} lg={4} md={4} className="form-sm ">
                    <Select
                      label="Organization"
                      options={organizationNames}
                      placeholder="Select"
                      onChange={(e: any) => handleOrganizationChange(e)}
                      value={selectedOrganization}
                      isDisabled={orgTypeId === ''}
                    />
                  </Col>
                </>
              )}
              <Col md="auto">
                {(!isUserSystemAdmin() || (isUserSystemAdmin() && hasAddNewUserPermissionSystemAdmin)) && (
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() =>
                      navigate('/adduser', {
                        state: {
                          parentPage: 'manageuser',
                        },
                      })
                    }
                  >
                    <FormattedMessage id="Button.AddUser" />
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </div>
        <div className="content-area-padding">
          {orgnameByOrgTypeSuccess && rolesByOrgTypeSuccess && organizationId !== '' ? (
            <div>
              <Tabs defaultActiveKey="6" transition={false} id="noanim-tab-example" className="mb-3" onSelect={(key: string | null) => changeUserRoleId(key)}>
                <Tab eventKey="6" title={getTabLabel(RoleTypeIds.APP_USER)}>
                  <>
                    <Col className="d-flex justify-content-end mb-3">
                      <Col lg={3} className="form-sm">
                        <Search placeholder={searchPlaceHolder} size="sm" onChange={handleSearchChange} onKeyDown={handleSearchChange} onClick={handleSearchClick} />
                      </Col>
                      <Filter
                        width={450}
                        right
                        pageWrapId={'filter-wrapper'}
                        outerContainerId={'outer-container'}
                        customCrossIcon={<img src={Close} />}
                        isOpen={isOpen}
                        onOpen={() => setIsOpen(true)}
                        onClose={() => setIsOpen(false)}
                        burgerButtonClassName={filterApplied ? 'active' : ''}
                      >
                        <div id="filter-wrapper" className="filter-main">
                          <h4 className="mb-4">
                            <FormattedMessage id="Label.FilterUsers" />
                          </h4>
                          <Col className="form-sm">
                            <Select
                              label="Availability"
                              options={availabilityOptions}
                              placeholder="Select"
                              onChange={(e: any) => handleAvailabilityChange(e)}
                              value={availability}
                            />
                            <Row>
                              <Col>
                                <Select
                                  label="Category"
                                  options={categories}
                                  placeholder="Select"
                                  onChange={(e: ISelectOption) => handleCategoryChange(e)}
                                  value={selectedCategory}
                                />
                              </Col>
                              <Col>
                                <Select
                                  label="Sub Category"
                                  options={subcategories}
                                  placeholder="Select"
                                  onChange={(e: any) => handleSubcategoryChange(e)}
                                  value={selectedClass}
                                  isMulti
                                />
                              </Col>
                            </Row>
                            <Col>
                              <Select
                                label="Tags"
                                options={tagOptions}
                                placeholder="Select Tags"
                                onChange={handleStudentTagChange}
                                isMulti
                                value={selectedStudentTag.map((tag) => ({ value: tag, label: tag }))}
                              />
                            </Col>
                            <div className="custom-calendar form-sm mb-4">
                              <label className="form-label">
                                <FormattedMessage id="Label.DateofJoining" />
                              </label>
                              <DatePicker selected={dateFilter} onChange={(date) => handleDateChange(date)} />
                            </div>
                          </Col>
                          <Row className="mt-4 mb-4">
                            <Col>
                              <Button variant="outline-primary" className="w-100" onClick={clearFilters}>
                                <FormattedMessage id="Button.ClearFilter" />
                              </Button>
                            </Col>
                            <Col>
                              <Button variant="primary" className="w-100" onClick={() => applyFiltersStudent()}>
                                <FormattedMessage id="Button.ApplyFilters" />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Filter>
                    </Col>
                    <Table striped hover responsive>
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id="Label.Name" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.Username" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.USERNAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.OrgName" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.ORG_NAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.Categories" />
                          </th>
                          <th>
                            <FormattedMessage id="Label.Classes" />
                          </th>
                          <th>
                            <FormattedMessage id="Label.DateofJoining" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.DOJ)}>
                              <Sort />
                            </Link>
                          </th>
                          <th className="w-50px"></th>
                        </tr>
                      </thead>
                      <tbody> {getLevel6UsersTableRows()}</tbody>
                    </Table>
                    {userListApiSuccess && userListApiData.length > 0 && userListApiData[0].TotalCount > PAGE_SIZE && (
                      <Pagination
                        pageSize={PAGE_SIZE}
                        totalitems={userListApiData[0].TotalCount}
                        pageLimit={PAGE_SIZE}
                        setCurrentPage={(page: number) => setCurrentPage(page)}
                        currentPage={currentPage - 1}
                        prevPage={-1}
                        itemsDisplayed={userListApiData?.length}
                      />
                    )}
                  </>
                </Tab>
                <Tab eventKey="5" title={getTabLabel(RoleTypeIds.ACCOUNTABILITY_PARTNER)}>
                  <>
                    <Col className="d-flex justify-content-end mb-3">
                      <Col lg={3} className="form-sm">
                        <Search placeholder={searchPlaceHolder} size="sm" onChange={handleSearchChange} onKeyDown={handleSearchChange} onClick={handleSearchClick} />
                      </Col>
                      <Filter
                        width={450}
                        right
                        pageWrapId={'filter-wrapper'}
                        outerContainerId={'outer-container'}
                        customCrossIcon={<img src={Close} />}
                        isOpen={isOpen}
                        onOpen={() => setIsOpen(true)}
                        onClose={() => setIsOpen(false)}
                        burgerButtonClassName={filterApplied ? 'active' : ''}
                      >
                        <div id="filter-wrapper" className="filter-main">
                          <h4 className="mb-4">
                            <FormattedMessage id="Label.FilterUsers" />
                          </h4>
                          <Col className="form-sm">
                            <Select
                              label="Availability"
                              options={availabilityOptions}
                              placeholder="Select"
                              onChange={(e: any) => handleAvailabilityChange(e)}
                              value={availability}
                              // error="Error"
                            />
                            <div className="custom-calendar form-sm mb-4">
                              <label className="form-label">
                                <FormattedMessage id="Label.DateofJoining" />
                              </label>
                              <DatePicker selected={dateFilter} onChange={(date) => handleDateChange(date)} />
                            </div>
                          </Col>
                          <Row className="mt-4 mb-4">
                            <Col>
                              <Button variant="outline-primary" className="w-100" onClick={() => clearFilters()}>
                                <FormattedMessage id="Button.ClearFilter" />
                              </Button>
                            </Col>
                            <Col>
                              <Button variant="primary" className="w-100" onClick={() => applyFiltersOtherUsers()}>
                                <FormattedMessage id="Button.ApplyFilters" />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Filter>
                    </Col>
                    <Table striped hover responsive>
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id="Label.Name" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.Username" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.USERNAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.OrgName" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.ORG_NAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.NoStudentsAssigned" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NO_OF_USERS)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.DateofJoining" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.DOJ)}>
                              <Sort />
                            </Link>
                          </th>
                          <th className="w-50px"></th>
                        </tr>
                      </thead>
                      <tbody>{getLevelFiveToThreeTableRows()}</tbody>
                    </Table>
                    {userListApiSuccess && userListApiData.length > 0 && userListApiData[0].TotalCount > PAGE_SIZE && (
                      <Pagination
                        pageSize={PAGE_SIZE}
                        totalitems={userListApiData[0].TotalCount}
                        pageLimit={PAGE_SIZE}
                        setCurrentPage={(page: number) => setCurrentPage(page)}
                        currentPage={currentPage - 1}
                        prevPage={-1}
                        itemsDisplayed={userListApiData?.length}
                      />
                    )}
                  </>
                </Tab>
                <Tab eventKey="4" title={getTabLabel(RoleTypeIds.APP_USER_MANAGER)}>
                  <>
                    <Col className="d-flex justify-content-end mb-3">
                      <Col lg={3} className="form-sm">
                        <Search placeholder={searchPlaceHolder} size="sm" onChange={handleSearchChange} onKeyDown={handleSearchChange} onClick={handleSearchClick} />
                      </Col>
                      <Filter
                        width={450}
                        right
                        pageWrapId={'filter-wrapper'}
                        outerContainerId={'outer-container'}
                        customCrossIcon={<img src={Close} />}
                        isOpen={isOpen}
                        onOpen={() => setIsOpen(true)}
                        onClose={() => setIsOpen(false)}
                        burgerButtonClassName={filterApplied ? 'active' : ''}
                      >
                        <div id="filter-wrapper" className="filter-main">
                          <h4 className="mb-4">
                            <FormattedMessage id="Label.FilterUsers" />
                          </h4>
                          <Col className="form-sm">
                            <Select
                              label="Availability"
                              options={availabilityOptions}
                              placeholder="Select"
                              onChange={(e: any) => handleAvailabilityChange(e)}
                              value={availability}
                            />
                            <div className="custom-calendar form-sm mb-4">
                              <label className="form-label">
                                <FormattedMessage id="Label.DateofJoining" />
                              </label>
                              <DatePicker selected={dateFilter} onChange={(date) => handleDateChange(date)} />
                            </div>
                          </Col>
                          <Row className="mt-4 mb-4">
                            <Col>
                              <Button variant="outline-primary" className="w-100" onClick={() => clearFilters()}>
                                <FormattedMessage id="Button.ClearFilter" />
                              </Button>
                            </Col>
                            <Col>
                              <Button variant="primary" className="w-100" onClick={() => applyFiltersOtherUsers()}>
                                <FormattedMessage id="Button.ApplyFilters" />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Filter>
                    </Col>
                    <Table striped hover responsive>
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id="Label.Name" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.Username" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.USERNAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.OrgName" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.ORG_NAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.NoStudentsAssigned" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NO_OF_USERS)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.DateofJoining" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.DOJ)}>
                              <Sort />
                            </Link>
                          </th>
                          <th className="w-50px"></th>
                        </tr>
                      </thead>
                      <tbody>{getLevelFiveToThreeTableRows()}</tbody>
                    </Table>
                    {userListApiSuccess && userListApiData.length > 0 && userListApiData[0].TotalCount > PAGE_SIZE && (
                      <Pagination
                        pageSize={PAGE_SIZE}
                        totalitems={userListApiData[0].TotalCount}
                        pageLimit={PAGE_SIZE}
                        setCurrentPage={(page: number) => setCurrentPage(page)}
                        currentPage={currentPage - 1}
                        prevPage={-1}
                        itemsDisplayed={userListApiData?.length}
                      />
                    )}
                  </>
                </Tab>
                <Tab eventKey="3" title={getTabLabel(RoleTypeIds.ORGANIZATION_MANGER)}>
                  <>
                    <Col className="d-flex justify-content-end mb-3">
                      <Col lg={3} className="form-sm">
                        <Search placeholder={searchPlaceHolder} size="sm" onChange={handleSearchChange} onKeyDown={handleSearchChange} onClick={handleSearchClick} />
                      </Col>
                      <Filter
                        width={450}
                        right
                        pageWrapId={'filter-wrapper'}
                        outerContainerId={'outer-container'}
                        customCrossIcon={<img src={Close} />}
                        isOpen={isOpen}
                        onOpen={() => setIsOpen(true)}
                        onClose={() => setIsOpen(false)}
                        burgerButtonClassName={filterApplied ? 'active' : ''}
                      >
                        <div id="filter-wrapper" className="filter-main">
                          <h4 className="mb-4">
                            <FormattedMessage id="Label.FilterUsers" />
                          </h4>
                          <Col className="form-sm">
                            <Select
                              label="Availability"
                              options={availabilityOptions}
                              placeholder="Select"
                              onChange={(e: any) => handleAvailabilityChange(e)}
                              value={availability}
                            />
                            <div className="custom-calendar form-sm mb-4">
                              <label className="form-label">
                                <FormattedMessage id="Label.DateofJoining" />
                              </label>
                              <DatePicker selected={dateFilter} onChange={(date) => handleDateChange(date)} />
                            </div>
                            <h5 className="mb-3">
                              <FormattedMessage id="Label.AdditionalFilters" />
                            </h5>
                            <div className="check-sm">
                              <Checkbox type="Checkbox" label="Students with Completed To-Do items" />
                              <Checkbox type="Checkbox" label="Students with In Progress To-Do items" />
                              <Checkbox type="Checkbox" label="Students with Overdue To-Do items" />
                              <Checkbox type="Checkbox" label="Students with Not Started To-Do items" />
                              <Checkbox type="Checkbox" label="Students who Rescheduled their Goals" />
                            </div>
                          </Col>
                          <Row className="mt-4 mb-4">
                            <Col>
                              <Button variant="outline-primary" className="w-100" onClick={() => clearFilters()}>
                                <FormattedMessage id="Button.ClearFilter" />
                              </Button>
                            </Col>
                            <Col>
                              <Button variant="primary" className="w-100" onClick={() => applyFiltersOtherUsers()}>
                                <FormattedMessage id="Button.ApplyFilters" />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Filter>
                    </Col>
                    <Table striped hover responsive>
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id="Label.Name" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.Username" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.USERNAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.OrgName" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.ORG_NAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.NoStudentsAssigned" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NO_OF_USERS)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.DateofJoining" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.DOJ)}>
                              <Sort />
                            </Link>
                          </th>
                          <th className="w-50px"></th>
                        </tr>
                      </thead>
                      <tbody>{getLevelFiveToThreeTableRows()}</tbody>
                    </Table>
                    {userListApiSuccess && userListApiData.length > 0 && userListApiData[0].TotalCount > PAGE_SIZE && (
                      <Pagination
                        pageSize={PAGE_SIZE}
                        totalitems={userListApiData[0].TotalCount}
                        pageLimit={PAGE_SIZE}
                        setCurrentPage={(page: number) => setCurrentPage(page)}
                        currentPage={currentPage - 1}
                        prevPage={-1}
                        itemsDisplayed={userListApiData?.length}
                      />
                    )}
                  </>
                </Tab>
                <Tab eventKey="2" title={getTabLabel(RoleTypeIds.ORGANIZATION_ADMIN)}>
                  <>
                    <Col className="d-flex justify-content-end mb-3">
                      <Col lg={3} className="form-sm">
                        <Search placeholder={searchPlaceHolder} size="sm" onChange={handleSearchChange} onKeyDown={handleSearchChange} onClick={handleSearchClick} />
                      </Col>
                      <Filter
                        width={450}
                        right
                        pageWrapId={'filter-wrapper'}
                        outerContainerId={'outer-container'}
                        customCrossIcon={<img src={Close} />}
                        isOpen={isOpen}
                        onOpen={() => setIsOpen(true)}
                        onClose={() => setIsOpen(false)}
                        burgerButtonClassName={filterApplied ? 'active' : ''}
                      >
                        <div id="filter-wrapper" className="filter-main">
                          <h4 className="mb-4">
                            <FormattedMessage id="Label.FilterUsers" />
                          </h4>
                          <Col className="form-sm">
                            <Select
                              label="Availability"
                              options={availabilityOptions}
                              placeholder="Select"
                              onChange={(e: any) => handleAvailabilityChange(e)}
                              value={availability}
                            />
                            <div className="custom-calendar form-sm mb-4">
                              <label className="form-label">
                                <FormattedMessage id="Label.DateofJoining" />
                              </label>
                              <DatePicker selected={dateFilter} onChange={(date) => handleDateChange(date)} />
                            </div>
                          </Col>
                          <Row className="mt-4 mb-4">
                            <Col>
                              <Button variant="outline-primary" className="w-100" onClick={() => clearFilters()}>
                                <FormattedMessage id="Button.ClearFilter" />
                              </Button>
                            </Col>
                            <Col>
                              <Button variant="primary" className="w-100" onClick={() => applyFiltersOtherUsers()}>
                                <FormattedMessage id="Button.ApplyFilters" />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Filter>
                    </Col>
                    <Table striped hover responsive>
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id="Label.Name" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.Username" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.USERNAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.OrgName" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.ORG_NAME)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.NoStudentsAssigned" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NO_OF_USERS)}>
                              <Sort />
                            </Link>
                          </th>
                          <th>
                            <FormattedMessage id="Label.DateofJoining" />
                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.DOJ)}>
                              <Sort />
                            </Link>
                          </th>
                          <th className="w-50px"></th>
                        </tr>
                      </thead>
                      <tbody>{getLevelFiveToThreeTableRows()}</tbody>
                    </Table>
                    {userListApiSuccess && userListApiData.length > 0 && userListApiData[0].TotalCount > PAGE_SIZE && (
                      <Pagination
                        pageSize={PAGE_SIZE}
                        totalitems={userListApiData[0].TotalCount}
                        pageLimit={PAGE_SIZE}
                        setCurrentPage={(page: number) => setCurrentPage(page)}
                        currentPage={currentPage - 1}
                        prevPage={-1}
                        itemsDisplayed={userListApiData?.length}
                      />
                    )}
                  </>
                </Tab>
                {isUserAdmin() && (
                  <Tab eventKey={RoleTypeIds.SYSTEM_ADMIN.toString()} title={getTabLabel(RoleTypeIds.SYSTEM_ADMIN)}>
                    <>
                      <Col className="d-flex justify-content-end mb-3">
                        <Col lg={3} className="form-sm">
                          <Search placeholder={searchPlaceHolder} size="sm" onChange={handleSearchChange} onKeyDown={handleSearchChange} onClick={handleSearchClick} />
                        </Col>
                        <Filter
                          width={450}
                          right
                          pageWrapId={'filter-wrapper'}
                          outerContainerId={'outer-container'}
                          customCrossIcon={<img src={Close} />}
                          isOpen={isOpen}
                          onOpen={() => setIsOpen(true)}
                          onClose={() => setIsOpen(false)}
                          burgerButtonClassName={filterApplied ? 'active' : ''}
                        >
                          <div id="filter-wrapper" className="filter-main">
                            <h4 className="mb-4">
                              <FormattedMessage id="Label.FilterUsers" />
                            </h4>
                            <Col className="form-sm">
                              <Select
                                label="Availability"
                                options={availabilityOptions}
                                placeholder="Select"
                                onChange={(e: any) => handleAvailabilityChange(e)}
                                value={availability}
                              />
                              <div className="custom-calendar form-sm mb-4">
                                <label className="form-label">
                                  <FormattedMessage id="Label.DateofJoining" />
                                </label>
                                <DatePicker selected={dateFilter} onChange={(date) => handleDateChange(date)} />
                              </div>
                            </Col>
                            <Row className="mt-4 mb-4">
                              <Col>
                                <Button variant="outline-primary" className="w-100" onClick={() => clearFilters()}>
                                  <FormattedMessage id="Button.ClearFilter" />
                                </Button>
                              </Col>
                              <Col>
                                <Button variant="primary" className="w-100" onClick={() => applyFiltersOtherUsers()}>
                                  <FormattedMessage id="Button.ApplyFilters" />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Filter>
                      </Col>
                      <Table striped hover responsive>
                        <thead>
                          <tr>
                            <th>
                              <FormattedMessage id="Label.Name" />
                              <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                                <Sort />
                              </Link>
                            </th>
                            <th>
                              <FormattedMessage id="Label.Email" />
                              <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.EMAIL)}>
                                <Sort />
                              </Link>
                            </th>
                            <th>
                              <FormattedMessage id="Label.PhoneNum" />
                            </th>
                            <th>
                              <FormattedMessage id="Label.DateofJoining" />
                              <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.DOJ)}>
                                <Sort />
                              </Link>
                            </th>
                            <th className="w-50px"></th>
                          </tr>
                        </thead>
                        <tbody>{getLevelOneTableRows()}</tbody>
                      </Table>
                      {userListApiSuccess && userListApiData.length > 0 && userListApiData[0].TotalCount > PAGE_SIZE && (
                        <Pagination
                          pageSize={PAGE_SIZE}
                          totalitems={userListApiData[0].TotalCount}
                          pageLimit={PAGE_SIZE}
                          setCurrentPage={(page: number) => setCurrentPage(page)}
                          currentPage={currentPage - 1}
                          prevPage={-1}
                          itemsDisplayed={userListApiData?.length}
                        />
                      )}
                    </>
                  </Tab>
                )}
              </Tabs>
            </div>
          ) : (
            <div>
              {selectedOrganization.length === 0 && isUserAdmin() && (
                <h4 className="text-center">
                  <FormattedMessage id="Listusers.Intial.Message" />
                </h4>
              )}
              <Tabs transition={false} id="noanim-tab-example" className="mb-3">
                {isUserAdmin() && (
                  <Tab eventKey={RoleTypeIds.SYSTEM_ADMIN.toString()} title={<FormattedMessage id="Label.SystemAdmin" />}>
                    <>
                      <Col className="d-flex justify-content-end mb-3">
                        <Col lg={3} className="form-sm">
                          <Search placeholder={searchPlaceHolder} size="sm" onChange={handleSearchChange} onKeyDown={handleSearchChange} onClick={handleSearchClick} />
                        </Col>
                        <Filter
                          width={450}
                          right
                          pageWrapId={'filter-wrapper'}
                          outerContainerId={'outer-container'}
                          customCrossIcon={<img src={Close} />}
                          isOpen={isOpen}
                          onOpen={() => setIsOpen(true)}
                          onClose={() => setIsOpen(false)}
                          burgerButtonClassName={filterApplied ? 'active' : ''}
                        >
                          <div id="filter-wrapper" className="filter-main">
                            <h4 className="mb-4">
                              <FormattedMessage id="Label.FilterUsers" />
                            </h4>
                            <Col className="form-sm">
                              <Select
                                label="Availability"
                                options={availabilityOptions}
                                placeholder="Select"
                                onChange={(e: any) => handleAvailabilityChange(e)}
                                value={availability}
                              />
                              <div className="custom-calendar form-sm mb-4">
                                <label className="form-label">
                                  <FormattedMessage id="Label.DateofJoining" />
                                </label>
                                <DatePicker selected={dateFilter} onChange={(date) => handleDateChange(date)} />
                              </div>
                            </Col>
                            <Row className="mt-4 mb-4">
                              <Col>
                                <Button variant="outline-primary" className="w-100" onClick={() => clearFilters()}>
                                  <FormattedMessage id="Button.ClearFilter" />
                                </Button>
                              </Col>
                              <Col>
                                <Button variant="primary" className="w-100" onClick={() => applyFiltersOtherUsers()}>
                                  <FormattedMessage id="Button.ApplyFilters" />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Filter>
                      </Col>
                      <Table striped hover responsive>
                        <thead>
                          <tr>
                            <th>
                              <FormattedMessage id="Label.Name" />
                              <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                                <Sort />
                              </Link>
                            </th>
                            <th>
                              <FormattedMessage id="Label.Email" />
                              <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.EMAIL)}>
                                <Sort />
                              </Link>
                            </th>
                            <th>
                              <FormattedMessage id="Label.PhoneNum" />
                            </th>
                            <th>
                              <FormattedMessage id="Label.DateofJoining" />
                              <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.DOJ)}>
                                <Sort />
                              </Link>
                            </th>
                            <th className="w-50px"></th>
                          </tr>
                        </thead>
                        <tbody>{getLevelOneTableRows()}</tbody>
                      </Table>
                      {userListApiSuccess && userListApiData.length > 0 && userListApiData[0].TotalCount > PAGE_SIZE && (
                        <Pagination
                          pageSize={PAGE_SIZE}
                          totalitems={userListApiData[0].TotalCount}
                          pageLimit={PAGE_SIZE}
                          setCurrentPage={(page: number) => setCurrentPage(page)}
                          currentPage={currentPage - 1}
                          prevPage={-1}
                          itemsDisplayed={userListApiData?.length}
                        />
                      )}
                    </>
                  </Tab>
                )}
              </Tabs>
            </div>
          )}
        </div>
        {(userListApiLoading || getStudentTagsApiLoading) && <Loader />}
      </div>
    </>
  );
};
export default ManageUsers;
