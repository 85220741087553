/**
 * @file   src\containers\notifications\List.tsx
 * @brief  Notifications list page.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import NotificationCard from '../../components/NotificationCard';
import Pagination from '../../components/Pagination';
import { PAGE_SIZE, DEFAULT_PAGE_INDEX, DATE_TIME_FORMAT_DISPLAY } from '../../utils/constants';
import { IListNotificationParams } from '../../interfaces/Notification';
import { deleteNotifications, getNotificationList } from '../../store/actions/notificationActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import Loader from '../../components/Loader';
import moment from 'moment';
import { useIntlMessages, useIntlActionMessages } from '../../utils/helper';
import { Button } from 'react-bootstrap';
import DeleteConfirmPopup from '../../components/DeleteConfirmation';
import { resetDeleteNotifications } from '../../store/slices/notificationSlice';
import { MessageToaster } from '../../utils/ToastUtil';
import Delete from '../../assets/img/icon/Delete';

// Default params for api request
const notificationDefaultParams: IListNotificationParams = {
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE,
};

const Notifications = () => {
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Message toaster object creation
  const toastObj = new MessageToaster();
  // Access redux state variables
  const {
    notificationListApiData,
    notificationListApiLoading,
    notificationListApiSuccess,
    deleteNotificationsApiLoading,
    deleteNotificationsApiSuccess,
    deleteNotificationsApiResponseMessage,
  } = useAppSelector((state: RootState) => state.notification);

  // Initialize component state variables.
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationApiParams, setNotificationApiParams] = useState<any>(notificationDefaultParams);
  const [isShowDeleteConfirmPopup, setIsShowDeleteConfirmPopup] = useState<boolean>(false);
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');

  // Reset the form after listing competency
  useEffect(() => {
    try {
      return () => {
        dispatch(resetDeleteNotifications());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // api call to get organization list
  useEffect(() => {
    try {
      dispatch(getNotificationList(notificationApiParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [notificationApiParams]);

  // handle pagination
  useEffect(() => {
    try {
      setNotificationApiParams((apiParams: any) => ({
        ...apiParams,
        Page: currentPage,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [currentPage]);

  // Handle delete confirmation
  const showDeleteConfirmPopup = () => {
    try {
      setIsShowDeleteConfirmPopup(true);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  useEffect(() => {
    try {
      if (deleteNotificationsApiSuccess) {
        setIsShowDeleteConfirmPopup(false);
        dispatch(getNotificationList(notificationApiParams));
        toastObj.toastSuccess(deleteNotificationsApiResponseMessage);
      } else if (!deleteNotificationsApiSuccess && deleteNotificationsApiResponseMessage.length > 0) {
        toastObj.toastError(deleteNotificationsApiResponseMessage);
        dispatch(resetDeleteNotifications());
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [deleteNotificationsApiLoading]);

  // Api call to delete resource
  const handleDeleteNotificationsClick = () => {
    try {
      dispatch(deleteNotifications({}));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Hd.Notifications" />
        </h3>
        {notificationListApiSuccess && notificationListApiData?.Notifications?.length > 0 && notificationListApiData?.TotalCount > 0 && (
          <Button variant="dark" size="sm" className="icon-btn delete-btn" onClick={() => showDeleteConfirmPopup()}>
            <Delete />
          </Button>
        )}
      </div>
      <div className="content-sub">
        <div className="content-area-padding">
          {notificationListApiSuccess && (notificationListApiData?.Notifications == null || notificationListApiData?.Notifications?.length == 0 || !notificationListApiData) ? (
            <div className="text-center mt-5">No results found.</div>
          ) : (
            notificationListApiSuccess &&
            notificationListApiData?.Notifications?.length > 0 &&
            notificationListApiData?.Notifications?.map((notification: any) => (
              <NotificationCard content={notification.Message} date={moment(notification?.CreatedAt).format(DATE_TIME_FORMAT_DISPLAY)} />
            ))
          )}
          {notificationListApiSuccess && notificationListApiData?.Notifications?.length > 0 && notificationListApiData?.TotalCount > PAGE_SIZE && (
            <Pagination
              pageSize={PAGE_SIZE}
              totalitems={notificationListApiData.TotalCount}
              pageLimit={PAGE_SIZE}
              setCurrentPage={(page: number) => setCurrentPage(page)}
              currentPage={currentPage - 1}
              prevPage={-1}
              itemsDisplayed={notificationListApiData?.Notifications?.length}
            />
          )}
        </div>
      </div>
      {notificationListApiLoading && <Loader />}
      <DeleteConfirmPopup
        show={isShowDeleteConfirmPopup}
        handleClose={() => setIsShowDeleteConfirmPopup(false)}
        onCancel={() => setIsShowDeleteConfirmPopup(false)}
        onOkay={() => handleDeleteNotificationsClick()}
        title={useIntlActionMessages('Text.DeleteConfirmation.Notifications')}
        content={useIntlActionMessages('Text.DeleteConfirmation.Notifications.Desc')}
      />
    </>
  );
};
export default Notifications;
