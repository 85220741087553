import SelectableCalendar from '../../components/SelectableCalendar';
import { Button, Col, Row, Tab, Tabs, ButtonGroup } from 'react-bootstrap';
import { slide as Filter } from 'react-burger-menu';
import { FormattedMessage } from 'react-intl';
import { useState, useRef } from 'react';
import Close from '../../assets/img/Close.svg';
import { Scrollbar } from 'react-scrollbars-custom';
import '../../assets/css/Input.scss';
import Input from '../../components/MAInput';
import Select from '../../components/MASelect';
import { NumberValues } from '../../utils/enums';
import DatePicker from 'react-datepicker';
import LinkIcon from '../../assets/img/icon/Link';
import AddIcon from '../../assets/img/icon/Add';
import Checkbox from '../../components/MACheck';
import Upload from '../../assets/img/icon/Upload';
import { useIntlActionMessages } from '../../utils/helper';

const GoalStep3NewDesign = () => {
  const [open, setOpen] = useState<boolean>(false);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <h3 className="text-center mb-4">Setup Tasks/Events</h3>
      <div className="page-title d-flex justify-content-center align-items-center">
        <Col xl={10}>
          <h5 className="fw-normal">Here are your milestones – tailored to match the timeline you’ve set for this goal!</h5>
          <div className="mt-3">
            <span className="milestone-legends weekly">Weekly Milestone</span>
            <span className="milestone-legends monthly">Monthly Milestone</span>
            <span className="milestone-legends halfway">Halfway Milestone</span>
            <span className="milestone-legends daily">Daily Milestone</span>
          </div>
          <div className="border-b rounded p-4 my-4">
            <h5 className="fw-normal">To achieve your 3-month goal, you need to set up the following milestones:</h5>
            <ul className="list-style">
              <li>Halfway Milestone: Requires at least 3 tasks per week for 4 weeks within half of the goal duration.</li>
              <li>1 Month Milestone: Requires at least 3 tasks per week for 3 weeks within the month.</li>
              <li>Weekly Milestone: Requires at least 3 tasks in a week.</li>
              <li>Daily Milestone (optional): If you create 3 tasks on the same day, it will count as a daily milestone for that day.</li>
            </ul>
          </div>
          <div className="calendar-main">
            <h5 className="fw-normal">Tap on a date on the calendar to add a new task or event.</h5>
            <SelectableCalendar onSelect={() => setOpen(true)} />
          </div>
          <Col className="btn-container d-flex justify-content-end my-4">
            <Button variant="outline-primary" className="me-2">
              Cancel
            </Button>
            <Button>Next</Button>
          </Col>
          <div className="d-flex justify-content-center my-5">
            <Col xl={4}>
              <Row>
                <Col className="resource-button">
                  <div className="custom-berger-menu">
                    <Filter
                      width={450}
                      right
                      isOpen={open}
                      onOpen={() => {
                        setOpen(true);
                        document.body.style.overflow = 'hidden';
                      }}
                      onClose={() => setOpen(false)}
                      pageWrapId={'filter-wrapper'}
                      outerContainerId={'outer-container'}
                      customCrossIcon={<img src={Close} />}
                    >
                      <div id="filter-wrapper" className="filter-main">
                        <h4>Add Task/Add Event</h4>
                        <Tabs defaultActiveKey="Task" id="uncontrolled-tab-example" className="my-3">
                          <Tab eventKey="Task" title="Task">
                            <Scrollbar className="h-68vh">
                              <Col className="form-sm mt-2">
                                <Input
                                  label="Name"
                                  id="Resource"
                                  name="Name"
                                  type="text"
                                  placeholder="Name"
                                  maxLength={100}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    console.log('ss');
                                  }}
                                  errorMessage=""
                                  value=""
                                />
                              </Col>
                              <Col className="addOrg-textarea">
                                <Input
                                  label="Description"
                                  id="Description"
                                  name="Description"
                                  type="text"
                                  placeholder="Description"
                                  maxLength={NumberValues.NUM_1000}
                                  as="textarea"
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    console.log('ss');
                                  }}
                                  errorMessage=""
                                  value=""
                                />
                                <span className="txt-count">0/1000</span>
                              </Col>
                              <div className="d-flex">
                                <Col className="form-sm pe-2">
                                  <Select label="Category" options={[]} value={[]} placeholder="Select" onChange={(e: any) => console.log('ss')} error="" />
                                </Col>
                                <Col className="form-sm ps-2">
                                  <Select label="Subcategory" options={[]} value={[]} placeholder="Select" onChange={(e: any) => console.log('ss')} error="" isMulti />
                                </Col>
                              </div>
                              <div className="custom-calendar form-sm mb-4">
                                <label className="form-label">
                                  <FormattedMessage id="Label.StartDateTime" />
                                </label>
                                <DatePicker
                                  selected={new Date()}
                                  onChange={(date: any) => {
                                    console.log('ll');
                                  }}
                                  showTimeSelect
                                  timeIntervals={1}
                                  timeInputLabel="Time:"
                                  dateFormat="MM/dd/yyyy h:mm aa"
                                  placeholderText="MM/DD/YYYY"
                                />
                              </div>
                              <div className="custom-calendar form-sm mb-3">
                                <label className="form-label">End Date and Time</label>
                                <DatePicker
                                  selected={new Date()}
                                  onChange={(date: any) => {
                                    console.log('ll');
                                  }}
                                  showTimeSelect
                                  timeIntervals={1}
                                  timeInputLabel="Time:"
                                  dateFormat="MM/dd/yyyy h:mm aa"
                                  placeholderText="MM/DD/YYYY"
                                />
                              </div>
                              <Checkbox type="Checkbox" id="IsGeneral" name="IsGeneral" label="Add Recurring" />
                              <Col className="d-flex">
                                <Checkbox type="radio" id="IsGeneral" name="IsGeneral" label="Recurring By Day" className="me-4" />
                                <Checkbox type="radio" id="IsGeneral" name="IsGeneral" label="Recurring By Date" />
                              </Col>
                              <ButtonGroup className="d-flex weeks mb-3" aria-label="First group">
                                <Button>MON</Button>
                                <Button className="active">TUE</Button>
                                <Button>WED</Button>
                                <Button>THU</Button>
                                <Button>FRI</Button>
                                <Button>SAT</Button>
                                <Button>SUN</Button>
                              </ButtonGroup>
                              <div className="custom-calendar form-sm mb-3">
                                <label className="form-label">Recurring Up to</label>
                                <DatePicker
                                  selected={new Date()}
                                  onChange={(date: any) => {
                                    console.log('ll');
                                  }}
                                  showTimeSelect
                                  timeIntervals={1}
                                  timeInputLabel="Time:"
                                  dateFormat="MM/dd/yyyy h:mm aa"
                                  placeholderText="MM/DD/YYYY"
                                />
                              </div>
                              <h5>Associate Competency</h5>
                              <Col className="form-sm mt-3">
                                <Select label="Select Competency Categories" options={[]} value={[]} placeholder="Select" onChange={(e: any) => console.log('ss')} error="" />
                              </Col>
                              <Col className="form-sm mt-4">
                                <Select label="Select Sub Categories" options={[]} value={[]} placeholder="Select" onChange={(e: any) => console.log('ss')} error="" />
                              </Col>
                              <Col className="form-sm mt-4">
                                <Select label="Select Competencies" options={[]} value={[]} placeholder="Select" onChange={(e: any) => console.log('ss')} error="" />
                              </Col>
                              <h6>Add Motivational Resources</h6>
                              <div className="d-flex">
                                <Col className="form-sm pe-2">
                                  <Button variant="secondary" className="btn-icon-top w-100">
                                    <span>
                                      <LinkIcon />
                                    </span>
                                    <FormattedMessage id="Button.LinkResource" />
                                  </Button>
                                </Col>
                                <Col className="form-sm ps-2">
                                  <Button variant="secondary" className="btn-icon-top w-100" onClick={() => setOpen(true)}>
                                    <span>
                                      <AddIcon />
                                    </span>
                                    <FormattedMessage id="Button.AddNew" />
                                  </Button>
                                </Col>
                              </div>
                              <div className="justify-content-center d-flex h-200 align-items-center bg-secondary rounded mt-3">
                                <div className="addMotiv-res">
                                  <div className="fileupload-sec  mb-2">
                                    <input
                                      ref={fileUploadRef}
                                      type="file"
                                      name="file-3[]"
                                      id="file-3"
                                      className="inputfile inputfile-3"
                                      data-multiple-caption="{count} files selected"
                                      multiple={false}
                                      accept="image/*"
                                      // onChange={(e: any) => uploadResourceFile(e)}
                                    />
                                    <label htmlFor="file-3" className="fileupload">
                                      <Upload />
                                      <span className="mt-1">{useIntlActionMessages('Button.AddImgVideo')}</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <Row className="align-items-center pt-3">
                                <Col>
                                  <small className="text-secondary">
                                    Would you like to review and approve the submitted proofs to mark this task as complete for the assignee?
                                  </small>
                                </Col>
                                <Col xs="auto" className="pe-3">
                                  <Button variant="outline-primary" className="me-2">
                                    No
                                  </Button>
                                  <Button>Yes</Button>
                                </Col>
                              </Row>
                            </Scrollbar>
                            <Row className="mt-4 pb-4 d-flex">
                              <Col>
                                <Button variant="outline-primary" className="w-100" onClick={() => setOpen(false)}>
                                  <FormattedMessage id="Button.Cancel" />
                                </Button>
                              </Col>
                              <Col>
                                <Button variant="primary" className="w-100" onClick={() => setOpen(false)}>
                                  <FormattedMessage id="Button.Done" />
                                </Button>
                              </Col>
                            </Row>
                          </Tab>
                          <Tab eventKey="Event" title="Event">
                            <Scrollbar className="h-68vh">
                              <Col className="form-sm mt-2">
                                <Input
                                  label="Name"
                                  id="Resource"
                                  name="Name"
                                  type="text"
                                  placeholder="Name"
                                  maxLength={100}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    console.log('ss');
                                  }}
                                  errorMessage=""
                                  value=""
                                />
                              </Col>
                              <Col className="addOrg-textarea">
                                <Input
                                  label="Description"
                                  id="Description"
                                  name="Description"
                                  type="text"
                                  placeholder="Description"
                                  maxLength={NumberValues.NUM_1000}
                                  as="textarea"
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    console.log('ss');
                                  }}
                                  errorMessage=""
                                  value=""
                                />
                                <span className="txt-count">0/1000</span>
                              </Col>
                              <div className="d-flex">
                                <Col className="form-sm pe-2">
                                  <Select label="Category" options={[]} value={[]} placeholder="Select" onChange={(e: any) => console.log('ss')} error="" />
                                </Col>
                                <Col className="form-sm ps-2">
                                  <Select label="Subcategory" options={[]} value={[]} placeholder="Select" onChange={(e: any) => console.log('ss')} error="" isMulti />
                                </Col>
                              </div>
                              <div className="custom-calendar form-sm mb-4">
                                <label className="form-label">
                                  <FormattedMessage id="Label.StartDateTime" />
                                </label>
                                <DatePicker
                                  selected={new Date()}
                                  onChange={(date: any) => {
                                    console.log('ll');
                                  }}
                                  showTimeSelect
                                  timeIntervals={1}
                                  timeInputLabel="Time:"
                                  dateFormat="MM/dd/yyyy h:mm aa"
                                  placeholderText="MM/DD/YYYY"
                                />
                              </div>
                              <Checkbox type="Checkbox" id="IsGeneral" name="IsGeneral" label="Add Recurring" />
                              <Col className="d-flex">
                                <Checkbox type="radio" id="IsGeneral" name="IsGeneral" label="Recurring By Day" className="me-4" />
                                <Checkbox type="radio" id="IsGeneral" name="IsGeneral" label="Recurring By Date" />
                              </Col>
                              <ButtonGroup className="d-flex weeks mb-3" aria-label="First group">
                                <Button>MON</Button>
                                <Button className="active">TUE</Button>
                                <Button>WED</Button>
                                <Button>THU</Button>
                                <Button>FRI</Button>
                                <Button>SAT</Button>
                                <Button>SUN</Button>
                              </ButtonGroup>
                              <div className="justify-content-center d-flex h-200 align-items-center bg-secondary rounded mt-3">
                                <div className="addMotiv-res">
                                  <div className="fileupload-sec  mb-2">
                                    <input
                                      ref={fileUploadRef}
                                      type="file"
                                      name="file-3[]"
                                      id="file-3"
                                      className="inputfile inputfile-3"
                                      data-multiple-caption="{count} files selected"
                                      multiple={false}
                                      accept="image/*"
                                      // onChange={(e: any) => uploadResourceFile(e)}
                                    />
                                    <label htmlFor="file-3" className="fileupload">
                                      <Upload />
                                      <span className="mt-1">{useIntlActionMessages('Button.AddImgVideo')}</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </Scrollbar>
                            <Row className="mt-4 pb-4 d-flex">
                              <Col>
                                <Button variant="outline-primary" className="w-100" onClick={() => setOpen(false)}>
                                  <FormattedMessage id="Button.Cancel" />
                                </Button>
                              </Col>
                              <Col>
                                <Button variant="primary" className="w-100" onClick={() => setOpen(false)}>
                                  <FormattedMessage id="Button.Done" />
                                </Button>
                              </Col>
                            </Row>
                          </Tab>
                        </Tabs>
                      </div>
                    </Filter>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </Col>
      </div>
    </>
  );
};

export default GoalStep3NewDesign;
