/**
 * @file   src\containers\organizations\TaskDetails.tsx
 * @brief  Task details page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { FormattedMessage } from 'react-intl';
import { useIntlActionMessages, useIntlMessages, getFromLocalStorage, checkValidFile, getFileTypeNumber, bytesToSize } from '../../utils/helper';
import React, { useEffect, useState, useRef } from 'react';
import { Col, Breadcrumb, Accordion, Nav, NavDropdown, Row, ProgressBar, Button, Table, Tabs, Tab } from 'react-bootstrap';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Search from '../../components/MASearch';
import { end } from '@popperjs/core';
import More from '../../assets/img/More.svg';
import UserImg from '../../assets/img/Userimg.jpg';
import { Scrollbar } from 'react-scrollbars-custom';
import { slide as Filter } from 'react-burger-menu';
import Close from '../../assets/img/Close.svg';
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import Input from '../../components/MAInput';
import AddIcon from '../../assets/img/icon/Add';
import DeleteConfirmPopup from '../../components/DeleteConfirmation';
import { NumberValues, AssignmentType, ASSIGNMENT_STUDENTLIST_TABS, FILE_TYPES_SUPPORTED } from '../../utils/enums';
import {
  getEventDetails,
  getTaskDetails,
  deleteEventsTasks,
  getUsersByGoalId,
  getCategoryList,
  uploadFile,
  uploadFilePathUpdateMultiple,
} from '../../store/actions/organizationActions';
import {
  getAssignmentStudentsList,
  getAssignmentProofs,
  getAssignmentStatusCount,
  approveAssignment,
  rejectAssignment,
  sendAssignmentMessage,
} from '../../store/actions/goalAction';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import moment from 'moment';
import Default from '../../assets/img/default.jpg';
import { PAGE_SIZE, PAGE_SIZE_SORT_50, BUCKET_URL, DEFAULT_PAGE_INDEX, DEFAULT_SELECT_OPTION_NUMBER, DATE_TIME_FORMAT_DISPLAY } from '../../utils/constants';
import {
  IGetUsersByGoalIdAPIRequest,
  IDeleteTaskEventParams,
  IEventDetailsRequest,
  ITaskDetailsRequest,
  IuploadFilePathUpdateMultipleParams,
} from '../../interfaces/OrganisationInterface';
import {
  IGetAssignmentStudentsList,
  IAssignmentAttachmentApi,
  IApproveAssignmentApi,
  IAssignmentStatusCountApi,
  IRejectAssignmentApi,
  IMessageAssignmentApi,
} from '../../interfaces/GoalInterface';
import { MessageToaster } from '../../utils/ToastUtil';
import Loader from '../../components/Loader';
import { resetAssignmentDelete, resetaddGoalStepThree, resetUploadFile, resetUploadFilePathUpdateMultiple } from '../../store/slices/organizationSlice';
import { ISelectOptionsNumber } from '../../interfaces/GeneralInterface';
import {
  resetAssignmentStudentsList,
  resetAssignmentStatusCount,
  resetApproveAssignment,
  resetAssignmentProofs,
  resetRejectAssignment,
  resetAssignmentMessage,
} from '../../store/slices/goalSlice';
import CategoryFilter from './CategoryFilter';
import AssignmentStudentList from './AssignmentStudentList';
import useDebounce from '../../hooks/useDebounce';
import downdocicn from '../../assets/img/icn-download-doc.png';
import { validateForm } from '../../utils/formValidation';
import { ASSIGNMENT_SCHEMA } from '../../validations/assignmentSchema';
import Image from '../../assets/img/icon/Image';
import Audio from '../../assets/img/icon/Audio';
import Video from '../../assets/img/icon/Video';
import Doc from '../../assets/img/icon/Attachment';
import AttachmentCard from '../../components/AttachmentCard';
import PopupRecorder from '../../components/PopupRecorder';

// default params
const assignmentUserListAPIDefaultParams: IGetAssignmentStudentsList = {
  CategoryId: 0,
  GoalId: 0,
  ID: 0,
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE,
  Search: '',
  Status: 'Completed',
  SubCategories: [],
  Type: 'Event',
};

const userlistDefaultFilter = {
  Category: 0,
  SubCategories: [],
};

// Call to get  file Icon
const getIcon = (fileType: string) => {
  switch (fileType?.toLowerCase()) {
    case 'audio':
      return <Audio />;
    case 'image':
      return <Image />;
    case 'video':
      return <Video />;
    case 'document':
      return <Doc />;
    default:
      return <Image />;
  }
};

function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const TaskDetails = () => {
  const defaultRejectFormErrors = {
    Reason: useIntlActionMessages('Form.RejectReason.Required'),
  };
  // Action dispatch object creation
  const dispatch = useAppDispatch();
  // Navigate object creation.
  const navigate = useNavigate();
  // Location objects
  const location = useLocation();
  // Message toast object creation
  const toastObj = new MessageToaster();
  // Ref for  input element on the page
  const fileUploadRef = useRef<any>();

  const orgDetail = getFromLocalStorage('ORG_DETAIL');
  // accessing redux state variables
  const {
    getGoalDetailAPiData,
    deleteTaskEventsApiLoading,
    deleteTaskEventsApiSuccess,
    deleteTaskEventsApiErrorMessage,
    getEventDetailsApiLoading,
    getEventDetailsApiData,
    getEventDetailsApiSuccess,
    getTaskDetailsApiData,
    getTaskDetailsApiLoading,
    getTaskDetailsApiSuccess,
    getUsersByGoalIdApiLoading,
    getUsersByGoalIdAPiData,
    getUsersByGoalIdApiSuccess,
    getCatergoryListAPiData,
    getCategoryListApiLoading,
    uploadFileApiSuccess,
    uploadFileResponseCode,
    uploadFileResponseMessage,
    uploadFileApiData,
    uploadFilePathUpdateMultipleApiLoading,
    uploadFilePathUpdateMultipleApiSuccess,
    uploadFilePathUpdateMultipleResponseCode,
    uploadFilePathUpdateMultipleResponseMessage,
  } = useAppSelector((state: RootState) => state.organization);

  const {
    getAssignmentStudentsListAPiData,
    getAssignmentStudentsListApiLoading,
    getAssignmentStatusCountAPiData,
    getAssignmentStatusCountApiLoading,
    getAssignmentStudentsListApiSuccess,
    getAssignmentProofsAPiData,
    getAssignmentProofsApiLoading,
    approveAssignmentApiLoading,
    approveAssignmentApiResponseMessage,
    approveAssignmentApiSuccess,
    rejectAssignmentApiLoading,
    rejectAssignmentApiResponseMessage,
    rejectAssignmentApiSuccess,
    sendAssignmentMessageApiLoading,
    sendAssignmentMessageApiResponseCode,
    sendAssignmentMessageApiResponseMessage,
    sendAssignmentMessageApiSuccess,
  } = useAppSelector((state: RootState) => state.goal);

  const [isShowDeleteConfirmPopup, setIsShowDeleteConfirmPopup] = useState<boolean>(false);
  const [assignmentDetails, setAssignmentDetails] = useState<any>({});
  const [context, setContext] = useState<string>(AssignmentType.EVENT);
  const [confirmedUsers, setConfirmedUsers] = useState<any[]>([]);
  const [isOpenAssignedUsers, setIsOpenAssignedUsers] = useState<boolean>(false);
  const [isOpenRejectComment, setIsOpenRejectComment] = useState<boolean>(false);
  const [isOpenMessage, setIsOpenMessage] = useState<boolean>(false);
  const [assignmentUserListApiParams, setAssignmentUserListApiParams] = useState<IGetAssignmentStudentsList>(assignmentUserListAPIDefaultParams);
  const [categoryList, setCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [subCategoryList, setSubCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOptionsNumber>(DEFAULT_SELECT_OPTION_NUMBER);
  const [selectedSubCategory, setSelectedSubCategory] = useState<ISelectOptionsNumber[]>([]);
  const [userlistFilter, setUserlistFilter] = useState<any>(userlistDefaultFilter);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [activeStudentDetails, setActiveStudentDetails] = useState<any>({});
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [items, setItems] = useState<any>({});
  const [searchText, setSearchText] = useState<string>('');
  const [assignmentRejectFormdata, setAssignmentRejectFormData] = useState<any>({});
  const [assignmentRejectFormErrors, setAssignmentRejectFormErrors] = useState<any>(defaultRejectFormErrors);
  const [characterCount, setCharacterCount] = useState<number>(0);
  const [messageCharacterCount, setMessageCharacterCount] = useState<number>(0);
  const [message, setMessage] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const [actionBtnHide, setActionBtnHide] = useState<boolean>(false);
  const [fileUploadBegin, setFileUploadBegin] = useState<boolean>(false);
  const [fileUploadError, setFileUploadError] = useState<string>('');
  const [fileUploadType, setFileUploadType] = useState<string>('');
  const [upImageAttachment, setUpImageAttachment] = useState<string | null>(null);
  const [upImageAttachmentDetails, setUpImageAttachmentDetails] = useState<any>({});
  const [uploadedFileInfo, setUploadedFileInfo] = useState<any>([]);
  const [uploadAttachment, setUploadAttachment] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState(false);
  const [recordType, setRecordType] = useState<string>('');

  // Invoke debounce component on intervel
  const debouncedSearch = useDebounce(searchText, 500);
  const storageData = getFromLocalStorage('MI_USR_DATA');

  const UnsupportedFileTypeText = useIntlActionMessages('Label.UnsupportedFileType');
  const DownloadFileText = useIntlActionMessages('Label.DownloadFile');
  const NoattachmentsFoundText = useIntlActionMessages('Label.NoattachmentsFound');
  const DeleteAssignmentError = useIntlActionMessages('Form.authorization');
  const PHSpecifyReasonRejection = useIntlMessages('PH.SpecifyReasonRejection');
  const ImageUploadFileError = useIntlActionMessages('Image.Upload.FileError');
  const AddMessageSuccess = useIntlActionMessages('Message.Success');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');

  // intitial loading
  useEffect(() => {
    try {
      if (getGoalDetailAPiData?.CreatedByDetails?.UserId != storageData?.UserId || !location?.state?.reviewUsers?.includes(storageData?.RoleTypeId)) {
        setActionBtnHide(true);
      }
      setItems({});
      setIsOpenMessage(false);
      setUploadedFileInfo([]);
      setAssignmentUserListApiParams(() => ({
        ...assignmentUserListAPIDefaultParams,
        GoalId: location?.state?.goalId,
        ID: location?.state?.assignmentId,
        Status: 'Completed',
        Type: capitalizeFirstLetter(location?.state?.assignmentType),
      }));
      if (location?.state?.assignmentType === AssignmentType.EVENT) {
        const requestBody: IEventDetailsRequest = {
          GoalId: location?.state?.goalId,
          EventId: location?.state?.assignmentId,
        };
        dispatch(getEventDetails(requestBody));
      } else {
        const requestBody: ITaskDetailsRequest = {
          GoalId: location?.state?.goalId,
          TaskId: location?.state?.assignmentId,
        };
        dispatch(getTaskDetails(requestBody));
      }
      const statusCountRequest: IAssignmentStatusCountApi = {
        GoalId: location?.state?.goalId,
        ID: location?.state?.assignmentId,
        Type: capitalizeFirstLetter(location?.state?.assignmentType),
      };
      dispatch(getAssignmentStatusCount(statusCountRequest));
      const getUsersByGoalIdAPIRequest: IGetUsersByGoalIdAPIRequest = {
        GoalId: location?.state?.goalId,
        PageNo: DEFAULT_PAGE_INDEX,
        PageSize: PAGE_SIZE_SORT_50,
      };
      dispatch(getUsersByGoalId(getUsersByGoalIdAPIRequest));
      dispatch(getCategoryList({ OrganizationId: orgDetail?.OrganizationId }));
      setIsOpenRejectComment(false);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
      return () => {
        dispatch(resetAssignmentDelete());
        dispatch(resetAssignmentStudentsList());
        dispatch(resetAssignmentStatusCount());
        dispatch(resetApproveAssignment());
        dispatch(resetRejectAssignment());
        dispatch(resetAssignmentProofs());
        dispatch(resetUploadFile());
        dispatch(resetAssignmentMessage());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [approveAssignmentApiSuccess, rejectAssignmentApiSuccess]);
  // params setting after debounce for search
  useEffect(() => {
    try {
      setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
        ...assignmentUserListApiParams,
        Search: searchText,
        Page: DEFAULT_PAGE_INDEX,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [debouncedSearch]);

  // handle event details api response
  useEffect(() => {
    try {
      if (getEventDetailsApiSuccess && Object.keys(getEventDetailsApiData).length > 0) {
        const startDateObj = new Date(getEventDetailsApiData.StartDateWithTime);
        const endDateObj = new Date(getEventDetailsApiData.EndDateWithTime);
        setContext(AssignmentType.EVENT);
        const eventDetails = {
          Name: getEventDetailsApiData.Name,
          CategoryId: getEventDetailsApiData.CategoryId,
          Description: getEventDetailsApiData.Description,
          StartDate: moment(startDateObj).format(DATE_TIME_FORMAT_DISPLAY),
          EndDate: moment(endDateObj).format(DATE_TIME_FORMAT_DISPLAY),
          CategoryName: getEventDetailsApiData.CategoryName,
          GoalType: getEventDetailsApiData.GoalType,
          AssignedBy: getEventDetailsApiData.AssignedBy,
          AssignedByRole: getEventDetailsApiData.AssignedByRole,
          Status: getEventDetailsApiData.Status,
          StatusPercentage: getEventDetailsApiData.StatusPercentage,
          SubCategories: getEventDetailsApiData?.SubCategories?.map((classItem: any, index: number) => (
            <span key={index}>
              {classItem.classname}
              {index !== getEventDetailsApiData?.SubCategories?.length - 1 && ', '}
            </span>
          )),
        };
        setAssignmentDetails(eventDetails);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getEventDetailsApiLoading]);

  // set up task details for editing
  useEffect(() => {
    try {
      if (getTaskDetailsApiSuccess && Object.keys(getTaskDetailsApiData).length > 0) {
        const startDateObj = new Date(getTaskDetailsApiData.StartDate);
        const endDateObj = new Date(getTaskDetailsApiData.EndDate);
        setContext(AssignmentType.TASK);
        const taskDetails = {
          Name: getTaskDetailsApiData.TaskName,
          CategoryId: getTaskDetailsApiData.CategoryId,
          Description: getTaskDetailsApiData.Description,
          StartDate: moment(startDateObj).format(DATE_TIME_FORMAT_DISPLAY),
          EndDate: moment(endDateObj).format(DATE_TIME_FORMAT_DISPLAY),
          CategoryName: getTaskDetailsApiData.CategoryName,
          GoalType: getTaskDetailsApiData.GoalType,
          AssignedBy: getTaskDetailsApiData.AssignedBy,
          AssignedByRole: getTaskDetailsApiData.AssignedbyRole,
          Status: getTaskDetailsApiData.Status,
          StatusPercentage: getTaskDetailsApiData.StatusPercentage,
          SubCategories: getTaskDetailsApiData?.Classes?.map((classItem: any, index: number) => (
            <span key={index}>
              {classItem.Classname}
              {index !== getTaskDetailsApiData?.Classes?.length - 1 && ', '}
            </span>
          )),
        };
        setAssignmentDetails(taskDetails);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getTaskDetailsApiLoading]);

  // Set assigned users associated with goal while backward navigation
  useEffect(() => {
    try {
      if (getUsersByGoalIdApiSuccess && getUsersByGoalIdAPiData) {
        const usersSelected: any[] = [];
        getUsersByGoalIdAPiData?.Users?.forEach((value: any) => {
          usersSelected.push({ id: value.UserId, name: value.UserName });
        });
        setConfirmedUsers([...usersSelected]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getUsersByGoalIdApiLoading]);

  // show  delete task event api success message and error message
  useEffect(() => {
    try {
      if (deleteTaskEventsApiSuccess && deleteTaskEventsApiErrorMessage) {
        dispatch(resetaddGoalStepThree());
        toastObj.toastSuccess(deleteTaskEventsApiErrorMessage);
        navigate('/goaldetail/' + location?.state?.goalId);
      } else if (!deleteTaskEventsApiSuccess && deleteTaskEventsApiErrorMessage?.length > 0) {
        toastObj.toastError(deleteTaskEventsApiErrorMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [deleteTaskEventsApiLoading]);
  // show  approve api success message and error message
  useEffect(() => {
    try {
      if (approveAssignmentApiSuccess && approveAssignmentApiResponseMessage) {
        toastObj.toastSuccess(approveAssignmentApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [approveAssignmentApiLoading]);
  // show  reject api success message and error message
  useEffect(() => {
    try {
      if (rejectAssignmentApiSuccess && rejectAssignmentApiResponseMessage) {
        toastObj.toastSuccess(rejectAssignmentApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [rejectAssignmentApiLoading]);
  // handle assignment studentlist api response
  useEffect(() => {
    try {
      if (getAssignmentStudentsListApiSuccess) {
        if (getAssignmentStudentsListApiSuccess && getAssignmentStudentsListAPiData?.List?.length > 0 && getAssignmentStudentsListAPiData?.TotalCount > 0) {
          const newItems = getAssignmentStudentsListAPiData?.List;
          if (assignmentUserListApiParams.Page > 1) {
            setItems(() => [...items, ...newItems]);
          } else {
            setItems(newItems);
          }
          if (items.length != getAssignmentStudentsListAPiData?.TotalCount) {
            setHasMore(newItems.length > 0 && newItems.length === assignmentUserListApiParams.PageSize);
          } else {
            setHasMore(false);
          }
          const updatedUserInfo = getAssignmentStudentsListAPiData?.List.filter((item: any) => activeStudentDetails.UserID == item.UserID);
          setActiveStudentDetails(updatedUserInfo);
        } else {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getAssignmentStudentsListApiLoading]);

  // Set assigned users associated with goal while backward navigation
  useEffect(() => {
    try {
      if (assignmentUserListApiParams.GoalId > 0) {
        dispatch(getAssignmentStudentsList(assignmentUserListApiParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [assignmentUserListApiParams]);

  // Get category details by organization
  useEffect(() => {
    try {
      if (getCatergoryListAPiData && getCatergoryListAPiData.length > 0) {
        const categoryData = getCatergoryListAPiData.map(
          (category: any): ISelectOptionsNumber => ({
            label: category.CategoryName,
            value: +category.CategoryId,
          }),
        );
        setCategoryList(categoryData);
      } else {
        // If ResponseData is null, update category state with null
        setCategoryList([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getCategoryListApiLoading]);

  // Handle the upload path api response
  useEffect(() => {
    try {
      const submitUploadsAndSubsequentActions = async () => {
        if (fileUploadBegin && uploadFileResponseCode > 0 && uploadFileApiSuccess && upImageAttachmentDetails.size) {
          uploadAttachmentFileToBucket();
          dispatch(resetUploadFile());
          if (fileUploadRef.current) {
            fileUploadRef.current.value = '';
          }
        }
      };
      submitUploadsAndSubsequentActions();
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFileResponseCode, uploadFileResponseMessage]);

  // Show messages after upload file message api
  useEffect(() => {
    try {
      if (uploadFilePathUpdateMultipleApiSuccess && uploadFilePathUpdateMultipleResponseCode > 0 && uploadFilePathUpdateMultipleApiSuccess) {
        setUploadAttachment(false);
        dispatch(resetUploadFilePathUpdateMultiple());
        setFileUploadBegin(false);
        toastObj.toastSuccess(AddMessageSuccess);
        clearMessage();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFilePathUpdateMultipleResponseCode, uploadFilePathUpdateMultipleResponseMessage]);

  // Show messages upon save message api
  useEffect(() => {
    try {
      if (message && !uploadAttachment && sendAssignmentMessageApiResponseCode > 0 && sendAssignmentMessageApiSuccess) {
        toastObj.toastSuccess(sendAssignmentMessageApiResponseMessage);
        clearMessage();
        setIsOpenMessage(false);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [sendAssignmentMessageApiResponseCode, sendAssignmentMessageApiResponseMessage]);

  const clearMessage = () => {
    try {
      setMessage('');
      setUploadedFileInfo([]);
      dispatch(resetAssignmentMessage());
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Put file to bucket after getting the path
  const uploadAttachmentFileToBucket = async () => {
    try {
      const uploadURL = uploadFileApiData;
      if (uploadURL) {
        // PUT request: upload file to S3
        const result = await fetch(uploadURL, {
          method: 'PUT',
          body: upImageAttachment,
        });
        if (result.status == 200) {
          const fileSize = bytesToSize(upImageAttachmentDetails.size);
          const fileInfo = { ...upImageAttachmentDetails, bucketURL: uploadURL, size: fileSize };
          setUpImageAttachmentDetails(fileInfo);
          setUploadedFileInfo([...uploadedFileInfo, fileInfo]);
          setFileUploadBegin(false);
        } else {
          setFileUploadError('Failed to Upload.');
          setFileUploadBegin(false);
          if (fileUploadRef.current) {
            fileUploadRef.current.value = '';
          }
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle category change filter goal tasks/events list
  const handleCategoryChange = (event: any) => {
    try {
      const selectedCategoryId = event.value;
      setSelectedCategory(event);
      const category = getCatergoryListAPiData.find((category: any) => category.CategoryId === selectedCategoryId.toString());
      if (category) {
        const subcategories = category.Subcategory.map((subcategory: any) => ({
          label: subcategory.classname,
          value: subcategory.classid,
        }));
        setSubCategoryList(subcategories);
        setSelectedSubCategory([]);
      } else {
        setSubCategoryList([]);
      }
      setUserlistFilter((info: any) => ({
        ...info,
        Category: selectedCategoryId,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle sub category change filter goal tasks/events list
  const handleSubCategoryChange = (event: any) => {
    try {
      if (event) {
        const subcategories = event.map((subcategory: any) => subcategory.value);
        setSelectedSubCategory(event);
        setUserlistFilter((info: any) => ({
          ...info,
          SubCategories: subcategories,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle reset filter goal tasks/events list
  const handleFilterReset = () => {
    try {
      setUserlistFilter((info: any) => ({
        ...info,
        Category: 0,
        SubCategories: [],
      }));
      setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
        ...assignmentUserListApiParams,
        CategoryId: 0,
        SubCategories: [],
        Page: DEFAULT_PAGE_INDEX,
        PageSize: PAGE_SIZE,
      }));
      setIsFilterApplied(false);
      setSelectedCategory(DEFAULT_SELECT_OPTION_NUMBER);
      setSelectedSubCategory([]);
      setIsOpenFilter(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle apply filter goal tasks/events list
  const handleApplyFilter = () => {
    try {
      setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
        ...assignmentUserListApiParams,
        CategoryId: userlistFilter.Category,
        SubCategories: userlistFilter.SubCategories,
        Page: DEFAULT_PAGE_INDEX,
        PageSize: PAGE_SIZE,
      }));
      // setCurrentPage(DEFAULT_PAGE_INDEX);
      setIsFilterApplied(true);
      setIsOpenFilter(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Task Event List Filter click.
  const handleFilterClick = () => {
    try {
      setIsOpenFilter(true);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Task Event List Filter close.
  const handleFilterClose = () => {
    try {
      setIsOpenFilter(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle student assignment detail click.
  const handlestudentDetail = (studentInfo: any) => {
    try {
      setActiveStudentDetails(studentInfo);
      if (studentInfo.assignmentdetailid > 0) {
        // student started task/event
        const assignmentApiParams: IAssignmentAttachmentApi = {
          AppUserId: studentInfo.UserID,
          GoalId: location?.state?.goalId,
          TaskOrEventID: studentInfo.TaskID,
          Type: location?.state?.assignmentType == 'task' ? 1 : 2,
        };
        dispatch(getAssignmentProofs(assignmentApiParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Assigned users click.
  const handleAssignedUsersClick = () => {
    try {
      setIsOpenAssignedUsers(true);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Assignes users overlay close.
  const handleAssignedUsersClose = () => {
    try {
      setIsOpenAssignedUsers(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle delete confirmation
  const ShowDeleteConfirmPopup = (event: any) => {
    try {
      if (location?.state?.isViewMode) {
        event.preventDefault();
        toastObj.toastInfo(DeleteAssignmentError);
      } else {
        setIsShowDeleteConfirmPopup(true);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Api call to delete task/event
  const handleDeleteTakEventClick = () => {
    try {
      const requestBody: IDeleteTaskEventParams = {
        Type: location?.state?.assignmentType,
        id: location?.state?.assignmentId,
      };
      dispatch(deleteEventsTasks(requestBody));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Reset values when tabs change
  const handleTabChange = (key: string) => {
    try {
      dispatch(resetAssignmentStudentsList());
      dispatch(resetAssignmentProofs());
      setItems({});
      setActiveStudentDetails({});
      setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
        ...assignmentUserListApiParams,
        Status: key,
        Page: DEFAULT_PAGE_INDEX,
        PageSize: PAGE_SIZE,
        Search: '',
        CategoryId: 0,
        SubCategories: [],
      }));
      handleFilterReset();
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle goal detail click
  const HandleGoalDetailClick = () => {
    try {
      dispatch(resetaddGoalStepThree());
      navigate('/goaldetail/' + location?.state?.goalId);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Fetching next page items
  const fetchMoreData = () => {
    try {
      setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
        ...assignmentUserListApiParams,
        Page: assignmentUserListApiParams.Page + 1,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle seach box change
  const handleSearchChange = (event: any) => {
    try {
      setSearchText(event.target.value);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Search Field input key down.
  const handleSearchKeyDown = (event: any) => {
    try {
      if (event.key === 'Enter') {
        event.preventDefault();
        setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
          ...assignmentUserListApiParams,
          Search: searchText,
          Page: DEFAULT_PAGE_INDEX,
        }));
      } else {
        setSearchText(event.target.value);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle seach icon click
  const handleSearchClick = () => {
    try {
      setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
        ...assignmentUserListApiParams,
        Search: searchText,
        Page: 0,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle approve assignment
  const ApproveAssignment = () => {
    try {
      if (activeStudentDetails.assignmentdetailid) {
        const requestParams: IApproveAssignmentApi = {
          AssignmentDetailId: activeStudentDetails.assignmentdetailid.toString(),
        };
        dispatch(approveAssignment(requestParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Function to display attachments
  const renderAttachment = (filepath: any) => {
    try {
      const fileType = filepath.toLowerCase().split('.').pop();
      if (!filepath) {
        return <p>{NoattachmentsFoundText}</p>;
      }
      if (FILE_TYPES_SUPPORTED.image.includes(fileType)) {
        return <img src={BUCKET_URL + filepath} width="100%" className="att-img-vid" />;
      } else if (FILE_TYPES_SUPPORTED.video.includes(fileType)) {
        return <ReactPlayer url={BUCKET_URL + filepath} controls width="100%" className="att-img-vid" />;
      } else if (FILE_TYPES_SUPPORTED.audio.includes(fileType)) {
        return <ReactAudioPlayer src={BUCKET_URL + filepath} controls />;
      } else if (FILE_TYPES_SUPPORTED.document.includes(fileType)) {
        return (
          <a href={BUCKET_URL + filepath} target="_blank" rel="noopener noreferrer" className="">
            <img src={downdocicn} alt="" /> {DownloadFileText}
          </a>
        );
      } else {
        return <p>{UnsupportedFileTypeText}</p>;
      }
    } catch (error) {
      console.log('Error: ', error);
      return <></>;
    }
  };

  // handle add task add or edit submit button
  const handleRejectAssignment = async () => {
    try {
      const errorResult = await validateForm(assignmentRejectFormdata, ASSIGNMENT_SCHEMA, assignmentRejectFormErrors);
      if (Object.keys(errorResult).length === 0) {
        const requestParams: IRejectAssignmentApi = {
          AssignmentDetailId: activeStudentDetails.assignmentdetailid,
          Reason: assignmentRejectFormdata.Reason,
        };
        dispatch(rejectAssignment(requestParams));
        setIsOpenRejectComment(false);
      } else {
        setAssignmentRejectFormErrors(errorResult);
        setErrorVisible(true);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle form fields validtaion
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      setCharacterCount(event.target.value.length);
      setAssignmentRejectFormData((info: any) => ({
        ...info,
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      CheckValidation(validateObj);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Check validation after form change
  const CheckValidation = async (validateObj: any) => {
    try {
      const errorresult = await validateForm(validateObj, ASSIGNMENT_SCHEMA, assignmentRejectFormErrors);
      setAssignmentRejectFormErrors(errorresult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle Message Change
  const onMessageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setMessageCharacterCount(event.target.value.length);
      // Check if message is empty
      if (!event.target.value.trim()) {
        setMessageError(useIntlActionMessages('Error.MessageRequired'));
      } else {
        setMessage(event.target.value);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle send message submit
  const handleSendMessage = async () => {
    try {
      if (message) {
        const requestParams: IMessageAssignmentApi = {
          AssignmentDetailId: activeStudentDetails.assignmentdetailid,
          Message: message,
        };
        dispatch(sendAssignmentMessage(requestParams));
        setIsOpenRejectComment(false);
      }

      if (uploadedFileInfo.length > 0) {
        const uplodedFileInfo = uploadedFileInfo.map((file: any) => {
          return {
            FileName: file.name,
            DisplayName: file.displayName,
            FileSize: file.size,
            FileType: file.typeNumber.toString(),
          };
        });
        setUploadAttachment(true);
        // upload attachments
        const reqParams: IuploadFilePathUpdateMultipleParams = {
          Files: uplodedFileInfo,
          Type: 'goal_review_comments',
          TypeId: activeStudentDetails.assignmentdetailid,
        };
        // Api request to update file info after bucket upload and save organization info
        dispatch(uploadFilePathUpdateMultiple(reqParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle send message submit
  const handleAssignmentUploadMessage = (fileType: string) => {
    try {
      setFileUploadType(fileType);
      const acceptedFileExtensions = FILE_TYPES_SUPPORTED[fileType as keyof typeof FILE_TYPES_SUPPORTED] || [];
      const acceptString = acceptedFileExtensions.map((ext) => `.${ext}`).join(',');
      if (fileUploadRef?.current) {
        fileUploadRef.current.accept = acceptString;
        fileUploadRef.current.click();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const uploadResourceFile = async (e: any) => {
    try {
      setFileUploadBegin(true);
      setFileUploadError('');
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const lastDotIndex = file?.name?.lastIndexOf('.');
        const fileNameWithoutExtension = file?.name?.slice(0, lastDotIndex);
        const fileExtension = file?.name?.slice(lastDotIndex + 1);
        const modifiedFileName = fileNameWithoutExtension + new Date().getTime() + '.' + fileExtension;
        // replacing special characters with underscores
        const formattedFilename = modifiedFileName.replace(/[^a-zA-Z0-9.\-_]/g, '_');
        const isValid = checkValidFile(formattedFilename, fileUploadType, file.size);

        if (isValid) {
          setUpImageAttachment(e.target.files[0]);
          const fileType = { value: fileUploadType };
          const fileInfo = {
            name: formattedFilename,
            displayName: file.name,
            type: fileExtension,
            size: file.size,
            typeNumber: getFileTypeNumber(fileType),
            fileType: fileUploadType,
          };
          setUpImageAttachmentDetails(fileInfo);
          const formData = new FormData();
          formData.append('UploadExtension', fileInfo.type);
          formData.append('UploadObjectKey', fileInfo.name);
          formData.append('UploadType', 'goal_review_comments');
          dispatch(uploadFile(formData));
        } else {
          setFileUploadError(ImageUploadFileError);
          setFileUploadBegin(false);
          if (fileUploadRef.current) {
            fileUploadRef.current.value = '';
          }
        }
      } else {
        setFileUploadBegin(false);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Remove Attachment from listing
  const deleteAttachment = (deleteIndex: number) => {
    try {
      const newUploadedFileInfo = [...uploadedFileInfo];
      setUploadedFileInfo(newUploadedFileInfo.filter((item: any, index: number) => index != deleteIndex));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const handleShowPopup = (type: string) => {
    try {
      setIsOpenMessage(false);
      setRecordType(type);
      setShowPopup(true);
      setUploadedFileInfo([]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const handleClosePopup = () => {
    try {
      setShowPopup(false);
      clearMessage();
      setUploadedFileInfo([]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>{context === AssignmentType.TASK ? <FormattedMessage id="Hd.TaskDetails" /> : <FormattedMessage id="Hd.EventDetails" />}</h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>
              <FormattedMessage id="Hd.ManageOrganizations" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate('/activities')}>
              <FormattedMessage id="Hd.Activities" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={HandleGoalDetailClick}>
              <FormattedMessage id="Hd.GoalDetails" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Hd.TaskDetails" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="mb-4">
        <div className="org-selected">
          <span className="goal-task-img">
            <img src={orgDetail?.ImageURL ? BUCKET_URL + orgDetail?.ImageURL : Default} className="thumbnail-image w-100" />
          </span>
          <div>
            <h6 className="mb-1">{orgDetail?.Name}</h6>
            <small>{orgDetail?.Address}</small>
          </div>
        </div>
      </div>
      <div className="content-sub">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            {!location?.state?.isViewMode && (
              <Nav className="more-dropdown">
                <NavDropdown align={end} className="icon-btn-more" title={<img src={More} alt="more" />}>
                  <NavDropdown.Item onClick={(e) => ShowDeleteConfirmPopup(e)}>
                    <FormattedMessage id="Button.Delete" />
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            )}
            <Accordion.Header>
              <h6>{assignmentDetails.Name}</h6>
            </Accordion.Header>
            <Accordion.Body>
              <Row className="details-view">
                <label className="font-sm text-secondary mb-2">
                  <FormattedMessage id="Label.Description" />
                </label>
                <p>{assignmentDetails.Description} </p>
              </Row>
              <Row className="details-view">
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.Category" />
                  </label>
                  <p> {assignmentDetails.CategoryName ? assignmentDetails.CategoryName : '-'}</p>
                </Col>
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.GoalName" />
                  </label>
                  <p>{location?.state?.goalName}</p>
                </Col>
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.GoalType" />
                  </label>
                  <p>{assignmentDetails.GoalType}</p>
                </Col>
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.SubCategory" />
                  </label>
                  <p>
                    <div>{assignmentDetails.SubCategories ? assignmentDetails.SubCategories : '-'}</div>
                  </p>
                </Col>
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.StartDateTime" />
                  </label>
                  <p>{assignmentDetails.StartDate}</p>
                </Col>
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.EndDateTime" />
                  </label>
                  <p>{assignmentDetails.EndDate}</p>
                </Col>
                <Col xl lg={4}>
                  <label>Assignees</label>
                  <Col xs="auto" className="align-items-center d-flex assign-users">
                    <div className="custom-berger-menu">
                      <Filter
                        onOpen={handleAssignedUsersClick}
                        onClose={handleAssignedUsersClose}
                        isOpen={isOpenAssignedUsers}
                        width={450}
                        right
                        pageWrapId={'filter-wrapper'}
                        outerContainerId={'outer-container'}
                        customCrossIcon={<img src={Close} />}
                        customBurgerIcon={
                          <div className="d-flex align-items-center multiple-user">
                            <span className="td-user-image">
                              <img src={UserImg} className="w-100" alt="user" />
                            </span>
                            <span className="td-user-image">
                              <img src={UserImg} className="w-100" alt="user" />
                            </span>
                            <span className="td-user-image">
                              <img src={UserImg} className="w-100" alt="user" />
                            </span>
                            +{getUsersByGoalIdAPiData?.TotalCount} {confirmedUsers.length == 1 ? <FormattedMessage id="Label.Member" /> : <FormattedMessage id="Label.Members" />}
                          </div>
                        }
                      >
                        <div id="filter-wrapper" className="filter-main">
                          <Scrollbar className="h-80vh">
                            <Table striped hover responsive size="sm">
                              <thead>
                                <tr>
                                  <th>
                                    <FormattedMessage id="Label.Name" />{' '}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {confirmedUsers &&
                                  confirmedUsers.length > 0 &&
                                  confirmedUsers.map((user: any, index: number) => (
                                    <tr key={index}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <span className="td-user-image">
                                            <img src={UserImg} className="w-100" alt="user" />
                                          </span>
                                          {user.name}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          </Scrollbar>
                        </div>
                      </Filter>
                    </div>
                  </Col>
                </Col>
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.Assignedby" />
                  </label>
                  <div className="d-flex align-items-center">
                    <span className="td-user-image">
                      <img src={assignmentDetails.AssignedUserImage ? BUCKET_URL + assignmentDetails.AssignedUserImage : UserImg} className="w-100" alt="user" />
                    </span>
                    <p className="mb-0">
                      {assignmentDetails.AssignedBy}
                      <span className="d-block font-xs">{assignmentDetails.AssignedByRole}</span>
                    </p>
                  </div>
                </Col>
                <Col className="d-flex align-items-center">
                  <div className="d-flex align-items-center multiple-user">
                    <ProgressBar now={assignmentDetails.StatusPercentage} />{' '}
                    <span className="ps-2">
                      <p className="td-progressbar mb-0">
                        {assignmentDetails?.StatusPercentage ? assignmentDetails?.StatusPercentage : '0'}% <span className="inprogress">{assignmentDetails.Status}</span>
                      </p>
                    </span>
                  </div>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="content-sub">
        <div className="content-area-padding">
          <Row>
            <Col xl={6}>
              <Tabs defaultActiveKey={ASSIGNMENT_STUDENTLIST_TABS[0]} transition={false} id="noanim-tab-example" className="mb-3" onSelect={(key: any) => handleTabChange(key)}>
                <Tab
                  disabled={getAssignmentStatusCountAPiData?.CompletedCount > 0 ? false : true}
                  eventKey={ASSIGNMENT_STUDENTLIST_TABS[0]}
                  title={'Completed (' + (getAssignmentStatusCountAPiData?.CompletedCount ? getAssignmentStatusCountAPiData?.CompletedCount : '0') + ')'}
                >
                  <div className="d-flex mb-3">
                    <Col lg={10} className="form-sm">
                      <Search
                        placeholder="Search with Name"
                        size="sm"
                        onChange={handleSearchChange}
                        onKeyDown={handleSearchKeyDown}
                        onClick={handleSearchClick}
                        value={searchText ? searchText : ''}
                      />
                    </Col>
                    <CategoryFilter
                      isOpenFilter={isOpenFilter}
                      handleFilterClick={handleFilterClick}
                      handleFilterClose={handleFilterClose}
                      isFilterApplied={isFilterApplied}
                      handleSubCategoryChange={handleSubCategoryChange}
                      handleCategoryChange={handleCategoryChange}
                      selectedCategory={selectedCategory}
                      selectedSubCategory={selectedSubCategory}
                      categoryList={categoryList}
                      subCategoryList={subCategoryList}
                      handleFilterReset={handleFilterReset}
                      handleApplyFilter={handleApplyFilter}
                    />
                  </div>
                  <div>
                    <AssignmentStudentList
                      StudentList={items}
                      activeStudentDetails={activeStudentDetails}
                      handlestudentDetail={handlestudentDetail}
                      fetchMoreData={fetchMoreData}
                      hasMore={hasMore}
                      totalCount={getAssignmentStudentsListAPiData?.TotalCount}
                    />
                  </div>
                </Tab>
                <Tab
                  disabled={getAssignmentStatusCountAPiData?.InProgressCount > 0 ? false : true}
                  eventKey={ASSIGNMENT_STUDENTLIST_TABS[1]}
                  title={'In Progress (' + (getAssignmentStatusCountAPiData?.InProgressCount ? getAssignmentStatusCountAPiData?.InProgressCount : '0') + ')'}
                >
                  <div className="d-flex mb-3">
                    <Col lg={10} className="form-sm">
                      <Search
                        placeholder="Search with Name"
                        size="sm"
                        onChange={handleSearchChange}
                        onKeyDown={handleSearchKeyDown}
                        onClick={handleSearchClick}
                        value={searchText ? searchText : ''}
                      />
                    </Col>
                    <CategoryFilter
                      isOpenFilter={isOpenFilter}
                      handleFilterClick={handleFilterClick}
                      handleFilterClose={handleFilterClose}
                      isFilterApplied={isFilterApplied}
                      handleSubCategoryChange={handleSubCategoryChange}
                      handleCategoryChange={handleCategoryChange}
                      selectedCategory={selectedCategory}
                      selectedSubCategory={selectedSubCategory}
                      categoryList={categoryList}
                      subCategoryList={subCategoryList}
                      handleFilterReset={handleFilterReset}
                      handleApplyFilter={handleApplyFilter}
                    />
                  </div>
                  <div>
                    <AssignmentStudentList
                      StudentList={items}
                      activeStudentDetails={activeStudentDetails}
                      handlestudentDetail={handlestudentDetail}
                      fetchMoreData={fetchMoreData}
                      hasMore={hasMore}
                      totalCount={getAssignmentStudentsListAPiData?.TotalCount}
                    />
                  </div>
                </Tab>
                <Tab
                  disabled={getAssignmentStatusCountAPiData?.NotStartedCount > 0 ? false : true}
                  eventKey={ASSIGNMENT_STUDENTLIST_TABS[2]}
                  title={'Not Started (' + (getAssignmentStatusCountAPiData?.NotStartedCount ? getAssignmentStatusCountAPiData?.NotStartedCount : '0') + ')'}
                >
                  <div className="d-flex mb-3">
                    <Col lg={10} className="form-sm">
                      <Search
                        placeholder="Search with Name"
                        size="sm"
                        onChange={handleSearchChange}
                        onKeyDown={handleSearchKeyDown}
                        onClick={handleSearchClick}
                        value={searchText ? searchText : ''}
                      />
                    </Col>
                    <CategoryFilter
                      isOpenFilter={isOpenFilter}
                      handleFilterClick={handleFilterClick}
                      handleFilterClose={handleFilterClose}
                      isFilterApplied={isFilterApplied}
                      handleSubCategoryChange={handleSubCategoryChange}
                      handleCategoryChange={handleCategoryChange}
                      selectedCategory={selectedCategory}
                      selectedSubCategory={selectedSubCategory}
                      categoryList={categoryList}
                      subCategoryList={subCategoryList}
                      handleFilterReset={handleFilterReset}
                      handleApplyFilter={handleApplyFilter}
                    />
                  </div>
                  <div>
                    <AssignmentStudentList
                      StudentList={items}
                      activeStudentDetails={activeStudentDetails}
                      handlestudentDetail={handlestudentDetail}
                      fetchMoreData={fetchMoreData}
                      hasMore={hasMore}
                      totalCount={getAssignmentStudentsListAPiData?.TotalCount}
                    />
                  </div>
                </Tab>
              </Tabs>
            </Col>
            <Col className="pt-5">
              {activeStudentDetails && activeStudentDetails.UserID > 0 && getAssignmentProofsAPiData?.ProofData?.length > 0 ? (
                <>
                  <h5 className="mt-2 mb-3">
                    <FormattedMessage id="Label.Notes" />
                  </h5>
                  <div className="notes-main">
                    <p>{getAssignmentProofsAPiData?.Notes}</p>
                  </div>
                  <h5 className="mt-2 mb-3">
                    <FormattedMessage id="Label.Attachments" />
                    {getAssignmentProofsAPiData?.ProofData?.length > 0 && ' (' + getAssignmentProofsAPiData?.ProofData?.length + ')'}
                  </h5>
                  <Scrollbar className="h-250px scroll-color">
                    {getAssignmentProofsAPiData?.ProofData?.length > 0 &&
                      getAssignmentProofsAPiData?.ProofData?.map((proof: any, index: number) => (
                        <Col className="mb-4" key={index}>
                          {renderAttachment(proof.ProofPath)}
                        </Col>
                      ))}
                  </Scrollbar>
                  <div className="btn-container d-flex mt-5 justify-content-end">
                    {(activeStudentDetails?.ReviewStatus == 'Pending' || activeStudentDetails?.ReviewStatus == 'Rejected') && (
                      //  !actionBtnHide &&
                      <div className="custom-berger-menu ms-3">
                        <Filter
                          width={450}
                          right
                          pageWrapId={'filter-wrapper'}
                          outerContainerId={'outer-container'}
                          customCrossIcon={<img src={Close} />}
                          customBurgerIcon={
                            <Link to="" className="btn btn-outline-primary d-inline-block ml-0">
                              <FormattedMessage id="Button.AddMessage" />
                            </Link>
                          }
                          isOpen={isOpenMessage}
                          onOpen={() => setIsOpenMessage(true)}
                          onClose={() => setIsOpenMessage(false)}
                        >
                          <div id="filter-wrapper" className="filter-main">
                            <Scrollbar className="h-80vh">
                              <h4 className="mb-4">
                                <FormattedMessage id="Button.AddMessage" />
                              </h4>

                              <Col className="form-sm  position-relative">
                                <Input
                                  id="Message"
                                  name="Message"
                                  type="textarea"
                                  placeholder={useIntlActionMessages('Label.Message.Description')}
                                  maxLength={NumberValues.NUM_1000}
                                  as="textarea"
                                  rows={3}
                                  onChange={onMessageChange}
                                  errorMessage={messageError}
                                  value={message}
                                />
                                <span className="txtMessage-count">
                                  {messageCharacterCount}/{NumberValues.NUM_1000}
                                </span>
                              </Col>
                              <Row>
                                <Col>
                                  <Nav className="overlay-dropdown">
                                    <NavDropdown
                                      align={end}
                                      id="nav-dropdown-dark-example"
                                      title={
                                        <Button variant="secondary" className="btn-icon-top w-100">
                                          <span>
                                            <AddIcon />
                                          </span>
                                          <FormattedMessage id="Button.AddVideo" />
                                        </Button>
                                      }
                                    >
                                      <NavDropdown.Item onClick={() => handleAssignmentUploadMessage('video')}>
                                        <FormattedMessage id="Link.UploadVideo" />
                                      </NavDropdown.Item>
                                      <NavDropdown.Item onClick={() => handleShowPopup('video')}>
                                        <FormattedMessage id="Link.RecordVideo" />
                                      </NavDropdown.Item>
                                    </NavDropdown>
                                  </Nav>
                                </Col>
                                <Col>
                                  <Nav className="overlay-dropdown">
                                    <NavDropdown
                                      align={end}
                                      id="nav-dropdown-dark-example"
                                      title={
                                        <Button variant="secondary" className="btn-icon-top w-100">
                                          <span>
                                            <AddIcon />
                                          </span>
                                          <FormattedMessage id="Button.AddAudio" />
                                        </Button>
                                      }
                                    >
                                      <NavDropdown.Item onClick={() => handleAssignmentUploadMessage('audio')}>
                                        <FormattedMessage id="Link.UploadAudio" />
                                      </NavDropdown.Item>
                                      <NavDropdown.Item onClick={() => handleShowPopup('audio')}>
                                        <FormattedMessage id="Link.RecordAudio" />
                                      </NavDropdown.Item>
                                    </NavDropdown>
                                  </Nav>
                                </Col>

                                <PopupRecorder
                                  show={showPopup}
                                  onClose={handleClosePopup}
                                  video={recordType == 'video' ? true : false}
                                  audio={recordType == 'audio' ? true : false}
                                  fileType={recordType}
                                  devicesByType={recordType}
                                />
                                <input
                                  ref={fileUploadRef}
                                  type="file"
                                  name="file-3[]"
                                  id="file-3"
                                  className="inputfile inputfile-3 d-none"
                                  data-multiple-caption="{count} files selected"
                                  multiple={false}
                                  accept="audio/*,video/*"
                                  onChange={(e: any) => uploadResourceFile(e)}
                                />
                                <div className="error text-center pt-2"> {fileUploadError}</div>
                                {uploadedFileInfo.length}
                                {uploadedFileInfo.map((filedetails: any, index: number) => (
                                  <Col mt={6} key={index} className="addmessage-main mb-2" sm={12}>
                                    <AttachmentCard
                                      fileType={getIcon(filedetails.fileType)}
                                      filename={filedetails.displayName}
                                      category={filedetails.size}
                                      deleteOption
                                      onDelete={() => deleteAttachment(index)}
                                    />
                                  </Col>
                                ))}
                              </Row>
                            </Scrollbar>
                            <Row className="mt-4 pb-4">
                              <Col>
                                <Button variant="outline-primary" className="w-100" onClick={() => setIsOpenMessage(false)}>
                                  <FormattedMessage id="Button.Cancel" />
                                </Button>
                              </Col>
                              <Col>
                                <Button variant="primary" className="w-100" onClick={handleSendMessage}>
                                  <FormattedMessage id="Button.SendMessage" />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Filter>
                      </div>
                    )}
                    {activeStudentDetails?.assignmentdetailid > 0 &&
                      activeStudentDetails?.ReviewStatus != 'Approved' &&
                      activeStudentDetails?.Status != 'In Progress' &&
                      actionBtnHide && (
                        <>
                          <div className="custom-berger-menu ms-3">
                            <Filter
                              width={450}
                              right
                              pageWrapId={'filter-wrapper'}
                              outerContainerId={'outer-container'}
                              customCrossIcon={<img src={Close} />}
                              customBurgerIcon={
                                <Link to="" className="btn btn-outline-primary d-inline-block ml-0">
                                  <FormattedMessage id="Button.Reject" />
                                </Link>
                              }
                              isOpen={isOpenRejectComment}
                              onOpen={() => setIsOpenRejectComment(true)}
                              onClose={() => setIsOpenRejectComment(false)}
                            >
                              <div id="filter-wrapper" className="filter-main">
                                <Scrollbar className="h-80vh">
                                  <h4 className="mb-4">
                                    <FormattedMessage id="Hd.ReasonforRejection" />
                                  </h4>
                                  <Col className="form-sm">
                                    <Input
                                      label="Reason"
                                      id="Reason"
                                      name="Reason"
                                      type="text"
                                      placeholder={PHSpecifyReasonRejection}
                                      maxLength={NumberValues.NUM_1000}
                                      value={assignmentRejectFormdata.Description}
                                      onChange={onInputHandleChange}
                                      errorMessage={errorVisible && assignmentRejectFormErrors?.Reason}
                                      as="textarea"
                                      rows="3"
                                    />
                                    <span className="txt-count">
                                      {characterCount}/{NumberValues.NUM_1000}
                                    </span>
                                  </Col>
                                </Scrollbar>
                                <Row className="mt-4 pb-4">
                                  <Col>
                                    <Button variant="outline-primary" className="w-100" onClick={() => setIsOpenRejectComment(false)}>
                                      <FormattedMessage id="Button.Cancel" />
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Button variant="primary" className="w-100" onClick={handleRejectAssignment}>
                                      <FormattedMessage id="Button.Submit" />
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Filter>
                          </div>{' '}
                          <Button variant="primary" onClick={ApproveAssignment}>
                            <FormattedMessage id="Button.Approve" />
                          </Button>
                        </>
                      )}
                  </div>
                </>
              ) : (
                <p className="mt-2 mb-3">
                  {' '}
                  {activeStudentDetails?.Status == 'Not Started' || getAssignmentProofsAPiData?.ProofData == null
                    ? 'No records found.'
                    : 'Click on a student to view the submitted documents.'}
                </p>
              )}
            </Col>
          </Row>
        </div>
        <DeleteConfirmPopup
          show={isShowDeleteConfirmPopup}
          handleClose={() => setIsShowDeleteConfirmPopup(false)}
          onCancel={() => setIsShowDeleteConfirmPopup(false)}
          onOkay={() => handleDeleteTakEventClick()}
          title="Delete Task/Event?"
          content="Are you sure you want to delete this task/event?"
        />
        {(deleteTaskEventsApiLoading ||
          getAssignmentStudentsListApiLoading ||
          getAssignmentProofsApiLoading ||
          getUsersByGoalIdApiLoading ||
          getEventDetailsApiLoading ||
          getAssignmentStatusCountApiLoading ||
          getEventDetailsApiLoading ||
          approveAssignmentApiLoading ||
          fileUploadBegin ||
          uploadFilePathUpdateMultipleApiLoading ||
          uploadAttachment ||
          sendAssignmentMessageApiLoading) && <Loader />}
      </div>
    </>
  );
};
export default TaskDetails;
