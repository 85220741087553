/**
 * @file   src\containers\organizations\UserBulkUpload.tsx
 * @brief  user bulk upload  page.
 * @date   August, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import {
  useIntlActionMessages,
  useIntlMessages,
  isUserAdmin,
  getFromLocalStorage,
  isAdminTeacherManager,
  getCurrentOrgDetails,
  getFileTypeByExtension,
  checkValidFile,
  isUserSystemAdmin,
} from '../../utils/helper';
import { useNavigate, useLocation } from 'react-router-dom';
import { validateForm } from '../../utils/formValidation';
import { usersBulkUpload, userCountForSubscription } from '../../store/actions/userActions';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { resetUserBulkUpload } from '../../store/slices/userSlice';
import { MessageToaster } from '../../utils/ToastUtil';
import Upload from '../../assets/img/icon/Upload';
import Select from '../../components/MASelect';
import Download from '../../assets/img/Download.svg';
import { uploadFile } from '../../store/actions/organizationActions';
import { ISelectOptionsNumber } from '../../interfaces/GeneralInterface';
import { RoleTypeIds } from '../../utils/enums';
import { USER_UPLOAD_SCHEMA } from '../../validations/userSchema';
import { IUserUploadForm } from '../../interfaces/UserInterface';
import { resetUploadFile } from '../../store/slices/organizationSlice';
import Loader from '../../components/Loader';
import { BUCKET_URL, APP_USER_ROLES, WEB_USER_ROLES } from '../../utils/constants';
import { getOrganizationTypeDropDownList, getUserRolesByOrgType, getOrganizationNameByOrgType } from '../../store/actions/organizationTypeActions';
// Declare default params
const savedefaultRequestParams: IUserUploadForm = {
  OrganizationID: '',
  OrganizationTypeID: 0,
  UserRoleID: 0,
  S3_FileName: '',
};

const UserBulkUpload = () => {
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Location object
  const location = useLocation();
  // Toast object creation.
  const toast = new MessageToaster();
  // Access redux state variables
  const {
    userBulkUploadApiLoading,
    userBulkUploadApiSuccess,
    userBulkUploadApiResponseCode,
    userBulkUploadApiResponseMessage,
    usersCountForSubscriptionData,
    usersCountForSubscriptionLoading,
    usersCountForSubscriptionSuccess,
    usersCountForSubscriptionResponseCode,
  } = useAppSelector((state: RootState) => state.user);
  const {
    organizationTypeDropDownData,
    organizationTypeDropDownSuccess,
    organizationTypeDropDownLoading,
    rolesByOrgTypeData,
    rolesByOrgTypeLoading,
    orgnameByOrgTypeData,
    orgnameByOrgTypeLoading,
  } = useAppSelector((state: RootState) => state.organizationType);
  const { uploadFileResponseCode, uploadFileResponseMessage, uploadFileApiSuccess, uploadFileApiData } = useAppSelector((state: RootState) => state.organization);
  const { getSystemAdminPermissionsApiSuccess, getSystemAdminPermissionsApiData } = useAppSelector((state: RootState) => state.settings);

  // Initialize component state variables.
  const [errorFields, setErrorFields] = useState<any>({});
  const [userForm, setUserForm] = useState<IUserUploadForm>(savedefaultRequestParams);
  const [fileUploadBegin, setFileUploadBegin] = useState<boolean>(false);
  const [upImageAttachment, setUpImageAttachment] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string>('');
  const [upImageAttachmentDetails, setUpImageAttachmentDetails] = useState<any>({});
  const [orgName, setOrgName] = useState<ISelectOptionsNumber[] | null>([]);
  const [organizationTypeValue, setOrganizationTypeValue] = useState<ISelectOptionsNumber>();
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<Array<any>>([]);
  const [selectedUser, setSelectedUser] = useState<ISelectOptionsNumber[] | null>([]);
  const [userRoles, setUserRoles] = useState<ISelectOptionsNumber[] | null>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<ISelectOptionsNumber | null>();
  const [disableFields, setDisableFields] = useState<boolean>(false);

  // Initialize language variables.
  const ImageUploadFailed = useIntlActionMessages('Image.Upload.Failed');
  const ImageUploadChooseImage = useIntlActionMessages('Image.Upload.ChooseAttachment');
  const ImageUploadSuccess = useIntlActionMessages('File.Upload.success');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // Reset the form after creating user
  useEffect(() => {
    try {
      dispatch(resetUploadFile());
      dispatch(getOrganizationTypeDropDownList({}));
      return () => {
        dispatch(resetUserBulkUpload());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // Get organization details based user roles
  useEffect(() => {
    try {
      const userData = getFromLocalStorage('MI_USR_DATA');
      const organizationId = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : userData.OrganizationId;
      const organizationTypeId = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationTypeId : userData.OrganizationTypeId;
      const OrganizationType = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationType : userData.OrganizationType;
      const Name = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationName : userData.OrganizationName;
      if (isAdminTeacherManager()) {
        if (organizationTypeId) {
          setOrganizationTypeValue({ label: OrganizationType, value: organizationTypeId });
          dispatch(getUserRolesByOrgType({ organizationTypeId }));
        }
        if (organizationId) {
          setSelectedOrganization({ label: Name, value: organizationId });
        }
        setUserForm((prevUserForm) => ({
          ...prevUserForm,
          OrganizationTypeID: organizationTypeId,
          OrganizationID: organizationId,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [localStorage.getItem('CURRENT_ORG')]);
  // Populate organization details when coming from organization page
  useEffect(() => {
    try {
      if (isUserAdmin() && location?.state) {
        const { organizationId, OrganizationTypeId, Name, OrganizationType } = location.state;
        if (OrganizationTypeId) {
          setOrganizationTypeValue({ label: OrganizationType, value: OrganizationTypeId });
          dispatch(getUserRolesByOrgType({ OrganizationTypeId }));
        }
        if (organizationId) {
          setSelectedOrganization({ label: Name, value: organizationId });
        }
        setUserForm((prevUserForm) => ({
          ...prevUserForm,
          OrganizationTypeID: OrganizationTypeId,
          OrganizationID: organizationId,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [isUserAdmin, location.state]);
  // Show message after system admin form submit, success/failure
  useEffect(() => {
    try {
      if (userBulkUploadApiResponseCode > 0 && userBulkUploadApiSuccess) {
        toast.toastSuccess(userBulkUploadApiResponseMessage);
        navigate('/manageuser');
      } else if (userBulkUploadApiResponseCode > 0 && userBulkUploadApiResponseMessage && !userBulkUploadApiSuccess) {
        toast.toastError(userBulkUploadApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [userBulkUploadApiLoading, userBulkUploadApiResponseCode, userBulkUploadApiSuccess, userBulkUploadApiResponseMessage]);
  // Get organization type
  useEffect(() => {
    try {
      if (organizationTypeDropDownSuccess && organizationTypeDropDownData?.length > 0) {
        const orgTypeOptions = organizationTypeDropDownData.map(
          (orgtype: any): ISelectOptionsNumber => ({
            label: orgtype.orgTypeName,
            value: orgtype.orgTypeId,
          }),
        );
        setOrganizationTypeOptions([...orgTypeOptions]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationTypeDropDownLoading]);
  // Get user roles by organization type
  useEffect(() => {
    try {
      if (organizationTypeValue) {
        let allowedRoles: number[] = [];
        const userData = getFromLocalStorage('MI_USR_DATA');
        switch (userData.RoleTypeId) {
          case RoleTypeIds.ORGANIZATION_ADMIN:
            allowedRoles = [RoleTypeIds.ORGANIZATION_MANGER, RoleTypeIds.APP_USER_MANAGER, RoleTypeIds.ACCOUNTABILITY_PARTNER, RoleTypeIds.APP_USER];
            break;
          case RoleTypeIds.ORGANIZATION_MANGER:
            allowedRoles = [RoleTypeIds.APP_USER_MANAGER, RoleTypeIds.ACCOUNTABILITY_PARTNER, RoleTypeIds.APP_USER];
            break;
          case RoleTypeIds.APP_USER_MANAGER:
            allowedRoles = [RoleTypeIds.ACCOUNTABILITY_PARTNER, RoleTypeIds.APP_USER];
            break;
          default:
            allowedRoles = [
              RoleTypeIds.SUPER_ADMIN,
              RoleTypeIds.ORGANIZATION_ADMIN,
              RoleTypeIds.ORGANIZATION_MANGER,
              RoleTypeIds.APP_USER_MANAGER,
              RoleTypeIds.ACCOUNTABILITY_PARTNER,
              RoleTypeIds.APP_USER,
            ];
            break;
        }
        if (rolesByOrgTypeData && rolesByOrgTypeData.length > 0) {
          if (isUserSystemAdmin()) {
            if (getSystemAdminPermissionsApiSuccess) {
              const managingRoles = getSystemAdminPermissionsApiData[0]?.permission_details?.find((per: any) => per.org_type_id === organizationTypeValue?.value)?.managing_roles;
              const filteredRolesData = rolesByOrgTypeData.filter((role: any) => managingRoles?.some((managingRole: any) => managingRole.roleid === role.RoleId));
              const rolesData = filteredRolesData.map((role: any) => ({
                label: role.Name,
                value: role.RoleId,
                roleTypeId: role.RoleTypeID,
              }));
              setUserRoles(rolesData);
              setSelectedUser([]);
            }
          } else {
            const filteredRolesData = rolesByOrgTypeData.filter((role: any) => allowedRoles.includes(role.RoleTypeID));
            const rolesData = filteredRolesData.map((role: any) => ({
              label: role.Name,
              value: role.RoleId,
              roleTypeId: role.RoleTypeID,
            }));
            setUserRoles(rolesData);
            setSelectedUser([]);
          }
        } else {
          setUserRoles([]);
          setSelectedUser([]);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [rolesByOrgTypeLoading]);
  // Get organization details by organization type
  useEffect(() => {
    try {
      if (organizationTypeValue) {
        if (orgnameByOrgTypeData && orgnameByOrgTypeData.length > 0) {
          const organizationData = orgnameByOrgTypeData.map(
            (organization: any): ISelectOptionsNumber => ({
              label: organization.Name,
              value: organization.ID,
            }),
          );
          setOrgName(organizationData);
          setSelectedOrganization(null);
        } else {
          // If ResponseData is null, update orgName state with null
          setOrgName([]);
          setSelectedOrganization(null);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [orgnameByOrgTypeLoading]);
  // Handle the upload path api response
  useEffect(() => {
    try {
      const submitUploadsAndSubsequentActions = async () => {
        if (uploadFileResponseCode > 0 && uploadFileApiSuccess) {
          uploadAttachmentFileToBucket();
          dispatch(resetUploadFile());
        }
      };
      submitUploadsAndSubsequentActions();
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFileResponseCode, uploadFileResponseMessage]);
  // limiting user roles based on subscription count
  useEffect(() => {
    if (usersCountForSubscriptionSuccess) {
      const newRoles: ISelectOptionsNumber[] = [];
      userRoles?.forEach((role: any) => {
        if (APP_USER_ROLES.includes(role.roleTypeId) && usersCountForSubscriptionData.NoOfMobileAppUsers > 0) {
          newRoles.push(role);
        } else if (WEB_USER_ROLES.includes(role.roleTypeId) && usersCountForSubscriptionData.NoOfWebUsers > 0) {
          newRoles.push(role);
        }
      });
      setUserRoles(newRoles);
      setDisableFields(false);
    }
    if (usersCountForSubscriptionResponseCode === 4262) {
      toast.toastError('This organization has no active subscription plan.');
      setDisableFields(true);
    }
  }, [usersCountForSubscriptionLoading]);

  // Put file to bucket after getting the path
  const uploadAttachmentFileToBucket = async () => {
    try {
      const uploadURL = uploadFileApiData;
      if (uploadURL) {
        // PUT request: upload file to S3
        const result = await fetch(uploadURL, {
          method: 'PUT',
          body: upImageAttachment,
        });
        if (result.status == 200) {
          toast.toastSuccess(ImageUploadSuccess);
          setFileUploadBegin(false);
          setFileError('');
        } else {
          setFileError(ImageUploadFailed);
          setFileUploadBegin(false);
          toast.toastError('Failed to Upload.');
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle user bulk upload submit.
  const onSubmitUserExport = async () => {
    try {
      if (!upImageAttachment) {
        setFileError(ImageUploadChooseImage);
        return;
      }
      const errorResult = await validateForm(userForm, USER_UPLOAD_SCHEMA, setErrorFields);
      if (Object.keys(errorResult).length > 0) {
        setErrorFields(errorResult);
      } else {
        dispatch(usersBulkUpload(userForm));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const uploadUserFile = async (e: any) => {
    try {
      setFileUploadBegin(true);
      setFileError('');
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const lastDotIndex = file?.name?.lastIndexOf('.');
        const fileNameWithoutExtension = file?.name?.slice(0, lastDotIndex);
        const fileExtension = file?.name?.slice(lastDotIndex + 1);
        const modifiedFileName = fileNameWithoutExtension + new Date().getTime() + '.' + fileExtension;
        const fileTypeValue = getFileTypeByExtension(fileExtension.toLowerCase());
        // replacing special characters with underscores
        const formattedFilename = modifiedFileName.replace(/[^a-zA-Z0-9.\-_]/g, '_');
        const isValid = checkValidFile(formattedFilename, fileTypeValue, file.size);

        if (isValid) {
          setUpImageAttachment(e.target.files[0]);
          setUserForm((prevUserForm) => ({
            ...prevUserForm,
            S3_FileName: formattedFilename,
          }));
          const fileInfo = {
            name: formattedFilename,
            displayName: file.name,
            type: fileExtension,
            size: file.size,
            fileType: fileTypeValue,
          };

          setUpImageAttachmentDetails(fileInfo);
          const formData = new FormData();
          formData.append('UploadExtension', fileInfo.type);
          formData.append('UploadObjectKey', fileInfo.name);
          formData.append('UploadType', 'bulkuser_upload');
          dispatch(uploadFile(formData));
        } else {
          setFileError(ImageUploadChooseImage);
          setFileUploadBegin(false);
        }
      } else {
        setFileUploadBegin(false);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Get user roles and organization name on organization type change
  const handleOrgTypeChange = async (event: any) => {
    try {
      const selectedOrgTypeId = parseInt(event.value);
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        OrganizationTypeID: selectedOrgTypeId,
        UserRoleID: 0,
        OrganizationID: '',
      }));
      setOrganizationTypeValue(event);
      dispatch(
        getUserRolesByOrgType({
          OrganizationTypeId: selectedOrgTypeId,
        }),
      );
      dispatch(
        getOrganizationNameByOrgType({
          OrganizationTypeId: selectedOrgTypeId,
        }),
      );
      const validateObj = {
        ...userForm,
        OrganizationTypeID: selectedOrgTypeId,
        UserRoleID: 0,
        OrganizationID: '',
      };
      const errorResult = await validateForm(validateObj, USER_UPLOAD_SCHEMA, errorFields);
      setErrorFields(errorResult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle selection change
  const handleSelectionChange = async (event: any, type: string) => {
    try {
      const selectedValue = event.value;
      switch (type) {
        case 'role': {
          setSelectedUser(event.value);
          setUserForm((prevUserForm) => ({
            ...prevUserForm,
            UserRoleID: selectedValue,
          }));
          setSelectedUser(event);
          const validateRoleObj = {
            ...userForm,
            UserRoleID: selectedValue,
          };
          const roleErrorResult = await validateForm(validateRoleObj, USER_UPLOAD_SCHEMA, errorFields);
          setErrorFields(roleErrorResult);
          break;
        }
        case 'organization': {
          setSelectedOrganization(event.value);
          dispatch(userCountForSubscription({ organizationId: event.value }));
          setUserForm((prevUserForm) => ({
            ...prevUserForm,
            OrganizationID: selectedValue,
          }));
          setSelectedOrganization(event);
          const validateOrgObj = {
            ...userForm,
            OrganizationID: selectedValue,
          };
          const orgErrorResult = await validateForm(validateOrgObj, USER_UPLOAD_SCHEMA, errorFields);
          setErrorFields(orgErrorResult);
          break;
        }
        default:
          break;
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <>
      <Select
        label={useIntlMessages('Label.OrgType')}
        options={organizationTypeOptions}
        value={organizationTypeValue}
        name="OrganizationTypeID"
        placeholder={useIntlMessages('PH.Select')}
        onChange={(e: any) => handleOrgTypeChange(e)}
        error={errorFields?.OrganizationTypeID}
        isDisabled={isAdminTeacherManager()}
      />
      <Select
        label={useIntlMessages('Label.OrgName')}
        options={orgName}
        value={selectedOrganization}
        name="OrganizationID"
        id="OrganizationID"
        placeholder={useIntlMessages('PH.Select')}
        onChange={(e: any) => handleSelectionChange(e, 'organization')}
        error={errorFields?.OrganizationID}
        isDisabled={isAdminTeacherManager()}
      />
      <Select
        label={useIntlMessages('Label.Userrole')}
        options={userRoles}
        value={selectedUser}
        placeholder={useIntlMessages('PH.Select')}
        onChange={(e: any) => handleSelectionChange(e, 'role')}
        name="UserRoleID"
        id="UserRoleID"
        error={errorFields?.UserRoleID}
      />
      <div>
        <Row className="justify-content-center">
          <div className="fileupload-sec userbulk-label dropzone mb-3">
            <input
              type="file"
              name="file-3[]"
              id="file-3"
              className="inputfile inputfile-3 "
              data-multiple-caption="{count} files selected"
              multiple={false}
              onChange={(e: any) => uploadUserFile(e)}
              accept=".xls, .xlsx"
            />
            <label htmlFor="file-3">
              <Upload />
              <span>{useIntlActionMessages('Label.Upload.Template')}</span>
            </label>
          </div>
        </Row>
        <div className="error text-center pt-2"> {fileError}</div>
        <aside className="upload-helper-text">
          {upImageAttachmentDetails?.displayName && (
            <ul>
              {upImageAttachmentDetails.displayName} - {upImageAttachmentDetails.size} {useIntlActionMessages('text.Bytes')}
            </ul>
          )}
        </aside>
        <Row className="d-flex align-items-center mt-2">
          <Col md="auto">
            <a href={`${BUCKET_URL}user_bulkupload/sample.xlsx`} className="download-icn" target="_blank" rel="noopener noreferrer">
              <img src={Download} alt="Download" />
              {useIntlActionMessages('Label.Download.Template')}
            </a>
          </Col>
          <Col className="border-start">
            <small className="d-inline-block">{useIntlActionMessages('text.Templatenotes')}</small>
          </Col>
        </Row>
      </div>
      <Row className="mt-5 mb-5">
        <Col>
          <Button variant="outline-primary" className="w-100" onClick={() => navigate('/manageuser')}>
            {useIntlActionMessages('Button.Cancel')}
          </Button>
        </Col>
        <Col>
          <Button variant="primary" className="w-100" onClick={onSubmitUserExport} disabled={disableFields}>
            {useIntlActionMessages('Button.AddUser')}
          </Button>
        </Col>
      </Row>
      {(userBulkUploadApiLoading || organizationTypeDropDownLoading || fileUploadBegin) && <Loader />}
    </>
  );
};

export default UserBulkUpload;
