import React from 'react';
const ImgIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="34.828" height="29.023" viewBox="0 0 34.828 29.023">
            <g id="icons" transform="translate(-5 -2)">
                <path id="pic" d="M34.828,7.8a5.822,5.822,0,0,0-5.8-5.8H5.8A5.822,5.822,0,0,0,0,7.8V25.219a5.822,5.822,0,0,0,5.8,5.8H29.023a5.822,5.822,0,0,0,5.8-5.8ZM8.707,7.8a2.9,2.9,0,1,1-2.9,2.9A2.911,2.911,0,0,1,8.707,7.8ZM31.926,25.219a2.911,2.911,0,0,1-2.9,2.9H6.385a1.452,1.452,0,0,1-1.016-2.467l5.224-5.224a1.4,1.4,0,0,1,2.032,0l.871.871a1.4,1.4,0,0,0,2.032,0l9.578-9.578a1.4,1.4,0,0,1,2.032,0l4.354,4.354a1.317,1.317,0,0,1,.435,1.016v8.127Z" transform="translate(5)" fill="#8390db" />
            </g>
        </svg>
    );
}

export default ImgIcon;
