/**
 * @file   src/containers/profile/CreateAccount.tsx
 * @brief  Create user account page
 * @date   May, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages } from '../../utils/helper';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col } from 'react-bootstrap';
import icnEye from '../../assets/img/icnEye.svg';
import eyeOff from '../../assets/img/eye-off.svg';
import Input from '../../components/MAInput';
import Checkbox from '../../components/MACheck';
import HomeLayout from '../../components/HomeLayout';
import { useAppSelector, useAppDispatch } from '../../hooks';
import { RootState } from '../../store';
import Loader from '../../components/Loader';
import { CREATE_ACCOUNT_SCHEMA } from '../../validations/profileSchema';
import { validateForm } from '../../utils/formValidation';
import { useIntlActionMessages, setItemLocalStorage, removeItemLocalStorage } from '../../utils/helper';
import { validateToken, createUserAccount } from '../../store/actions/profileActions';
import { useParams } from 'react-router-dom';
import { MessageToaster } from '../../utils/ToastUtil';
import { resetCreateAccount } from '../../store/slices/profileSlice';
// Component to create user account
const Createaccount = () => {
  // Route navigation object creation
  const navigate = useNavigate();
  // Action dispatch object creation
  const dispatch = useAppDispatch();
  // Route params object creation
  const params = useParams();
  // Message toast object creation
  const toastObj = new MessageToaster();
  // Access redux state variables
  const {
    validateTokenApiData,
    validateTokenApiLoading,
    validateTokenApiResponseCode,
    validateTokenApiSuccess,
    createAccountApiData,
    createAccountApiLoading,
    createAccountApiResponseMessage,
    createAccountApiSuccess,
  } = useAppSelector((state: RootState) => state.profile);
  // component state variables
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showCnfPassword, setShowCnfPassword] = useState<boolean>(false);
  const [accountFormData, setAccountFormData] = useState<any>({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errorFields, setErrorFields] = useState<any>({
    password: useIntlActionMessages('Form.Password.Required'),
    confirmPassword: useIntlActionMessages('Form.CnfPassword.Required'),
  });
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const accountCreatedMessage = useIntlMessages('Account.Created.Message');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // call to validate token api
  useEffect(() => {
    try {
      const token = params.token;
      dispatch(validateToken({ Token: token }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // set data from validate token api to state
  useEffect(() => {
    try {
      if (validateTokenApiSuccess) {
        setAccountFormData((prev: any) => ({
          ...prev,
          email: validateTokenApiData.Email,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [validateTokenApiLoading]);
  //
  useEffect(() => {
    try {
      if (createAccountApiSuccess) {
        toastObj.toastSuccess(accountCreatedMessage);
        setItemLocalStorage('MI_USR_DATA', createAccountApiData);
        navigate('/profilecreate');
      } else if (!createAccountApiLoading && createAccountApiResponseMessage.length > 0) {
        toastObj.toastError(createAccountApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [createAccountApiLoading]);
  // reset create account on component unmount
  useEffect(() => {
    try {
      return () => {
        dispatch(resetCreateAccount());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // handle form fields validation
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      setAccountFormData((info: any) => ({
        ...info,
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      const errorresult = await validateForm(validateObj, CREATE_ACCOUNT_SCHEMA, errorFields);
      setErrorFields(errorresult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // api call to create account
  const createAccount = () => {
    try {
      const requestData = {
        Email: accountFormData.email,
        Password: accountFormData.password,
        ConfirmPassword: accountFormData.confirmPassword,
      };
      dispatch(createUserAccount(requestData));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle form submit
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const errorresult = await validateForm(accountFormData, CREATE_ACCOUNT_SCHEMA, errorFields);
      if (Object.keys(errorresult).length > 0) {
        setErrorFields(errorresult);
        setErrorVisible(true);
      } else {
        createAccount();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  const getError = () => {
    let error;
    try {
      if (errorVisible && accountFormData.confirmPassword.length == 0) {
        error = errorFields?.confirmPassword;
      } else if (errorVisible && accountFormData.password !== accountFormData.confirmPassword) {
        error = errorFields?.confirmPassword;
      } else {
        error = '';
      }
    } catch (error) {
      console.log('Error: ', error);
    }
    return error;
  };
  // handle remember me fucntionality
  const handleRememberMe = (event: any) => {
    try {
      setRememberMe(!rememberMe);
      if (!event.target.checked) {
        removeItemLocalStorage('username');
      } else {
        localStorage.setItem('username', validateTokenApiData.Email);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  return (
    <HomeLayout>
      <div className="login-main">
        {validateTokenApiSuccess && validateTokenApiResponseCode !== 3013 ? (
          <>
            <h2 className="m-0 mb-2">Create Account</h2>
            <p className="mb-4">The Mirror IM Admin has added you to the application. Enter your Email and Password to complete creating your account.</p>
            <form name="loginform " onSubmit={onSubmit}>
              <Col>
                <Input
                  label={useIntlMessages('Label.Email')}
                  id="username"
                  name="email"
                  type="text"
                  placeholder={useIntlMessages('PH.Email')}
                  maxLength={200}
                  onChange={onInputHandleChange}
                  value={accountFormData.email}
                  errorMessage={errorVisible ? errorFields?.email : ''}
                  status={true}
                />
              </Col>
              <Col className="position-relative">
                <Input
                  label={useIntlMessages('Label.Password')}
                  id="Password"
                  name="password"
                  type={!showPassword ? 'password' : 'text'}
                  placeholder={useIntlMessages('PH.Password')}
                  maxLength={200}
                  onChange={onInputHandleChange}
                  value={accountFormData.password}
                  errorMessage={errorVisible ? errorFields?.password : ''}
                  autoFocus={true}
                />
                <Button variant="outline-secondary" className="btn-eye" onClick={() => setShowPassword(!showPassword)}>
                  <img src={!showPassword ? icnEye : eyeOff} alt="" />
                </Button>
              </Col>
              <Col className="position-relative">
                <Input
                  label={useIntlMessages('Label.ConfirmPassword')}
                  id="ConfirmPassword"
                  name="confirmPassword"
                  type={!showCnfPassword ? 'password' : 'text'}
                  placeholder={useIntlMessages('PH.ConfirmPassword')}
                  maxLength={200}
                  onChange={onInputHandleChange}
                  value={accountFormData.confirmPassword}
                  errorMessage={getError()}
                />
                <Button variant="outline-secondary" className="btn-eye" onClick={() => setShowCnfPassword(!showCnfPassword)}>
                  <img src={!showCnfPassword ? icnEye : eyeOff} alt="" />
                </Button>
              </Col>
              <Col className="mb-4">
                <Checkbox type="Checkbox" label={useIntlMessages('Label.RememberMe')} onChange={handleRememberMe} checked={rememberMe} />
              </Col>
              <Button className="w-100 mb-4" variant="primary" type="submit">
                <FormattedMessage id="Button.Signin" />
              </Button>
              <div className="text-center">
                Already have an account?
                <Link to="/signin" className="text-center ms-2">
                  <FormattedMessage id="Label.ImIn" />
                </Link>
              </div>
            </form>
          </>
        ) : null}
        {validateTokenApiResponseCode === 3013 && <h1>The link has expired.</h1>}
      </div>
      {(validateTokenApiLoading || createAccountApiLoading) && <Loader />}
    </HomeLayout>
  );
};
export default Createaccount;
