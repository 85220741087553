/**
 * @file   src\components\Goals details.tsx
 * @brief  404 page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../../assets/css/goalDetail.scss';
import '../../assets/css/Resource.scss';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Breadcrumb, Row, Accordion, Nav, NavDropdown, Table } from 'react-bootstrap';
import { end } from '@popperjs/core';
import UserImg from '../../assets/img/Userimg.jpg';
import More from '../../assets/img/More.svg';
import { Scrollbar } from 'react-scrollbars-custom';
import { slide as Filter } from 'react-burger-menu';
import Delete from '../../assets/img/icon/Delete';
import Checkbox from '../../components/MACheck';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Sort from '../../assets/img/icon/Sort';
import Close from '../../assets/img/Close.svg';
import DatePicker from 'react-datepicker';
import { getFromLocalStorage, useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import Select from '../../components/MASelect';
import Search from '../../components/MASearch';
import Slider from 'react-slick';
import CompetenciesCard from '../../components/CompetenciesCard';
import Pagination from '../../components/Pagination';
import { IGetUsersByGoalIdAPIRequest, IGoalViewAPIRequest } from '../../interfaces/OrganisationInterface';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { deleteGoal, getCategoryList, getGoalDetail, getGoalResource, getUsersByGoalId } from '../../store/actions/organizationActions';
import { RootState } from '../../store';
import {
  resetAddTaskEventGoalDetail,
  resetGoalStepOneApiData,
  resetEditTaskEventGoalDetail,
  resetGoalResource,
  resetaddGoalStepThree,
  resetGetUsersByGoalIdApi,
} from '../../store/slices/organizationSlice';
import moment from 'moment';
import { BUCKET_URL, DATE_FORMAT1, DEFAULT_PAGE_INDEX, PAGE_SIZE, PAGE_SIZE_SORT_50 } from '../../utils/constants';
import { GoalTaskEventListSortField, NumberValues, RoleTypeIds, SortOrder, SortOrderType, UsersSortFields } from '../../utils/enums';
import Default from '../../assets/img/resource_grey.png';
import DeleteConfirmPopup from '../../components/DeleteConfirmation';
import { resetDeleteGoalDetail } from '../../store/slices/organizationSlice';
import Loader from '../../components/Loader';
import { MessageToaster } from '../../utils/ToastUtil';
import MoreImg from '../../assets/img/more-image.jpg';
import { assignGoalToUsers, listGoalCompetencies, listGoalTasksEvents, listUsersWithSurveyGoalAssignmentDetails } from '../../store/actions/goalAction';
import useDebounce from '../../hooks/useDebounce';
import { ISelectOptionsNumber } from '../../interfaces/GeneralInterface';
import Event from '../../assets/img/icon/EventThumb';
import Task from '../../assets/img/icon/TaskThumb';
import InfiniteScroll from 'react-infinite-scroll-component';
import EndMessage from '../EndMessage';
import { resetListGoalCompetencies, resetListGoalTasksEvents, resetListUsersWithSurveyGoalAssignmentDetailsApi } from '../../store/slices/goalSlice';
import { IUserListWithGoalSurveyAssignmentApiParams } from '../../interfaces/GoalInterface';
import { getCurrentOrgDetails } from '../../utils/helper';
import SelectableCalendar from '../../components/SelectableCalendar';
import ReactPlayer from 'react-player';
import { FILE_TYPES_SUPPORTED } from '../../utils/enums';

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 1050,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const goalTaskEventListAPIDefaultParams = {
  Category: 0,
  GoalId: 0,
  Page: NumberValues.NUM_1,
  PageSize: NumberValues.NUM_10,
  Search: '',
  SortColumnName: '',
  SortOrder: '',
  Status: '',
  SubCategories: [],
};
const userListAPIDefaultParams = {
  Availability: true,
  Category: [],
  Completed: false,
  DOJ: '',
  InProgress: false,
  NotStarted: false,
  OrganizationId: '',
  Overdue: false,
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE_SORT_50,
  Rescheduled: false,
  RoleTypeId: RoleTypeIds.APP_USER,
  Search: '',
  SortColumn: UsersSortFields.NAME,
  SortOrder: SortOrder.ASC,
  SubCategories: [],
  GoalOrSurveyID: 0,
  IsGoalOrSurvey: 0,
};
const listUsersByGoalIdApiDefaultParams = {
  GoalId: 0,
  PageNo: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE,
};
const GoalsDetail = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Toast object creation.
  const toast = new MessageToaster();
  // Create route param object to access the route parameters.
  const params = useParams();
  const goalId: number = params.goalId ? Number(params.goalId) : 0;
  const orgDetail = getFromLocalStorage('ORG_DETAIL');
  const storageData = getFromLocalStorage('MI_USR_DATA');
  const {
    getGoalDetailAPiData,
    getGoalDetailApiLoading,
    getGoalDetailApiSuccess,
    getGoalResourceApiData,
    deleteGoalApiLoading,
    deleteGoalApiSuccess,
    deleteGoalApiResponseCode,
    deleteGoalApiResponseMessage,
    getCatergoryListAPiData,
    getCategoryListApiLoading,
    addEventApiSuccess,
    addEventApiLoading,
    addTaskApiLoading,
    addTaskApiSuccess,
    addTaskApiResponseMessage,
    addEventApiResponseMessage,
    getEventDetailsApiLoading,
    getTaskDetailsApiLoading,
    editEventApiLoading,
    editEventApiSuccess,
    editEventApiResponseMessage,
    editTaskApiLoading,
    editTaskApiSuccess,
    editTaskApiResponseMessage,
    getUsersByGoalIdApiLoading,
    getUsersByGoalIdAPiData,
    getUsersByGoalIdApiSuccess,
  } = useAppSelector((state: RootState) => state.organization);
  // Accessing redux state variables
  const {
    listGoalTasksEventsApiLoading,
    listGoalTasksEventsApiData,
    listGoalCompetenciesApiData,
    listUsersWithSurveyGoalAssignmentDetailsAPiData,
    listUsersWithSurveyGoalAssignmentDetailsApiLoading,
    listUsersWithSurveyGoalAssignmentDetailsApiSuccess,
  } = useAppSelector((state: RootState) => state.goal);
  const currentTime = new Date();
  // Initialize component state variables.
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_INDEX);
  const [searchText, setSearchText] = useState<string>('');
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('');
  const [isShowDeleteConfirmPopup, setIsShowDeleteConfirmPopup] = useState<boolean>(false);
  const [categoryList, setCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [goalTaskEventListAPIParams, setGoalTaskEventListAPIParams] = useState<any>(goalTaskEventListAPIDefaultParams);
  const [userListApiParams, setUserListApiParams] = useState<IUserListWithGoalSurveyAssignmentApiParams>(userListAPIDefaultParams);
  const [confirmedUsers, setConfirmedUsers] = useState<any[]>([]);
  const [selectedUserListForDelete, setSelectedUserListForDelete] = useState<any[]>([]);
  const [isOpenAssignedUsers, setIsOpenAssignedUsers] = useState<boolean>(false);
  const [searchTextUserList, setSearchTextUserList] = useState<string>('');
  const [selectedUserList, setSelectedUserList] = useState<any[]>([]);
  const [isAllSelectedForDelete, setIsAllSelectedForDelete] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [subCategoryListBasedOnGoal, setSubCategoryListBasedOnGoal] = useState<ISelectOptionsNumber[]>([]);
  const [selectedCategoryUserList, setSelectedCategoryUserList] = useState<ISelectOptionsNumber[]>([]);
  const [selectedSubCategoryUserList, setSelectedSubCategoryUserList] = useState<ISelectOptionsNumber[]>([]);
  const [joiningDate, setJoiningDate] = useState<Date | null>(null);
  const [items, setItems] = useState<any>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isViewMode, setIsViewMode] = useState<boolean>(false);
  const [viewOnlyReason, setViewOnlyReason] = useState<string>('');
  const [isShowRemoveAssigneeConfirmPopup, setIsShowRemoveAssigneeConfirmPopup] = useState<boolean>(false);
  const [listUsersByGoalIdApiParams, setlistUsersByGoalIdApiParams] = useState<IGetUsersByGoalIdAPIRequest>(listUsersByGoalIdApiDefaultParams);
  const [confirmedItems, setConfirmedItems] = useState<any>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [fileType, setFileType] = useState<string>('');
  const noData = useIntlActionMessages('Label.Nodata');
  const noPermissionMessage = useIntlActionMessages('Form.authorization');
  const activeGoalCannotEditDeleteMessage = useIntlActionMessages('Text.ActiveGoal.CannotEditDelete');
  const labelLoading = useIntlActionMessages('Label.Loading');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // Invoke debounce component on intervel.
  const debouncedSearch = useDebounce(searchText, 500);
  // Invoke debounce component on intervel.
  const debouncedSearchUserList = useDebounce(searchTextUserList, 500);
  const unSupportedFileTypeText = useIntlActionMessages('Label.UnsupportedFileType');
  // works on unmounting. used to reset add task event
  useEffect(() => {
    try {
      return () => {
        dispatch(resetAddTaskEventGoalDetail());
        dispatch(resetEditTaskEventGoalDetail());
        dispatch(resetListGoalCompetencies());
        dispatch(resetListGoalTasksEvents());
        dispatch(resetGoalResource());
        dispatch(resetaddGoalStepThree());
        dispatch(resetGetUsersByGoalIdApi());
        dispatch(resetListUsersWithSurveyGoalAssignmentDetailsApi());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // Initial loading
  useEffect(() => {
    try {
      setlistUsersByGoalIdApiParams((apiParams: any) => ({
        ...apiParams,
        GoalId: id,
        PageNo: DEFAULT_PAGE_INDEX,
      }));
      const id = goalId;
      dispatch(getCategoryList({ OrganizationId: getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : orgDetail?.OrganizationId }));
      const goalDetailsAPIRequest: IGoalViewAPIRequest = {
        goalId: id,
      };
      dispatch(getGoalDetail(goalDetailsAPIRequest));
      const resourceParams = {
        GoalId: id,
        Page: NumberValues.NUM_1,
        PageSize: NumberValues.NUM_10,
        TypeID: 3, // 3 For getting both motivational resources and incentive contents under the specified goal
      };
      dispatch(getGoalResource(resourceParams));
      const listGoalCompetenciesRequest = {
        GoalId: id,
        Page: NumberValues.NUM_1,
        PageSize: NumberValues.NUM_10,
      };
      dispatch(listGoalCompetencies(listGoalCompetenciesRequest));
      setGoalTaskEventListAPIParams((apiParams: any) => ({
        ...apiParams,
        GoalId: id,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // Set View/Edit Mode
  useEffect(() => {
    try {
      if (getGoalDetailApiSuccess && getGoalDetailAPiData) {
        // Set Category, sub category for assign goal to student section based on goal
        setSelectedCategoryUserList([{ label: getGoalDetailAPiData?.CategoryName, value: getGoalDetailAPiData?.CategoryId }]);
        const subcategoriesOfGoal = getGoalDetailAPiData?.Class?.map((subcategory: any) => ({
          label: subcategory.classname,
          value: subcategory.classid,
        }));
        setSubCategoryListBasedOnGoal(subcategoriesOfGoal);
        setSelectedSubCategoryUserList(subcategoriesOfGoal);
        const category = getGoalDetailAPiData?.IsGeneral ? [] : [{ categoryid: getGoalDetailAPiData?.CategoryId }];
        setUserListApiParams((info: any) => ({
          ...info,
          OrganizationId: getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : orgDetail.OrganizationId,
          Category: category,
          Page: DEFAULT_PAGE_INDEX,
          GoalOrSurveyID: goalId,
          IsGoalOrSurvey: 1,
        }));

        setIsViewMode(false);
        const startdate = moment(getGoalDetailAPiData?.StartDate).toDate();
        if (getGoalDetailAPiData?.CreatedByDetails?.UserId != storageData?.UserId) {
          setIsViewMode(true);
          setViewOnlyReason(noPermissionMessage);
        } else if (startdate <= currentTime) {
          setIsViewMode(true);
          setViewOnlyReason(activeGoalCannotEditDeleteMessage);
        }

        const fileType = getGoalDetailAPiData?.GoalImage.toLowerCase().split('.').pop();
        if (FILE_TYPES_SUPPORTED.image.includes(fileType)) {
          setFileType('image');
        } else if (FILE_TYPES_SUPPORTED.video.includes(fileType)) {
          setFileType('video');
        } else {
          setFileType('');
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getGoalDetailApiLoading]);

  // Get Student List for assign goal to on search, filter change
  useEffect(() => {
    try {
      if (userListApiParams.OrganizationId != '') dispatch(listUsersWithSurveyGoalAssignmentDetails(userListApiParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [userListApiParams]);

  useEffect(() => {
    try {
      if (listUsersByGoalIdApiParams.GoalId != 0) dispatch(getUsersByGoalId(listUsersByGoalIdApiParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listUsersByGoalIdApiParams]);

  // Set assigned users associated with goal
  useEffect(() => {
    try {
      if (getUsersByGoalIdApiSuccess && getUsersByGoalIdAPiData) {
        const newItems = getUsersByGoalIdAPiData?.Users;
        const totalPages = getUsersByGoalIdAPiData?.TotalCount / listUsersByGoalIdApiParams.PageSize;
        if (newItems != null && newItems.length > 0) setConfirmedItems((prevItems: any) => (listUsersByGoalIdApiParams.PageNo === 1 ? newItems : [...prevItems, ...newItems]));
        if (newItems?.length > 0 && newItems?.length === listUsersByGoalIdApiParams.PageSize && listUsersByGoalIdApiParams.PageNo < totalPages) {
          setlistUsersByGoalIdApiParams((apiParams: any) => ({
            ...apiParams,
            PageNo: listUsersByGoalIdApiParams.PageNo + 1,
          }));
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getUsersByGoalIdApiLoading]);

  useEffect(() => {
    try {
      if (confirmedItems?.length > 0) {
        const usersSelected: any[] = [];
        confirmedItems?.forEach((value: any) => {
          usersSelected.push({ id: value.UserId, name: value.UserName, image: value.Image });
        });
        setConfirmedUsers([...usersSelected]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [confirmedItems]);

  // Handle Get User List APi success
  useEffect(() => {
    try {
      if (listUsersWithSurveyGoalAssignmentDetailsApiSuccess) {
        if (listUsersWithSurveyGoalAssignmentDetailsAPiData) {
          const newItems = listUsersWithSurveyGoalAssignmentDetailsAPiData;
          if (newItems.length > 0) setTotalItems(newItems[0].TotalCount);
          const totalPages = newItems.length > 0 ? newItems[0].TotalCount / userListApiParams.PageSize : 0;
          setItems((prevItems: any) => (userListApiParams.Page === 1 ? newItems : [...prevItems, ...newItems]));
          setHasMore(newItems.length > 0 && newItems.length === userListApiParams.PageSize && userListApiParams.Page < totalPages);
          setIsAllSelected(false);
        } else {
          setItems([]);
          setHasMore(false);
          setTotalItems(0);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listUsersWithSurveyGoalAssignmentDetailsApiLoading]);

  // Fetching next page items
  const fetchMoreData = () => {
    try {
      setUserListApiParams((info: any) => ({
        ...info,
        Page: info.Page + 1,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Set API params on bebounced search.
  useEffect(() => {
    try {
      setGoalTaskEventListAPIParams((apiParams: any) => ({
        ...apiParams,
        Search: searchText,
        Page: DEFAULT_PAGE_INDEX,
      }));
      setCurrentPage(DEFAULT_PAGE_INDEX);
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [debouncedSearch]);

  // Set API params on filter change.
  useEffect(() => {
    try {
      setGoalTaskEventListAPIParams((apiParams: any) => ({
        ...apiParams,
        Page: currentPage,
        SortColumnName: sortField,
        SortOrder: sortOrder,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [currentPage, sortField, sortOrder]);

  // Get category details by organization
  useEffect(() => {
    try {
      if (getCatergoryListAPiData && getCatergoryListAPiData.length > 0) {
        const categoryData = getCatergoryListAPiData.map(
          (category: any): ISelectOptionsNumber => ({
            label: category.CategoryName,
            value: +category.CategoryId,
          }),
        );
        setCategoryList(categoryData);
      } else {
        // If ResponseData is null, update category state with null
        setCategoryList([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getCategoryListApiLoading]);

  // Get task event list on API Param Change
  useEffect(() => {
    try {
      if (goalTaskEventListAPIParams.GoalId != 0) dispatch(listGoalTasksEvents(goalTaskEventListAPIParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [goalTaskEventListAPIParams]);

  // Delete Goal Success
  useEffect(() => {
    try {
      if (deleteGoalApiSuccess && deleteGoalApiResponseCode > 0) {
        dispatch(resetDeleteGoalDetail());
        toast.toastSuccess(deleteGoalApiResponseMessage);
        navigate('/activities', {
          state: {
            organizationId: orgDetail.OrganizationId,
            parentPage: 'orgview',
          },
        });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [deleteGoalApiLoading]);

  // show add task api success and failure error message
  useEffect(() => {
    try {
      if (addTaskApiSuccess) {
        toast.toastSuccess(addTaskApiResponseMessage);
        dispatch(listGoalTasksEvents(goalTaskEventListAPIParams));
      } else if (!addTaskApiSuccess && addTaskApiResponseMessage.length > 0) {
        toast.toastError(addTaskApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [addTaskApiLoading]);

  // show add event api success message and error message
  useEffect(() => {
    try {
      if (addEventApiSuccess) {
        toast.toastSuccess(addEventApiResponseMessage);
        dispatch(listGoalTasksEvents(goalTaskEventListAPIParams));
      } else if (!addEventApiSuccess && addEventApiResponseMessage.length > 0) {
        toast.toastError(addEventApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [addEventApiLoading]);

  // show edit task api success and failure error message
  useEffect(() => {
    try {
      if (editTaskApiSuccess) {
        toast.toastSuccess(editTaskApiResponseMessage);
        dispatch(listGoalTasksEvents(goalTaskEventListAPIParams));
      } else if (!editTaskApiSuccess && editTaskApiResponseMessage.length > 0) {
        toast.toastError(editTaskApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [editTaskApiLoading]);

  // show edit event api success message and error message
  useEffect(() => {
    try {
      if (editEventApiSuccess) {
        toast.toastSuccess(editEventApiResponseMessage);
        dispatch(listGoalTasksEvents(goalTaskEventListAPIParams));
      } else if (!editEventApiSuccess && editEventApiResponseMessage.length > 0) {
        toast.toastError(editEventApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [editEventApiLoading]);

  // Set API params on bebounced search.
  useEffect(() => {
    try {
      setUserListApiParams((apiParams: any) => ({
        ...apiParams,
        Search: searchTextUserList,
        Page: DEFAULT_PAGE_INDEX,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [debouncedSearchUserList]);

  // Handle Assigned users click.
  const handleAssignedUsersClick = () => {
    try {
      setSelectedUserListForDelete([]);
      setIsAllSelectedForDelete(false);
      setIsOpenAssignedUsers(true);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Assignes users overlay close.
  const handleAssignedUsersClose = () => {
    setIsOpenAssignedUsers(false);
  };

  // Handle Select All checkbox click
  const handleSelectAllCheckboxClickForDelete = (event: any) => {
    try {
      const usersSelected: any[] = [];
      if (event.target.checked) {
        setIsAllSelectedForDelete(true);
        confirmedUsers.forEach((value: any) => {
          if (event.target.checked) {
            usersSelected.push({ id: value.id, name: value.name });
          }
        });
      } else {
        setIsAllSelectedForDelete(false);
      }
      setSelectedUserListForDelete([...usersSelected]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Sort header change event.
  const changeSortField = (field: string) => {
    try {
      let newSortOrder = 'ASC';
      if (field === sortField) {
        newSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
      }
      setUserListApiParams((info: any) => ({
        ...info,
        SortColumn: field,
        SortOrder: newSortOrder,
      }));
      setSortField(field);
      setSortOrder(newSortOrder);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Shoew Confirm pop up on delete click
  const ShowRemoveAssigneeConfirmPopup = () => {
    try {
      if (selectedUserListForDelete && selectedUserListForDelete.length > 0) setIsShowRemoveAssigneeConfirmPopup(true);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Delete button click
  const handleDeleteButtonClick = () => {
    try {
      let usersSelected: any[] = [];
      usersSelected = confirmedUsers.filter((user) => {
        return !selectedUserListForDelete.some((selectedUser) => selectedUser.id === user.id);
      });
      const assignGoalToUsersRequest = {
        GoalID: goalId,
        UserIds: usersSelected.map((user: any) => user.id),
      };
      dispatch(assignGoalToUsers(assignGoalToUsersRequest));
      setConfirmedUsers([...usersSelected]);
      setSelectedUserList([...usersSelected]);
      setSelectAllCheckboxSelection();
      setIsOpenAssignedUsers(false);
      setIsShowRemoveAssigneeConfirmPopup(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle select all checbox selection.
  const setSelectAllCheckboxSelection = () => {
    try {
      const checkboxes: HTMLInputElement[] = [];
      const elements = document.querySelectorAll('[id^="overlay1checkbox_"]');
      elements.forEach((element) => {
        checkboxes.push(element as HTMLInputElement);
      });
      let selectall = true;
      if (checkboxes.length > 0) {
        checkboxes.forEach((checkbox) => {
          if (!checkbox.checked) {
            selectall = false;
          }
        });
      } else {
        selectall = false;
      }
      setIsAllSelected(...[selectall]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Cancel button click
  const handleCancelButtonClick = () => {
    try {
      setIsOpenAssignedUsers(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle User Select checkbox click for Delete
  const handleUserSelectCheckboxClickForDelete = (event: any) => {
    try {
      let usersSelected = selectedUserListForDelete;
      if (event.target.checked) {
        usersSelected.push({ id: event.target.id.split('_')[1], name: event.target.id.split('_')[2] });
      } else {
        usersSelected = usersSelected.filter((e) => e.id != event.target.id.split('_')[1].toString());
      }
      setSelectedUserListForDelete([...usersSelected]);
      setSelectAllCheckboxForDeleteSelection();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle select all checbox for delete selection.
  const setSelectAllCheckboxForDeleteSelection = () => {
    try {
      const checkboxes: HTMLInputElement[] = [];
      const elements = document.querySelectorAll('[id^="checkbox_"]');
      elements.forEach((element) => {
        checkboxes.push(element as HTMLInputElement);
      });
      let selectall = true;
      checkboxes.forEach((checkbox) => {
        if (!checkbox.checked) {
          selectall = false;
        }
      });
      setIsAllSelectedForDelete(...[selectall]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Assign goal to button click.
  const handleAssignGoaltoClick = () => {
    try {
      if (!isViewMode) {
        setSelectedUserList([...confirmedUsers]);
        setIsOpen(true);
        setSelectAllCheckboxSelection();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Assign Goal to Overlay close
  const handleAssignGoaltoClose = () => {
    try {
      setSelectedUserList([...confirmedUsers]);
      setIsOpen(false);
      handleFilterReset();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle User Select checkbox click
  const handleUserSelectCheckboxClick = (event: any) => {
    try {
      let usersSelected = selectedUserList;
      if (event.target.checked) {
        usersSelected.push({ id: event.target.id?.split('_')[1], name: event.target.id?.split('_')[2] });
      } else {
        usersSelected = usersSelected.filter((e) => e.id != event.target.id?.split('_')[1].toString());
      }
      setSelectedUserList([...usersSelected]);
      setSelectAllCheckboxSelection();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Select All checkbox click
  const handleSelectAllCheckboxClick = (event: any) => {
    try {
      const usersSelected: any[] = [];
      if (event.target.checked) {
        setIsAllSelected(true);
        items.forEach((value: any) => {
          if (event.target.checked) {
            usersSelected.push({ id: value.UserId, name: value.Name });
          }
        });
      } else {
        setIsAllSelected(false);
      }
      setSelectedUserList([...usersSelected]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Assign user Select button click.
  const handleAssignUserSelectButtonClick = () => {
    try {
      const assignGoalToUsersRequest = {
        GoalID: goalId,
        UserIds: selectedUserList.map((user: any) => user.id),
      };
      dispatch(assignGoalToUsers(assignGoalToUsersRequest));
      setConfirmedUsers([...selectedUserList]);
      setIsOpen(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Search field change event.
  const handleSearchChangeUserList = (event: any) => {
    try {
      setSearchTextUserList(event.target.value);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Search Click.
  const handleSearchClickUserList = () => {
    try {
      setUserListApiParams((apiParams: any) => ({
        ...apiParams,
        Search: searchTextUserList,
        Page: DEFAULT_PAGE_INDEX,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Search Field input key down.
  const handleSearchKeyDownUserList = (event: any) => {
    try {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleSearchClickUserList();
      } else {
        setSearchTextUserList(event.target.value);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Sub Category change handler.
  const handleSubCategoryChangeUserList = (event: any) => {
    try {
      if (event) {
        const subcategories = event.map((subcategory: any) => ({ classid: subcategory.value }));
        setSelectedSubCategoryUserList(event);
        setUserListApiParams((info: any) => ({
          ...info,
          SubCategories: subcategories,
          Page: DEFAULT_PAGE_INDEX,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Joining Date change event.
  const handleJoiningDateChange = (paramValue: any) => {
    try {
      setJoiningDate(paramValue);
      setUserListApiParams((info: any) => ({
        ...info,
        DOJ: moment(paramValue).format(DATE_FORMAT1),
        Page: DEFAULT_PAGE_INDEX,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle reset filter
  const handleFilterResetUserList = () => {
    try {
      setSelectedUserList([...confirmedUsers]);
      setUserListApiParams((apiParams: any) => ({
        ...apiParams,
        Category: [],
        DOJ: '',
        Page: DEFAULT_PAGE_INDEX,
        PageSize: PAGE_SIZE_SORT_50,
        Search: '',
        SortColumn: UsersSortFields.NAME,
        SortOrder: SortOrder.ASC,
        SubCategories: [],
      }));
      setSearchText('');
      // setSelectedCategoryUserList([]);
      setSelectedSubCategoryUserList([]);
      setJoiningDate(null);
      setIsOpen(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Edit Goal Click
  const handleEditGoalClick = (event: any) => {
    try {
      if (isViewMode) {
        event.preventDefault();
        toast.toastInfo(viewOnlyReason);
      } else {
        dispatch(resetGoalStepOneApiData());
        navigate('/assigngoal', { state: { goalId: goalId } });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Shoew Confirm pop up on delete click
  const ShowDeleteConfirmPopup = (event: any) => {
    try {
      if (isViewMode) {
        event.preventDefault();
        toast.toastInfo(viewOnlyReason);
      } else {
        setIsShowDeleteConfirmPopup(true);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle delete goal click
  const handleDeleteGoalClick = () => {
    try {
      dispatch(deleteGoal({ goalId: goalId }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Sort header change event.
  const handleSortFieldChange = (field: string) => {
    try {
      let newSortOrder = SortOrderType.ASC;
      if (field === sortField) {
        newSortOrder = sortOrder === SortOrderType.ASC ? SortOrderType.DESC : SortOrderType.ASC;
      }
      setSortField(field);
      setSortOrder(newSortOrder);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle reset filter goal tasks/events list
  const handleFilterReset = () => {
    try {
      setGoalTaskEventListAPIParams((apiParams: any) => ({
        ...apiParams,
        Status: '',
        Category: 0,
        SubCategories: [],
        Page: DEFAULT_PAGE_INDEX,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Hd.GoalDetails" />
        </h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>
              <FormattedMessage id="Hd.ManageOrganizations" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate('/activities')}>
              <FormattedMessage id="Hd.Activities" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Hd.GoalDetails" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="mb-4">
        <div className="org-selected">
          <span className="goal-task-img">
            <img className="thumbnail-image" src={orgDetail?.ImageURL ? BUCKET_URL + orgDetail?.ImageURL : Default} />
          </span>
          <div>
            <h6 className="mb-1">{orgDetail?.Name}</h6>
            <small>{orgDetail?.Address}</small>
          </div>
        </div>
      </div>
      <div className="goalDetails rep-contIssue-wr resources-wrap mb-3">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0" className="mb-4">
            <Nav className="more-dropdown">
              <NavDropdown align={end} className="icon-btn-more" title={<img src={More} alt="more" />}>
                <NavDropdown.Item onClick={(e) => handleEditGoalClick(e)}>
                  <FormattedMessage id="Button.Edit" />
                </NavDropdown.Item>
                <NavDropdown.Item onClick={(e) => ShowDeleteConfirmPopup(e)}>
                  <FormattedMessage id="Button.Delete" />
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Accordion.Header>
              <div className="content-sub-header d-flex justify-content-between align-items-center">
                <h6>
                  <FormattedMessage id="Hd.GoalsDetails" />
                </h6>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Row className="d-flex r-rep-contIssue">
                <Col md={6}>
                  <div className="res-det-img">
                    {fileType === 'image' ? (
                      <img src={getGoalDetailAPiData && getGoalDetailAPiData?.GoalImage ? BUCKET_URL + getGoalDetailAPiData?.GoalImage : Default} alt="" />
                    ) : fileType === 'video' ? (
                      <ReactPlayer
                        url={getGoalDetailAPiData && getGoalDetailAPiData?.GoalImage && BUCKET_URL + getGoalDetailAPiData?.GoalImage}
                        controls
                        width="100%"
                        className="att-img-vid"
                      />
                    ) : (
                      <p>{unSupportedFileTypeText}</p>
                    )}
                  </div>
                </Col>
                <Col md={6} className="r-sec-rgt">
                  <div>
                    <h5>
                      {getGoalDetailAPiData?.GoalName}
                      {getGoalDetailAPiData?.IsGeneral && <span className="general-tag">General</span>}
                    </h5>
                    <p className="p-gray">{getGoalDetailAPiData?.Description}</p>
                  </div>
                  <div>
                    <Row className="border-top-0 label-with-content">
                      <Col xl={4} lg={4} md={4} className="mb-4">
                        <label>
                          <FormattedMessage id="Label.GoalType" />
                        </label>
                        <p>{getGoalDetailAPiData?.GoalType}</p>
                      </Col>
                      {!getGoalDetailAPiData.IsGeneral && (
                        <>
                          <Col xl={4} lg={4} md={4} className="mb-4">
                            <label>
                              <FormattedMessage id="Label.Category" />
                            </label>
                            <p>{getGoalDetailAPiData?.CategoryName}</p>
                          </Col>
                          <Col xl={4} lg={4} md={4} className="mb-2">
                            <label>
                              <FormattedMessage id="Label.SubCategory" />
                            </label>
                            <p>
                              {getGoalDetailAPiData?.Class?.map((subcategory: any, index: number) => (
                                <>
                                  {subcategory.classname}
                                  {index !== getGoalDetailAPiData?.Class?.length - 1 && ', '}
                                </>
                              ))}
                            </p>
                          </Col>
                        </>
                      )}
                      <Col sm={12}>
                        <Row>
                          <Col xl={4} lg={4} md={4} className="mb-4">
                            <label>
                              <FormattedMessage id="Label.OrgType" />
                            </label>
                            <p>{orgDetail?.OrganizationType}</p>
                          </Col>
                          <Col xl={4} lg={4} md={4} className="mb-4">
                            <label>
                              <FormattedMessage id="Label.OrgName" />
                            </label>
                            <p>{orgDetail?.Name}</p>
                          </Col>
                          <Col xl={4} lg={4} md={4} className="mb-4">
                            <label>
                              <FormattedMessage id="Label.KudosPoint" />
                            </label>
                            <p>{getGoalDetailAPiData?.KudosPoint}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xl={4} lg={4} md={4} className="mb-4">
                        <label>
                          <FormattedMessage id="Label.StartDateTime" />
                        </label>
                        <p>{moment(getGoalDetailAPiData?.StartDate).format('MMM DD, YYYY | hh:mm A')}</p>
                      </Col>
                      <Col xl={4} lg={4} md={4} className="mb-4">
                        <label>
                          <FormattedMessage id="Label.EndDateTime" />
                        </label>
                        <p>{moment(getGoalDetailAPiData?.EndDate).format('MMM DD, YYYY | hh:mm A')}</p>
                      </Col>
                    </Row>
                  </div>
                  <Row className="label-with-content mt-0">
                    <Col xl={8}>
                      <label className="form-label">
                        <FormattedMessage id="Label.AssignedGoalto" />{' '}
                      </label>
                      <Row>
                        <Col lg={6} md={6} xs="auto" className="align-items-center d-flex assign-users">
                          <div className="custom-berger-menu p-gray">
                            <Filter
                              onOpen={handleAssignedUsersClick}
                              onClose={handleAssignedUsersClose}
                              isOpen={isOpenAssignedUsers}
                              width={450}
                              right
                              pageWrapId={'filter-wrapper'}
                              outerContainerId={'outer-container'}
                              customCrossIcon={<img src={Close} />}
                              customBurgerIcon={
                                <div className="d-flex align-items-center multiple-user">
                                  <span className="td-user-image">
                                    <img src={UserImg} className="w-100" alt="user" />
                                  </span>
                                  <span className="td-user-image">
                                    <img src={UserImg} className="w-100" alt="user" />
                                  </span>
                                  <span className="td-user-image">
                                    <img src={UserImg} className="w-100" alt="user" />
                                  </span>
                                  +{confirmedUsers.length} {confirmedUsers.length == 1 ? <FormattedMessage id="Label.Member" /> : <FormattedMessage id="Label.Members" />}
                                </div>
                              }
                            >
                              <div id="filter-wrapper" className="filter-main">
                                <Scrollbar className="h-80vh">
                                  <Table striped hover responsive size="sm">
                                    <thead>
                                      <tr>
                                        <th className="w-50px">
                                          <div className="no-margin">
                                            <Checkbox
                                              disabled={isViewMode ? true : confirmedUsers && confirmedUsers.length > 0 ? false : true}
                                              type="Checkbox"
                                              checked={isAllSelectedForDelete}
                                              onChange={handleSelectAllCheckboxClickForDelete}
                                            />
                                          </div>
                                        </th>
                                        <th>
                                          <FormattedMessage id="Label.Name" /> <Link to="" className="ms-1"></Link>
                                        </th>
                                        <th className="w-50px">
                                          <Button
                                            disabled={isViewMode ? true : confirmedUsers && confirmedUsers.length > 0 ? false : true}
                                            variant="dark"
                                            size="sm"
                                            className="icon-btn"
                                            onClick={() => ShowRemoveAssigneeConfirmPopup()}
                                          >
                                            <Delete />
                                          </Button>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {confirmedUsers &&
                                        confirmedUsers.length > 0 &&
                                        confirmedUsers.map((user: any, index: number) => (
                                          <tr key={index}>
                                            <td>
                                              <div className="no-margin">
                                                <Checkbox
                                                  disabled={isViewMode ? true : false}
                                                  checked={selectedUserListForDelete.map((x: any) => x.id).includes(user.id)}
                                                  class="assigned-users-checkbox"
                                                  type="Checkbox"
                                                  name={'checkbox_' + user.id}
                                                  id={'checkbox_' + user.id}
                                                  onChange={handleUserSelectCheckboxClickForDelete}
                                                />
                                              </div>
                                            </td>
                                            <td>
                                              <div className="d-flex align-items-center">
                                                <span className="td-user-image">
                                                  <img src={user.image ? BUCKET_URL + user.image : UserImg} className="w-100" alt="user" />
                                                </span>
                                                {user.name}
                                              </div>
                                            </td>
                                            <td></td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </Table>
                                </Scrollbar>
                                <Row className="mt-4 pb-4">
                                  <Col>
                                    <Button variant="outline-primary" className="w-100" onClick={() => handleCancelButtonClick()}>
                                      <FormattedMessage id="Button.Cancel" />
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Button variant="primary" className="w-100" onClick={() => handleCancelButtonClick()}>
                                      <FormattedMessage id="Button.Done" />
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Filter>
                          </div>
                        </Col>
                        <Col lg={6} md={6} className="assign-users">
                          <div className="custom-berger-menu">
                            <Filter
                              onOpen={handleAssignGoaltoClick}
                              onClose={handleAssignGoaltoClose}
                              isOpen={isOpen}
                              width={450}
                              right
                              pageWrapId={'filter-wrapper'}
                              outerContainerId={'outer-container'}
                              customCrossIcon={<img src={Close} />}
                              customBurgerIcon={
                                <Button disabled={isViewMode ? true : false} variant="secondary" className="w-100">
                                  <FormattedMessage id="Button.AssignGoalTo" />
                                </Button>
                              }
                            >
                              <div id="filter-wrapper" className="filter-main">
                                <Col sm={12}>
                                  <div className="d-flex justify-content-between align-items-center mb-4">
                                    <h4 className="mb-0">
                                      <FormattedMessage id="Hd.Users" />
                                    </h4>
                                  </div>
                                  <Col className="form-sm">
                                    <Search
                                      value={searchTextUserList}
                                      onChange={handleSearchChangeUserList}
                                      onKeyDown={handleSearchKeyDownUserList}
                                      onClick={handleSearchClickUserList}
                                      placeholder={useIntlMessages('PH.SearchName')}
                                      size="sm"
                                    />
                                  </Col>
                                  {!getGoalDetailAPiData.IsGeneral && (
                                    <Col>
                                      <div className="form-sm d-flex">
                                        <Col className="pe-2">
                                          <Select
                                            label={useIntlMessages('Label.Category')}
                                            options={categoryList}
                                            value={selectedCategoryUserList}
                                            placeholder={useIntlMessages('PH.Select')}
                                            isDisabled={true}
                                          />
                                        </Col>
                                        <Col className="ps-2">
                                          <Select
                                            label={useIntlMessages('Label.SubCategory')}
                                            id="ClassID"
                                            name="ClassID"
                                            options={subCategoryListBasedOnGoal}
                                            value={selectedSubCategoryUserList}
                                            placeholder={useIntlMessages('PH.Select')}
                                            onChange={(e: any) => handleSubCategoryChangeUserList(e)}
                                            isMulti
                                          />
                                        </Col>
                                      </div>
                                    </Col>
                                  )}
                                  <Col>
                                    <div className="custom-calendar mb-4 form-sm">
                                      <label className="form-label">
                                        <FormattedMessage id="Label.DateofJoining" />
                                      </label>
                                      <DatePicker selected={joiningDate} onChange={(date) => handleJoiningDateChange(date)} onKeyDown={(e) => e.preventDefault()} />
                                    </div>
                                  </Col>
                                  <InfiniteScroll
                                    dataLength={items?.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                    loader={items?.length > 0 && <h3>{labelLoading}</h3>}
                                    height={300}
                                    endMessage={<EndMessage />}
                                  >
                                    <Table striped hover responsive size="sm" className="mt-2">
                                      <thead>
                                        <tr>
                                          <th className="w-50px">
                                            <div className="no-margin">
                                              <Checkbox
                                                disabled={items && items.length > 0 ? false : true}
                                                type="Checkbox"
                                                checked={isAllSelected}
                                                onChange={handleSelectAllCheckboxClick}
                                              />
                                            </div>
                                          </th>
                                          <th>
                                            <FormattedMessage id="Label.Name" />{' '}
                                            <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                                              <Sort />
                                            </Link>
                                          </th>
                                          <th className="w-75px"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {items &&
                                          items?.length > 0 &&
                                          items.map((user: any, index: number) => (
                                            <tr key={index}>
                                              <td>
                                                <div className="no-margin">
                                                  <Checkbox
                                                    checked={selectedUserList.map((user: any) => user.id).includes(user.UserId)}
                                                    name={'overlay1checkbox_' + user.UserId}
                                                    id={'overlay1checkbox_' + user.UserId}
                                                    type="Checkbox"
                                                    onChange={handleUserSelectCheckboxClick}
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                <div className="d-flex align-items-center">
                                                  <span className="td-user-image">
                                                    <img src={user?.ProfileImage ? BUCKET_URL + user?.ProfileImage : UserImg} className="w-100" alt="user" />
                                                  </span>
                                                  {user.Name}
                                                </div>
                                              </td>
                                              <td>
                                                <FormattedMessage id="Label.Active" />
                                              </td>
                                            </tr>
                                          ))}
                                      </tbody>
                                    </Table>
                                  </InfiniteScroll>
                                  {/* </Scrollbars> */}
                                </Col>
                                <Row className="mt-4 pb-4">
                                  <Col>
                                    <Button variant="outline-primary" className="w-100" onClick={() => handleFilterResetUserList()}>
                                      <FormattedMessage id="Button.Cancel" />
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Button variant="primary" className="w-100" onClick={() => handleAssignUserSelectButtonClick()}>
                                      <FormattedMessage id="Button.Select" /> ({selectedUserList.length}/{totalItems})
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Filter>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={4} lg={12} md={12} className="mb-4">
                      <label>{useIntlMessages('Label.CreatedBy')}</label>
                      <div className="d-flex">
                        <div className="name-desi-sec">
                          <img src={UserImg} alt="" />
                        </div>
                        <div className="creater-name-sec">
                          <h6 className="mb-1">{getGoalDetailAPiData?.CreatedByDetails?.created_by}</h6>
                          <p className="teach-desi">{getGoalDetailAPiData?.CreatedByDetails?.created_by_role}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <div>
                  <h6 className="pb-3">Resources</h6>
                  <Slider {...settings}>
                    {getGoalResourceApiData?.Resources?.map((filedetails: any, index: number) => {
                      if (index < 9) {
                        return (
                          <div key={filedetails.ResourceID} className="slide-box">
                            <img
                              src={filedetails && filedetails.ResourceThumbnail ? BUCKET_URL + filedetails.ResourceThumbnail : Default}
                              alt={filedetails.ResourceName}
                              onClick={() =>
                                navigate(`/resourceDetails/${filedetails.ResourceID}`, {
                                  state: {
                                    tabId: 3,
                                    goalId: goalId,
                                  },
                                })
                              }
                            />
                            <div className="slid-name">
                              <p className="text-capitalize">{filedetails.ResourceName}</p>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div key="default" className="slide-box">
                            <img src={MoreImg} onClick={() => navigate(`/goalResourcesViewall/${goalId}`)} />
                            <div className="slid-name">
                              <p>
                                <FormattedMessage id="Link.ViewAll" />
                              </p>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </Slider>
                </div>
                <div className="calendar-main">
                  <SelectableCalendar />
                </div>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {listGoalCompetenciesApiData && listGoalCompetenciesApiData.Competencies && listGoalCompetenciesApiData.Competencies.length > 0 && (
          <div className="mb-4">
            <h6 className="pb-2">Competencies selected for this goal</h6>
            <Accordion defaultActiveKey="0">
              {listGoalCompetenciesApiData.Competencies.map((competency: any) => {
                return (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <h6>
                        {competency.CompentancyName} - {competency.Count}
                      </h6>
                    </Accordion.Header>
                    <Accordion.Body>
                      {competency?.Options?.map((option: any) => {
                        return <CompetenciesCard count={option.Count} competencies={option.CompetencyOption} />;
                      })}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        )}
        <h6 className="pb-2">Milestone</h6>
        <div className="content-sub">
          <div className="content-sub-header header-light-bg d-flex justify-content-between align-items-center">
            <Col>
              <h6 className="m-0">Weekly Milestone(02/15/24)</h6>
            </Col>
            <Col md="auto" className="btn-container d-flex">
              <Button
                variant="secondary"
                size="sm"
                // className="icon-btn"
              >
                View Details
              </Button>
            </Col>
          </div>
          <div className="content-area-padding">
            <Table striped hover responsive className="min-w-table">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="Label.Task/EventName" />
                    <Link to="" className="ms-1" onClick={() => handleSortFieldChange(GoalTaskEventListSortField.NAME)}>
                      <Sort />
                    </Link>
                  </th>
                  <th>
                    <FormattedMessage id="Label.Category" />
                    <Link to="" className="ms-1" onClick={() => handleSortFieldChange(GoalTaskEventListSortField.CATEGORY)}>
                      <Sort />
                    </Link>
                  </th>
                  <th>
                    <FormattedMessage id="Label.SubCategories" />
                    <Link to="" className="ms-1"></Link>
                  </th>
                  <th>
                    <FormattedMessage id="Label.CreatedBy" />
                    <Link to="" className="ms-1" onClick={() => handleSortFieldChange(GoalTaskEventListSortField.CREATED_BY)}>
                      <Sort />
                    </Link>
                  </th>
                  <th>
                    <FormattedMessage id="Label.Status" />
                    <Link to="" className="ms-1"></Link>
                  </th>
                  <th className="w-130px"></th>
                </tr>
              </thead>
              <tbody>
                {listGoalTasksEventsApiData &&
                  listGoalTasksEventsApiData.TasksAndEvents &&
                  listGoalTasksEventsApiData.TasksAndEvents.length > 0 &&
                  listGoalTasksEventsApiData.TasksAndEvents.map((item: any) => (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="me-2 task-event-img">{item.Type == 'task' ? <Task /> : <Event />}</span>
                          <label className="w-180px">{item.Name}</label>
                        </div>
                      </td>
                      <td>{item.Category}</td>
                      <td>
                        {item.SubCategories?.map((subCategory: any, index: number) => (
                          <>
                            {subCategory.classname}
                            {index !== item.SubCategories?.length - 1 && ', '}
                          </>
                        ))}
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="td-user-image">
                            <img src={UserImg} className="w-100" alt="user" />
                          </span>
                          <label>
                            {item.CreatedBy}
                            <span className="d-block font-xs">{item.CreatedUserRole}</span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center multiple-user">
                          <span>
                            <label className="td-progressbar">
                              <span className="inprogress">{item.Status}</span>
                            </label>
                          </span>
                        </div>
                      </td>
                      <td>
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={() =>
                            navigate('/taskdetails', {
                              state: {
                                goalId: item.GoalID,
                                assignmentId: item.ID,
                                assignmentType: item.Type,
                                isViewMode: isViewMode,
                                goalName: getGoalDetailAPiData?.GoalName,
                                reviewUsers: getGoalDetailAPiData?.ReviewUserRoleTypeId,
                              },
                            })
                          }
                        >
                          View Details
                        </Button>
                      </td>
                    </tr>
                  ))}
                {!listGoalTasksEventsApiData ||
                  (listGoalTasksEventsApiData && !listGoalTasksEventsApiData.TasksAndEvents && (
                    <tr>
                      <td colSpan={8}>
                        <div className="content-sub content-area-padding border-top text-center">
                          <h6>{noData}</h6>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {listGoalTasksEventsApiData &&
              listGoalTasksEventsApiData.TasksAndEvents &&
              listGoalTasksEventsApiData.TasksAndEvents.length > 0 &&
              listGoalTasksEventsApiData.TotalCount > PAGE_SIZE && (
                <Pagination
                  pageSize={PAGE_SIZE}
                  totalitems={listGoalTasksEventsApiData.TotalCount}
                  pageLimit={PAGE_SIZE}
                  setCurrentPage={(page: number) => setCurrentPage(page)}
                  currentPage={currentPage - 1}
                  prevPage={-1}
                  itemsDisplayed={listGoalTasksEventsApiData?.TasksAndEvents?.length}
                />
              )}
          </div>
        </div>
        <DeleteConfirmPopup
          show={isShowDeleteConfirmPopup}
          handleClose={() => setIsShowDeleteConfirmPopup(false)}
          onCancel={() => setIsShowDeleteConfirmPopup(false)}
          onOkay={() => handleDeleteGoalClick()}
          title="Delete Goal ?"
          content="Are you sure you want to delete this goal?"
        />
        <DeleteConfirmPopup
          show={isShowRemoveAssigneeConfirmPopup}
          handleClose={() => setIsShowRemoveAssigneeConfirmPopup(false)}
          onCancel={() => setIsShowRemoveAssigneeConfirmPopup(false)}
          onOkay={() => handleDeleteButtonClick()}
          title="Remove Assignee(s)?"
          content="Are you sure you want to proceed?"
        />
      </div>
      {(deleteGoalApiLoading ||
        listGoalTasksEventsApiLoading ||
        getTaskDetailsApiLoading ||
        getEventDetailsApiLoading ||
        editTaskApiLoading ||
        editEventApiLoading ||
        addTaskApiLoading ||
        addEventApiLoading) && <Loader />}
    </>
  );
};
export default GoalsDetail;
