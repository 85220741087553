import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, Col } from 'react-bootstrap';
import '../assets/css/AttachmentmentCard.scss';
import { sendMailContactAdmin } from '../store/actions/dashboardActions';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';
import Loader from './Loader';
import { useIntlMessages } from '../utils/helper';
import { MessageToaster } from '../utils/ToastUtil';
import Input from './MAInput';
import { NumberValues } from '../utils/enums';
import { IFeedbackFormApiParams } from '../interfaces/FeedbackInterface';
import { FEEDBACK_SCHEMA } from '../validations/feedbackSchema';
import { validateForm } from '../utils/formValidation';
import { resetContactAdminMessage } from '../store/slices/dashboardSlice';
import useAutoFocus from '../hooks/useAutoFocus';
// Declare default params
const savedefaultRequestParams: IFeedbackFormApiParams = {
  ContactAdminSubject: '',
  ContactAdminMessage: '',
  Type: 1,
};

const Feedback = (props: any) => {
  // Message toast object creation
  const toastObj = new MessageToaster();
  const inputRef = useAutoFocus();
  // Action dispatch object creation
  const dispatch = useAppDispatch(); // accessing redux state variables

  // Accessing redux state variables
  const { contactAdminMessageApiLoading, contactAdminMessageApiResponseCode, contactAdminMessageApiResponseMessage, contactAdminMessageApiSuccess } = useAppSelector(
    (state: RootState) => state.dashboard,
  );

  // Component state values
  const [feedbackForm, setFeedbackForm] = useState<IFeedbackFormApiParams>(savedefaultRequestParams);
  const [characterCount, setCharacterCount] = useState<number>(0);
  const [errorFields, setErrorFields] = useState<any>({});
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');

  useEffect(() => {
    try {
      setTimeout(() => {
        if (inputRef?.current && props.show) {
          inputRef.current.focus();
        }
      }, 1000);
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [props]);

  // works on unmounting.
  useEffect(() => {
    try {
      setFeedbackForm((params: any) => ({
        ...params,
        Type: props.type,
      }));
      return () => {
        dispatch(resetContactAdminMessage());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // Handle send mail Success/Failure.
  useEffect(() => {
    try {
      if (contactAdminMessageApiResponseCode > 0 && contactAdminMessageApiSuccess) {
        toastObj.toastSuccess(contactAdminMessageApiResponseMessage);
        setFeedbackForm(savedefaultRequestParams);
        handleHidePopup();
      } else if (contactAdminMessageApiResponseCode > 0 && !contactAdminMessageApiSuccess) {
        toastObj.toastError(contactAdminMessageApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [contactAdminMessageApiLoading]);

  // handle hide popup
  const handleHidePopup = async () => {
    try {
      setErrorFields({});
      setErrorVisible(false);
      props.onClose();
      setFeedbackForm(savedefaultRequestParams);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle form fields validtaion
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      if (name == 'ContactAdminMessage') {
        setCharacterCount(event.target.value.length);
      }
      setFeedbackForm((info: any) => ({
        ...info,
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      CheckValidation(validateObj);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // After upating field, check for validation
  const CheckValidation = async (validateObj: any) => {
    try {
      const errorResult = await validateForm(validateObj, FEEDBACK_SCHEMA, errorFields);
      setErrorFields(errorResult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle reset password button click
  const onSubmit = async () => {
    try {
      setErrorVisible(true);
      let errorresult: any = null;
      errorresult = await validateForm(feedbackForm, FEEDBACK_SCHEMA, errorFields);
      if (Object.keys(errorresult).length === 0) {
        setErrorVisible(false);
        dispatch(sendMailContactAdmin(feedbackForm));
      } else {
        setErrorFields({ ...errorresult });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <>
      <Modal show={props.show} onHide={handleHidePopup} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> {props.type === 1 ? <FormattedMessage id="HD.ContactAdmin" /> : <FormattedMessage id="HD.HelpSupport" />}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <Input
              label={useIntlMessages('Label.Subject')}
              id="ContactAdminSubject"
              name="ContactAdminSubject"
              type="text"
              maxLength={NumberValues.NUM_100}
              value={feedbackForm.ContactAdminSubject}
              onChange={onInputHandleChange}
              errorMessage={errorVisible ? errorFields?.ContactAdminSubject : ''}
              autoFocus={true}
              ref={inputRef}
            />
          </Col>
          <Col lg={12} className="addOrg-textarea">
            <Input
              label={useIntlMessages('Label.Content')}
              id="ContactAdminMessage"
              name="ContactAdminMessage"
              type="textarea"
              maxLength={NumberValues.NUM_1000}
              value={feedbackForm.ContactAdminMessage}
              onChange={onInputHandleChange}
              errorMessage={errorVisible ? errorFields?.ContactAdminMessage : ''}
              as="textarea"
              rows={3}
            />
            <span className="txt-count">
              {characterCount}/{NumberValues.NUM_1000}
            </span>
            <Button className="btn-primary" id="button-addon2" onClick={onSubmit}>
              <FormattedMessage id="Button.Submit" />
            </Button>
          </Col>
        </Modal.Body>
      </Modal>{' '}
      {contactAdminMessageApiLoading && <Loader />}
    </>
  );
};
export default Feedback;
