import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Modal, Button, Col, Form } from 'react-bootstrap';
import { useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import { MessageToaster } from '../../utils/ToastUtil';
import Input from '../../components/MAInput';
import { getReportReasonList, addResourceReport } from '../../store/actions/resourceActions';
import { RootState } from '../../store';
import { resetGetReportReasonList, resetAddResourceReport } from '../../store/slices/resourceSlice';
import { validateForm } from '../../utils/formValidation';
import { RESOURCE_REPORT_SCHEMA } from '../../validations/resourceSchema';
import { IReportResourceDataRequest } from '../../interfaces/ResourceInterface';
import Loader from '../../components/Loader';
import { useNavigate, useLocation } from 'react-router-dom';
const Report = (props: any) => {
  // Navigation object
  const navigate = useNavigate();
  // Location object
  const location = useLocation();
  // Action dispatch object
  const dispatch = useAppDispatch();
  // Message toast object creation
  const toastObj = new MessageToaster();
  const savedefaultRequestParams: IReportResourceDataRequest = {
    ResourceID: props?.resourceId,
    ReportedBy: props?.reportedBy,
    ReportReason: 0,
    Comments: '',
  };

  const {
    getReportReasonListApiLoading,
    getReportReasonListApiSuccess,
    getReportReasonListApiData,
    addResourceReportApiLoading,
    addResourceReportApiSuccess,
    addResourceReportApiData,
  } = useAppSelector((state: RootState) => state.resource);
  const [reportForm, setReportForm] = useState<IReportResourceDataRequest>(savedefaultRequestParams);
  const [errorFields, setErrorFields] = useState<any>({});
  const ResourceReportAddSuccess = useIntlActionMessages('ResourceReport.Add.Success');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // works on initial rendering
  useEffect(() => {
    try {
      dispatch(getReportReasonList({}));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // works on unmounting.
  useEffect(() => {
    try {
      return () => {
        dispatch(resetGetReportReasonList());
        dispatch(resetAddResourceReport());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  useEffect(() => {
    try {
      if (addResourceReportApiSuccess && addResourceReportApiData !== null) {
        toastObj.toastSuccess(ResourceReportAddSuccess);
        handleHidePopup();
        if (location?.state?.tabId === 0) {
          navigate('/resources', { state: { tabId: 0 } });
        } else if (location?.state?.tabId === 1) {
          navigate('/resources', { state: { tabId: 1 } });
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [addResourceReportApiLoading]);

  const handleShowPopup = async () => {
    try {
      setErrorFields({});
      setReportForm((reportForm) => ({
        ...reportForm,
        ReportReason: 0,
        Comments: '',
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const handleHidePopup = async () => {
    try {
      props.onClose();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Permission Radio Group change
  const handleReportRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      setReportForm((reportForm) => ({
        ...reportForm,
        ReportReason: parseInt(value),
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      setReportForm((info: any) => ({
        ...info,
        [name]: value,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const onSubmit = async () => {
    try {
      setErrorFields({});
      let errorresult: any = null;
      errorresult = await validateForm(reportForm, RESOURCE_REPORT_SCHEMA, errorFields);
      if (Object.keys(errorresult).length === 0) {
        dispatch(addResourceReport(reportForm));
      } else {
        setErrorFields({ ...errorresult });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <>
      <Modal show={props.show} onShow={handleShowPopup} onHide={handleHidePopup} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="mb-3">Why are you reporting this?</h6>
          <small className="error form-text">{errorFields?.ReportReason} </small>
          {getReportReasonListApiSuccess &&
            getReportReasonListApiData.map((item: any) => (
              <Col md={12} className="d-flex">
                <Form.Check
                  className="form-check-report"
                  type="radio"
                  id={`inline-reason-1`}
                  label={item.Reason}
                  name={`rbnResourceReport`}
                  value={item.ReasonID}
                  onChange={handleReportRadioChange}
                />
              </Col>
            ))}

          <Col lg={12} className="addOrg-textarea">
            <Input
              label="Comment"
              id="Comments"
              name="Comments"
              type="textarea"
              placeholder="Add Comment"
              maxLength={300}
              errorMessage={errorFields?.Comments}
              as="textarea"
              rows={3}
              onChange={onInputHandleChange}
              value={reportForm?.Comments}
            />
            <Button className="btn-primary" id="button-addon2" onClick={onSubmit}>
              Submit
            </Button>
          </Col>
        </Modal.Body>
      </Modal>{' '}
      {getReportReasonListApiLoading && <Loader />}
    </>
  );
};
export default Report;
