/**
 * @file   src\containers\organizations\SurveyDetails.tsx
 * @brief  Survey details page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages, getFromLocalStorage, useIntlActionMessages } from '../../utils/helper';
import { Col, Breadcrumb, Accordion, Nav, NavDropdown, Row, ProgressBar, Button, Table, Tabs, Tab } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { MessageToaster } from '../../utils/ToastUtil';
import Search from '../../components/MASearch';
import { end } from '@popperjs/core';
import More from '../../assets/img/More.svg';
import UserImg from '../../assets/img/Userimg.jpg';
import { Scrollbar } from 'react-scrollbars-custom';
import { slide as Filter } from 'react-burger-menu';
import Close from '../../assets/img/Close.svg';
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import DeleteConfirmPopup from '../../components/DeleteConfirmation';
import {
  getSurveyDetails,
  getAssignmentStudentsList,
  getAssignmentStatusCount,
  getStudentSurveyResponse,
  deleteSurvey,
  listusersassociatedtosurvey,
} from '../../store/actions/surveyAction';
import { resetListUsersAssociatedtoSurveyApi, resetSurveyDetail } from '../../store/slices/surveySlice';
import { resetAssignmentStudentsList, resetDeleteSurvey, resetGetStudentSurveyResponse } from '../../store/slices/surveySlice';
import { PAGE_SIZE, BUCKET_URL, DEFAULT_PAGE_INDEX, DEFAULT_SELECT_OPTION_NUMBER, DATE_TIME_FORMAT_DISPLAY } from '../../utils/constants';
import moment from 'moment';
import { IGetAssignmentStudentsListAPIRequest, IAssignmentStatusCountApiRequest, IStudentSurveyResponseAPIRequest, IStudentDetail } from '../../interfaces/SurveyInterface';
import { ISelectOptionsNumber } from '../../interfaces/GeneralInterface';
import { ASSIGNMENT_STUDENTLIST_TABS, FILE_TYPES_SUPPORTED, RoleTypeIds } from '../../utils/enums';
import SurveyAssignmentStudentList from './SurveyAssignmentStudentList';
import CategoryFilter from './CategoryFilter';
import { getCategoryList } from '../../store/actions/organizationActions';
import downdocicn from '../../assets/img/icn-download-doc.png';
import Default from '../../assets/img/resource_grey.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import EndMessage from '../EndMessage';
import Loader from '../../components/Loader';
import { getUserRolesByOrgType } from '../../store/actions/organizationTypeActions';

// default params
const assignmentUserListAPIDefaultParams: IGetAssignmentStudentsListAPIRequest = {
  SurveyId: 0,
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE,
  Search: '',
  Status: 'NotStarted',
  CategoryId: 0,
  SubCategories: [],
};

const userlistDefaultFilter = {
  Category: 0,
  SubCategories: [],
};
const SurveyDetails = (props: any) => {
  // Create route param object to access the route parameters.
  const params = useParams();
  const surveyId: number = params.surveyId ? Number(params.surveyId) : 0;
  // Route navigation object creation
  const navigate = useNavigate();
  // Action dispatch object creation
  const dispatch = useAppDispatch();
  // Message toaster object creation
  const toastObj = new MessageToaster();

  const {
    surveyDetailApiLoading,
    surveyDetailApiSuccess,
    surveyDetailApiData,
    getUsersBySurveyIdApiSuccess,
    getUsersBySurveyIdApiLoading,
    getUsersBySurveyIdApiData,
    getAssignmentStudentsListApiSuccess,
    getAssignmentStudentsListApiLoading,
    getAssignmentStudentsListApiData,
    getAssignmentStatusCountApiLoading,
    getAssignmentStatusCountApiData,
    getStudentSurveyResponseApiSuccess,
    getStudentSurveyResponseApiResponseCode,
    getStudentSurveyResponseApiLoading,
    getStudentSurveyResponseApiData,
    deleteSurveyApiSuccess,
    deleteSurveyApiResponseCode,
    deleteSurveyApiResponseMessage,
    deleteSurveyApiLoading,
    listUsersAssociatedtoSurveyApiLoading,
    listUsersAssociatedtoSurveyApiSuccess,
    listUsersAssociatedtoSurveyApiResponseCode,
    listUsersAssociatedtoSurveyApiData,
  } = useAppSelector((state: RootState) => state.survey);

  const { rolesByOrgTypeData } = useAppSelector((state: RootState) => state.organizationType);

  // accessing redux state variables
  const { getCatergoryListAPiData, getCategoryListApiLoading } = useAppSelector((state: RootState) => state.organization);

  // messages from assets file
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  const orgDetail = getFromLocalStorage('ORG_DETAIL');
  const storageData = getFromLocalStorage('MI_USR_DATA');
  const [isShowDeleteConfirmPopup, setIsShowDeleteConfirmPopup] = useState<boolean>(false);
  const listUsersBySurveyIdApiDefaultParams = {
    SurveyId: 0,
    Page: DEFAULT_PAGE_INDEX,
    PageSize: PAGE_SIZE,
  };
  const currentTime = new Date();
  const [isOpenAssignedUsers, setIsOpenAssignedUsers] = useState<boolean>(false);
  const [surveyDetails, setSurveyDetails] = useState<any>({});
  const [confirmedUsers, setConfirmedUsers] = useState<IStudentDetail[]>([]);
  const [assignmentUserListApiParams, setAssignmentUserListApiParams] = useState<IGetAssignmentStudentsListAPIRequest>(assignmentUserListAPIDefaultParams);
  const [isViewMode, setIsViewMode] = useState<boolean>(false);
  const [items, setItems] = useState<any>({});
  const [activeStudentDetails, setActiveStudentDetails] = useState<any>({});
  const [userlistFilter, setUserlistFilter] = useState<any>(userlistDefaultFilter);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOptionsNumber>(DEFAULT_SELECT_OPTION_NUMBER);
  const [selectedSubCategory, setSelectedSubCategory] = useState<ISelectOptionsNumber[]>([]);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [hasAssigneeMore, setHasAssigneeMore] = useState<boolean>(true);
  const [surveyUserListCount, setSurveyUserListCount] = useState<any>({});
  const [searchCompletedText, setSearchCompletedText] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [categoryList, setCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [subCategoryList, setSubCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [viewOnlyReason, setViewOnlyReason] = useState<string>('');
  const [listUsersBySurveyIdApiParams, setlistUsersBySurveyIdApiParams] = useState<any>(listUsersBySurveyIdApiDefaultParams);
  const noPermissionMessage = useIntlActionMessages('Form.authorization');
  const activeSurveyCannotEditDeleteMessage = useIntlActionMessages('Text.ActiveSurvey.CannotEditDelete');
  const labelLoading = useIntlActionMessages('Label.Loading');

  // Reset survey detail
  useEffect(() => {
    try {
      return () => {
        dispatch(resetSurveyDetail());
        dispatch(resetListUsersAssociatedtoSurveyApi());
        dispatch(resetGetStudentSurveyResponse());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // Load survey detail
  useEffect(() => {
    try {
      if (orgDetail?.OrganizationTypeId !== '') {
        dispatch(
          getUserRolesByOrgType({
            OrganizationTypeId: orgDetail?.OrganizationTypeId,
          }),
        );
      }
      dispatch(getCategoryList({ OrganizationId: orgDetail?.OrganizationId }));
      setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
        ...assignmentUserListApiParams,
        SurveyId: surveyId,
      }));
      dispatch(getSurveyDetails({ SurveyId: surveyId }));
      const statusCountRequestBody: IAssignmentStatusCountApiRequest = {
        SurveyId: surveyId,
      };
      dispatch(getAssignmentStatusCount(statusCountRequestBody));
      setlistUsersBySurveyIdApiParams((apiParams: any) => ({
        ...apiParams,
        SurveyId: surveyId,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // Get category details by organization
  useEffect(() => {
    try {
      if (getCatergoryListAPiData && getCatergoryListAPiData.length > 0) {
        const categoryData = getCatergoryListAPiData.map(
          (category: any): ISelectOptionsNumber => ({
            label: category.CategoryName,
            value: +category.CategoryId,
          }),
        );
        setCategoryList(categoryData);
      } else {
        // If ResponseData is null, update category state with null
        setCategoryList([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getCategoryListApiLoading]);

  // Bind survey details
  useEffect(() => {
    try {
      if (surveyDetailApiSuccess && surveyDetailApiData !== null) {
        const startDateObj = new Date(surveyDetailApiData.StartDate);
        const endDateObj = new Date(surveyDetailApiData.EndDate);
        const surveyDetails = {
          Id: surveyDetailApiData.SurveyId,
          Title: surveyDetailApiData.Title,
          Description: surveyDetailApiData.Description,
          FormTitle: surveyDetailApiData.FormTitle,
          CategoryId: surveyDetailApiData.CategoryId,
          CategoryName: surveyDetailApiData.CategoryName,
          SubCategoryName: surveyDetailApiData.SubCategoryName,
          StartDate: moment(startDateObj).format(DATE_TIME_FORMAT_DISPLAY),
          EndDate: moment(endDateObj).format(DATE_TIME_FORMAT_DISPLAY),
          Status: surveyDetailApiData.Status,
          SurveyCompletedPercentage: surveyDetailApiData.SurveyCompletedPercentage,
          CreatedUser: surveyDetailApiData.CreatedUser,
          AssignedUsersCount: surveyDetailApiData.AssignedUsersCount,
          CreatedUserImage: surveyDetailApiData.CreatedUserImage,
          AssignedUsersImages: surveyDetailApiData.AssignedUsersImages,
          SubCategories: surveyDetailApiData?.SubCategoryName?.map((classItem: any, index: number) => (
            <span key={index}>
              {classItem.classname}
              {index !== surveyDetailApiData?.SubCategoryName?.length - 1 && ', '}
            </span>
          )),
          KudosPoint: surveyDetailApiData.KudosPoint,
          CreatedUserName: surveyDetailApiData.CreatedUserName,
          CreatedUserRoleName: surveyDetailApiData.CreatedUserRoleName,
        };
        setSurveyDetails(surveyDetails);
        setIsViewMode(false);
        const startdate = moment(surveyDetailApiData?.StartDate).toDate();
        if (surveyDetails?.CreatedUser?.toString() != storageData?.UserId) {
          setIsViewMode(true);
          setViewOnlyReason(noPermissionMessage);
        } else if (startdate <= currentTime) {
          setIsViewMode(true);
          setViewOnlyReason(activeSurveyCannotEditDeleteMessage);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [surveyDetailApiLoading]);

  // Survey count
  useEffect(() => {
    try {
      if (getAssignmentStatusCountApiData) {
        const userCount = {
          CompletedCount: getAssignmentStatusCountApiData.CompletedCount,
          NotStartedCount: getAssignmentStatusCountApiData.NotStartedCount,
        };
        setSurveyUserListCount(userCount);
      } else {
        setSurveyUserListCount([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getAssignmentStatusCountApiLoading]);

  // Set assigned users associated with survey while backward navigation
  useEffect(() => {
    try {
      if (assignmentUserListApiParams.SurveyId > 0) {
        dispatch(getAssignmentStudentsList(assignmentUserListApiParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [assignmentUserListApiParams]);

  // Set assigned users associated with survey
  useEffect(() => {
    try {
      if (getUsersBySurveyIdApiSuccess && getUsersBySurveyIdApiData) {
        const usersSelected: IStudentDetail[] = [];
        getUsersBySurveyIdApiData?.Users?.forEach((value: any) => {
          usersSelected.push({ id: value.UserId, name: value.UserName });
        });
        setConfirmedUsers([...usersSelected]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getUsersBySurveyIdApiLoading]);

  // List users
  useEffect(() => {
    try {
      if (listUsersBySurveyIdApiParams.SurveyId != 0) {
        dispatch(listusersassociatedtosurvey(listUsersBySurveyIdApiParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listUsersBySurveyIdApiParams]);

  // Set assigned users associated with survey while backward navigation
  useEffect(() => {
    try {
      if (listUsersAssociatedtoSurveyApiSuccess && listUsersAssociatedtoSurveyApiResponseCode > 0) {
        if (listUsersAssociatedtoSurveyApiSuccess && listUsersAssociatedtoSurveyApiData?.Users?.length > 0 && listUsersAssociatedtoSurveyApiData?.TotalCount > 0) {
          if (params.surveyId || props.surveyId) {
            const usersSelected: IStudentDetail[] = [];
            listUsersAssociatedtoSurveyApiData?.Users?.forEach((value: any) => {
              usersSelected.push({ id: value.UserId, name: value.UserName, image: value.Image });
            });
            if (listUsersBySurveyIdApiParams.Page > 1) {
              setConfirmedUsers(() => [...confirmedUsers, ...usersSelected]);
            } else {
              setConfirmedUsers(usersSelected);
            }
            const bindedUsers = confirmedUsers?.length + usersSelected.length;
            if (bindedUsers != listUsersAssociatedtoSurveyApiData?.TotalCount) {
              setHasAssigneeMore(usersSelected.length > 0 && usersSelected.length === listUsersBySurveyIdApiParams.PageSize);
            } else {
              setHasAssigneeMore(false);
            }
          }
        } else {
          setHasAssigneeMore(false);
          setConfirmedUsers([]);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listUsersAssociatedtoSurveyApiLoading]);

  // handle assignment studentlist api response
  useEffect(() => {
    try {
      if (getAssignmentStudentsListApiSuccess) {
        if (getAssignmentStudentsListApiSuccess && getAssignmentStudentsListApiData?.List?.length > 0 && getAssignmentStudentsListApiData?.TotalCount > 0) {
          const newItems = getAssignmentStudentsListApiData?.List;
          if (assignmentUserListApiParams.Page > 1) {
            setItems(() => [...items, ...newItems]);
          } else {
            setItems(newItems);
          }
          if (items.length != getAssignmentStudentsListApiData?.TotalCount) {
            setHasMore(newItems.length > 0 && newItems.length === assignmentUserListApiParams.PageSize);
          } else {
            setHasMore(false);
          }
          const updatedUserInfo = getAssignmentStudentsListApiData?.List.filter((item: any) => activeStudentDetails.UserID == item.UserID);
          setActiveStudentDetails(updatedUserInfo);
        } else {
          setHasMore(false);
          setItems({});
          setActiveStudentDetails({});
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getAssignmentStudentsListApiLoading]);

  // Delete survey Success
  useEffect(() => {
    try {
      if (deleteSurveyApiSuccess && deleteSurveyApiResponseCode > 0) {
        dispatch(resetDeleteSurvey());
        toastObj.toastSuccess(deleteSurveyApiResponseMessage);
        navigate('/activities', {
          state: {
            tabId: 2,
            organizationId: orgDetail.OrganizationId,
            parentPage: 'orgview',
          },
        });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [deleteSurveyApiLoading]);

  const handleAssignedUsersClick = () => {
    try {
      setIsOpenAssignedUsers(true);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Assignes users overlay close.
  const handleAssignedUsersClose = () => {
    try {
      setIsOpenAssignedUsers(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle category change filter student list
  const handleCategoryChange = (event: any) => {
    try {
      const selectedCategoryId = event.value;
      setSelectedCategory(event);
      const category = getCatergoryListAPiData.find((category: any) => category.CategoryId === selectedCategoryId.toString());
      if (category) {
        const subcategories = category.Subcategory.map((subcategory: any) => ({
          label: subcategory.classname,
          value: subcategory.classid,
        }));
        setSubCategoryList(subcategories);
        setSelectedSubCategory([]);
      } else {
        setSubCategoryList([]);
      }
      setUserlistFilter((info: any) => ({
        ...info,
        Category: selectedCategoryId,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Fetching next page items
  const fetchMoreAssignedUsers = () => {
    try {
      setlistUsersBySurveyIdApiParams((apiParams: any) => ({
        ...apiParams,
        Page: apiParams.Page + 1,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Cancel button click
  const handleCancelButtonClick = () => {
    try {
      setIsOpenAssignedUsers(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Reset values when tabs change
  const handleTabChange = (key: string) => {
    try {
      dispatch(resetGetStudentSurveyResponse());
      dispatch(resetAssignmentStudentsList());
      setItems({});
      setActiveStudentDetails({});
      setSearchCompletedText('');
      setSearchText('');
      setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
        ...assignmentUserListApiParams,
        Status: key,
        Page: DEFAULT_PAGE_INDEX,
        PageSize: PAGE_SIZE,
        Search: '',
        CategoryId: 0,
        SubCategories: [],
      }));
      handleFilterReset();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle seach box change
  const handleCompletedSearchChange = (event: any) => {
    try {
      setSearchCompletedText(event.target.value);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Search Field input key down.
  const handleCompletedSearchKeyDown = (event: any) => {
    try {
      if (event.key === 'Enter') {
        event.preventDefault();
        setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
          ...assignmentUserListApiParams,
          Search: searchCompletedText,
          Page: DEFAULT_PAGE_INDEX,
        }));
      } else {
        setSearchCompletedText(event.target.value);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle seach icon click
  const handleCompletedSearchClick = () => {
    try {
      setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
        ...assignmentUserListApiParams,
        Search: searchCompletedText,
        Page: 0,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle seach box change
  const handleSearchChange = (event: any) => {
    try {
      setSearchText(event.target.value);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Search Field input key down.
  const handleSearchKeyDown = (event: any) => {
    try {
      if (event.key === 'Enter') {
        event.preventDefault();
        setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
          ...assignmentUserListApiParams,
          Search: searchText,
          Page: DEFAULT_PAGE_INDEX,
        }));
      } else {
        setSearchText(event.target.value);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle seach icon click
  const handleSearchClick = () => {
    try {
      setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
        ...assignmentUserListApiParams,
        Search: searchText,
        Page: 0,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Task Event List Filter click.
  const handleFilterClick = () => {
    try {
      setIsOpenFilter(true);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Task Event List Filter close.
  const handleFilterClose = () => {
    try {
      setIsOpenFilter(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle sub category change filter student list
  const handleSubCategoryChange = (event: any) => {
    try {
      if (event) {
        const subcategories = event.map((subcategory: any) => subcategory.value);
        setSelectedSubCategory(event);
        setUserlistFilter((info: any) => ({
          ...info,
          SubCategories: subcategories,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle apply filter survey student list
  const handleApplyFilter = () => {
    try {
      setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
        ...assignmentUserListApiParams,
        CategoryId: userlistFilter.Category,
        SubCategories: userlistFilter.SubCategories,
        Page: DEFAULT_PAGE_INDEX,
        PageSize: PAGE_SIZE,
      }));
      setIsFilterApplied(true);
      setIsOpenFilter(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle reset filter student list
  const handleFilterReset = () => {
    try {
      setUserlistFilter((info: any) => ({
        ...info,
        Category: 0,
        SubCategories: [],
      }));
      setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
        ...assignmentUserListApiParams,
        CategoryId: 0,
        SubCategories: [],
        Page: DEFAULT_PAGE_INDEX,
        PageSize: PAGE_SIZE,
      }));
      setIsFilterApplied(false);
      setSelectedCategory(DEFAULT_SELECT_OPTION_NUMBER);
      setSelectedSubCategory([]);
      setIsOpenFilter(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Fetching next page items
  const fetchMoreData = () => {
    try {
      setAssignmentUserListApiParams((assignmentUserListApiParams: any) => ({
        ...assignmentUserListApiParams,
        Page: assignmentUserListApiParams.Page + 1,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle student assignment detail click.
  const handlestudentDetail = (studentInfo: any) => {
    try {
      dispatch(resetGetStudentSurveyResponse());
      if (assignmentUserListApiParams.Status == 'Completed') {
        setActiveStudentDetails(studentInfo);
        if (studentInfo.assignmentdetailid > 0 || studentInfo.SurveyAssignId > 0) {
          const surveyResponseRequestBody: IStudentSurveyResponseAPIRequest = {
            SurveyId: studentInfo.SurveyId,
            UserID: studentInfo.UserID,
          };
          dispatch(getStudentSurveyResponse(surveyResponseRequestBody));
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Bind student survey response - question documents
  const showQuestionSupportingDoc = (data: any) => {
    try {
      if (data?.QuestionSupportingDocPath !== '') {
        const filepath = data?.QuestionSupportingDocPath;
        const fileExt = data?.QuestionSupportingDocDisplayName.split('.').pop();
        if (FILE_TYPES_SUPPORTED.document.includes(fileExt)) {
          return (
            <div>
              <a href={BUCKET_URL + filepath} target="_blank" rel="noopener noreferrer" className="">
                <img src={downdocicn} alt="" />
                &nbsp;&nbsp;{data?.QuestionSupportingDocDisplayName}
              </a>
            </div>
          );
        } else if (FILE_TYPES_SUPPORTED.image.includes(fileExt)) {
          return (
            <div>
              <img width="100%" src={BUCKET_URL + filepath} alt="" />
              <label className="mt-2 mb-4 d-block">{data?.QuestionSupportingDocDisplayName}</label>
            </div>
          );
        } else if (FILE_TYPES_SUPPORTED.video.includes(fileExt)) {
          return (
            <div>
              <ReactPlayer url={BUCKET_URL + filepath} controls width="100%" className="att-img-vid" />
              <label className="mt-2">{data?.QuestionSupportingDocDisplayName}</label>
            </div>
          );
        } else if (FILE_TYPES_SUPPORTED.audio.includes(fileExt)) {
          return (
            <div>
              <ReactAudioPlayer src={BUCKET_URL + filepath} controls />
              <label className="mt-2">{data?.QuestionSupportingDocDisplayName}</label>
            </div>
          );
        }
      } else {
        return <div></div>;
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Bind student survey response - answer documents
  const showAnswerSupportingDoc = (data: any) => {
    try {
      const isAnswerList = Array.isArray(data?.Answer);
      if (isAnswerList === true && data?.Answer[0]?.path !== '') {
        const filepath = data?.Answer[0]?.attachment;
        const fileExt = data?.Answer[0]?.name.split('.').pop();
        if (FILE_TYPES_SUPPORTED.document.includes(fileExt)) {
          return (
            <div className="mt-2 mb-2">
              <a id={data?.Answer[0]?.id} href={BUCKET_URL + filepath} target="_blank" rel="noopener noreferrer" className="" download={data?.Answer[0]?.name}>
                <img src={downdocicn} alt="" />
                &nbsp;&nbsp;{data?.Answer[0]?.name}
              </a>
            </div>
          );
        } else if (FILE_TYPES_SUPPORTED.image.includes(fileExt)) {
          return (
            <div className="mt-2 mb-2">
              <img src={BUCKET_URL + filepath} alt="" />
              <label className="mt-2 mb-4 d-block">{data?.Answer[0]?.name}</label>
            </div>
          );
        } else if (FILE_TYPES_SUPPORTED.video.includes(fileExt)) {
          return (
            <div className="mt-2 mb-2">
              <ReactPlayer url={BUCKET_URL + filepath} controls width="100%" className="att-img-vid" />
              <label className="mt-2">{data?.Answer[0]?.name}</label>
            </div>
          );
        } else if (FILE_TYPES_SUPPORTED.audio.includes(fileExt)) {
          return (
            <div className="mt-2 mb-2">
              <ReactAudioPlayer src={BUCKET_URL + filepath} controls />
              <label className="mt-2">{data?.Answer[0]?.name}</label>
            </div>
          );
        }
      }
    } catch (error) {
      console.log('Error: ', error);
      return <></>;
    }
  };

  // Format Question Answer
  const formatAnswers = (data: any) => {
    try {
      let answerOlItem = '';
      const response = data?.Answer.map((answer: any) => {
        if (answerOlItem.length > 0) answerOlItem = ', ' + answer.name;
        else answerOlItem = answer.name;
        return answerOlItem;
      });
      return response;
    } catch (error) {
      console.log('Error: ', error);
      return '';
    }
  };

  // Bind Question Answer
  const bindQuestionAnswer = (_data: any) => {
    try {
      const data = _data.QuestionAndAnswers.map((data: any, index: any) => {
        const isAnswerList = Array.isArray(data?.Answer);
        if (isAnswerList == true && data?.Answer[0]?.fileType !== undefined) {
          return (
            <Col className="mb-4">
              <h6 className="mb-3">
                Q{index + 1} - {data.Question}
              </h6>
              {showQuestionSupportingDoc(data)}
              <h6 className="mt-2 mb-1">Response from {getTabLabel(RoleTypeIds.APP_USER)}</h6>
              {showAnswerSupportingDoc(data)}
            </Col>
          );
        } else if (data?.Answer?.name?.length > 0) {
          return (
            <div className="questionanswer">
              <h6>
                Q{index + 1} - {data.Question}
              </h6>
              {showQuestionSupportingDoc(data)}
              <div className="d-flex">
                {' '}
                <span className="pe-2">Answer. </span>
                <p>{data?.Answer?.name}</p>
              </div>
            </div>
          );
        } else {
          let answerOlItem;
          if (isAnswerList == true) {
            answerOlItem = (
              <div className="d-flex">
                {' '}
                <span className="pe-2">Answer. </span>
                <p>{formatAnswers(data)}</p>
              </div>
            );
          } else {
            answerOlItem = (
              <div className="d-flex">
                {' '}
                <span className="pe-2">Answer. </span>
                <p>{data?.Answer !== null ? data?.Answer : '-'}</p>
              </div>
            );
          }
          return (
            <div className="questionanswer">
              <h6>
                Q{index + 1} - {data.Question}
              </h6>
              {showQuestionSupportingDoc(data)}
              {answerOlItem}
            </div>
          );
        }
      });
      return data;
    } catch (error) {
      console.log('Error: ', error);
      return <></>;
    }
  };

  // Bind Survey Question Answer Response
  const renderSurveyResponse = (_data: any) => {
    try {
      if (_data.QuestionAndAnswers && _data.QuestionAndAnswers?.length > 0) {
        return (
          <div>
            <h5 className="mt-2 mb-3">{_data?.FormTitle}</h5>
            {bindQuestionAnswer(_data)}
          </div>
        );
      }
    } catch (error) {
      console.log('Error: ', error);
      return <></>;
    }
  };

  // Handle Edit Survey Click
  const handleEditSurveyClick = () => {
    try {
      if (isViewMode) {
        toastObj.toastInfo(viewOnlyReason);
      } else {
        navigate('/assignsurvey', { state: { surveyId: surveyId } });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle delete confirmation
  const ShowDeleteConfirmPopup = (event: any) => {
    try {
      if (isViewMode) {
        event.preventDefault();
        toastObj.toastInfo(viewOnlyReason);
      } else {
        setIsShowDeleteConfirmPopup(true);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Api call to delete survey
  const handleDeleteSurveyClick = () => {
    try {
      const deleteRequestParams = {
        SurveyId: surveyId,
      };
      dispatch(deleteSurvey(deleteRequestParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // get the label for tabs
  const getTabLabel = (userLevel: number) => {
    let tabLabel = '';
    try {
      if (rolesByOrgTypeData.length > 0) {
        const element = rolesByOrgTypeData.filter((role: any) => userLevel === role.RoleTypeID);
        tabLabel = element.length === 1 ? element[0].Name : 'Not found';
      } else {
        tabLabel = 'Not Found';
      }
    } catch (error) {
      console.log('Error: ', error);
    }
    return tabLabel;
  };

  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Hd.SurveyDetails" />
        </h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>
              <FormattedMessage id="Hd.ManageOrganizations" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate('/activities', { state: { tabId: 2 } })}>
              <FormattedMessage id="Hd.Activities" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Hd.SurveyDetails" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="mb-4">
        <div className="org-selected">
          <span className="goal-task-img">
            <img className="thumbnail-image" src={orgDetail?.ImageURL ? BUCKET_URL + orgDetail?.ImageURL : Default} />
          </span>
          <div>
            <h6 className="mb-1">{orgDetail?.Name}</h6>
            <small>{orgDetail?.Address}</small>
          </div>
        </div>
      </div>
      <div className="content-sub">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Nav className="more-dropdown">
              <NavDropdown align={end} className="icon-btn-more" title={<img src={More} alt="more" />}>
                <NavDropdown.Item onClick={() => handleEditSurveyClick()}>
                  <FormattedMessage id="Button.Edit" />
                </NavDropdown.Item>
                <NavDropdown.Item onClick={(e) => ShowDeleteConfirmPopup(e)}>
                  <FormattedMessage id="Button.Delete" />
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Accordion.Header>
              <h6>{surveyDetails.Title}</h6>
            </Accordion.Header>
            <Accordion.Body>
              <label className="font-sm text-secondary mb-2">
                <FormattedMessage id="Label.Description" />
              </label>
              <p>{surveyDetails.Description}</p>
              <Row className="details-view">
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.OrgType" />
                  </label>
                  <p>{orgDetail?.OrganizationType}</p>
                </Col>
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.OrgName" />
                  </label>
                  <p>{orgDetail?.Name}</p>
                </Col>
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.Category" />
                  </label>
                  <p>{surveyDetails?.CategoryName}</p>
                </Col>
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.SubCategory" />
                  </label>
                  <p>{surveyDetails?.SubCategories}</p>
                </Col>
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.KudosPoint" />
                  </label>
                  <p>{surveyDetails?.KudosPoint}</p>
                </Col>
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.StartDateTime" />
                  </label>
                  <p>{surveyDetails.StartDate}</p>
                </Col>
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.EndDateTime" />
                  </label>
                  <p>{surveyDetails.EndDate}</p>
                </Col>
                <Col xl lg={4}>
                  <label>Assignees</label>
                  <Col xs="auto" className="align-items-center d-flex assign-users">
                    <div className="custom-berger-menu p-gray">
                      <Filter
                        onOpen={handleAssignedUsersClick}
                        onClose={handleAssignedUsersClose}
                        isOpen={isOpenAssignedUsers}
                        width={450}
                        right
                        pageWrapId={'filter-wrapper'}
                        outerContainerId={'outer-container'}
                        customCrossIcon={<img src={Close} />}
                        customBurgerIcon={
                          <div className="d-flex align-items-center multiple-user">
                            <span className="td-user-image">
                              <img src={UserImg} className="w-100" alt="user" />
                            </span>
                            <span className="td-user-image">
                              <img src={UserImg} className="w-100" alt="user" />
                            </span>
                            <span className="td-user-image">
                              <img src={UserImg} className="w-100" alt="user" />
                            </span>
                            +{listUsersAssociatedtoSurveyApiData?.TotalCount}{' '}
                            {listUsersAssociatedtoSurveyApiData?.TotalCount == 1 ? <FormattedMessage id="Label.Member" /> : <FormattedMessage id="Label.Members" />}
                          </div>
                        }
                      >
                        <div id="filter-wrapper" className="filter-main">
                          <div>
                            <Table striped hover responsive size="sm" className="mb-0">
                              <thead>
                                <tr>
                                  <th>
                                    <FormattedMessage id="Label.Name" />
                                  </th>
                                </tr>
                              </thead>
                            </Table>
                            <InfiniteScroll
                              dataLength={getAssignmentStudentsListApiData?.TotalCount > 0 ? confirmedUsers?.length : 0}
                              next={fetchMoreAssignedUsers}
                              hasMore={hasAssigneeMore}
                              loader={getAssignmentStudentsListApiData?.TotalCount > 0 && confirmedUsers?.length > 0 && <h3>{labelLoading}</h3>}
                              height={500}
                              endMessage={getAssignmentStudentsListApiData?.TotalCount > 0 && confirmedUsers?.length > 0 && <EndMessage />}
                            >
                              <Table striped hover responsive size="sm">
                                <tbody>
                                  {confirmedUsers &&
                                    confirmedUsers.length > 0 &&
                                    confirmedUsers.map((user: any) => (
                                      <tr>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                              <img src={user.image ? BUCKET_URL + user.image : user.image ? BUCKET_URL + user.image : UserImg} className="w-100" alt="user" />
                                            </span>
                                            {user.name}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </InfiniteScroll>
                          </div>
                          <Row className="mt-4 pb-4">
                            <Col>
                              <Button variant="primary" className="w-100" onClick={() => handleCancelButtonClick()}>
                                <FormattedMessage id="Button.Close" />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Filter>
                    </div>
                  </Col>
                </Col>
                <Col xl lg={4}>
                  <label>
                    <FormattedMessage id="Label.CreatedBy" />
                  </label>
                  <div className="d-flex align-items-center">
                    <span className="td-user-image">
                      <img src={UserImg} className="w-100" alt="user" />
                    </span>
                    <p className="mb-0">
                      {surveyDetails.CreatedUserName}
                      <span className="d-block font-xs">{surveyDetails.CreatedUserRoleName}</span>
                    </p>
                  </div>
                </Col>
                <Col className="d-flex align-items-center">
                  <div className="d-flex align-items-center multiple-user">
                    <ProgressBar now={surveyDetails?.SurveyCompletedPercentage} />{' '}
                    <span className="ps-2">
                      <p className="td-progressbar mb-0">
                        {surveyDetails?.SurveyCompletedPercentage ? surveyDetails?.SurveyCompletedPercentage : '0'}% <span className="inprogress">{surveyDetails?.Status}</span>
                      </p>
                    </span>
                  </div>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="content-sub">
        <div className="content-area-padding">
          <Row>
            <Col xl={6}>
              <Tabs
                defaultActiveKey={surveyUserListCount?.CompletedCount > 0 ? ASSIGNMENT_STUDENTLIST_TABS[0] : ASSIGNMENT_STUDENTLIST_TABS[2]}
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
                onSelect={(key: any) => handleTabChange(key)}
              >
                <Tab eventKey={ASSIGNMENT_STUDENTLIST_TABS[0]} title={'Completed (' + (surveyUserListCount?.CompletedCount ? surveyUserListCount?.CompletedCount : '0') + ')'}>
                  <div className="d-flex mb-3">
                    <Col lg={10} className="form-sm">
                      <Search
                        placeholder="Search with Name"
                        size="sm"
                        onChange={handleCompletedSearchChange}
                        onKeyDown={handleCompletedSearchKeyDown}
                        onClick={handleCompletedSearchClick}
                        value={searchCompletedText ? searchCompletedText : ''}
                      />
                    </Col>
                    <CategoryFilter
                      isOpenFilter={isOpenFilter}
                      handleFilterClick={handleFilterClick}
                      handleFilterClose={handleFilterClose}
                      isFilterApplied={isFilterApplied}
                      handleSubCategoryChange={handleSubCategoryChange}
                      handleCategoryChange={handleCategoryChange}
                      selectedCategory={selectedCategory}
                      selectedSubCategory={selectedSubCategory}
                      categoryList={categoryList}
                      subCategoryList={subCategoryList}
                      handleFilterReset={handleFilterReset}
                      handleApplyFilter={handleApplyFilter}
                    />
                  </div>
                  <div>
                    <SurveyAssignmentStudentList
                      StudentList={items}
                      activeStudentDetails={activeStudentDetails}
                      handlestudentDetail={handlestudentDetail}
                      fetchMoreData={fetchMoreData}
                      hasMore={hasMore}
                      totalCount={getAssignmentStudentsListApiData?.TotalCount}
                    />
                  </div>
                </Tab>
                <Tab
                  disabled={surveyUserListCount?.NotStartedCount > 0 ? false : true}
                  eventKey={ASSIGNMENT_STUDENTLIST_TABS[2]}
                  title={'Not Started (' + (surveyUserListCount?.NotStartedCount ? surveyUserListCount?.NotStartedCount : '0') + ')'}
                >
                  <div className="d-flex mb-3">
                    <Col lg={10} className="form-sm">
                      <Search
                        placeholder={useIntlMessages('PH.SearchName')}
                        size="sm"
                        onChange={handleSearchChange}
                        onKeyDown={handleSearchKeyDown}
                        onClick={handleSearchClick}
                        value={searchText ? searchText : ''}
                      />
                    </Col>
                    <CategoryFilter
                      isOpenFilter={isOpenFilter}
                      handleFilterClick={handleFilterClick}
                      handleFilterClose={handleFilterClose}
                      isFilterApplied={isFilterApplied}
                      handleSubCategoryChange={handleSubCategoryChange}
                      handleCategoryChange={handleCategoryChange}
                      selectedCategory={selectedCategory}
                      selectedSubCategory={selectedSubCategory}
                      categoryList={categoryList}
                      subCategoryList={subCategoryList}
                      handleFilterReset={handleFilterReset}
                      handleApplyFilter={handleApplyFilter}
                    />
                  </div>
                  <div>
                    {
                      <SurveyAssignmentStudentList
                        StudentList={items}
                        activeStudentDetails={activeStudentDetails}
                        handlestudentDetail={handlestudentDetail}
                        fetchMoreData={fetchMoreData}
                        hasMore={hasMore}
                        totalCount={getAssignmentStudentsListApiData?.TotalCount}
                      />
                    }
                  </div>
                </Tab>
              </Tabs>
            </Col>
            {
              <Col className="pt-5">
                {getStudentSurveyResponseApiSuccess && getStudentSurveyResponseApiResponseCode > 0 && getStudentSurveyResponseApiData != null ? (
                  <>
                    <Scrollbar className="h-800px scroll-color">{getStudentSurveyResponseApiData?.Forms?.map((data: any) => renderSurveyResponse(data))}</Scrollbar>
                  </>
                ) : (
                  <p className="mt-2 mb-3">
                    {' '}
                    {assignmentUserListApiParams.Status == 'Completed' && getStudentSurveyResponseApiData?.QuestionAndAnswers == null ? (
                      <FormattedMessage id="NoResults.Found" />
                    ) : assignmentUserListApiParams.Status == 'Completed' ? (
                      <FormattedMessage id="Student.Click.ForDocuments" />
                    ) : (
                      ''
                    )}
                  </p>
                )}
              </Col>
            }
          </Row>
        </div>
      </div>
      <DeleteConfirmPopup
        show={isShowDeleteConfirmPopup}
        handleClose={() => setIsShowDeleteConfirmPopup(false)}
        onCancel={() => setIsShowDeleteConfirmPopup(false)}
        onOkay={() => handleDeleteSurveyClick()}
        title={useIntlMessages('Text.DeleteConfirmation.Survey')}
        content={useIntlMessages('Text.DeleteConfirmation.Survey.Desc')}
      />
      {surveyDetailApiLoading && <Loader />}
      {getAssignmentStudentsListApiLoading && <Loader />}
      {getStudentSurveyResponseApiLoading && <Loader />}
    </>
  );
};
export default SurveyDetails;
