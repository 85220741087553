/**
 * @file   src\components\manageresorces\addMotivation.tsx
 * @brief  404 page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import '../../assets/css/org.scss';
import '../../assets/css/style.scss';
import '../../assets/css/Resource.scss';
import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Breadcrumb, Row } from 'react-bootstrap';
import Select from '../../components/MASelect';
import plusicon from '../../assets/img/icon_plus_wt.svg';
import playIcn from '../../assets/img/play_circle.svg';
import audioIcn from '../../assets/img/ResourceAudio.svg';
import imageIcn from '../../assets/img/ResourceImg.svg';
import documentIcn from '../../assets/img/icn-doc.svg';
import icnDeleteCr from '../../assets/img/icn-delete-clr.svg';
import Input from '../../components/MAInput';
import Checkbox from '../../components/MACheck';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import {
  useIntlActionMessages,
  useIntlMessages,
  getFromLocalStorage,
  isUserAdmin,
  getCurrentOrgDetails,
  isAdminTeacherManager,
  checkValidFile,
  bytesToSize,
  getFileTypeNumber,
} from '../../utils/helper';
import { getOrganizationTypeDropDownList, getOrganizationNameByOrgType, getCategoryDetailsById } from '../../store/actions/organizationTypeActions';
import { uploadFile, uploadFilePathUpdate, uploadFilePathUpdateMultiple } from '../../store/actions/organizationActions';
import { ISelectOptionsNumber, ISelectOptionsString } from '../../interfaces/GeneralInterface';
import { NumberValues, ResourcetypeID, UploaderProps, IMAGE_TYPES_SUPPORTED, FILE_TYPES_SUPPORTED } from '../../utils/enums';
import { IResourceForm } from '../../interfaces/ResourceInterface';
import { IUploadFilePathUpdateParams, IuploadFilePathUpdateMultipleParams } from '../../interfaces/OrganisationInterface';
import { validateForm } from '../../utils/formValidation';
import { MOTIVATIONAL_RESOURCE_SCHEMA, MOTIVATIONAL_RESOURCE_ORG_SCHEMA,MOTIVATIONAL_RESOURCE_GENERAL_ORG_SCHEMA,MOTIVATIONAL_RESOURCE_GENERAL_SCHEMA } from '../../validations/resourceSchema';
import { addResource, resourcedetails, editResource, deleteResourceAttachment } from '../../store/actions/resourceActions';
import { resetResourceAdd, resetResourceEdit, resetResourceDetail, resetDeleteResourceAttachment } from '../../store/slices/resourceSlice';
import { MessageToaster } from '../../utils/ToastUtil';
import Loader from '../../components/Loader';
import ImageUpload from '../../components/ImageUpload';
import { resetUploadFile } from '../../store/slices/organizationSlice';
import UploadNote from '../../components/UploadNote';
// Declare default params
const savedefaultRequestParams: IResourceForm = {
  ResourceName: '',
  ResourcetypeID: ResourcetypeID.MOTIVATIONAL_RESOURCE,
  CategoryID: 0,
  ClassID: [0],
  CreatedUserRoleTypeID: 0,
  Description: '',
  OrganizationTypeId: 0,
  OrganizationID: '',
  IsGeneral: false,
  IsGeneralGroup: false,
  Visibility: [0],
  GoalID: '',
  Groups: [],
};

const AddMotivationResource = () => {
  // Navigation object
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Location object
  const location = useLocation();
  // Toast object creation.
  const toast = new MessageToaster();
  // Ref for file upload input element on the page
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const resourceInputRef = useRef<any>();
  const imageRef = useRef<any>();
  const containerRef = useRef<any>();
  // Access redux state variables
  const {
    organizationTypeDropDownData,
    organizationTypeDropDownSuccess,
    organizationTypeDropDownLoading,
    orgnameByOrgTypeData,
    orgnameByOrgTypeLoading,
    categoryDetailsByOrgIdLoading,
    categoryDetailsByOrgIdData,
  } = useAppSelector((state: RootState) => state.organizationType);
  const {
    uploadFileApiLoading,
    uploadFileResponseCode,
    uploadFileResponseMessage,
    uploadFileApiSuccess,
    uploadFileApiData,
    uploadFilePathUpdateApiLoading,
    uploadFilePathUpdateApiSuccess,
    uploadFilePathUpdateResponseCode,
    uploadFilePathUpdateResponseMessage,
    uploadFilePathUpdateMultipleApiLoading,
    uploadFilePathUpdateMultipleApiSuccess,
    uploadFilePathUpdateMultipleResponseCode,
    uploadFilePathUpdateMultipleResponseMessage,
  } = useAppSelector((state: RootState) => state.organization);

  const {
    addResourceApiLoading,
    addResourceApiSuccess,
    addResourceApiResponseCode,
    addResourceApiResponseMessage,
    addResourceApiData,
    editResourceApiSuccess,
    editResourceApiResponseCode,
    editResourceApiResponseMessage,
    resourceDetail,
    resourceDetailApiLoading,
    resourceDetailViewApiSuccess,
    resourceDetailViewApiResponseCode,
    deleteResourceAttachmentApiLoading,
  } = useAppSelector((state: RootState) => state.resource);
  // Declare constants
  const fileType = [
    { label: 'Image', value: 'image' },
    { label: 'Video', value: 'video' },
    { label: 'Audio', value: 'audio' },
    { label: 'Document', value: 'document' },
  ];
  // Initialize component state variables.
  const [motivationalResourceForm, setMotivationalResourceForm] = useState<IResourceForm>(savedefaultRequestParams);
  const [fileTypeValue, setfileTypeValue] = useState<any>(fileType[0]);
  const [organizationTypeValue, setOrganizationTypeValue] = useState<ISelectOptionsNumber>();
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<Array<any>>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<ISelectOptionsNumber[] | null>([]);
  const [orgName, setOrgName] = useState<ISelectOptionsNumber[] | null>([]);
  const [category, setCategory] = useState<ISelectOptionsNumber[] | null>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOptionsString[]>([]);
  const [selectedClasses, setSelectedClasses] = useState<ISelectOptionsString[]>([]);
  const [classes, setClasses] = useState<ISelectOptionsString[]>([]);
  const [errorFields, setErrorFields] = useState<any>({});
  const [characterCount, setCharacterCount] = useState<number>(0);
  const [isGeneralMotivationalResource, setIsGeneralMotivationalResource] = useState(false);
  const [imgError, setImgError] = useState<string>('');
  const [fileError, setFileError] = useState<string>('');
  const [upImageAttachment, setUpImageAttachment] = useState<string | null>(null);
  const [upImageAttachmentDetails, setUpImageAttachmentDetails] = useState<any>({});
  const [fileUploadBegin, setFileUploadBegin] = useState<boolean>(false);
  const [uploadedFileInfo, setUploadedFileInfo] = useState<any>([]);
  const [uploadThumbnail, setUploadThumbnail] = useState<boolean>(false);
  const [uploadAttachment, setUploadAttachment] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [upImageThumbnailDetails, setUpImageThumbnailDetails] = useState<any>({});
  const [upImageThumbnail, setUpImageThumbnail] = useState<string | null>(null);
  const [startSubmit, setStartSubmit] = useState<boolean>(false);
  const [beginUpdate, setBeginUpdate] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [uploadedFileInfoOld, setUploadedFileInfoOld] = useState<any>([]);
  const [deleteList, setDeleteList] = useState<string[]>([]);
  // Initialize language variables.
  const ImageUploadChooseImage = useIntlActionMessages('Image.Choose.Thumbnail');
  const ImageUploadChooseAttachment = useIntlActionMessages('Label.Upload.Attachment.type');
  const ImageUploadFailed = useIntlActionMessages('Image.Upload.Failed');
  const AttachmentsText = useIntlActionMessages('Label.Attachments');
  const OrgTypeText = useIntlActionMessages('Label.OrgType');
  const SelectText = useIntlMessages('PH.Select');
  const OrgNameText = useIntlActionMessages('Label.OrgName');
  const CategoryText = useIntlActionMessages('Label.Category');
  const SubCategoryText = useIntlActionMessages('Label.SubCategory');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  const storageData = getFromLocalStorage('MI_USR_DATA');

  // Initial rendering
  useEffect(() => {
    try {
      dispatch(resetUploadFile());
      dispatch(resetResourceAdd());
      dispatch(resetDeleteResourceAttachment());
      if (location?.state?.resourceId) {
        dispatch(resourcedetails(location?.state?.resourceId));
      } else {
        setMotivationalResourceForm({
          ...savedefaultRequestParams,
          CreatedUserRoleTypeID: storageData?.RoleTypeId,
        });
        setUploadedFileInfo([]);
        setUpImageAttachmentDetails([]);
        handlefileTypeChange({ value: 'image' });
        setfileTypeValue(fileType.find((option) => option?.value?.toLowerCase() === 'image'));
      }
      return () => {
        dispatch(resetResourceEdit());
        dispatch(resetResourceDetail());
        dispatch(resetDeleteResourceAttachment());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // Get organization details based roles
  useEffect(() => {
    try {
      const userData = getFromLocalStorage('MI_USR_DATA');
      const OrganizationId = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : userData.OrganizationId;
      if (isAdminTeacherManager()) {
        setMotivationalResourceForm((prevMotivationForm) => ({
          ...prevMotivationForm,
          OrganizationTypeId: 0,
          OrganizationID: OrganizationId,
        }));
        dispatch(
          getCategoryDetailsById({
            OrganizationId: OrganizationId,
          }),
        );
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [localStorage.getItem('CURRENT_ORG')]);
  // Handle the upload path api response
  useEffect(() => {
    try {
      const submitUploadsAndSubsequentActions = async () => {
        if (uploadFileResponseCode > 0 && uploadFileApiSuccess && !submit && upImageAttachmentDetails) {
          uploadAttachmentFileToBucket();
          dispatch(resetUploadFile());
        } else if (submit && uploadFileResponseCode > 0 && uploadFileApiSuccess) {
          await uploadThumbnailToBucket();
          dispatch(resetUploadFile());
          if (location?.state?.resourceId) {
            setBeginUpdate(true);
          } else {
            dispatch(addResource(motivationalResourceForm));
          }
        }
      };
      submitUploadsAndSubsequentActions();
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFileResponseCode, uploadFileResponseMessage]);
  // api request to the update resource
  useEffect(() => {
    try {
      if (beginUpdate) {
        dispatch(editResource(motivationalResourceForm));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [beginUpdate]);
  // Upload thumb to database
  useEffect(() => {
    try {
      if (submit && addResourceApiResponseCode > 0 && addResourceApiResponseMessage && !addResourceApiSuccess) {
        toast.toastError(addResourceApiResponseMessage);
      } else if (submit && addResourceApiResponseCode > 0 && addResourceApiSuccess) {
        setUploadThumbnail(true);
        setSubmit(false);
        const reqParams: IUploadFilePathUpdateParams = {
          FilePath: upImageThumbnailDetails.name,
          Type: 'resourceimage',
          TypeId: addResourceApiData?.ResourceId,
        };
        // Api request to update file info after bucket upload and save organization info
        dispatch(uploadFilePathUpdate(reqParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [addResourceApiResponseCode, addResourceApiResponseMessage]);

  // Handle edit respource api response
  useEffect(() => {
    try {
      if (submit && editResourceApiResponseCode > 0 && editResourceApiResponseMessage && !editResourceApiSuccess) {
        toast.toastError(editResourceApiResponseMessage);
        setStartSubmit(false);
      } else if (submit && editResourceApiResponseCode > 0 && editResourceApiSuccess) {
        setUploadThumbnail(true);
        setSubmit(false);
        // upload thumbnail
        const reqParams: IUploadFilePathUpdateParams = {
          FilePath: upImageThumbnailDetails.name,
          Type: 'resourceimage',
          TypeId: location?.state?.resourceId,
        };
        // Api request to update file info after bucket upload and save organization info
        dispatch(uploadFilePathUpdate(reqParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [editResourceApiResponseCode, editResourceApiResponseMessage]);
  // Upload attachments to database
  useEffect(() => {
    try {
      if (uploadThumbnail && uploadFilePathUpdateResponseCode > 0 && uploadFilePathUpdateResponseMessage && !uploadFilePathUpdateApiSuccess) {
        toast.toastError(uploadFilePathUpdateResponseMessage);
        setStartSubmit(false);
      } else if (uploadThumbnail && uploadFilePathUpdateResponseCode > 0 && uploadFilePathUpdateApiSuccess) {
        setUploadAttachment(true);
        setUploadThumbnail(false);
        const uplodedFileInfo = uploadedFileInfo.map((file: any) => {
          return {
            FileName: file.name,
            DisplayName: file.displayName,
            FileSize: file.size,
            FileType: file.typeNumber ? file.typeNumber.toString() : '',
          };
        });
        const reqParams: IuploadFilePathUpdateMultipleParams = {
          Files: uplodedFileInfo,
          Type: 'resource_attachments',
          TypeId: location?.state?.resourceId ? location?.state?.resourceId : addResourceApiData?.ResourceId,
        };
        // Api request to update file info after bucket upload and save organization info
        dispatch(uploadFilePathUpdateMultiple(reqParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFilePathUpdateResponseCode, uploadFilePathUpdateResponseMessage]);

  // Show message after form submit, success/failure
  useEffect(() => {
    try {
      if (uploadAttachment && uploadFilePathUpdateMultipleResponseCode > 0 && uploadFilePathUpdateMultipleApiSuccess) {
        setUploadAttachment(false);
        setStartSubmit(false);
        if (location?.state?.resourceId) {
          toast.toastSuccess(editResourceApiResponseMessage);
        } else {
          toast.toastSuccess(addResourceApiResponseMessage);
        }
        navigate('/resources');
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFilePathUpdateMultipleResponseCode, uploadFilePathUpdateMultipleResponseMessage]);
  // handle resource detail api response
  useEffect(() => {
    try {
      if (resourceDetailViewApiResponseCode > 0 && resourceDetailViewApiSuccess) {
        setCharacterCount(resourceDetail.Description.length);
        setIsGeneralMotivationalResource(resourceDetail?.IsGeneral);
        if (resourceDetail?.IsGeneral) {
          setSelectedOrganization([]);
        } else {
          setOrganizationTypeValue({
            label: resourceDetail.OrganizationTypeName,
            value: resourceDetail.OrganizationTypeId,
          });
          setSelectedOrganization([
            {
              label: resourceDetail.OrganizationName,
              value: resourceDetail.OrganizationID,
            },
          ]);
        }
        if (resourceDetail?.CategoryName != null && resourceDetail?.CategoryId != null && resourceDetail?.IsGeneral === false) {
          setSelectedCategory([{ label: resourceDetail?.CategoryName, value: resourceDetail?.CategoryId }]);
          setCategory([]);
        } else {
          setSelectedCategory([]);
        }
        const subcategoriesForSelection = resourceDetail?.ClassName?.map((subcategory: any) => ({
          label: subcategory.classname,
          value: subcategory.classid,
        }));
        setSelectedClasses(subcategoriesForSelection);
        const subcategories = resourceDetail?.ClassName?.map((subcategory: any) => subcategory.classid);
        setMotivationalResourceForm((resourceForm) => ({
          ...resourceForm,
          ResourceName: resourceDetail?.ResourceName,
          ResourcetypeID: ResourcetypeID.MOTIVATIONAL_RESOURCE,
          CategoryID: resourceDetail?.CategoryId,
          ClassID: subcategories,
          CreatedUserRoleTypeID: storageData?.RoleTypeId,
          Description: resourceDetail?.Description,
          OrganizationID: resourceDetail?.OrganizationID,
          OrganizationTypeId: resourceDetail?.OrganizationTypeId,
          IsGeneral: resourceDetail?.IsGeneral,
          Visibility: [0],
          GoalID: '',
          ResourceId: location?.state?.resourceId,
        }));
        setPreviewImage(resourceDetail?.ResourceThumbnail);
        const fileInfo = resourceDetail?.Attachments?.map((filedetails: any, index: number) => {
          return {
            name: filedetails?.resourcefilepath?.slice(filedetails?.resourcefilepath?.lastIndexOf('/') + 1),
            displayName: filedetails?.resourcefilepath?.slice(filedetails?.resourcefilepath?.lastIndexOf('/') + 1),
            type: filedetails?.resourcefilepath?.slice(filedetails?.resourcefilepath?.lastIndexOf('.') + 1),
            size: filedetails.filesize,
            typeNumber: filedetails.filetypeid,
            resourceID: filedetails.resourcedetailsid,
          };
        });
        const imgInfo = {
          name: resourceDetail?.ResourceThumbnail?.slice(resourceDetail?.ResourceThumbnail?.lastIndexOf('/') + 1),
          type: resourceDetail?.ResourceThumbnail?.slice(resourceDetail?.ResourceThumbnail?.lastIndexOf('.') + 1),
        };
        setPicture(imgInfo);
        setUpImageAttachmentDetails(fileInfo);
        setUploadedFileInfoOld(fileInfo);
        handlefileTypeChange({ value: 'image' });
        setfileTypeValue(fileType.find((option) => option?.value?.toLowerCase() === 'image'));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [resourceDetailApiLoading, resourceDetailViewApiResponseCode]);
  // Get organization type
  useEffect(() => {
    try {
      if (organizationTypeDropDownSuccess && organizationTypeDropDownData?.length > 0) {
        const orgTypeOptions = organizationTypeDropDownData.map(
          (orgtype: any): ISelectOptionsNumber => ({
            label: orgtype.orgTypeName,
            value: orgtype.orgTypeId,
          }),
        );
        setOrganizationTypeOptions([...orgTypeOptions]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationTypeDropDownLoading]);
  // Prevent enter key press
  useEffect(() => {
    try {
      dispatch(getOrganizationTypeDropDownList({}));
      const listener = (event: any) => {
        if (event.keyCode === NumberValues.NUM_13 && event.target.nodeName != 'TEXTAREA') {
          event.preventDefault();
        }
      };
      const currentContainer = containerRef?.current;
      if (currentContainer) {
        currentContainer.addEventListener('keydown', listener);
      }
      return () => {
        if (currentContainer) {
          currentContainer.removeEventListener('keydown', listener);
        }
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // Get organization details by organization type
  useEffect(() => {
    try {
      if (organizationTypeValue) {
        if (orgnameByOrgTypeData && orgnameByOrgTypeData.length > 0) {
          const organizationData = orgnameByOrgTypeData.map(
            (organization: any): ISelectOptionsNumber => ({
              label: organization.Name,
              value: organization.ID,
            }),
          );
          setOrgName(organizationData);
          setSelectedOrganization([]);
          setSelectedCategory([]);
          setSelectedClasses([]);
          setClasses([]);
          setCategory([]);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [orgnameByOrgTypeLoading]);
  // Get category details by organization
  useEffect(() => {
    try {
      if (categoryDetailsByOrgIdData && categoryDetailsByOrgIdData.length > 0) {
        const categoryData = categoryDetailsByOrgIdData.map(
          (category: any): ISelectOptionsNumber => ({
            label: category.CategoryName,
            value: category.CategoryId,
          }),
        );
        setCategory(categoryData);
      } else {
        // If ResponseData is null, update category state with null
        setCategory([]);
        setSelectedCategory([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [categoryDetailsByOrgIdLoading]);
  // Add event listener for Enter key press
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
        if (isFormValid()) {
          event.preventDefault();
          onSubmit();
        }
      }
    };
    const isFormValid = () => {
      return Object.values(errorFields).every((error) => !error);
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [errorFields]);
  // Handle accepted value based file type
  useEffect(() => {
    try {
      handlefileTypeChange({ value: 'image' });
      setfileTypeValue(fileType.find((option) => option.value === 'image'));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // Put file to bucket after getting the path
  const uploadThumbnailToBucket = async () => {
    try {
      const uploadURL = uploadFileApiData;
      if (uploadURL) {
        // PUT request: upload file to S3
        const result = await fetch(uploadURL, {
          method: 'PUT',
          body: upImageThumbnail,
        });
        if (result.status == 200) {
          const fileSize = bytesToSize(upImageThumbnailDetails.size);
          const fileInfo = { ...upImageThumbnailDetails, bucketURL: uploadURL, size: fileSize };
          setUpImageThumbnailDetails(fileInfo);
        } else {
          setStartSubmit(false);
          setFileError(ImageUploadFailed);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle organization type change
  const handleOrgTypeChange = async (event: any) => {
    try {
      const selectedOrgTypeId = parseInt(event.value);
      setMotivationalResourceForm((prevMotivationForm) => ({
        ...prevMotivationForm,
        OrganizationTypeId: selectedOrgTypeId,
        OrganizationID: '',
      }));
      setOrganizationTypeValue(event);
      dispatch(
        getOrganizationNameByOrgType({
          OrganizationTypeId: selectedOrgTypeId,
        }),
      );
      if (isUserAdmin()) {
        const validateObj = {
          ...motivationalResourceForm,
          OrganizationTypeId: selectedOrgTypeId,
          OrganizationID: '',
          CategoryID: [0],
          ClassID: [0],
        };

        const errorResult = await validateForm(validateObj, MOTIVATIONAL_RESOURCE_SCHEMA, errorFields);
        setErrorFields(errorResult);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle organization change
  const handleOrganizationChange = async (event: any) => {
    try {
      const selectedOrganization = event.value;
      setSelectedOrganization(event.value);
      setMotivationalResourceForm((prevMotivationForm) => ({
        ...prevMotivationForm,
        OrganizationID: selectedOrganization,
      }));
      setSelectedOrganization(event);
      dispatch(
        getCategoryDetailsById({
          OrganizationId: selectedOrganization,
        }),
      );
      setSelectedCategory([]);
      setSelectedClasses([]);
      if (isUserAdmin()) {
        const validateObj = {
          ...motivationalResourceForm,
          OrganizationID: selectedOrganization,
          CategoryID: [0],
          ClassID: [0],
        };
        const errorResult = await validateForm(validateObj, MOTIVATIONAL_RESOURCE_SCHEMA, errorFields);
        setErrorFields(errorResult);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Get subcategories based on category
  const handleCategoryChange = async (event: any) => {
    try {
      const actualCategoryId = event.value;
      setSelectedCategory(event);
      setMotivationalResourceForm((prevMotivationForm) => ({
        ...prevMotivationForm,
        CategoryID: parseInt(actualCategoryId),
      }));
      const category = categoryDetailsByOrgIdData.find((category: any) => category.CategoryId === actualCategoryId);
      if (category) {
        const subcategories = category.Subcategory.map((subcategory: any) => ({
          label: subcategory.classname,
          value: subcategory.classid,
        }));
        setClasses(subcategories);
        setSelectedClasses([]);
        const validateObj = {
          ...motivationalResourceForm,
          CategoryID: parseInt(actualCategoryId),
          ClassID: [0],
        };
        const errorResult = await validateForm(validateObj, MOTIVATIONAL_RESOURCE_SCHEMA, errorFields);
        setErrorFields(errorResult);
      } else {
        setClasses([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle class chanage
  const handleClassChange = async (event: any) => {
    try {
      if (event) {
        const subcategories = event.map((subcategory: any) => subcategory.value);
        setSelectedClasses(event);
        setMotivationalResourceForm((prevMotivationForm: any) => ({
          ...prevMotivationForm,
          ClassID: subcategories,
        }));
        const validateObj = {
          ...motivationalResourceForm,
          ClassID: subcategories.length > 0 ? subcategories : undefined,
        };
        const errorResult = await validateForm(validateObj, MOTIVATIONAL_RESOURCE_SCHEMA, errorFields);
        setErrorFields(errorResult);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    try {
      setIsGeneralMotivationalResource(!isGeneralMotivationalResource);
      // Reset selected options when checkbox is checked
      if (!isGeneralMotivationalResource) {
        setMotivationalResourceForm((prevMotivationForm) => ({
          ...prevMotivationForm,
          IsGeneral: true,
          OrganizationTypeId: 0,
          OrganizationID: isUserAdmin() ? '' : prevMotivationForm.OrganizationID,
          CategoryID: 0,
          ClassID: [0],
        }));
        setSelectedOrganization([]);
        setSelectedClasses([]);
        setSelectedCategory([]);
      } else {
        if (isAdminTeacherManager()) {
          if (categoryDetailsByOrgIdData && categoryDetailsByOrgIdData.length > 0) {
            const categoryData = categoryDetailsByOrgIdData.map(
              (category: any): ISelectOptionsNumber => ({
                label: category.CategoryName,
                value: category.CategoryId,
              }),
            );
            setCategory(categoryData);
          } else {
            // If ResponseData is null, update category state with null
            setCategory([]);
            setSelectedCategory([]);
          }
        }
        setMotivationalResourceForm((prevMotivationForm) => ({
          ...prevMotivationForm,
          IsGeneral: false,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle validations
  const CheckValidation = async (validateObj: any) => {
    try {
      const errorResult = await validateForm(validateObj, MOTIVATIONAL_RESOURCE_SCHEMA, errorFields);
      setErrorFields(errorResult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle file type
  const handlefileTypeChange = (event: { value: string }) => {
    try {
      setfileTypeValue(event);
      const fileType = event.value.toLowerCase();
      const acceptedFileExtensions = FILE_TYPES_SUPPORTED[fileType as keyof typeof FILE_TYPES_SUPPORTED] || [];
      const acceptString = acceptedFileExtensions.map((ext) => `.${ext}`).join(',');
      if (fileUploadRef?.current) {
        fileUploadRef.current.accept = acceptString;
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle form fields validtaion
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      if (name == 'Description') {
        setCharacterCount(event.target.value.length);
      }
      setMotivationalResourceForm((info: any) => ({
        ...info,
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      CheckValidation(validateObj);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Function to upload attchments
  const uploadResourceFile = async (e: any) => {
    try {
      setFileUploadBegin(true);
      setFileError('');
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const lastDotIndex = file?.name?.lastIndexOf('.');
        const fileNameWithoutExtension = file?.name?.slice(0, lastDotIndex);
        const fileExtension = file?.name?.slice(lastDotIndex + 1);
        const modifiedFileName = fileNameWithoutExtension + new Date().getTime() + '.' + fileExtension;
        // replacing special characters with underscores
        const formattedFilename = modifiedFileName.replace(/[^a-zA-Z0-9.\-_]/g, '_');
        const isValid = checkValidFile(modifiedFileName, fileTypeValue.value, file.size);
        if (isValid) {
          setUpImageAttachment(e.target.files[0]);
          const fileInfo = {
            name: formattedFilename,
            displayName: file.name,
            type: fileExtension,
            size: file.size,
            typeNumber: getFileTypeNumber(fileTypeValue),
          };
          setUpImageAttachmentDetails(fileInfo);
          const formData = new FormData();
          formData.append('UploadExtension', fileInfo.type);
          formData.append('UploadObjectKey', fileInfo.name);
          formData.append('UploadType', 'resource_attachments');
          dispatch(uploadFile(formData));
        } else {
          setFileError(ImageUploadChooseImage);
          setFileUploadBegin(false);
          if (fileUploadRef.current) {
            fileUploadRef.current.value = '';
          }
        }
      } else {
        setFileUploadBegin(false);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Put file to bucket after getting the path
  const uploadAttachmentFileToBucket = async () => {
    try {
      const uploadURL = uploadFileApiData;
      if (uploadURL) {
        // PUT request: upload file to S3
        const result = await fetch(uploadURL, {
          method: 'PUT',
          body: upImageAttachment,
        });
        if (result.status == 200) {
          const fileSize = bytesToSize(upImageAttachmentDetails.size);
          const fileInfo = { ...upImageAttachmentDetails, bucketURL: uploadURL, size: fileSize };
          setUpImageAttachmentDetails(fileInfo);
          setUploadedFileInfo([...uploadedFileInfo, fileInfo]);
          setFileUploadBegin(false);
          setFileError('');
        } else {
          setFileError(ImageUploadFailed);
          setFileUploadBegin(false);
          if (fileUploadRef.current) {
            fileUploadRef.current.value = '';
          }
          setStartSubmit(false);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle form submit
  const onSubmit = async () => {
    try {
      let errorResult = {};
      if (!isGeneralMotivationalResource) {
        if (isUserAdmin()) {
          // Use MOTIVATIONAL_RESOURCE_SCHEMA for Admin
          errorResult = await validateForm(motivationalResourceForm, MOTIVATIONAL_RESOURCE_SCHEMA, errorFields);
        } else if (isAdminTeacherManager()) {
          // Use MOTIVATIONAL_RESOURCE_ORG_SCHEMA for org users
          errorResult = await validateForm(motivationalResourceForm, MOTIVATIONAL_RESOURCE_ORG_SCHEMA, errorFields);
        }
      }else{
        if (isUserAdmin()) {
          // Use MOTIVATIONAL_RESOURCE_SCHEMA for Admin
          errorResult = await validateForm(motivationalResourceForm, MOTIVATIONAL_RESOURCE_GENERAL_SCHEMA, errorFields);
        } else if (isAdminTeacherManager()) {
          // Use MOTIVATIONAL_RESOURCE_ORG_SCHEMA for org users
          errorResult = await validateForm(motivationalResourceForm, MOTIVATIONAL_RESOURCE_GENERAL_ORG_SCHEMA, errorFields);
        }
      }
      if (Object.keys(errorResult).length === 0) {
        if (deleteList.length > 0) {
          const deleteRequestParams = {
            AttachmentIDs: deleteList,
            ResourceID: location?.state?.resourceId,
          };
          dispatch(deleteResourceAttachment(deleteRequestParams));
        }
        if (upImageThumbnail && (uploadedFileInfo?.length > 0 || uploadedFileInfoOld?.length > 0)) {
          setImgError('');
          setFileError('');
          if (location?.state?.resourceId && upImageThumbnailDetails?.name === resourceDetail?.ResourceThumbnail?.slice(resourceDetail?.ResourceThumbnail?.lastIndexOf('/') + 1)) {
            setBeginUpdate(true);
            setSubmit(true);
            setStartSubmit(true); // state to manage loader
          } else {
            const formData = new FormData();
            formData.append('UploadExtension', upImageThumbnailDetails.type);
            formData.append('UploadObjectKey', upImageThumbnailDetails.name);
            formData.append('UploadType', 'resourceimage');
            dispatch(uploadFile(formData));
            setSubmit(true);
            setStartSubmit(true); // state to manage loader
          }
        } else if (upImageThumbnail && uploadedFileInfo.length == 0) {
          setImgError('');
          setFileError(ImageUploadChooseAttachment);
          window.scrollTo(0, 0);
          if (fileUploadRef?.current) {
            fileUploadRef?.current?.focus();
          }
          setStartSubmit(false);
        } else {
          setFileError('');
          setImgError(ImageUploadChooseImage);
          window.scrollTo(0, 0);
          if (imageRef?.current) {
            imageRef?.current?.focus();
          }
          setStartSubmit(false);
        }
      } else {
        setErrorFields(errorResult);
        setStartSubmit(false);
        // If there are validation errors, focus on the input field
        const firstErrorField = Object.keys(errorResult)[0];
        if (firstErrorField && resourceInputRef?.current) {
          resourceInputRef?.current?.focus();
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Set image selected
  const setPicture = (pic: any) => {
    try {
      setUpImageThumbnail(pic);
      const imgInfo = {
        name: pic.name,
        type: pic?.name?.split('.')[1],
      };
      setUpImageThumbnailDetails(imgInfo);
      setImgError('');
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Remove Attachment from listing
  const deleteAttachment = (deleteIndex: number) => {
    try {
      const newUploadedFileInfo = [...uploadedFileInfo];
      setUploadedFileInfo(newUploadedFileInfo.filter((item: any, index: number) => index != deleteIndex));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Remove Attachment from listing
  const deleteAttachmentOld = (attachmentID: string, deleteIndex: number) => {
    try {
      const newUploadedFileInfo = [...uploadedFileInfoOld];
      setUploadedFileInfoOld(newUploadedFileInfo.filter((item: any, index: number) => index != deleteIndex));
      setDeleteList((prevList) => [...prevList, attachmentID]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  return (
    <>
      <div className="addMotiv-res">
        <div className="page-title orgAdd-page-title d-flex justify-content-between align-items-center">
          <h3>{location?.state?.resourceId ? <FormattedMessage id="Label.EditMotivationalResource" /> : <FormattedMessage id="HD.AddMotivationalResources" />}</h3>
          <Col md="auto">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item onClick={() => navigate('/resources')}>
                <FormattedMessage id="Hd.Resources" />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {location?.state?.resourceId ? <FormattedMessage id="Label.EditMotivationalResource" /> : <FormattedMessage id="Label.AddMotivationalResources" />}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </div>
        <div className="content-sub" ref={containerRef}>
          <div className="content-area-padding">
            <div className="justify-content-center p-5">
              <div>
                <h3 className="text-center">
                  {location?.state?.resourceId ? <FormattedMessage id="Label.EditMotivationalResource" /> : <FormattedMessage id="HD.AddMotivationalResources" />}
                </h3>
                <p className="text-center">{useIntlActionMessages('SubHd.MotivationalResources')}</p>
                <div className="sel-fileType mb-5">
                  <Select
                    label={useIntlActionMessages('Label.SelectFileType')}
                    options={fileType}
                    value={fileTypeValue}
                    placeholder={useIntlMessages('PH.Select')}
                    onChange={(e: any) => handlefileTypeChange(e)}
                  />
                </div>
              </div>
              <div className="fileupload-sec  mb-3">
                <input
                  ref={fileUploadRef}
                  type="file"
                  name="file-3[]"
                  id="file-3"
                  className="inputfile inputfile-3"
                  data-multiple-caption="{count} files selected"
                  multiple={false}
                  accept="image/*"
                  onChange={(e: any) => uploadResourceFile(e)}
                />
                <label htmlFor="file-3">
                  <img src={plusicon} alt="" />
                  <span>{useIntlActionMessages('Button.AddFile')}</span>
                </label>
              </div>
              <div className="upload-info">
                <UploadNote
                  fileType={Object.entries(FILE_TYPES_SUPPORTED)
                    .map(([key, value]) => `${value.map((ext) => `.${ext}`).join(', ')}`)
                    .join(', ')}
                />
              </div>
              <div className="error text-center pt-2" ref={imageRef}>
                {' '}
                {fileError}
              </div>
              <Row className="text-center">
                {(uploadedFileInfo?.length > 0 || uploadedFileInfoOld?.length > 0) && (
                  <h4>
                    {AttachmentsText}({uploadedFileInfo?.length + (uploadedFileInfoOld?.length ?? 0)})
                  </h4>
                )}
                {uploadedFileInfoOld?.map((filedetails: any, index: number) => (
                  <Col md={6} key={index}>
                    <div className="att-list-b d-flex mb-3">
                      {filedetails?.type && FILE_TYPES_SUPPORTED.image.includes(filedetails.type.toLowerCase()) && <img src={imageIcn} alt="" />}
                      {filedetails?.type && FILE_TYPES_SUPPORTED.audio.includes(filedetails.type.toLowerCase()) && <img src={audioIcn} alt="" />}
                      {filedetails?.type && FILE_TYPES_SUPPORTED.video.includes(filedetails.type.toLowerCase()) && <img src={playIcn} alt="" />}
                      {filedetails?.type && FILE_TYPES_SUPPORTED.document.includes(filedetails.type.toLowerCase()) && <img src={documentIcn} alt="" />}
                      <div className="text-start text-ar flex-grow-1">
                        <p className="text-white">{filedetails?.displayName}</p>
                        <span className="mb-f">{filedetails?.size}</span>
                      </div>
                      <div className="delete-sec">
                        <Button className="Plus-btn" variant="outline-secondary" id="button-addon2" onClick={() => deleteAttachmentOld(filedetails?.resourceID, index)}>
                          <img src={icnDeleteCr} />
                        </Button>
                      </div>
                    </div>
                  </Col>
                ))}
                {uploadedFileInfo?.length > 0 &&
                  uploadedFileInfo.map((filedetails: any, index: number) => (
                    <Col md={6} key={index}>
                      <div className="att-list-b d-flex mb-3">
                        {filedetails?.type && FILE_TYPES_SUPPORTED.image.includes(filedetails.type.toLowerCase()) && <img src={imageIcn} alt="" />}
                        {filedetails?.type && FILE_TYPES_SUPPORTED.audio.includes(filedetails.type.toLowerCase()) && <img src={audioIcn} alt="" />}
                        {filedetails?.type && FILE_TYPES_SUPPORTED.video.includes(filedetails.type.toLowerCase()) && <img src={playIcn} alt="" />}
                        {filedetails?.type && FILE_TYPES_SUPPORTED.document.includes(filedetails.type.toLowerCase()) && <img src={documentIcn} alt="" />}
                        <div className="text-start text-ar flex-grow-1">
                          <p className="text-white">{filedetails.displayName}</p>
                          <span className="mb-f">{filedetails.size}</span>
                        </div>
                        <div className="delete-sec">
                          <Button className="Plus-btn" variant="outline-secondary" id={`delete-button-${index}`} onClick={() => deleteAttachment(index)}>
                            <img src={icnDeleteCr} alt="Delete" />
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ))}
              </Row>
              <Row className="d-flex justify-content-center">
                <div className="sec-addThunmb d-flex flex-wrap align-content-center justify-content-center">
                  <div className="c px-4">
                    <ImageUpload
                      setError={(error: string) => setImgError(error)}
                      imageFile={previewImage ? previewImage : ''}
                      setPicture={setPicture}
                      maxSize={Number(UploaderProps.IMAGE_MAX_SIZE)}
                      acceptedFileTypes={IMAGE_TYPES_SUPPORTED}
                      invalidSizeMessage={useIntlActionMessages('Image.Upload.Size.Message')}
                      invalidTypeMessage={useIntlActionMessages('Image.Upload.AcceptedType.Error')}
                      mode={location?.state?.resourceId ? 'editresource' : 'addresource'}
                    />
                  </div>
                </div>
                <div className="error text-center pt-2"> {imgError}</div>
                <div className="upload-info">
                  <UploadNote fileSize="50 MB" />
                </div>
              </Row>
              <Row>
                <div className="p-3">
                  <Input
                    ref={resourceInputRef}
                    label={useIntlActionMessages('Label.NameofResource')}
                    id="ResourceName"
                    name="ResourceName"
                    type="text"
                    placeholder={useIntlActionMessages('PH.MotivationalResource')}
                    maxLength={NumberValues.NUM_200}
                    onChange={onInputHandleChange}
                    value={motivationalResourceForm?.ResourceName}
                    errorMessage={errorFields?.ResourceName}
                    autoFocus
                  />
                  <Col lg={12} className="addOrg-textarea">
                    <Input
                      label={useIntlActionMessages('Label.Description')}
                      id="Description"
                      name="Description"
                      type="textarea"
                      placeholder={useIntlActionMessages('Label.ResourceDescription')}
                      maxLength={NumberValues.NUM_1000}
                      as="textarea"
                      onChange={onInputHandleChange}
                      value={motivationalResourceForm?.Description}
                      rows={3}
                      errorMessage={errorFields?.Description}
                    />
                    <span className="txt-count">
                      {characterCount}/{NumberValues.NUM_1000}
                    </span>
                  </Col>
                  <div className="check-sm">
                    <Checkbox
                      type="Checkbox"
                      id="IsGeneral"
                      name="IsGeneral"
                      label={
                        isAdminTeacherManager()
                          ? useIntlActionMessages('Label.Checkbox.GeneralMotivationalResourceOrgAdmin')
                          : useIntlActionMessages('Label.Checkbox.GeneralMotivationalResourceAdmin')
                      }
                      onChange={handleCheckboxChange}
                      value={motivationalResourceForm?.IsGeneral}
                      checked={motivationalResourceForm.IsGeneral}
                    />
                  </div>
                </div>
              </Row>
              {!isGeneralMotivationalResource && (
                <>
                  {!isAdminTeacherManager() && (
                    <>
                      <Row>
                        <Col md={6}>
                          <Select
                            label={OrgTypeText}
                            options={organizationTypeOptions}
                            value={organizationTypeValue}
                            name="OrganizationTypeId"
                            id="OrganizationTypeId"
                            placeholder={SelectText}
                            onChange={(e: any) => handleOrgTypeChange(e)}
                            error={errorFields?.OrganizationTypeId}
                          />
                        </Col>
                        <Col md={6}>
                          <Select
                            label={OrgNameText}
                            name="OrganizationID"
                            id="OrganizationID"
                            options={orgName}
                            value={selectedOrganization}
                            placeholder={SelectText}
                            onChange={(e: any) => handleOrganizationChange(e)}
                            error={errorFields?.OrganizationID}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col md={6}>
                      <Select
                        label={CategoryText}
                        name="CategoryID"
                        id="CategoryID"
                        options={category}
                        value={selectedCategory}
                        placeholder={SelectText}
                        onChange={(e: any) => handleCategoryChange(e)}
                        error={errorFields?.CategoryID}
                      />
                    </Col>
                    <Col md={6}>
                      <Select
                        label={SubCategoryText}
                        name="ClassID"
                        id="ClassID"
                        options={classes}
                        value={selectedClasses}
                        placeholder={SelectText}
                        onChange={(e: any) => handleClassChange(e)}
                        error={errorFields?.ClassID}
                        isMulti
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col lg={12} className="d-flex justify-content-end ">
                  <Button className="mb-4 btn-cancel" variant="outline-primary" onClick={() => navigate('/resources')}>
                    <FormattedMessage id="Button.Cancel" />
                  </Button>
                  <Button
                    className="mb-4 btn-Done"
                    variant="primary"
                    onClick={onSubmit}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onSubmit();
                      }
                    }}
                  >
                    <FormattedMessage id="Button.Save" />
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {(uploadFileApiLoading ||
          fileUploadBegin ||
          orgnameByOrgTypeLoading ||
          addResourceApiLoading ||
          uploadFilePathUpdateApiLoading ||
          uploadFilePathUpdateMultipleApiLoading ||
          startSubmit ||
          resourceDetailApiLoading ||
          deleteResourceAttachmentApiLoading) && <Loader />}
      </div>
    </>
  );
};
export default AddMotivationResource;
