/**
 * @file   src\containers\organizations\SurveyStep1.tsx
 * @brief  Survey Step 1 page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { isBeforeOrEqual, useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import { Button, Col, Table, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { slide as Filter } from 'react-burger-menu';
import { Scrollbar } from 'react-scrollbars-custom';
import UserImg from '../../assets/img/Userimg.jpg';
import Checkbox from '../../components/MACheck';
import Sort from '../../assets/img/icon/Sort';
import Select from '../../components/MASelect';
import Search from '../../components/MASearch';
import Close from '../../assets/img/Close.svg';
import Delete from '../../assets/img/icon/Delete';
import Input from '../../components/MAInput';
import { BUCKET_URL, DATE_FORMAT1, DATE_TIME_FORMAT, DEFAULT_PAGE_INDEX, DEFAULT_SELECT_OPTION_NUMBER, PAGE_SIZE } from '../../utils/constants';
import { NumberValues, RoleTypeIds, SortOrder, UsersSortFields } from '../../utils/enums';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getCategoryList } from '../../store/actions/organizationActions';
import { RootState } from '../../store';
import { ISelectOptionsNumber } from '../../interfaces/GeneralInterface';
import moment from 'moment';
import { ICreateSurveyForm } from '../../interfaces/SurveyInterface';
import { createSurvey, getSurveyDetails, listusersassociatedtosurvey, updateSurvey } from '../../store/actions/surveyAction';
import { validateForm } from '../../utils/formValidation';
import { CREATE_SURVEY_SCHEMA } from '../../validations/surveySchema';
import InfiniteScroll from 'react-infinite-scroll-component';
import EndMessage from '../EndMessage';
import useDebounce from '../../hooks/useDebounce';
import Loader from '../../components/Loader';
import { resetCreateSurvey, resetListUsersAssociatedtoSurveyApi, resetUpdateSurvey } from '../../store/slices/surveySlice';
import { listUsersWithSurveyGoalAssignmentDetails } from '../../store/actions/goalAction';
import { IUserListWithGoalSurveyAssignmentApiParams } from '../../interfaces/GoalInterface';
import { resetListUsersWithSurveyGoalAssignmentDetailsApi } from '../../store/slices/goalSlice';
import { MessageToaster } from '../../utils/ToastUtil';

const priority = [
  { label: 'High', value: 1 },
  { label: 'Medium', value: 2 },
  { label: 'Low', value: 3 },
];

const userListAPIDefaultParams = {
  Availability: true,
  Category: [],
  Completed: false,
  DOJ: '',
  InProgress: false,
  NotStarted: false,
  OrganizationId: '',
  Overdue: false,
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE,
  Rescheduled: false,
  RoleTypeId: RoleTypeIds.APP_USER,
  Search: '',
  SortColumn: UsersSortFields.NAME,
  SortOrder: SortOrder.ASC,
  SubCategories: [],
  GoalOrSurveyID: 0,
  IsGoalOrSurvey: 0,
};
const createSurveyAPIDefaultParams = {
  SurveyId: 0,
  Title: '',
  Description: '',
  CategoryID: 0,
  SubCategoryId: [],
  PriorityId: 0,
  StartTime: '',
  EndTime: '',
  KudosPoint: '',
  AssignedUsers: [],
  OrganizationID: '',
  CreatedEndDateWithTime: '',
  CreatedStartDateWithTime: '',
};
const listUsersBySurveyIdApiDefaultParams = {
  SurveyId: 0,
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE,
};
const SurveyStep1 = (props: any) => {
  // Navigation object
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Toast object creation.
  const toast = new MessageToaster();
  // Ref object creation.
  const inputRef = useRef<any>();
  // Access redux state variables.
  const { getCatergoryListAPiData, getCategoryListApiLoading } = useAppSelector((state: RootState) => state.organization);
  const { listUsersWithSurveyGoalAssignmentDetailsAPiData, listUsersWithSurveyGoalAssignmentDetailsApiLoading, listUsersWithSurveyGoalAssignmentDetailsApiSuccess } =
    useAppSelector((state: RootState) => state.goal);
  const {
    createSurveyApiLoading,
    createSurveyApiResponseCode,
    createSurveyApiSuccess,
    createSurveyApiData,
    surveyDetailApiLoading,
    surveyDetailApiSuccess,
    surveyDetailApiData,
    updateSurveyApiLoading,
    updateSurveyApiResponseCode,
    updateSurveyApiSuccess,
    updateSurveyApiResponseMessage,
    listUsersAssociatedtoSurveyApiLoading,
    listUsersAssociatedtoSurveyApiSuccess,
    listUsersAssociatedtoSurveyApiResponseCode,
    listUsersAssociatedtoSurveyApiData,
  } = useAppSelector((state: RootState) => state.survey);

  const currentTime = new Date();
  // Initialize component state variables.
  const [apiParams, setApiParams] = useState<IUserListWithGoalSurveyAssignmentApiParams>(userListAPIDefaultParams);
  const [categoryList, setCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [subCategoryList, setSubCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOptionsNumber>(DEFAULT_SELECT_OPTION_NUMBER);
  const [selectedSubCategory, setSelectedSubCategory] = useState<ISelectOptionsNumber[]>([]);
  const [selectedPriority, setSelectedPriority] = useState<ISelectOptionsNumber>(DEFAULT_SELECT_OPTION_NUMBER);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [errorFields, setErrorFields] = useState<any>({});
  const [createSurveyForm, setCreateSurveyForm] = useState<ICreateSurveyForm>(createSurveyAPIDefaultParams);
  const [characterCount, setCharacterCount] = useState<number>(0);
  const [subCategoryListForUser, setSubCategoryListForUser] = useState<ISelectOptionsNumber[]>([]);
  const [selectedCategoryForUser, setSelectedCategoryForUser] = useState<ISelectOptionsNumber[]>([]);
  const [selectedSubCategoryForUser, setSelectedSubCategoryForUser] = useState<ISelectOptionsNumber[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [joiningDate, setJoiningDate] = useState<Date | null>(null);
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('');
  const [selectedUserList, setSelectedUserList] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirmedUsers, setConfirmedUsers] = useState<any[]>([]);
  const [selectedUserListForDelete, setSelectedUserListForDelete] = useState<any[]>([]);
  const [isOpenAssignedUsers, setIsOpenAssignedUsers] = useState<boolean>(false);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [isAllSelectedForDelete, setIsAllSelectedForDelete] = useState<boolean>(false);
  const [items, setItems] = useState<any>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [startDateError, setStartDateError] = useState<string>('');
  const [endDateError, setEndDateError] = useState<string>('');
  const [listUsersBySurveyIdApiParams, setlistUsersBySurveyIdApiParams] = useState<any>(listUsersBySurveyIdApiDefaultParams);
  const [confirmedItems, setConfirmedItems] = useState<any>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const phSelect = useIntlActionMessages('PH.Select');
  const labelLoading = useIntlActionMessages('Label.Loading');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // Reset Slice.
  useEffect(() => {
    try {
      return () => {
        dispatch(resetCreateSurvey());
        dispatch(resetUpdateSurvey());
        dispatch(resetListUsersAssociatedtoSurveyApi());
        dispatch(resetListUsersWithSurveyGoalAssignmentDetailsApi());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  useEffect(() => {
    try {
      window.scrollTo(0, 0);
      if (inputRef?.current) {
        inputRef.current.focus();
      }
      // Get category list based on organization
      dispatch(getCategoryList({ OrganizationId: props.orgId }));
      setCreateSurveyForm((info: any) => ({
        ...info,
        OrganizationID: props.orgId,
      }));
      // Get Survey Details while backward navigation
      if (createSurveyApiData?.SurveyId || props.surveyId) {
        const id = parseInt(createSurveyApiData?.SurveyId ?? props.surveyId);
        const surveyDetailsAPIRequest = {
          SurveyId: id,
        };
        dispatch(getSurveyDetails(surveyDetailsAPIRequest));
        setlistUsersBySurveyIdApiParams((apiParams: any) => ({
          ...apiParams,
          Page: DEFAULT_PAGE_INDEX,
          SurveyId: id,
        }));
      } else {
        setApiParams((apiParams: any) => ({
          ...apiParams,
          OrganizationId: props.orgId,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // Set survey data while backward navigation/ Edit survey
  useEffect(() => {
    try {
      if (surveyDetailApiSuccess && surveyDetailApiData) {
        if (createSurveyApiData?.SurveyId || props.surveyId) {
          const surveyId = createSurveyApiData?.SurveyId ?? props.surveyId;
          if (surveyDetailApiData.StartDate != null) {
            const startdate = moment(surveyDetailApiData.StartDate).toDate();
            setStartDate(startdate);
          }
          if (surveyDetailApiData.EndDate != null) {
            const enddate = moment(surveyDetailApiData.EndDate).toDate();
            setEndDate(enddate);
          }
          const category = getCatergoryListAPiData.find((category: any) => category.CategoryId === surveyDetailApiData?.CategoryId?.toString());
          if (surveyDetailApiData?.CategoryName != null && surveyDetailApiData?.CategoryId != null) {
            setSelectedCategory({ label: surveyDetailApiData?.CategoryName, value: surveyDetailApiData?.CategoryId });
            setSelectedCategoryForUser([{ label: surveyDetailApiData?.CategoryName, value: surveyDetailApiData?.CategoryId }]);
          } else {
            setSelectedCategory({ label: 'Select', value: 0 });
          }
          if (category) {
            const subcategoryList = category.Subcategory.map((subcategory: any) => ({
              label: subcategory.classname,
              value: subcategory.classid,
            }));
            setSubCategoryList(subcategoryList);
          }
          const subcategoriesForSelection = surveyDetailApiData?.SubCategoryName?.map((subcategory: any) => ({
            label: subcategory.classname,
            value: subcategory.classid,
          }));
          setSelectedSubCategory(subcategoriesForSelection);
          setSelectedSubCategoryForUser(subcategoriesForSelection);
          const subcategories = surveyDetailApiData?.SubCategoryName?.map((subcategory: any) => subcategory.classid);
          setSelectedPriority({ label: surveyDetailApiData?.PriortyName, value: surveyDetailApiData?.PriorityId });
          setCreateSurveyForm((survey: any) => ({
            ...survey,
            SurveyId: surveyDetailApiData.SurveyId,
            Title: surveyDetailApiData?.Title,
            Description: surveyDetailApiData?.Description,
            CategoryID: surveyDetailApiData?.CategoryId,
            SubCategoryId: subcategories,
            PriorityId: surveyDetailApiData?.PriorityId,
            StartTime: moment(surveyDetailApiData.StartDate).utc().format(DATE_TIME_FORMAT),
            EndTime: moment(surveyDetailApiData.EndDate).utc().format(DATE_TIME_FORMAT),
            KudosPoint: surveyDetailApiData?.KudosPoint,
            AssignedUsers: [],
            OrganizationID: props.orgId,
            CreatedStartDateWithTime: moment(surveyDetailApiData.StartDate).format(DATE_TIME_FORMAT),
            CreatedEndDateWithTime: moment(surveyDetailApiData.EndDate).format(DATE_TIME_FORMAT),
          }));
          setCharacterCount(surveyDetailApiData?.Description?.length);
          // Get Student List based on goal
          const subcategoriesUserList = surveyDetailApiData?.SubCategoryName?.map((subcategory: any) => ({ classid: subcategory.classid }));
          setApiParams((apiParams: any) => ({
            ...apiParams,
            OrganizationId: props.orgId,
            GoalOrSurveyID: surveyId,
            IsGoalOrSurvey: 2,
            Category: [{ categoryid: surveyDetailApiData?.CategoryId }],
            SubCategories: subcategoriesUserList,
          }));
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [surveyDetailApiLoading]);

  // Invoke debounce component on intervel.
  const debouncedSearch = useDebounce(searchText, 500);
  // Set API params on bebounced search.
  useEffect(() => {
    try {
      setApiParams((apiParams: any) => ({
        ...apiParams,
        Search: searchText,
        Page: DEFAULT_PAGE_INDEX,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [debouncedSearch]);
  // Get Student List for assign goal to on search, filter change
  useEffect(() => {
    try {
      if (apiParams.OrganizationId != '' && apiParams.Category && apiParams.Category.length > 0) dispatch(listUsersWithSurveyGoalAssignmentDetails(apiParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [apiParams]);

  useEffect(() => {
    try {
      if (listUsersBySurveyIdApiParams.SurveyId != 0) dispatch(listusersassociatedtosurvey(listUsersBySurveyIdApiParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listUsersBySurveyIdApiParams]);

  // Set assigned users associated with goal while backward navigation
  useEffect(() => {
    try {
      if (listUsersAssociatedtoSurveyApiSuccess && listUsersAssociatedtoSurveyApiResponseCode > 0) {
        if (createSurveyApiData?.SurveyId || props.surveyId) {
          const newItems = listUsersAssociatedtoSurveyApiData?.Users;
          const totalPages = listUsersAssociatedtoSurveyApiData?.TotalCount / listUsersBySurveyIdApiParams.PageSize;
          if (newItems != null && newItems.length > 0) setConfirmedItems((prevItems: any) => (listUsersBySurveyIdApiParams.PageNo === 1 ? newItems : [...prevItems, ...newItems]));
          if (newItems?.length > 0 && newItems?.length === listUsersBySurveyIdApiParams.PageSize && listUsersBySurveyIdApiParams.Page < totalPages) {
            setlistUsersBySurveyIdApiParams((apiParams: any) => ({
              ...apiParams,
              Page: listUsersBySurveyIdApiParams.Page + 1,
            }));
          }
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listUsersAssociatedtoSurveyApiLoading]);

  useEffect(() => {
    try {
      if (confirmedItems?.length > 0) {
        const usersSelected: any[] = [];
        confirmedItems?.forEach((value: any) => {
          usersSelected.push({ id: value.UserId, name: value.UserName, image: value.Image });
        });
        setCreateSurveyForm((info: any) => ({
          ...info,
          AssignedUsers: usersSelected.map((user: any) => user.id),
        }));
        setConfirmedUsers([...usersSelected]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [confirmedItems]);

  // Get category details by organization
  useEffect(() => {
    try {
      if (getCatergoryListAPiData && getCatergoryListAPiData.length > 0) {
        const categoryData = getCatergoryListAPiData.map(
          (category: any): ISelectOptionsNumber => ({
            label: category.CategoryName,
            value: +category.CategoryId,
          }),
        );
        setCategoryList(categoryData);
      } else {
        // If ResponseData is null, update category state with null
        setCategoryList([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getCategoryListApiLoading]);

  // Handle get user list API success
  useEffect(() => {
    try {
      if (listUsersWithSurveyGoalAssignmentDetailsApiSuccess) {
        if (listUsersWithSurveyGoalAssignmentDetailsAPiData) {
          const newItems = listUsersWithSurveyGoalAssignmentDetailsAPiData;
          if (newItems.length > 0) setTotalItems(newItems[0].TotalCount);
          const totalPages = newItems.length > 0 ? newItems[0].TotalCount / apiParams.PageSize : 0;
          setItems((prevItems: any) => (apiParams.Page === 1 ? newItems : [...prevItems, ...newItems]));
          setHasMore(newItems.length > 0 && newItems.length === apiParams.PageSize && apiParams.Page < totalPages);
          setIsAllSelected(false);
        } else {
          setItems([]);
          setHasMore(false);
          setTotalItems(0);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listUsersWithSurveyGoalAssignmentDetailsApiLoading]);

  // Handle create survey Success/Failure.
  useEffect(() => {
    try {
      if (createSurveyApiResponseCode > 0 && createSurveyApiSuccess) {
        if (createSurveyApiData?.SurveyId) props.setSurveyId(createSurveyApiData?.SurveyId);
        props.onApiResponse(1);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [createSurveyApiLoading]);

  // Handle update survey Success/Failure.
  useEffect(() => {
    try {
      if (updateSurveyApiResponseCode > 0 && updateSurveyApiSuccess) {
        props.onApiResponse(1);
      } else if (!updateSurveyApiSuccess && updateSurveyApiResponseCode > 0) {
        toast.toastError(updateSurveyApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [updateSurveyApiLoading]);

  // Get sub categories based on category
  const handleCategoryChange = async (event: any) => {
    try {
      const selectedCategoryId = event.value;
      setSelectedCategory(event);
      const category = getCatergoryListAPiData.find((category: any) => category.CategoryId === selectedCategoryId.toString());
      if (category) {
        const subcategories = category.Subcategory.map((subcategory: any) => ({
          label: subcategory.classname,
          value: subcategory.classid,
        }));
        setSubCategoryList(subcategories);
        setSelectedSubCategory([]);
      } else {
        setSubCategoryList([]);
      }
      setCreateSurveyForm((info: any) => ({
        ...info,
        CategoryID: selectedCategoryId,
      }));
      const validateObj = {
        CategoryID: selectedCategoryId,
      };
      checkValidity(validateObj);
      // set category list of Assign user section
      setCategoryUserList(event);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Sub Category Change
  const handleSubCategoryChange = async (event: any) => {
    try {
      let subcategories: any = [];
      if (event) {
        subcategories = event.map((subcategory: any) => subcategory.value);
        setSelectedSubCategory(event);
        setCreateSurveyForm((info: any) => ({
          ...info,
          SubCategoryId: subcategories,
        }));
      }
      const validateObj = {
        SubCategoryId: subcategories,
      };
      checkValidity(validateObj);
      // set sub category list of Assign user section
      setSubCategoryUserList(event);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Get sub categories based on category
  const setCategoryUserList = (event: any) => {
    try {
      const selectedCategoryId = event.value;
      setSelectedCategoryForUser(event);
      setApiParams((info: any) => ({
        ...info,
        Category: [{ categoryid: selectedCategoryId }],
      }));
      setSubCategoryListForUser([]);
      setConfirmedUsers([]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Set Sub Category User List Based on Goal sub category selection
  const setSubCategoryUserList = (event: any) => {
    try {
      setSubCategoryListForUser(event);
      setSelectedSubCategoryForUser(event);
      const subcategories = event.map((subcategory: any) => ({ classid: subcategory.value }));
      setApiParams((info: any) => ({
        ...info,
        SubCategories: subcategories,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Sub Category change handler.
  const handleSubCategoryChangeUserList = (event: any) => {
    try {
      if (event) {
        const subcategories = event.map((subcategory: any) => ({ classid: subcategory.value }));
        setSelectedSubCategoryForUser(event);
        setApiParams((info: any) => ({
          ...info,
          SubCategories: subcategories,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Priority change
  const handlePriorityChange = async (event: any) => {
    try {
      const selectedPriorityId = event.value;
      setSelectedPriority(event);
      setCreateSurveyForm((info: any) => ({
        ...info,
        PriorityId: selectedPriorityId,
      }));
      const validateObj = {
        PriorityId: selectedPriorityId,
      };
      checkValidity(validateObj);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle Start date change.
  const handleStartDateChange = async (paramValue: any) => {
    try {
      setStartDate(...[paramValue]);
      const startdate = moment(paramValue).utc().format(DATE_TIME_FORMAT);
      setCreateSurveyForm((info: any) => ({
        ...info,
        StartTime: startdate,
        CreatedStartDateWithTime: moment(paramValue).format(DATE_TIME_FORMAT),
      }));
      const validateObj = {
        StartTime: startdate,
      };
      await checkValidity(validateObj);
      const errCode = await validateDate(paramValue, endDate);
      const errMsg = getErrorMessage(errCode.startDateErrorCode, 'startdate');
      setStartDateError(...[errMsg]);
      if (errCode.endDateErrorCode == 0) {
        setEndDateError(...['']);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle end date chnage
  const handleEndDateChange = async (paramValue: any) => {
    try {
      setEndDate(...[paramValue]);
      const enddate = moment(paramValue).utc().format(DATE_TIME_FORMAT);
      setCreateSurveyForm((info: any) => ({
        ...info,
        EndTime: enddate,
        CreatedEndDateWithTime: moment(paramValue).format(DATE_TIME_FORMAT),
      }));
      const validateObj = {
        EndTime: enddate,
      };
      await checkValidity(validateObj);
      const errCode = await validateDate(startDate, paramValue);
      const errMsg = getErrorMessage(errCode.endDateErrorCode, 'enddate');
      setEndDateError(...[errMsg]);
      if (errCode.startDateErrorCode == 0) {
        setStartDateError(...['']);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Validate start date and end date
  const validateDate = async (startDate: any, endDate: any) => {
    const errorCode = { startDateErrorCode: 0, endDateErrorCode: 0 };
    try {
      if (isBeforeOrEqual(startDate, new Date()) || isBeforeOrEqual(endDate, new Date())) {
        if (isBeforeOrEqual(startDate, new Date())) errorCode.startDateErrorCode = 1000;
        if (isBeforeOrEqual(endDate, new Date())) errorCode.endDateErrorCode = 1000;
      } else if (endDate != null && startDate != null && isBeforeOrEqual(endDate, startDate)) {
        errorCode.startDateErrorCode = 1001;
        errorCode.endDateErrorCode = 1001;
      }
    } catch (error) {
      console.log('Error: ', error);
    }
    return errorCode;
  };
  const getErrorMessage = (errorcode: number, field: string): string => {
    try {
      switch (errorcode) {
        case 1000:
          return field == 'startdate' ? 'Start Date & Time should not be earlier than Current Date & Time.' : 'End Date & Time should not be earlier than Current Date & Time.';
        case 1001:
          return field == 'startdate' ? 'Start Date & Time should be earlier than End Date & Time.' : 'End Date & Time should be later than Start Date & Time.';
        default:
          return '';
      }
    } catch (error) {
      console.log('Error: ', error);
      return '';
    }
  };
  // Set form on input change, handle form fields validation.
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      if (name == 'Description') {
        setCharacterCount(event.target.value.length);
      }
      if (name == 'KudosPoint') {
        setCreateSurveyForm((info: any) => ({
          ...info,
          [name]: parseInt(value),
        }));
      } else {
        setCreateSurveyForm((info: any) => ({
          ...info,
          [name]: value,
        }));
      }
      const validateObj = {
        [name]: value,
      };
      checkValidity(validateObj);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle key press
  const handleKeyPress = (event: any) => {
    try {
      if (event.which < 48 || event.which > 57) {
        event.preventDefault();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Submit handler to Add Goal Step 1.
  const onSubmit = async () => {
    try {
      let errCode = { startDateErrorCode: 0, endDateErrorCode: 0 };
      if (startDate != null && endDate != null) {
        errCode = await validateDate(startDate, endDate);
        if (errCode.startDateErrorCode != 0) {
          const startDateMsg = getErrorMessage(errCode.startDateErrorCode, 'startdate');
          setStartDateError(...[startDateMsg]);
        }
        if (errCode.endDateErrorCode != 0) {
          const endDateMsg = getErrorMessage(errCode.endDateErrorCode, 'enddate');
          setEndDateError(...[endDateMsg]);
        }
      }
      if (errCode.startDateErrorCode == 0 && errCode.endDateErrorCode == 0 && startDateError.length <= 0 && endDateError.length <= 0) {
        let errorresult: any = null;
        errorresult = await validateForm(createSurveyForm, CREATE_SURVEY_SCHEMA, errorFields);
        if (Object.keys(errorresult).length === 0) {
          if (createSurveyApiData?.SurveyId || props.surveyId) {
            dispatch(updateSurvey(createSurveyForm));
          } else {
            dispatch(createSurvey(createSurveyForm));
          }
        } else {
          setErrorFields({ ...errorresult });
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Validate fields
  const checkValidity = async (validateObj: any) => {
    try {
      let errorresult: any = [];
      errorresult = await validateForm(validateObj, CREATE_SURVEY_SCHEMA, errorFields);
      setErrorFields({ ...errorresult });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Assign user Select button click.
  const handleAssignUserSelectButtonClick = () => {
    try {
      setCreateSurveyForm((info: any) => ({
        ...info,
        AssignedUsers: selectedUserList.map((user: any) => user.id),
      }));
      setConfirmedUsers([...selectedUserList]);
      setIsOpen(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Assign goal to button click.
  const handleAssignGoaltoClick = () => {
    try {
      setSelectedUserList([...confirmedUsers]);
      setIsOpen(true);
      setSelectAllCheckboxSelection();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Assign Goal to Overlay close
  const handleAssignGoaltoClose = () => {
    try {
      setSelectedUserList([...confirmedUsers]);
      setIsOpen(false);
      handleFilterReset();
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle User Select checkbox click
  const handleUserSelectCheckboxClick = (event: any) => {
    try {
      let usersSelected = selectedUserList;
      const selectedUserId = event.target.id?.split('_')[1];
      const selectedUser = items.find((user: any) => user.UserId === selectedUserId);
      const selectedUserImage = selectedUser.ProfileImage;
      const selectedUserName = selectedUser.Name;
      if (event.target.checked) {
        usersSelected.push({ id: selectedUserId, name: selectedUserName, image: selectedUserImage });
      } else {
        usersSelected = usersSelected.filter((e) => e.id != selectedUserId.toString());
      }
      setSelectedUserList([...usersSelected]);
      setSelectAllCheckboxSelection();
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle select all checbox selection.
  const setSelectAllCheckboxSelection = () => {
    try {
      const checkboxes: HTMLInputElement[] = [];
      const elements = document.querySelectorAll('[id^="overlay1checkbox_"]');
      elements.forEach((element) => {
        checkboxes.push(element as HTMLInputElement);
      });
      let selectall = true;
      if (checkboxes.length > 0) {
        checkboxes.forEach((checkbox) => {
          if (!checkbox.checked) {
            selectall = false;
          }
        });
      } else {
        selectall = false;
      }
      setIsAllSelected(...[selectall]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Select All checkbox click
  const handleSelectAllCheckboxClick = (event: any) => {
    try {
      const usersSelected: any[] = [];
      if (event.target.checked) {
        setIsAllSelected(true);
        items.forEach((value: any) => {
          if (event.target.checked) {
            usersSelected.push({ id: value.UserId, name: value.Name, image: value.ProfileImage });
          }
        });
      } else {
        setIsAllSelected(false);
      }
      setSelectedUserList([...usersSelected]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Assigned users click.
  const handleAssignedUsersClick = () => {
    try {
      setSelectedUserListForDelete([]);
      setIsAllSelectedForDelete(false);
      setIsOpenAssignedUsers(true);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Assignes users overlay close.
  const handleAssignedUsersClose = () => {
    try {
      setIsOpenAssignedUsers(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle User Select checkbox click for Delete
  const handleUserSelectCheckboxClickForDelete = (event: any) => {
    try {
      let usersSelected = selectedUserListForDelete;
      const selectedUserId = event.target.id.split('_')[1];
      if (event.target.checked) {
        usersSelected.push({ id: selectedUserId });
      } else {
        usersSelected = usersSelected.filter((e) => e.id != selectedUserId.toString());
      }
      setSelectedUserListForDelete([...usersSelected]);
      setSelectAllCheckboxForDeleteSelection();
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle select all checbox for delete selection.
  const setSelectAllCheckboxForDeleteSelection = () => {
    try {
      const checkboxes: HTMLInputElement[] = [];
      const elements = document.querySelectorAll('[id^="checkbox_"]');
      elements.forEach((element) => {
        checkboxes.push(element as HTMLInputElement);
      });
      let selectall = true;
      checkboxes.forEach((checkbox) => {
        if (!checkbox.checked) {
          selectall = false;
        }
      });
      setIsAllSelectedForDelete(...[selectall]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Select All checkbox click
  const handleSelectAllCheckboxClickForDelete = (event: any) => {
    try {
      const usersSelected: any[] = [];
      if (event.target.checked) {
        setIsAllSelectedForDelete(true);
        confirmedUsers.forEach((value: any) => {
          if (event.target.checked) {
            usersSelected.push({ id: value.id });
          }
        });
      } else {
        setIsAllSelectedForDelete(false);
      }
      setSelectedUserListForDelete([...usersSelected]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Delete button click
  const handleDeleteButtonClick = () => {
    try {
      let usersSelected: any[] = [];
      usersSelected = confirmedUsers.filter((user) => {
        return !selectedUserListForDelete.some((selectedUser) => selectedUser.id === user.id);
      });
      setCreateSurveyForm((info: any) => ({
        ...info,
        AssignedUsers: usersSelected.map((user: any) => user.id),
      }));
      setConfirmedUsers([...usersSelected]);
      setSelectedUserList([...usersSelected]);
      setSelectAllCheckboxSelection();
      setIsOpenAssignedUsers(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Cancel button click
  const handleCancelButtonClick = () => {
    try {
      setIsOpenAssignedUsers(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Search field change event.
  const handleSearchChange = (event: any) => {
    try {
      setSearchText(event.target.value);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Search Click.
  const handleSearchClick = () => {
    try {
      setApiParams((apiParams: any) => ({
        ...apiParams,
        Search: searchText,
        Page: DEFAULT_PAGE_INDEX,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Search Field input key down.
  const handleSearchKeyDown = (event: any) => {
    try {
      if (event.key === 'Enter') {
        event.preventDefault();
        setApiParams((apiParams: any) => ({
          ...apiParams,
          Search: searchText,
          Page: DEFAULT_PAGE_INDEX,
        }));
      } else {
        setSearchText(event.target.value);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Joining Date change event.
  const handleJoiningDateChange = (paramValue: any) => {
    try {
      setJoiningDate(paramValue);
      setApiParams((info: any) => ({
        ...info,
        DOJ: moment(paramValue).format(DATE_FORMAT1),
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Sort header change event.
  const changeSortField = (field: string) => {
    try {
      let newSortOrder = 'ASC';
      if (field === sortField) {
        newSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
      }
      setApiParams((info: any) => ({
        ...info,
        SortColumn: field,
        SortOrder: newSortOrder,
      }));
      setSortField(field);
      setSortOrder(newSortOrder);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle reset filter
  const handleFilterReset = () => {
    try {
      setSelectedUserList([...confirmedUsers]);
      setApiParams((apiParams: any) => ({
        ...apiParams,
        DOJ: '',
        Page: DEFAULT_PAGE_INDEX,
        PageSize: PAGE_SIZE,
        Search: '',
        SortColumn: UsersSortFields.NAME,
        SortOrder: SortOrder.ASC,
        SubCategories: [],
      }));
      setSearchText('');
      setJoiningDate(null);
      setIsOpen(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Fetching next page items
  const fetchMoreData = () => {
    try {
      setApiParams((info: any) => ({
        ...info,
        Page: info.Page + 1,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  return (
    <>
      <div className="content-sub">
        <div className="content-area-padding">
          <div className="d-flex justify-content-center mb-3">
            <Col xl={6} lg={7}>
              <h3 className="text-center">
                <FormattedMessage id="Hd.AddSurvey" />
              </h3>
            </Col>
          </div>
          <Col className="justify-content-center d-flex">
            <Col xl={10}>
              <Row>
                <Col xl={6}>
                  <Input
                    ref={inputRef}
                    label={useIntlMessages('Label.NameofSurvey')}
                    id="Survey"
                    name="Title"
                    type="text"
                    placeholder={useIntlMessages('PH.EnterNameofSurvey')}
                    maxLength={NumberValues.NUM_100}
                    value={createSurveyForm.Title}
                    onChange={onInputHandleChange}
                    errorMessage={errorFields?.Title}
                  />
                </Col>
                <Col xl={12} className="addOrg-textarea">
                  <Input
                    label={useIntlMessages('Label.Description')}
                    id="Description"
                    name="Description"
                    type="text"
                    placeholder={useIntlMessages('PH.SurveyDescription')}
                    maxLength={NumberValues.NUM_1000}
                    as="textarea"
                    value={createSurveyForm.Description}
                    onChange={onInputHandleChange}
                    errorMessage={errorFields?.Description}
                  />
                  <span className="txt-count">
                    {characterCount}/{NumberValues.NUM_1000}
                  </span>
                </Col>
                <Col xl={6}>
                  <Select
                    label={useIntlMessages('Label.Category')}
                    options={categoryList}
                    value={selectedCategory}
                    placeholder={phSelect}
                    onChange={(e: any) => handleCategoryChange(e)}
                    error={errorFields?.CategoryID}
                    isDisabled={props.surveyId ? true : false}
                  />
                </Col>
                <Col xl={6} className="position-relative z-index-2">
                  <Select
                    label={useIntlMessages('Label.SubCategories')}
                    options={subCategoryList}
                    value={selectedSubCategory}
                    placeholder={phSelect}
                    onChange={(e: any) => handleSubCategoryChange(e)}
                    isMulti
                    error={errorFields?.SubCategoryId}
                    isDisabled={props.surveyId ? true : false}
                  />
                </Col>
                <Col xl={6}>
                  <Select
                    label={useIntlMessages('Label.SetPriority')}
                    id="Priority"
                    name="Priority"
                    options={priority}
                    value={selectedPriority}
                    placeholder={useIntlMessages('PH.Select')}
                    onChange={(e: any) => handlePriorityChange(e)}
                    error={errorFields?.PriorityId}
                  />
                </Col>
                <Col xl={6}>
                  <label className="form-label">
                    <FormattedMessage id="Label.AssignedSurveyto" />{' '}
                  </label>
                  <Row>
                    <Col xs="auto" className="align-items-center d-flex assign-users">
                      <div className="custom-berger-menu">
                        <Filter
                          onOpen={handleAssignedUsersClick}
                          onClose={handleAssignedUsersClose}
                          isOpen={isOpenAssignedUsers}
                          width={450}
                          right
                          pageWrapId={'filter-wrapper'}
                          outerContainerId={'outer-container'}
                          customCrossIcon={<img src={Close} />}
                          customBurgerIcon={
                            <div className="d-flex align-items-center multiple-user">
                              <span className="td-user-image">
                                <img src={UserImg} className="w-100" alt="user" />
                              </span>
                              <span className="td-user-image">
                                <img src={UserImg} className="w-100" alt="user" />
                              </span>
                              <span className="td-user-image">
                                <img src={UserImg} className="w-100" alt="user" />
                              </span>
                              +{confirmedUsers.length} {confirmedUsers.length == 1 ? <FormattedMessage id="Label.Member" /> : <FormattedMessage id="Label.Members" />}
                            </div>
                          }
                        >
                          <div id="filter-wrapper" className="filter-main">
                            <Scrollbar className="h-80vh">
                              <Table striped hover responsive size="sm">
                                <thead>
                                  <tr>
                                    <th className="w-50px">
                                      <div className="no-margin">
                                        <Checkbox
                                          disabled={confirmedUsers && confirmedUsers.length > 0 ? false : true}
                                          type="Checkbox"
                                          checked={isAllSelectedForDelete}
                                          onChange={handleSelectAllCheckboxClickForDelete}
                                        />
                                      </div>
                                    </th>
                                    <th>
                                      <FormattedMessage id="Label.Name" />{' '}
                                      <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                                        <Sort />
                                      </Link>
                                    </th>
                                    <th className="w-50px">
                                      <Button
                                        disabled={confirmedUsers && confirmedUsers.length > 0 ? false : true}
                                        variant="dark"
                                        size="sm"
                                        className="icon-btn"
                                        onClick={() => handleDeleteButtonClick()}
                                      >
                                        <Delete />
                                      </Button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {confirmedUsers &&
                                    confirmedUsers.length > 0 &&
                                    confirmedUsers.map((user: any) => (
                                      <tr>
                                        <td>
                                          <div className="no-margin">
                                            <Checkbox
                                              checked={selectedUserListForDelete.map((x: any) => x.id).includes(user.id)}
                                              class="assigned-users-checkbox"
                                              type="Checkbox"
                                              name={'checkbox_' + user.id}
                                              id={'checkbox_' + user.id}
                                              onChange={handleUserSelectCheckboxClickForDelete}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                              <img src={user.image ? BUCKET_URL + user.image : user.image ? BUCKET_URL + user.image : UserImg} className="w-100" alt="user" />
                                            </span>
                                            {user.name}
                                          </div>
                                        </td>
                                        <td></td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </Scrollbar>
                            <Row className="mt-4 pb-4">
                              <Col>
                                <Button variant="outline-primary" className="w-100" onClick={() => handleCancelButtonClick()}>
                                  <FormattedMessage id="Button.Cancel" />
                                </Button>
                              </Col>
                              <Col>
                                <Button variant="primary" className="w-100" onClick={() => handleCancelButtonClick()}>
                                  <FormattedMessage id="Button.Done" />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Filter>
                      </div>
                    </Col>
                    <Col className="assign-users">
                      <div className="custom-berger-menu">
                        <Filter
                          onOpen={handleAssignGoaltoClick}
                          onClose={handleAssignGoaltoClose}
                          isOpen={isOpen}
                          width={450}
                          right
                          pageWrapId={'filter-wrapper'}
                          outerContainerId={'outer-container'}
                          customCrossIcon={<img src={Close} />}
                          customBurgerIcon={
                            <Button variant="secondary" className="w-100">
                              <FormattedMessage id="Button.AssignSurveyTo" />
                            </Button>
                          }
                        >
                          <div id="filter-wrapper" className="filter-main">
                            <Col sm={12}>
                              <div className="d-flex justify-content-between align-items-center mb-4">
                                <h4 className="mb-0">
                                  <FormattedMessage id="Hd.Users" />
                                </h4>
                              </div>
                              <Col className="form-sm">
                                <Search
                                  value={searchText}
                                  onChange={handleSearchChange}
                                  onKeyDown={handleSearchKeyDown}
                                  onClick={handleSearchClick}
                                  placeholder={useIntlMessages('PH.SearchName')}
                                  size="sm"
                                />
                              </Col>
                              <Col>
                                <div className="form-sm d-flex">
                                  <Col className="pe-2">
                                    <Select
                                      label={useIntlMessages('Label.Category')}
                                      options={categoryList}
                                      value={selectedCategoryForUser}
                                      placeholder={useIntlMessages('PH.Select')}
                                      isDisabled={true}
                                    />
                                  </Col>
                                  <Col className="ps-2">
                                    <Select
                                      label={useIntlMessages('Label.SubCategory')}
                                      id="ClassID"
                                      name="ClassID"
                                      options={subCategoryListForUser}
                                      value={selectedSubCategoryForUser}
                                      placeholder={useIntlMessages('PH.Select')}
                                      onChange={(e: any) => handleSubCategoryChangeUserList(e)}
                                      isMulti
                                    />
                                  </Col>
                                </div>
                              </Col>
                              <Col>
                                <div className="custom-calendar mb-4 form-sm">
                                  <label className="form-label">
                                    <FormattedMessage id="Label.DateofJoining" />
                                  </label>
                                  <DatePicker selected={joiningDate} onChange={(date) => handleJoiningDateChange(date)} onKeyDown={(e) => e.preventDefault()} />
                                </div>
                              </Col>
                              <InfiniteScroll
                                dataLength={items?.length}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={items?.length > 0 && <h3>{labelLoading}</h3>}
                                height={300}
                                endMessage={<EndMessage />}
                              >
                                <Table striped hover responsive size="sm" className="mt-2">
                                  <thead>
                                    <tr>
                                      <th className="w-50px">
                                        <div className="no-margin">
                                          <Checkbox
                                            disabled={items && items.length > 0 ? false : true}
                                            type="Checkbox"
                                            checked={isAllSelected}
                                            onChange={handleSelectAllCheckboxClick}
                                          />
                                        </div>
                                      </th>
                                      <th>
                                        <FormattedMessage id="Label.Name" />{' '}
                                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                                          <Sort />
                                        </Link>
                                      </th>
                                      <th className="w-75px"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {items &&
                                      items.length > 0 &&
                                      items.map((user: any) => (
                                        <tr>
                                          <td>
                                            <div className="no-margin">
                                              <Checkbox
                                                checked={selectedUserList.map((user: any) => user.id).includes(user.UserId)}
                                                name={'overlay1checkbox_' + user.UserId}
                                                id={'overlay1checkbox_' + user.UserId}
                                                type="Checkbox"
                                                onChange={handleUserSelectCheckboxClick}
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <span className="td-user-image">
                                                <img src={user.ProfileImage ? BUCKET_URL + user.ProfileImage : UserImg} className="w-100" alt="user" />
                                              </span>
                                              {user.Name}
                                            </div>
                                          </td>
                                          <td>
                                            <FormattedMessage id="Label.Active" />
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </Table>
                              </InfiniteScroll>
                            </Col>

                            <Row className="mt-3">
                              <Col>
                                <Button variant="outline-primary" className="w-100" onClick={() => handleFilterReset()}>
                                  <FormattedMessage id="Button.Cancel" />
                                </Button>
                              </Col>
                              <Col>
                                <Button variant="primary" className="w-100" onClick={() => handleAssignUserSelectButtonClick()}>
                                  <FormattedMessage id="Button.Select" /> ({selectedUserList.length}/{totalItems})
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Filter>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xl={6}>
                  <div className="custom-calendar mb-4">
                    <label className="form-label">
                      <FormattedMessage id="Label.StartDateTime" />
                    </label>
                    <DatePicker
                      minDate={currentTime}
                      maxDate={endDate}
                      selected={startDate}
                      onChange={(date) => handleStartDateChange(date)}
                      showTimeSelect
                      timeIntervals={1}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      onKeyDown={(e) => e.preventDefault()}
                      placeholderText="Select Start Date & Time"
                    />
                    {errorFields?.StartTime?.length > 0 ? <label className="error">{errorFields.StartTime}</label> : null}
                    {startDateError?.length > 0 ? <label className="error">{startDateError}</label> : null}
                  </div>
                </Col>
                <Col xl={6}>
                  <div className="custom-calendar mb-4">
                    <label className="form-label">
                      <FormattedMessage id="Label.EndDateTime" />
                    </label>
                    <DatePicker
                      minDate={startDate}
                      selected={endDate}
                      onChange={(date) => handleEndDateChange(date)}
                      showTimeSelect
                      timeIntervals={1}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      onKeyDown={(e) => e.preventDefault()}
                      placeholderText="Select End Date & Time"
                    />
                    {errorFields?.EndTime?.length > 0 ? <label className="error">{errorFields.EndTime}</label> : null}
                    {endDateError?.length > 0 ? <label className="error">{endDateError}</label> : null}
                  </div>
                </Col>
              </Row>
              <div className="text-center mt-4">
                <h3>
                  <FormattedMessage id="Hd.SetKudos" />
                </h3>
                <p>
                  <FormattedMessage id="SubHd.SetKudosSurvey" />
                </p>
              </div>
              <div className="d-flex justify-content-center">
                <Col xl={3}>
                  <Input
                    name="KudosPoint"
                    label={useIntlMessages('Label.Points')}
                    id="KudosPoint"
                    type="text"
                    placeholder={useIntlActionMessages('Form.Goals.kudos.Add')}
                    value={createSurveyForm?.KudosPoint}
                    maxLength={NumberValues.NUM_4}
                    onChange={onInputHandleChange}
                    errorMessage={errorFields?.KudosPoint}
                    onKeyPress={handleKeyPress}
                  />
                </Col>
              </div>
              <div className="btn-container d-flex justify-content-end my-4">
                <Button variant="outline-primary" onClick={() => navigate('/activities', { state: { tabId: 2 } })}>
                  <FormattedMessage id="Button.Cancel" />
                </Button>
                <Button variant="primary" onClick={onSubmit}>
                  <FormattedMessage id="Button.SaveAndNext" />
                </Button>
              </div>
            </Col>
          </Col>
          {createSurveyApiLoading && <Loader />}
        </div>
      </div>
    </>
  );
};
export default SurveyStep1;
