/**
 * @file   src\containers\organizations\GoalStep1.tsx
 * @brief  Goal add first step.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect, useRef } from 'react';
import '../../assets/css/GoalSurvay.scss';
import { FormattedMessage } from 'react-intl';
import { isBeforeOrEqual, useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import { Button, Col, Table } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Input from '../../components/MAInput';
import Select from '../../components/MASelect';
import { useNavigate, Link } from 'react-router-dom';
import Checkbox from '../../components/MACheck';
import UserImg from '../../assets/img/Userimg.jpg';
import DatePicker from 'react-datepicker';
import { slide as Filter } from 'react-burger-menu';
import Close from '../../assets/img/Close.svg';
import Sort from '../../assets/img/icon/Sort';
import Delete from '../../assets/img/icon/Delete';
import Search from '../../components/MASearch';
import { Scrollbar } from 'react-scrollbars-custom';
import ImageUpload from '../../components/ImageUpload';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getCategoryList, addGoalStepOne, uploadFile, uploadFilePathUpdate, getGoalDetail, updateGoal, getUsersByGoalId } from '../../store/actions/organizationActions';
import { RootState } from '../../store';
import { ISelectOptionsNumber } from '../../interfaces/GeneralInterface';
import { BUCKET_URL, DATE_FORMAT1, DATE_TIME_FORMAT, DEFAULT_PAGE_INDEX, DEFAULT_SELECT_OPTION_NUMBER, PAGE_SIZE } from '../../utils/constants';
import { IAddGoalStepOneForm, IGetUsersByGoalIdAPIRequest, IGoalViewAPIRequest, IUploadFilePathUpdateParams } from '../../interfaces/OrganisationInterface';
import { IMAGE_TYPES_SUPPORTED, NumberValues, RoleTypeIds, SortOrder, UploaderProps, UsersSortFields } from '../../utils/enums';
import { validateForm } from '../../utils/formValidation';
import { ADD_GOAL_STEP_ONE_SCHEMA, ADD_GENERAL_GOAL_STEP_ONE_SCHEMA } from '../../validations/addGoalStepOneSchema';
import { MessageToaster } from '../../utils/ToastUtil';
import moment from 'moment';
import Loader from '../../components/Loader';
import { resetUploadFile, resetaddGoalStepOne, resetUpdateGoalStepOne, resetGetUsersByGoalIdApi } from '../../store/slices/organizationSlice';
import useDebounce from '../../hooks/useDebounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import EndMessage from '../EndMessage';
import DeleteConfirmPopup from '../../components/DeleteConfirmation';
import { listUsersWithSurveyGoalAssignmentDetails } from '../../store/actions/goalAction';
import { IUserListWithGoalSurveyAssignmentApiParams } from '../../interfaces/GoalInterface';
import { resetListUsersWithSurveyGoalAssignmentDetailsApi } from '../../store/slices/goalSlice';

const goalType = [
  { label: 'Big Goal', value: 1 },
  { label: 'Yearly Goal', value: 2 },
  { label: 'Terms Goal', value: 3 },
  { label: 'Quarterly Goal', value: 4 },
  { label: 'Monthly Goal', value: 5 },
  { label: 'Weekly Goal', value: 6 },
  { label: 'Daily Goal', value: 7 },
  { label: 'Hourly Goal', value: 8 },
];
const priority = [
  { label: 'High', value: 1 },
  { label: 'Medium', value: 2 },
  { label: 'Low', value: 3 },
];
const userListAPIDefaultParams = {
  Availability: true,
  Category: [],
  Completed: false,
  DOJ: '',
  InProgress: false,
  NotStarted: false,
  OrganizationId: '',
  Overdue: false,
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE,
  Rescheduled: false,
  RoleTypeId: RoleTypeIds.APP_USER,
  Search: '',
  SortColumn: UsersSortFields.NAME,
  SortOrder: SortOrder.ASC,
  SubCategories: [],
  GoalOrSurveyID: 0,
  IsGoalOrSurvey: 0,
};
const listUsersByGoalIdApiDefaultParams = {
  GoalId: 0,
  PageNo: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE,
};
const GoalStep1 = (props: any) => {
  // Navigation object
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Toast object creation.
  const toast = new MessageToaster();
  // Access redux state variables.
  const {
    getCatergoryListAPiData,
    getCategoryListApiLoading,
    addGoalStepOneApiLoading,
    addGoalStepOneApiSuccess,
    addGoalStepOneAPiData,
    addGoalStepOneApiResponseCode,
    addGoalStepOneApiResponseMessage,
    uploadFileApiLoading,
    uploadFileResponseCode,
    uploadFileResponseMessage,
    uploadFileApiSuccess,
    uploadFileApiData,
    uploadFilePathUpdateApiLoading,
    uploadFilePathUpdateApiSuccess,
    uploadFilePathUpdateResponseCode,
    uploadFilePathUpdateResponseMessage,
    getGoalDetailApiLoading,
    getGoalDetailApiSuccess,
    getGoalDetailAPiData,
    updateGoalApiLoading,
    updateGoalApiSuccess,
    updateGoalAPiData,
    updateGoalApiResponseCode,
    updateGoalApiResponseMessage,
    getUsersByGoalIdApiLoading,
    getUsersByGoalIdAPiData,
    getUsersByGoalIdApiSuccess,
  } = useAppSelector((state: RootState) => state.organization);
  const imageRef = useRef<any>();
  // Accessing redux state variables
  const { listUsersWithSurveyGoalAssignmentDetailsAPiData, listUsersWithSurveyGoalAssignmentDetailsApiLoading, listUsersWithSurveyGoalAssignmentDetailsApiSuccess } =
    useAppSelector((state: RootState) => state.goal);
  const userData: any = localStorage.getItem('MI_USR_DATA');
  const userObject = JSON.parse(userData);
  const currentTime = new Date();
  // Component state values.
  const [addGoalStepOneForm, setAddGoalStepOneForm] = useState<IAddGoalStepOneForm>({
    GoalId: '',
    CategoryID: 0,
    ClassID: [],
    CreatedUserRoleID: userObject.RoleId,
    Description: '',
    GoalName: '',
    GoalTypeID: 0,
    OrganizationID: props.orgId,
    GoalImage: '',
    GoalReview: 0,
    IsGeneral: false,
    Priority: 0,
    StartDateWithTime: '',
    EndDateWithTime: '',
    UserIds: [],
    Viewers: [],
    CreatedEndDateWithTime: '',
    CreatedStartDateWithTime: '',
  });
  // Initialize component state variables.
  const [categoryList, setCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [subCategoryList, setSubCategoryList] = useState<ISelectOptionsNumber[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOptionsNumber>(DEFAULT_SELECT_OPTION_NUMBER);
  const [selectedSubCategory, setSelectedSubCategory] = useState<ISelectOptionsNumber[]>([]);
  const [selectedGoalType, setSelectedGoalType] = useState<ISelectOptionsNumber>(DEFAULT_SELECT_OPTION_NUMBER);
  const [selectedPriority, setSelectedPriority] = useState<ISelectOptionsNumber>(DEFAULT_SELECT_OPTION_NUMBER);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [errorFields, setErrorFields] = useState<any>({});
  const [imgError, setImgError] = useState<string>('');
  const [upImage, setUpImage] = useState<string | null>(null);
  const [upImageDetails, setUpImageDetails] = useState<any>({});
  const [isGeneral, setIsGeneral] = useState<boolean>(false);
  const ImageUploadChooseImage = useIntlActionMessages('Image.Upload.ChooseImage');
  const uploadFailed = useIntlActionMessages('Image.Upload.Failed');
  const [apiParams, setApiParams] = useState<IUserListWithGoalSurveyAssignmentApiParams>(userListAPIDefaultParams);
  const [searchText, setSearchText] = useState<string>('');
  const [subCategoryListForUser, setSubCategoryListForUser] = useState<ISelectOptionsNumber[]>([]);
  const [selectedCategoryUserList, setSelectedCategoryUserList] = useState<ISelectOptionsNumber[]>([]);
  const [selectedSubCategoryUserList, setSelectedSubCategoryUserList] = useState<ISelectOptionsNumber[]>([]);
  const [joiningDate, setJoiningDate] = useState<Date | null>(null);
  const [selectedUserList, setSelectedUserList] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [confirmedUsers, setConfirmedUsers] = useState<any[]>([]);
  const [selectedUserListForDelete, setSelectedUserListForDelete] = useState<any[]>([]);
  const [isOpenAssignedUsers, setIsOpenAssignedUsers] = useState<boolean>(false);
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('');
  const [isOnSubmit, setIsOnSubmit] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [startDateError, setStartDateError] = useState<string>('');
  const [endDateError, setEndDateError] = useState<string>('');
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [isAllSelectedForDelete, setIsAllSelectedForDelete] = useState<boolean>(false);
  const [characterCount, setCharacterCount] = useState<number>(0);
  const [items, setItems] = useState<any>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isShowRemoveAssigneeConfirmPopup, setIsShowRemoveAssigneeConfirmPopup] = useState<boolean>(false);
  const [listUsersByGoalIdApiParams, setlistUsersByGoalIdApiParams] = useState<IGetUsersByGoalIdAPIRequest>(listUsersByGoalIdApiDefaultParams);
  const [confirmedItems, setConfirmedItems] = useState<any>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const labelCategory = useIntlActionMessages('Label.Category');
  const phSelect = useIntlActionMessages('PH.Select');
  const labelSubCategory = useIntlActionMessages('Label.SubCategory');
  const labelLoading = useIntlActionMessages('Label.Loading');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // Invoke debounce component on intervel.
  const debouncedSearch = useDebounce(searchText, 500);
  // Set API params on bebounced search.
  useEffect(() => {
    try {
      setApiParams((apiParams: any) => ({
        ...apiParams,
        Search: searchText,
        Page: DEFAULT_PAGE_INDEX,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [debouncedSearch]);
  // Reset Slice.
  useEffect(() => {
    try {
      return () => {
        dispatch(resetaddGoalStepOne());
        dispatch(resetUpdateGoalStepOne());
        dispatch(resetGetUsersByGoalIdApi());
        dispatch(resetListUsersWithSurveyGoalAssignmentDetailsApi());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  useEffect(() => {
    try {
      window.scrollTo(0, 0);
      // Get category list based on organization
      dispatch(getCategoryList({ OrganizationId: props.orgId }));
      // Get Goal Details while backward navigation
      if (addGoalStepOneAPiData?.GoalID || props.goalId) {
        const id = addGoalStepOneAPiData?.GoalID ?? props.goalId;
        const goalDetailsAPIRequest: IGoalViewAPIRequest = {
          goalId: id,
        };
        dispatch(getGoalDetail(goalDetailsAPIRequest));
        setlistUsersByGoalIdApiParams((apiParams: any) => ({
          ...apiParams,
          GoalId: id,
          PageNo: DEFAULT_PAGE_INDEX,
        }));
      } else {
        // Get Student List
        setApiParams((apiParams: any) => ({
          ...apiParams,
          OrganizationId: props.orgId,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // Set goal data while backward navigation/ Edit goal
  useEffect(() => {
    try {
      if (getGoalDetailApiSuccess && getGoalDetailAPiData) {
        if (addGoalStepOneAPiData?.GoalID || props.goalId) {
          const goalId = addGoalStepOneAPiData?.GoalID ?? props.goalId;
          setPreviewImage(getGoalDetailAPiData?.GoalImage);
          const imgInfo = {
            name: getGoalDetailAPiData?.GoalImage?.split('.')[0],
            type: getGoalDetailAPiData?.GoalImage?.split('.')[1],
          };
          setPicture(imgInfo);
          if (getGoalDetailAPiData.StartDate != null) {
            const startdate = moment(getGoalDetailAPiData.StartDate).toDate();
            setStartDate(startdate);
          }
          if (getGoalDetailAPiData.EndDate != null) {
            const enddate = moment(getGoalDetailAPiData.EndDate).toDate();
            setEndDate(enddate);
          }
          const category = getCatergoryListAPiData.find((category: any) => category.CategoryId === getGoalDetailAPiData?.CategoryId?.toString());
          if (getGoalDetailAPiData?.CategoryName != null && getGoalDetailAPiData?.CategoryId != null) {
            setSelectedCategory({ label: getGoalDetailAPiData?.CategoryName, value: getGoalDetailAPiData?.CategoryId });
            setSelectedCategoryUserList([{ label: getGoalDetailAPiData?.CategoryName, value: getGoalDetailAPiData?.CategoryId }]);
          } else {
            setSelectedCategory({ label: 'Select', value: 0 });
          }
          if (category) {
            const subcategoryList = category.Subcategory.map((subcategory: any) => ({
              label: subcategory.classname,
              value: subcategory.classid,
            }));
            setSubCategoryList(subcategoryList);
          }
          const subcategoriesForSelection = getGoalDetailAPiData?.Class?.map((subcategory: any) => ({
            label: subcategory.classname,
            value: subcategory.classid,
          }));
          setSelectedSubCategory(subcategoriesForSelection);
          setSelectedSubCategoryUserList(subcategoriesForSelection);
          const subcategories = getGoalDetailAPiData?.Class?.map((subcategory: any) => subcategory.classid);
          setSelectedGoalType({ label: getGoalDetailAPiData?.GoalType, value: getGoalDetailAPiData?.GoalTypeId });
          setSelectedPriority({ label: getGoalDetailAPiData?.Priority, value: getGoalDetailAPiData?.PriorityId });
          setIsGeneral(getGoalDetailAPiData?.IsGeneral);
          setAddGoalStepOneForm((goal: any) => ({
            ...goal,
            GoalId: getGoalDetailAPiData.GoalId.toString(),
            CategoryID: getGoalDetailAPiData?.CategoryId,
            ClassID: subcategories,
            CreatedUserRoleID: userObject.RoleId,
            Description: getGoalDetailAPiData?.Description,
            GoalName: getGoalDetailAPiData?.GoalName,
            GoalTypeID: getGoalDetailAPiData?.GoalTypeId,
            OrganizationID: props.orgId,
            GoalImage: '',
            GoalReview: 0,
            IsGeneral: getGoalDetailAPiData?.IsGeneral,
            Priority: getGoalDetailAPiData?.PriorityId,
            StartDateWithTime: moment(getGoalDetailAPiData.StartDate).utc().format(DATE_TIME_FORMAT),
            EndDateWithTime: moment(getGoalDetailAPiData.EndDate).utc().format(DATE_TIME_FORMAT),
            Viewers: [],
            CreatedStartDateWithTime: moment(getGoalDetailAPiData.StartDate).format(DATE_TIME_FORMAT),
            CreatedEndDateWithTime: moment(getGoalDetailAPiData.EndDate).format(DATE_TIME_FORMAT),
          }));
          setCharacterCount(getGoalDetailAPiData?.Description?.length);
          // Get Student List based on goal
          const subcategoriesUserList = getGoalDetailAPiData?.Class?.map((subcategory: any) => ({ classid: subcategory.classid }));
          setApiParams((apiParams: any) => ({
            ...apiParams,
            OrganizationId: props.orgId,
            GoalOrSurveyID: goalId,
            IsGoalOrSurvey: 1,
            Category: [{ categoryid: getGoalDetailAPiData?.CategoryId }],
            SubCategories: subcategoriesUserList,
          }));
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getGoalDetailApiLoading]);

  // Set assigned users associated with goal while backward navigation
  useEffect(() => {
    try {
      if (getUsersByGoalIdApiSuccess && getUsersByGoalIdAPiData) {
        if (addGoalStepOneAPiData?.GoalID || props.goalId) {
          const newItems = getUsersByGoalIdAPiData?.Users;
          const totalPages = getUsersByGoalIdAPiData?.TotalCount / listUsersByGoalIdApiParams.PageSize;
          if (newItems != null && newItems.length > 0) setConfirmedItems((prevItems: any) => (listUsersByGoalIdApiParams.PageNo === 1 ? newItems : [...prevItems, ...newItems]));
          if (newItems?.length > 0 && newItems?.length === listUsersByGoalIdApiParams.PageSize && listUsersByGoalIdApiParams.PageNo < totalPages) {
            setlistUsersByGoalIdApiParams((apiParams: any) => ({
              ...apiParams,
              PageNo: listUsersByGoalIdApiParams.PageNo + 1,
            }));
          }
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getUsersByGoalIdApiLoading]);

  useEffect(() => {
    try {
      if (confirmedItems?.length > 0) {
        const usersSelected: any[] = [];
        confirmedItems?.forEach((value: any) => {
          usersSelected.push({ id: value.UserId, name: value.UserName, image: value.Image });
        });
        setAddGoalStepOneForm((info: any) => ({
          ...info,
          UserIds: usersSelected.map((user: any) => user.id),
        }));
        setConfirmedUsers([...usersSelected]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [confirmedItems]);

  // Get Student List for assign goal to on search, filter change
  useEffect(() => {
    try {
      if (apiParams.OrganizationId != '' && apiParams.Category && apiParams.Category.length > 0) dispatch(listUsersWithSurveyGoalAssignmentDetails(apiParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [apiParams]);

  useEffect(() => {
    try {
      if (listUsersByGoalIdApiParams.GoalId != 0) dispatch(getUsersByGoalId(listUsersByGoalIdApiParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listUsersByGoalIdApiParams]);

  // Handle get user list API success
  useEffect(() => {
    try {
      if (listUsersWithSurveyGoalAssignmentDetailsApiSuccess) {
        if (listUsersWithSurveyGoalAssignmentDetailsAPiData) {
          const newItems = listUsersWithSurveyGoalAssignmentDetailsAPiData;
          if (newItems.length > 0) setTotalItems(newItems[0].TotalCount);
          const totalPages = newItems.length > 0 ? newItems[0].TotalCount / apiParams.PageSize : 0;
          setItems((prevItems: any) => (apiParams.Page === 1 ? newItems : [...prevItems, ...newItems]));
          setHasMore(newItems.length > 0 && newItems.length === apiParams.PageSize && apiParams.Page < totalPages);
          setIsAllSelected(false);
        } else {
          setItems([]);
          setHasMore(false);
          setTotalItems(0);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listUsersWithSurveyGoalAssignmentDetailsApiLoading]);

  // Get category details by organization
  useEffect(() => {
    try {
      if (getCatergoryListAPiData && getCatergoryListAPiData.length > 0) {
        const categoryData = getCatergoryListAPiData.map(
          (category: any): ISelectOptionsNumber => ({
            label: category.CategoryName,
            value: +category.CategoryId,
          }),
        );
        setCategoryList(categoryData);
      } else {
        // If ResponseData is null, update category state with null
        setCategoryList([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [getCategoryListApiLoading]);
  // Handle upload file path update Success/Failure.
  useEffect(() => {
    try {
      if (isOnSubmit && uploadFilePathUpdateResponseCode > 0 && !uploadFilePathUpdateApiSuccess) {
        toast.toastError(uploadFilePathUpdateResponseMessage);
        setIsOnSubmit(false);
      } else if (isOnSubmit && uploadFilePathUpdateResponseCode > 0 && uploadFilePathUpdateApiSuccess) {
        setIsOnSubmit(false);
        if (addGoalStepOneAPiData?.GoalID) props.setGoalId(addGoalStepOneAPiData?.GoalID);
        props.onApiResponse(1);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFilePathUpdateApiLoading]);

  // Handle Goal Add Step 1 Success/Failure.
  useEffect(() => {
    try {
      if (isOnSubmit && addGoalStepOneApiSuccess && addGoalStepOneApiResponseCode > 0) {
        const reqParams: IUploadFilePathUpdateParams = {
          FilePath: upImageDetails.name,
          Type: 'goalimage',
          TypeId: addGoalStepOneAPiData?.GoalID.toString(),
        };
        // Api request to update file info after bucket upload and add goal
        dispatch(uploadFilePathUpdate(reqParams));
      } else if (isOnSubmit && !addGoalStepOneApiSuccess && addGoalStepOneApiResponseCode > 0) {
        setIsOnSubmit(false);
        toast.toastError(addGoalStepOneApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [addGoalStepOneApiLoading]);

  // Handle Goal Update Step 1 Success/Failure.
  useEffect(() => {
    try {
      if (isOnSubmit && updateGoalApiSuccess && updateGoalApiResponseCode) {
        if (upImageDetails.name != getGoalDetailAPiData?.GoalImage?.split('.')[0]) {
          const reqParams: IUploadFilePathUpdateParams = {
            FilePath: upImageDetails.name,
            Type: 'goalimage',
            TypeId: updateGoalAPiData?.GoalID.toString(),
          };
          // Api request to update file info after bucket upload and add goal
          dispatch(uploadFilePathUpdate(reqParams));
        } else {
          setIsOnSubmit(false);
          props.onApiResponse(1);
        }
      } else if (isOnSubmit && !updateGoalApiSuccess && updateGoalApiResponseCode > 0) {
        setIsOnSubmit(false);
        toast.toastError(updateGoalApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [updateGoalApiLoading]);

  // Handle the upload path api response
  useEffect(() => {
    try {
      if (isOnSubmit && uploadFileResponseCode > 0 && uploadFileApiSuccess) {
        uploadProfilePic();
        dispatch(resetUploadFile());
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFileResponseCode, uploadFileResponseMessage]);

  // Put file to bucket after getting the path
  const uploadProfilePic = async () => {
    try {
      const uploadURL = uploadFileApiData;
      if (uploadURL) {
        // PUT request: upload file to S3
        const result = await fetch(uploadURL, {
          method: 'PUT',
          body: upImage,
        });
        if (result.status == 200) {
          if (addGoalStepOneAPiData?.GoalID || props.goalId) {
            dispatch(updateGoal(addGoalStepOneForm));
          } else {
            dispatch(addGoalStepOne(addGoalStepOneForm));
          }
        } else {
          setIsOnSubmit(false);
          setImgError(uploadFailed);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Validate fields
  const checkValidity = async (validateObj: any) => {
    try {
      let errorresult: any = [];
      if (isGeneral) {
        errorresult = await validateForm(validateObj, ADD_GENERAL_GOAL_STEP_ONE_SCHEMA, errorFields);
      } else {
        errorresult = await validateForm(validateObj, ADD_GOAL_STEP_ONE_SCHEMA, errorFields);
      }
      setErrorFields({ ...errorresult });
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Get sub categories based on category
  const handleCategoryChange = async (event: any) => {
    try {
      const selectedCategoryId = event.value;
      setSelectedCategory(event);
      const category = getCatergoryListAPiData.find((category: any) => category.CategoryId === selectedCategoryId.toString());
      if (category) {
        const subcategories = category.Subcategory.map((subcategory: any) => ({
          label: subcategory.classname,
          value: subcategory.classid,
        }));
        setSubCategoryList(subcategories);
        setSelectedSubCategory([]);
      } else {
        setSubCategoryList([]);
      }
      setAddGoalStepOneForm((info: any) => ({
        ...info,
        CategoryID: selectedCategoryId,
      }));
      const validateObj = {
        CategoryID: selectedCategoryId,
      };
      checkValidity(validateObj);
      setCategoryUserList(event);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Sub Category Change
  const handleSubCategoryChange = async (event: any) => {
    try {
      let subcategories: any = [];
      if (event) {
        subcategories = event.map((subcategory: any) => subcategory.value);
        setSelectedSubCategory(event);
        setAddGoalStepOneForm((info: any) => ({
          ...info,
          ClassID: subcategories,
        }));
      }
      const validateObj = {
        ClassID: subcategories,
      };
      checkValidity(validateObj);
      setSubCategoryUserList(event);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Priority change
  const handlePriorityChange = async (event: any) => {
    try {
      const selectedPriorityId = event.value;
      setSelectedPriority(event);
      setAddGoalStepOneForm((info: any) => ({
        ...info,
        Priority: selectedPriorityId,
      }));
      const validateObj = {
        Priority: selectedPriorityId,
      };
      checkValidity(validateObj);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Gol Type change.
  const handleGoalTypeChange = async (event: any) => {
    try {
      const selectedGoalTypeId = event.value;
      setSelectedGoalType(event);
      setAddGoalStepOneForm((info: any) => ({
        ...info,
        GoalTypeID: selectedGoalTypeId,
      }));
      const validateObj = {
        GoalTypeID: selectedGoalTypeId,
      };
      checkValidity(validateObj);
      validateStartAndEndDateOnGoalChange(startDate, endDate, event);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Set form on input change, handle form fields validation.
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      if (name == 'Description') {
        setCharacterCount(event.target.value.length);
      }
      setAddGoalStepOneForm((info: any) => ({
        ...info,
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      checkValidity(validateObj);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Validate start date and end date
  const validateDate = async (startDate: any, endDate: any) => {
    const errorCode = { startDateErrorCode: 0, endDateErrorCode: 0 };
    try {
      if (isBeforeOrEqual(startDate, new Date()) || isBeforeOrEqual(endDate, new Date())) {
        if (isBeforeOrEqual(startDate, new Date())) errorCode.startDateErrorCode = 1000;
        if (isBeforeOrEqual(endDate, new Date())) errorCode.endDateErrorCode = 1000;
      } else if (endDate != null && startDate != null && isBeforeOrEqual(endDate, startDate)) {
        errorCode.startDateErrorCode = 1001;
        errorCode.endDateErrorCode = 1001;
      }
      if (errorCode.startDateErrorCode == 0 || errorCode.endDateErrorCode == 0) {
        const goalTypeErrorCode = validateDatesOnGoalType(selectedGoalType, startDate, endDate);
        errorCode.startDateErrorCode = errorCode.startDateErrorCode == 0 ? goalTypeErrorCode : errorCode.startDateErrorCode;
        errorCode.endDateErrorCode = errorCode.endDateErrorCode == 0 ? goalTypeErrorCode : errorCode.endDateErrorCode;
      }
    } catch (error) {
      console.log('Error: ', error);
    }
    return errorCode;
  };
  interface GoalTypeDetails {
    maxDifference: number;
    unit: moment.unitOfTime.Diff;
    errorCode: number;
  }
  const getGoalTypeDetails = (label: string): GoalTypeDetails | null => {
    try {
      switch (label) {
        case 'Yearly Goal':
          return { maxDifference: 365, unit: 'days', errorCode: 1002 };
        case 'Monthly Goal':
          return { maxDifference: 30, unit: 'days', errorCode: 1003 };
        case 'Terms Goal':
          return { maxDifference: 180, unit: 'days', errorCode: 1004 };
        case 'Quarterly Goal':
          return { maxDifference: 120, unit: 'days', errorCode: 1005 };
        case 'Weekly Goal':
          return { maxDifference: 7, unit: 'days', errorCode: 1006 };
        case 'Daily Goal':
          return { maxDifference: 1, unit: 'days', errorCode: 1007 };
        case 'Hourly Goal':
          return { maxDifference: 1, unit: 'hours', errorCode: 1008 };
        default:
          return null;
      }
    } catch (error) {
      console.log('Error: ', error);
      return null;
    }
  };
  const validateDatesOnGoalType = (selectedGoalType: { label: string }, startDate: string, endDate: string) => {
    try {
      const goalTypeDetails = getGoalTypeDetails(selectedGoalType.label);
      if (goalTypeDetails) {
        const { maxDifference, unit, errorCode } = goalTypeDetails;
        const sdate = moment(startDate);
        const edate = moment(endDate);
        const difference = edate.diff(sdate, unit);

        if (difference > maxDifference) {
          return errorCode;
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
    return 0; // No error
  };
  const getErrorMessage = (errorcode: number, field: string): string => {
    try {
      switch (errorcode) {
        case 1000:
          return field == 'startdate' ? 'Start Date & Time should not be earlier than Current Date & Time.' : 'End Date & Time should not be earlier than Current Date & Time.';
        case 1001:
          return field == 'startdate' ? 'Start Date & Time should be earlier than End Date & Time.' : 'End Date & Time should be later than Start Date & Time.';
        case 1002:
          return 'Select a date range within a year.';
        case 1003:
          return 'Select a date range within a month.';
        case 1004:
          return 'Select a date range within a term.';
        case 1005:
          return 'Select a date range within a quarter.';
        case 1006:
          return 'Select a date range within a week.';
        case 1007:
          return 'Select a time range within a day.';
        case 1008:
          return 'Select a time range within an hour.';
        default:
          return '';
      }
    } catch (error) {
      console.log('Error: ', error);
      return '';
    }
  };
  // Validate start and end date
  const validateStartAndEndDateOnGoalChange = async (startDate: any, endDate: any, goalType: any) => {
    try {
      if (startDate != null && endDate != null) {
        if (startDate < endDate) {
          const errorCode = validateDatesOnGoalType(goalType, startDate, endDate);
          const errMsg = getErrorMessage(errorCode, '');
          setStartDateError(...[errMsg]);
          setEndDateError(...[errMsg]);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Submit handler to Add Goal Step 1.
  const onSubmit = async () => {
    try {
      let errCode = { startDateErrorCode: 0, endDateErrorCode: 0 };
      if (startDate != null && endDate != null) {
        errCode = await validateDate(startDate, endDate);
        if (errCode.startDateErrorCode != 0) {
          const startDateMsg = getErrorMessage(errCode.startDateErrorCode, 'startdate');
          setStartDateError(...[startDateMsg]);
        }
        if (errCode.endDateErrorCode != 0) {
          const endDateMsg = getErrorMessage(errCode.endDateErrorCode, 'enddate');
          setEndDateError(...[endDateMsg]);
        }
      }

      if (errCode.startDateErrorCode == 0 && errCode.endDateErrorCode == 0 && startDateError.length <= 0 && endDateError.length <= 0) {
        let errorresult: any = null;
        if (isGeneral) {
          errorresult = await validateForm(addGoalStepOneForm, ADD_GENERAL_GOAL_STEP_ONE_SCHEMA, errorFields);
        } else {
          errorresult = await validateForm(addGoalStepOneForm, ADD_GOAL_STEP_ONE_SCHEMA, errorFields);
        }
        if (Object.keys(errorresult).length === 0) {
          setIsOnSubmit(true);
          // APi request to get the fileupload path
          if (upImage) {
            setImgError('');
            const formData = new FormData();
            formData.append('UploadExtension', upImageDetails.type);
            formData.append('UploadObjectKey', upImageDetails.name);
            formData.append('UploadType', 'goalimage');
            dispatch(uploadFile(formData));
          } else {
            setIsOnSubmit(false);
            setImgError(ImageUploadChooseImage);
            window.scrollTo(0, 0);
            if (imageRef?.current) {
              imageRef?.current?.focus();
            }
          }
        } else {
          setErrorFields({ ...errorresult });
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // set image selected
  const setPicture = (pic: any) => {
    try {
      setImgError('');
      setUpImage(pic);
      const imgInfo = {
        name: pic.name,
        type: pic.type,
      };
      setUpImageDetails(imgInfo);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle Start date change.
  const handleStartDateChange = async (paramValue: any) => {
    try {
      setStartDate(...[paramValue]);
      const startdate = moment(paramValue).utc().format(DATE_TIME_FORMAT);
      setAddGoalStepOneForm((info: any) => ({
        ...info,
        StartDateWithTime: startdate,
        CreatedStartDateWithTime: moment(paramValue).format(DATE_TIME_FORMAT),
      }));
      const validateObj = {
        StartDateWithTime: startdate,
      };
      await checkValidity(validateObj);
      // Start date , end date related validations
      const errCode = await validateDate(paramValue, endDate);
      const errMsg = getErrorMessage(errCode.startDateErrorCode, 'startdate');
      setStartDateError(...[errMsg]);
      if (errCode.endDateErrorCode == 0) {
        setEndDateError(...['']);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle end date chnage
  const handleEndDateChange = async (paramValue: any) => {
    try {
      setEndDate(...[paramValue]);
      const enddate = moment(paramValue).utc().format(DATE_TIME_FORMAT);
      setAddGoalStepOneForm((info: any) => ({
        ...info,
        EndDateWithTime: enddate,
        CreatedEndDateWithTime: moment(paramValue).format(DATE_TIME_FORMAT),
      }));
      const validateObj = {
        EndDateWithTime: enddate,
      };
      await checkValidity(validateObj);
      // Start date , end date related validations
      const errCode = await validateDate(startDate, paramValue);
      const errMsg = getErrorMessage(errCode.endDateErrorCode, 'enddate');
      setEndDateError(...[errMsg]);
      if (errCode.startDateErrorCode == 0) {
        setStartDateError(...['']);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle General Click
  const handleGeneralClick = (event: any) => {
    try {
      let generalTemp = false;
      if (event.target.checked) {
        generalTemp = true;
      }
      setIsGeneral(generalTemp);
      setAddGoalStepOneForm((info: any) => ({
        ...info,
        IsGeneral: generalTemp,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Assign user Select button click.
  const handleAssignUserSelectButtonClick = () => {
    try {
      setAddGoalStepOneForm((info: any) => ({
        ...info,
        UserIds: selectedUserList.map((user: any) => user.id),
      }));
      setConfirmedUsers([...selectedUserList]);
      setIsOpen(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Assign goal to button click.
  const handleAssignGoaltoClick = () => {
    try {
      setSelectedUserList([...confirmedUsers]);
      setIsOpen(true);
      setSelectAllCheckboxSelection();
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Assign Goal to Overlay close
  const handleAssignGoaltoClose = () => {
    try {
      setSelectedUserList([...confirmedUsers]);
      setIsOpen(false);
      handleFilterReset();
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle User Select checkbox click
  const handleUserSelectCheckboxClick = (event: any) => {
    try {
      let usersSelected = selectedUserList;
      const selectedUserId = event.target.id?.split('_')[1];
      const selectedUser = items.find((user: any) => user.UserId === selectedUserId);
      const selectedUserImage = selectedUser.ProfileImage;
      const selectedUserName = selectedUser.Name;
      if (event.target.checked) {
        usersSelected.push({ id: selectedUserId, name: selectedUserName, image: selectedUserImage });
      } else {
        usersSelected = usersSelected.filter((e) => e.id != selectedUserId.toString());
      }
      setSelectedUserList([...usersSelected]);
      setSelectAllCheckboxSelection();
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle select all checbox selection.
  const setSelectAllCheckboxSelection = () => {
    try {
      const checkboxes: HTMLInputElement[] = [];
      const elements = document.querySelectorAll('[id^="overlay1checkbox_"]');
      elements.forEach((element) => {
        checkboxes.push(element as HTMLInputElement);
      });
      let selectall = true;
      if (checkboxes.length > 0) {
        checkboxes.forEach((checkbox) => {
          if (!checkbox.checked) {
            selectall = false;
          }
        });
      } else {
        selectall = false;
      }
      setIsAllSelected(...[selectall]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Select All checkbox click
  const handleSelectAllCheckboxClick = (event: any) => {
    try {
      const usersSelected: any[] = [];
      if (event.target.checked) {
        setIsAllSelected(true);
        items.forEach((value: any) => {
          if (event.target.checked) {
            usersSelected.push({ id: value.UserId, name: value.Name, image: value.ProfileImage });
          }
        });
      } else {
        setIsAllSelected(false);
      }
      setSelectedUserList([...usersSelected]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Assigned users click.
  const handleAssignedUsersClick = () => {
    try {
      setSelectedUserListForDelete([]);
      setIsAllSelectedForDelete(false);
      setIsOpenAssignedUsers(true);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Assignes users overlay close.
  const handleAssignedUsersClose = () => {
    try {
      setIsOpenAssignedUsers(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle User Select checkbox click for Delete
  const handleUserSelectCheckboxClickForDelete = (event: any) => {
    try {
      let usersSelected = selectedUserListForDelete;
      const selectedUserId = event.target.id.split('_')[1];
      if (event.target.checked) {
        usersSelected.push({ id: selectedUserId });
      } else {
        usersSelected = usersSelected.filter((e) => e.id != selectedUserId.toString());
      }
      setSelectedUserListForDelete([...usersSelected]);
      setSelectAllCheckboxForDeleteSelection();
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle select all checbox for delete selection.
  const setSelectAllCheckboxForDeleteSelection = () => {
    try {
      const checkboxes: HTMLInputElement[] = [];
      const elements = document.querySelectorAll('[id^="checkbox_"]');
      elements.forEach((element) => {
        checkboxes.push(element as HTMLInputElement);
      });
      let selectall = true;
      checkboxes.forEach((checkbox) => {
        if (!checkbox.checked) {
          selectall = false;
        }
      });
      setIsAllSelectedForDelete(...[selectall]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Select All checkbox click
  const handleSelectAllCheckboxClickForDelete = (event: any) => {
    try {
      const usersSelected: any[] = [];
      if (event.target.checked) {
        setIsAllSelectedForDelete(true);
        confirmedUsers.forEach((value: any) => {
          if (event.target.checked) {
            usersSelected.push({ id: value.id });
          }
        });
      } else {
        setIsAllSelectedForDelete(false);
      }
      setSelectedUserListForDelete([...usersSelected]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Show Confirm pop up on delete click
  const ShowRemoveAssigneeConfirmPopup = () => {
    try {
      if (selectedUserListForDelete && selectedUserListForDelete.length > 0) setIsShowRemoveAssigneeConfirmPopup(true);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Delete button click
  const handleDeleteButtonClick = () => {
    try {
      let usersSelected: any[] = [];
      usersSelected = confirmedUsers.filter((user) => {
        return !selectedUserListForDelete.some((selectedUser) => selectedUser.id === user.id);
      });
      setConfirmedUsers([...usersSelected]);
      setSelectedUserList([...usersSelected]);
      setSelectAllCheckboxSelection();
      setIsOpenAssignedUsers(false);
      setAddGoalStepOneForm((info: any) => ({
        ...info,
        UserIds: usersSelected.map((user: any) => user.id),
      }));
      setIsShowRemoveAssigneeConfirmPopup(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Cancel button click
  const handleCancelButtonClick = () => {
    try {
      setIsOpenAssignedUsers(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Search field change event.
  const handleSearchChange = (event: any) => {
    try {
      setSearchText(event.target.value);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Search Click.
  const handleSearchClick = () => {
    try {
      setApiParams((apiParams: any) => ({
        ...apiParams,
        Search: searchText,
        Page: DEFAULT_PAGE_INDEX,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Search Field input key down.
  const handleSearchKeyDown = (event: any) => {
    try {
      if (event.key === 'Enter') {
        event.preventDefault();
        setApiParams((apiParams: any) => ({
          ...apiParams,
          Search: searchText,
          Page: DEFAULT_PAGE_INDEX,
        }));
      } else {
        setSearchText(event.target.value);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Get sub categories based on category
  const setCategoryUserList = (event: any) => {
    try {
      const selectedCategoryId = event.value;
      setSelectedCategoryUserList(event);
      setApiParams((info: any) => ({
        ...info,
        Category: [{ categoryid: selectedCategoryId }],
      }));
      setConfirmedUsers([]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Set Sub Category User List Based on Goal sub category selection
  const setSubCategoryUserList = (event: any) => {
    try {
      setSubCategoryListForUser(event);
      setSelectedSubCategoryUserList(event);
      const subcategories = event.map((subcategory: any) => ({ classid: subcategory.value }));
      setApiParams((info: any) => ({
        ...info,
        SubCategories: subcategories,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Sub Category change handler.
  const handleSubCategoryChangeUserList = (event: any) => {
    try {
      if (event) {
        const subcategories = event.map((subcategory: any) => ({ classid: subcategory.value }));
        setSelectedSubCategoryUserList(event);
        setApiParams((info: any) => ({
          ...info,
          SubCategories: subcategories,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Joining Date change event.
  const handleJoiningDateChange = (paramValue: any) => {
    try {
      setJoiningDate(paramValue);
      setApiParams((info: any) => ({
        ...info,
        DOJ: moment(paramValue).format(DATE_FORMAT1),
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Sort header change event.
  const changeSortField = (field: string) => {
    try {
      let newSortOrder = 'ASC';
      if (field === sortField) {
        newSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
      }
      setApiParams((info: any) => ({
        ...info,
        SortColumn: field,
        SortOrder: newSortOrder,
      }));
      setSortField(field);
      setSortOrder(newSortOrder);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle reset filter
  const handleFilterReset = () => {
    try {
      setSelectedUserList([...confirmedUsers]);
      setApiParams((apiParams: any) => ({
        ...apiParams,
        DOJ: '',
        Page: DEFAULT_PAGE_INDEX,
        PageSize: PAGE_SIZE,
        Search: '',
        SortColumn: UsersSortFields.NAME,
        SortOrder: SortOrder.ASC,
        SubCategories: [],
      }));
      setSearchText('');
      setJoiningDate(null);
      setIsOpen(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Fetching next page items
  const fetchMoreData = () => {
    try {
      setApiParams((info: any) => ({
        ...info,
        Page: info.Page + 1,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  return (
    <>
      <div className="text-center mb-5">
        <h3>
          <FormattedMessage id="Hd.AddGoal" />
        </h3>
        <p>
          <FormattedMessage id="SubHd.AddGoal" />
        </p>
        <div className="cover-photo">
          <Col xl="auto">
            <ImageUpload
              setError={(error: string) => setImgError(error)}
              imageFile={previewImage ? previewImage : ''}
              setPicture={setPicture}
              maxSize={Number(UploaderProps.IMAGE_MAX_SIZE)}
              acceptedFileTypes={IMAGE_TYPES_SUPPORTED}
              invalidSizeMessage={useIntlActionMessages('Image.Upload.Size.Message')}
              invalidTypeMessage={useIntlActionMessages('Image.Upload.AcceptedType.Error')}
              mode={addGoalStepOneAPiData?.GoalID || props.goalId ? 'edit' : 'add'}
            />
            <div className="error" ref={imageRef}>
              {' '}
              {imgError}
            </div>
          </Col>
        </div>
        <div className="upload-info mt-2">
          <ul>
            <li>Upload image files with .png, .jpg or .jpeg extension with size below 5MB.</li>
          </ul>
        </div>
      </div>
      <Col className="justify-content-center d-flex">
        <Col xl={10}>
          <Row>
            <Col xl={6}>
              <Input
                label={useIntlMessages('Label.NameofGoal')}
                id="Goal"
                name="GoalName"
                type="text"
                value={addGoalStepOneForm.GoalName}
                onChange={onInputHandleChange}
                placeholder={useIntlMessages('PH.EnterNameofGoal')}
                maxLength={NumberValues.NUM_100}
                errorMessage={errorFields?.GoalName}
              />
            </Col>
            <Col xl={6} className="position-relative z-index-2">
              <Select
                label={useIntlMessages('Label.GoalType')}
                options={goalType}
                id="GoalType"
                name="GoalTypeID"
                value={selectedGoalType}
                placeholder="Select"
                onChange={(e: any) => handleGoalTypeChange(e)}
                error={errorFields?.GoalTypeID}
              />
            </Col>
            <Col xl={12} className="addOrg-textarea">
              <Input
                label={useIntlMessages('Label.GoalDescription')}
                id="GoalDescription"
                name="Description"
                type="text"
                value={addGoalStepOneForm.Description}
                onChange={onInputHandleChange}
                placeholder={useIntlMessages('PH.GoalDescription')}
                maxLength={NumberValues.NUM_1000}
                as="textarea"
                errorMessage={errorFields?.Description}
              />
              <span className="txt-count">
                {characterCount}/{NumberValues.NUM_1000}
              </span>
            </Col>
            <Col sm={12} className="mb-3">
              <Checkbox
                id="cbIsGenralGoal"
                type="Checkbox"
                checked={addGoalStepOneForm.IsGeneral}
                value={addGoalStepOneForm.IsGeneral}
                label={useIntlMessages('Label.Checkbox.Goal')}
                onChange={handleGeneralClick}
                disabled={props.goalId ? true : false}
              />
            </Col>
            {!isGeneral && (
              <>
                <Col xl={6}>
                  <Select
                    label={labelCategory}
                    id="CategoryID"
                    name="CategoryID"
                    options={categoryList}
                    value={selectedCategory}
                    placeholder={phSelect}
                    onChange={(e: any) => handleCategoryChange(e)}
                    error={errorFields?.CategoryID}
                    isDisabled={props.goalId ? true : false}
                  />
                </Col>
                <Col xl={6} className="position-relative z-index-2">
                  <Select
                    label={labelSubCategory}
                    id="ClassID"
                    name="ClassID"
                    options={subCategoryList}
                    value={selectedSubCategory}
                    placeholder={phSelect}
                    onChange={(e: any) => handleSubCategoryChange(e)}
                    isMulti
                    error={errorFields?.ClassID}
                    isDisabled={props.goalId ? true : false}
                  />
                </Col>
              </>
            )}
            <Col xl={6}>
              <Select
                label={useIntlMessages('Label.SetPriority')}
                id="Priority"
                name="Priority"
                options={priority}
                value={selectedPriority}
                placeholder={useIntlMessages('PH.Select')}
                onChange={(e: any) => handlePriorityChange(e)}
                error={errorFields?.Priority}
              />
            </Col>

            <Col xl={6}>
              {!isGeneral && (
                <>
                  <label className="form-label">
                    <FormattedMessage id="Label.AssignedGoalto" />{' '}
                  </label>
                  <Row>
                    <Col xs="auto" className="align-items-center d-flex assign-users">
                      <div className="custom-berger-menu">
                        <Filter
                          onOpen={handleAssignedUsersClick}
                          onClose={handleAssignedUsersClose}
                          isOpen={isOpenAssignedUsers}
                          width={450}
                          right
                          pageWrapId={'filter-wrapper'}
                          outerContainerId={'outer-container'}
                          customCrossIcon={<img src={Close} />}
                          customBurgerIcon={
                            <div className="d-flex align-items-center multiple-user">
                              <span className="td-user-image">
                                <img src={UserImg} className="w-100" alt="user" />
                              </span>
                              <span className="td-user-image">
                                <img src={UserImg} className="w-100" alt="user" />
                              </span>
                              <span className="td-user-image">
                                <img src={UserImg} className="w-100" alt="user" />
                              </span>
                              +{confirmedUsers.length} {confirmedUsers.length == 1 ? <FormattedMessage id="Label.Member" /> : <FormattedMessage id="Label.Members" />}
                            </div>
                          }
                        >
                          <div id="filter-wrapper" className="filter-main">
                            <Scrollbar className="h-80vh">
                              <Table striped hover responsive size="sm">
                                <thead>
                                  <tr>
                                    <th className="w-50px">
                                      <div className="no-margin">
                                        <Checkbox
                                          disabled={confirmedUsers && confirmedUsers.length > 0 ? false : true}
                                          type="Checkbox"
                                          checked={isAllSelectedForDelete}
                                          onChange={handleSelectAllCheckboxClickForDelete}
                                        />
                                      </div>
                                    </th>
                                    <th>
                                      <FormattedMessage id="Label.Name" /> <Link to="" className="ms-1"></Link>
                                    </th>
                                    <th className="w-50px">
                                      <Button
                                        disabled={confirmedUsers && confirmedUsers.length > 0 ? false : true}
                                        variant="dark"
                                        size="sm"
                                        className="icon-btn"
                                        onClick={() => ShowRemoveAssigneeConfirmPopup()}
                                      >
                                        <Delete />
                                      </Button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {confirmedUsers &&
                                    confirmedUsers.length > 0 &&
                                    confirmedUsers.map((user: any) => (
                                      <tr>
                                        <td>
                                          <div className="no-margin">
                                            <Checkbox
                                              checked={selectedUserListForDelete.map((x: any) => x.id).includes(user.id)}
                                              class="assigned-users-checkbox"
                                              type="Checkbox"
                                              name={'checkbox_' + user.id}
                                              id={'checkbox_' + user.id}
                                              onChange={handleUserSelectCheckboxClickForDelete}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <div className="d-flex align-items-center">
                                            <span className="td-user-image">
                                              <img src={user.image ? BUCKET_URL + user.image : UserImg} className="w-100" alt="user" />
                                            </span>
                                            {user.name}
                                          </div>
                                        </td>
                                        <td></td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </Scrollbar>
                            <Row className="mt-4 pb-4">
                              <Col>
                                <Button variant="outline-primary" className="w-100" onClick={() => handleCancelButtonClick()}>
                                  <FormattedMessage id="Button.Cancel" />
                                </Button>
                              </Col>
                              <Col>
                                <Button variant="primary" className="w-100" onClick={() => handleCancelButtonClick()}>
                                  <FormattedMessage id="Button.Done" />
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Filter>
                      </div>
                    </Col>
                    <Col className="assign-users">
                      <div className="custom-berger-menu">
                        <Filter
                          onOpen={handleAssignGoaltoClick}
                          onClose={handleAssignGoaltoClose}
                          isOpen={isOpen}
                          width={450}
                          right
                          pageWrapId={'filter-wrapper'}
                          outerContainerId={'outer-container'}
                          customCrossIcon={<img src={Close} />}
                          customBurgerIcon={
                            <Button variant="secondary" className="w-100">
                              <FormattedMessage id="Button.AssignGoalTo" />
                            </Button>
                          }
                        >
                          <div id="filter-wrapper" className="filter-main">
                            <Col sm={12}>
                              <div className="d-flex justify-content-between align-items-center mb-4">
                                <h4 className="mb-0">
                                  <FormattedMessage id="Hd.Users" />
                                </h4>
                              </div>
                              <Col className="form-sm">
                                <Search
                                  value={searchText}
                                  onChange={handleSearchChange}
                                  onKeyDown={handleSearchKeyDown}
                                  onClick={handleSearchClick}
                                  placeholder={useIntlMessages('PH.SearchName')}
                                  size="sm"
                                />
                              </Col>
                              {!getGoalDetailAPiData.IsGeneral && (
                                <Col>
                                  <div className="form-sm d-flex">
                                    <Col className="pe-2">
                                      <Select
                                        label={useIntlMessages('Label.Category')}
                                        options={categoryList}
                                        value={selectedCategoryUserList}
                                        placeholder={useIntlMessages('PH.Select')}
                                        isDisabled={true}
                                      />
                                    </Col>
                                    <Col className="ps-2">
                                      <Select
                                        label={useIntlMessages('Label.SubCategory')}
                                        id="ClassID"
                                        name="ClassID"
                                        options={subCategoryListForUser}
                                        value={selectedSubCategoryUserList}
                                        placeholder={useIntlMessages('PH.Select')}
                                        onChange={(e: any) => handleSubCategoryChangeUserList(e)}
                                        isMulti
                                        isDisabled={true}
                                      />
                                    </Col>
                                  </div>
                                </Col>
                              )}
                              <Col>
                                <div className="custom-calendar mb-4 form-sm">
                                  <label className="form-label">
                                    <FormattedMessage id="Label.DateofJoining" />
                                  </label>
                                  <DatePicker selected={joiningDate} onChange={(date) => handleJoiningDateChange(date)} onKeyDown={(e) => e.preventDefault()} />
                                </div>
                              </Col>
                              <InfiniteScroll
                                dataLength={items?.length}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={items?.length > 0 && <h3>{labelLoading}</h3>}
                                height={300}
                                endMessage={<EndMessage />}
                              >
                                <Table striped hover responsive size="sm" className="mt-2">
                                  <thead>
                                    <tr>
                                      <th className="w-50px">
                                        <div className="no-margin">
                                          <Checkbox
                                            disabled={items && items.length > 0 ? false : true}
                                            type="Checkbox"
                                            checked={isAllSelected}
                                            onChange={handleSelectAllCheckboxClick}
                                          />
                                        </div>
                                      </th>
                                      <th>
                                        <FormattedMessage id="Label.Name" />{' '}
                                        <Link to="" className="ms-1" onClick={() => changeSortField(UsersSortFields.NAME)}>
                                          <Sort />
                                        </Link>
                                      </th>
                                      <th className="w-75px"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {items &&
                                      items.length > 0 &&
                                      items.map((user: any) => (
                                        <tr>
                                          <td>
                                            <div className="no-margin">
                                              <Checkbox
                                                checked={selectedUserList.map((user: any) => user.id).includes(user.UserId)}
                                                name={'overlay1checkbox_' + user.UserId}
                                                id={'overlay1checkbox_' + user.UserId}
                                                type="Checkbox"
                                                onChange={handleUserSelectCheckboxClick}
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <span className="td-user-image">
                                                <img src={user.ProfileImage ? BUCKET_URL + user.ProfileImage : UserImg} className="w-100" alt="user" />
                                              </span>
                                              {user.Name}
                                            </div>
                                          </td>
                                          <td>
                                            <FormattedMessage id="Label.Active" />
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </Table>
                              </InfiniteScroll>
                            </Col>

                            <Row className="mt-3">
                              <Col>
                                <Button variant="outline-primary" className="w-100" onClick={() => handleFilterReset()}>
                                  <FormattedMessage id="Button.Cancel" />
                                </Button>
                              </Col>
                              <Col>
                                <Button variant="primary" className="w-100" onClick={() => handleAssignUserSelectButtonClick()}>
                                  <FormattedMessage id="Button.Select" /> ({selectedUserList.length}/{totalItems})
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Filter>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Col>

            <Col xl={6}>
              <div className="custom-calendar mb-4">
                <label className="form-label">
                  <FormattedMessage id="Label.StartDateTime" />
                </label>
                <DatePicker
                  minDate={currentTime}
                  maxDate={endDate}
                  selected={startDate}
                  onChange={(date) => handleStartDateChange(date)}
                  showTimeSelect
                  timeIntervals={1}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  onKeyDown={(e) => e.preventDefault()}
                  placeholderText="Select Start Date & Time"
                />
                {errorFields?.StartDateWithTime?.length > 0 ? <label className="error">{errorFields.StartDateWithTime}</label> : null}
                {startDateError?.length > 0 ? <label className="error">{startDateError}</label> : null}
              </div>
            </Col>
            <Col xl={6}>
              <div className="custom-calendar mb-4">
                <label className="form-label">
                  <FormattedMessage id="Label.EndDateTime" />
                </label>
                <DatePicker
                  minDate={startDate}
                  selected={endDate}
                  onChange={(date) => handleEndDateChange(date)}
                  showTimeSelect
                  timeIntervals={1}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy h:mm aa"
                  onKeyDown={(e) => e.preventDefault()}
                  placeholderText="Select End Date & Time"
                />
                {errorFields?.EndDateWithTime?.length > 0 ? <label className="error">{errorFields.EndDateWithTime}</label> : null}
                {endDateError?.length > 0 ? <label className="error">{endDateError}</label> : null}
              </div>
            </Col>
          </Row>
          <div className="btn-container d-flex justify-content-end my-4">
            <Button variant="outline-primary" onClick={() => navigate('/activities')}>
              <FormattedMessage id="Button.Cancel" />
            </Button>
            <Button variant="primary" onClick={onSubmit}>
              <FormattedMessage id="Button.Next" />
            </Button>
          </div>
        </Col>
      </Col>
      {(isOnSubmit || uploadFileApiLoading || addGoalStepOneApiLoading || uploadFilePathUpdateApiLoading) && <Loader />}
      <DeleteConfirmPopup
        show={isShowRemoveAssigneeConfirmPopup}
        handleClose={() => setIsShowRemoveAssigneeConfirmPopup(false)}
        onCancel={() => setIsShowRemoveAssigneeConfirmPopup(false)}
        onOkay={() => handleDeleteButtonClick()}
        title="Remove Assignee(s)?"
        content="Are you sure you want to proceed?"
      />
    </>
  );
};
export default GoalStep1;
