import { useState } from "react";
import '../assets/css/CompetenciesCard.scss'
import { Row, Col, Button, Modal } from 'react-bootstrap';
import Image from '../assets/img/icon/ImgCompetency';
import Video from '../assets/img/icon/VideoCompetency';
import SampleImg from '../assets/img/pro_img.png';
import ReactPlayer from 'react-player';

const CompetenciesCard = (props: any) => {
    const [show, setShow] = useState(false);
    const [showVideo, setVideoShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const videoShow = () => setVideoShow(true);
    const videoClose = () => setVideoShow(false);
    return (
        <div className="competenciescard-main">
            <Row >
                <Col xs="auto" className="count d-flex align-items-center"><h6>{props.count}</h6></Col>
                <Col className="competencies"><p>{props.competencies}</p></Col>
                <Col xs="auto" className="d-flex align-items-center">
                    <Button variant="link" onClick={handleShow} className="icon-btn">
                        <Image/>
                    </Button>
                    <Button variant="link" onClick={videoShow} className="icon-btn">
                        <Video/>
                    </Button>

                    <Modal show={show} onHide={handleClose} centered className="competency-pop">
                        <Modal.Header closeButton />
                        <Modal.Body><img src={SampleImg}/></Modal.Body>
                    </Modal>
                    <Modal show={showVideo} onHide={videoClose} centered size="lg" className="competency-pop">
                        <Modal.Header closeButton />
                        <Modal.Body>
                            <ReactPlayer url="https://betaassets.mirrorim.com/resource_attachments/big_buck_bunny_720p_1mb1735549848691.mp4" controls width="100%" className="att-img-vid" />
                        </Modal.Body>
                    </Modal>
                </Col>
            </Row>
        </div>
    );
}
export default CompetenciesCard;