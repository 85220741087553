/**
 * @file   src\components\SelectableCalendar.tsx
 * @brief  404 page.
 * @date   Dec, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../assets/css/style.scss';

// Setup localizer
const localizer = momentLocalizer(moment);

const events = [
  {
    title: 'All Day Event very long title',
    bgColor: '#ff7f50',
    allDay: true,
    start: new Date(2024, 11, 1),
    end: new Date(2024, 11, 2),
  },
  {
    title: 'Meeting',
    bgColor: '#8fbc8f',
    start: new Date(2024, 11, 12, 10, 30, 0),
    end: new Date(2024, 11, 12, 12, 30, 0),
    desc: 'Pre-meeting meeting, to prepare for the meeting',
  },
];

const BigCalendar = (props:any) => {
  const handleSelectEvent = (event: { title: string }) => {
    alert(`Event: ${event.title}`);
  };

  const handleSelectSlot = (slotInfo: { start: Date; end: Date; action: string }) => {
    props.onSelect();
    alert(`Selected slot: \nStart: ${slotInfo.start.toLocaleString()}\nEnd: ${slotInfo.end.toLocaleString()}\nAction: ${slotInfo.action}`);
  };

  const customDayPropGetter = (date:Date) => {
    if (date.getDate() === 7 || date.getDate() === 15)
      return {
        className: 'milestone-legends-weekly'
      }
    else return {}
  }

  return (
    <div>
      <Calendar
        selectable
        localizer={localizer}
        events={events}
        defaultView="week"
        scrollToTime={new Date(1970, 1, 1, 6)}
        defaultDate={new Date(2024, 11, 12)}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        // style={{ height: '500px' }}
        dayPropGetter={customDayPropGetter}
      />
      <div className='mt-3'>
      <span className='milestone-legends weekly'>Weekly Milestone</span>
      <span className='milestone-legends monthly'>Monthly Milestone</span>
      <span className='milestone-legends halfway'>Halfway Milestone</span>
      <span className='milestone-legends daily'>Daily Milestone</span>
      </div>
    </div>
  );
};

export default BigCalendar;
