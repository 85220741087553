/**
 * @file   src\containers\organizations\AssignGoal.tsx
 * @brief  Goal add page.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { useState, useEffect, createContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Breadcrumb } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stepper, Step } from 'react-form-stepper';
import Step1 from './GoalStep1';
import Step2 from './GoalStep2Incentive';
import Step3 from './GoalStep3';
import Step4 from './AddIncentiveResource';
import { getFromLocalStorage, useIntlMessages } from '../../utils/helper';
import { BUCKET_URL } from '../../utils/constants';
import Default from '../../assets/img/default.jpg';
import GoalStep3NewDesign from './GoalStep3New';

const AssignGoal = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Location object
  const location = useLocation();
  const orgDetail = getFromLocalStorage('ORG_DETAIL');
  const GoalContext = createContext('');

  // Component states
  const [activeStep, setActiveStep] = useState(0);
  const [activeGoalID, setActiveGoalID] = useState<string>('');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // Save action response event activated from steps.
  const onApiResponse = (page: number) => {
    try {
      setActiveStep(page);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Save action response event activated from steps.
  const addGoalId = (id: string) => {
    try {
      setActiveGoalID(id);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  useEffect(() => {
    try {
      if (location?.state?.goalId) {
        setActiveGoalID(location?.state?.goalId);
      }
      window.scrollTo(0, 0);
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>Assign Goal</h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/activities')}>
              <FormattedMessage id="Hd.Activities" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Button.AssignGoal" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="mb-4">
        <div className="org-selected">
          <img className="thumbnail-image" src={orgDetail?.ImageURL ? BUCKET_URL + orgDetail?.ImageURL : Default} alt="user pic" />
          <div>
            <h6 className="mb-1">{orgDetail?.Name}</h6>
            <small>{orgDetail?.Address}</small>
          </div>
        </div>
      </div>
      <div className="content-sub">
        <GoalContext.Provider value={activeGoalID}>
          <div className="content-area-padding">
            <Col xl={5} className="m-auto mb-4">
              <Stepper connectorStateColors activeStep={activeStep} className="from-stepper">
                <Step className="steps" onClick={() => setActiveStep(0)} />
                <Step onClick={() => setActiveStep(1)} />
                <Step onClick={() => setActiveStep(2)} />
                <Step onClick={() => setActiveStep(3)} />
              </Stepper>
            </Col>
            {activeStep === 0 && (
              <Step1 onApiResponse={(page: number) => onApiResponse(page)} goalId={location?.state?.goalId} setGoalId={addGoalId} orgId={orgDetail?.OrganizationId} />
            )}
            {activeStep === 1 && <Step2 onApiResponse={(page: number) => onApiResponse(page)} activeGoalID={activeGoalID} orgId={orgDetail?.OrganizationId} />}
            {activeStep === 2 && <GoalStep3NewDesign />}
            {activeStep === 3 && <Step4 onApiResponse={(page: number) => onApiResponse(page)} activeGoalID={activeGoalID} orgId={orgDetail?.OrganizationId} />}
          </div>
        </GoalContext.Provider>
      </div>
    </>
  );
};
export default AssignGoal;
