/**
 * @file   src\containers\organizations\UserAdd.tsx
 * @brief  User add page.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useState, useEffect, useRef } from 'react';
import '../../assets/css/org.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
  useIntlMessages,
  useIntlActionMessages,
  isUserSuperAdmin,
  isUserAdmin,
  getCurrentOrgDetails,
  getFromLocalStorage,
  isAdminTeacherManager,
  isUserSystemAdmin,
} from '../../utils/helper';
import { Button, Col, Tabs, Tab, Table } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Input from '../../components/MAInput';
import Select from '../../components/MASelect';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import AddIcon from '../../assets/img/icon/Add';
import Delete from '../../assets/img/icon/Delete';
import Edit from '../../assets/img/icon/Edit';
import { IUserForm } from '../../interfaces/UserInterface';
import {
  getOrganizationTypeDropDownList,
  getUserRolesByOrgType,
  getOrganizationNameByOrgType,
  getUserTagsByOrgType,
  getCategoryDetailsById,
} from '../../store/actions/organizationTypeActions';
import { addSingleUser, userCountForSubscription } from '../../store/actions/userActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { ISelectOptionsNumber, ISelectOptionsString, ISelectOption } from '../../interfaces/GeneralInterface';
import { USER_SCHEMA } from '../../validations/userSchema';
import { validateForm } from '../../utils/formValidation';
import { MessageToaster } from '../../utils/ToastUtil';
import { resetUserAdd, resetSubscriptionCount } from '../../store/slices/userSlice';
import Loader from '../../components/Loader';
import { NumberValues, RoleTypeIds, CountryCodes, GenderValues } from '../../utils/enums';
import SystemAdminAdd from './SystemAdminAdd';
import UserBulkUpload from './UserBulkUpload';
import CreatableSelect from 'react-select/creatable';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { DATE_FORMAT1, FIRST_NAME_REGEX, APP_USER_ROLES, WEB_USER_ROLES } from '../../utils/constants';
import { getStates, getCities } from '../../store/actions/organizationActions';

// Declare default params
const savedefaultRequestParams: IUserForm = {
  CategoryDetails: [
    {
      categoryid: 0,
      classid: [],
    },
  ],
  EmailId: '',
  OrganizationID: '',
  OrganizationTypeId: 0,
  RoleId: 0,
  Tags: [],
  Firstname: '',
  Lastname: '',
  Phonenum: '',
  Gender: '',
  DOB: '',
  CityId: 0,
  CityName: '',
  StateId: 0,
  Address: '',
  Zip: '',
  CountryCode: '',
  HighestEducation: '',
};
const countryCodeOptions = [
  { label: '+1 - US', value: CountryCodes.US },
  { label: '+91 - IND', value: CountryCodes.IND },
];
const genderOptions = [
  { label: 'Male', value: GenderValues.MALE },
  { label: 'Female', value: GenderValues.FEMALE },
];
const UserAdd = () => {
  // Navigation object
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Location object
  const location = useLocation();
  // Toast object creation.
  const toast = new MessageToaster();
  // Ref for  input element on the page
  const inputRef = useRef<any>();
  const updateButtonRef = useRef<any>();
  const containerRef = useRef<any>();
  // Access redux state variables
  const {
    organizationTypeDropDownData,
    organizationTypeDropDownSuccess,
    organizationTypeDropDownLoading,
    rolesByOrgTypeData,
    rolesByOrgTypeLoading,
    orgnameByOrgTypeData,
    orgnameByOrgTypeLoading,
    categoryDetailsByOrgIdLoading,
    categoryDetailsByOrgIdData,
    tagsByOrgTypeData,
    tagsByOrgTypeLoading,
  } = useAppSelector((state: RootState) => state.organizationType);
  const {
    addUserApiLoading,
    addUserApiSuccess,
    addUserApiResponseCode,
    addUserApiResponseMessage,
    addSystemAdminApiLoading,
    usersCountForSubscriptionData,
    usersCountForSubscriptionLoading,
    usersCountForSubscriptionSuccess,
    usersCountForSubscriptionResponseCode,
  } = useAppSelector((state: RootState) => state.user);
  // Accessing redux state variables
  const { listStatesApiData, listStatesApiLoading, listStatesApiSuccess, listCitiesApiData, listCitiesApiLoading, listCitiesApiSuccess } = useAppSelector(
    (state: RootState) => state.organization,
  );
  const { getSystemAdminPermissionsApiSuccess, getSystemAdminPermissionsApiData } = useAppSelector((state: RootState) => state.settings);

  // Initialize language variables.
  const categoryAlert = useIntlActionMessages('Submit.Mandatory.Category');
  const categoryValidationAlert = useIntlActionMessages('Select.Mandatory.Category');
  const categorySuccessAlert = useIntlActionMessages('Success.Add.Category');
  const categoryDeleteAlert = useIntlActionMessages('Success.Delete.Category');
  const categoryUpdateAlert = useIntlActionMessages('Success.Category');
  const updateBtn = useIntlActionMessages('text.Update');
  const selectText = useIntlActionMessages('text.Select');
  const addText = useIntlActionMessages('text.Add');
  const categoryText = useIntlActionMessages('Label.Categories');
  const subcategoryText = useIntlActionMessages('Label.SubCategories');
  const categoryLabel = useIntlActionMessages('Label.Category');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');

  // Initialize component state variables.
  const [selectedOrganization, setSelectedOrganization] = useState<ISelectOptionsNumber | null>();
  const [selectedUser, setSelectedUser] = useState<ISelectOptionsNumber[] | null>([]);
  const [selectedUserCheck, setSelectedUserCheck] = useState<number>(0);
  const [orgName, setOrgName] = useState<ISelectOptionsNumber[] | null>([]);
  const [userForm, setUserForm] = useState<IUserForm>(savedefaultRequestParams);
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<Array<any>>([]);
  const [organizationTypeValue, setOrganizationTypeValue] = useState<ISelectOptionsNumber>();
  const [errorFields, setErrorFields] = useState<any>({});
  const [userRoles, setUserRoles] = useState<ISelectOptionsNumber[] | null>([]);
  const [category, setCategory] = useState<ISelectOptionsNumber[] | null>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISelectOption>({ label: selectText || 'Select', value: '' });
  const [selectedClasses, setSelectedClasses] = useState<ISelectOptionsString[]>([]);
  const [classes, setClasses] = useState<ISelectOptionsString[]>([]);
  const [newCategory, setNewCategory] = useState<any>([]);
  const [selectedTags, setSelectedTags] = useState<ISelectOption[]>([]);
  const [selectedCity, setSelectedCity] = useState<ISelectOption[]>([]);
  const [tagOptions, setTagOptions] = useState<ISelectOption[]>([]);
  const [cityOptions, setCityOptions] = useState<ISelectOption[]>([]);
  const [countryCode, setCountryCode] = useState<ISelectOption[]>([]);
  const [genderValue, setGenderValue] = useState<ISelectOption[]>([]);
  const [dob, setDob] = useState<Date | null>(null);
  const [stateValue, setStateValue] = useState<ISelectOptionsNumber[]>([]);
  const [stateOptions, setStateOptions] = useState<ISelectOptionsNumber[]>([]);
  const [disableFields, setDisableFields] = useState<boolean>(false);

  // Get organization details based user roles
  useEffect(() => {
    try {
      const userData = getFromLocalStorage('MI_USR_DATA');
      const organizationId = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : userData.OrganizationId;
      const organizationTypeId = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationTypeId : userData.OrganizationTypeId;
      const OrganizationType = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationType : userData.OrganizationType;
      const Name = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationName : userData.OrganizationName;

      if (isAdminTeacherManager()) {
        if (organizationTypeId) {
          setOrganizationTypeValue({ label: OrganizationType, value: organizationTypeId });
          dispatch(getUserRolesByOrgType({ organizationTypeId }));
        }
        if (organizationId) {
          setSelectedOrganization({ label: Name, value: organizationId });
          dispatch(getCategoryDetailsById({ OrganizationId: organizationId }));
        }
        setUserForm((prevUserForm) => ({
          ...prevUserForm,
          OrganizationTypeId: organizationTypeId,
          OrganizationID: organizationId,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [localStorage.getItem('CURRENT_ORG')]);
  // Populate organization details when coming from organization page
  useEffect(() => {
    try {
      if (isUserAdmin() && location?.state) {
        const { organizationId, OrganizationTypeId, Name, OrganizationType } = location.state;
        if (OrganizationTypeId) {
          setOrganizationTypeValue({ label: OrganizationType, value: OrganizationTypeId });
          dispatch(getUserRolesByOrgType({ OrganizationTypeId }));
        }
        if (organizationId) {
          setSelectedOrganization({ label: Name, value: organizationId });
          dispatch(getCategoryDetailsById({ OrganizationId: organizationId }));
        }
        setUserForm((prevUserForm) => ({
          ...prevUserForm,
          OrganizationTypeId: OrganizationTypeId,
          OrganizationID: organizationId,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [isUserAdmin, location.state]);

  // Show message after form submit, success/failure
  useEffect(() => {
    try {
      if (addUserApiResponseCode === 4030 && addUserApiSuccess) {
        toast.toastSuccess(addUserApiResponseMessage);
        navigate('/manageuser');
        dispatch(resetUserAdd());
      } else if (addUserApiResponseCode === 4029 && addUserApiResponseMessage) {
        toast.toastError(addUserApiResponseMessage);
        dispatch(resetUserAdd());
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [addUserApiLoading, addUserApiResponseCode, addUserApiSuccess, addUserApiResponseMessage]);
  // Reset the form after creating user
  useEffect(() => {
    try {
      dispatch(getStates({}));
      return () => {
        dispatch(resetUserAdd());
      };
      console.log(userForm, 'userForm');
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // Set user form based on user role
  useEffect(() => {
    try {
      if (selectedUserCheck !== 2) {
        setUserForm((prevUserForm) => ({
          ...prevUserForm,
          CategoryDetails: savedefaultRequestParams.CategoryDetails,
        }));
      } else {
        setUserForm((prevUserForm) => ({
          ...prevUserForm,
          CategoryDetails: [],
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [selectedUserCheck]);
  // Prevent enter key press
  useEffect(() => {
    try {
      dispatch(getOrganizationTypeDropDownList({}));
      const listener = (event: any) => {
        if (event.keyCode === NumberValues.NUM_13 && event.target.nodeName != 'TEXTAREA') {
          event.preventDefault();
        }
      };
      document.addEventListener('keydown', listener);
      return () => {
        document.removeEventListener('keydown', listener);
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // Get user roles by organization type
  useEffect(() => {
    try {
      if (organizationTypeValue) {
        let allowedRoles: number[] = [];
        const userData = getFromLocalStorage('MI_USR_DATA');
        switch (userData.RoleTypeId) {
          case RoleTypeIds.ORGANIZATION_ADMIN:
            allowedRoles = [RoleTypeIds.ORGANIZATION_MANGER, RoleTypeIds.APP_USER_MANAGER, RoleTypeIds.ACCOUNTABILITY_PARTNER, RoleTypeIds.APP_USER];
            break;
          case RoleTypeIds.ORGANIZATION_MANGER:
            allowedRoles = [RoleTypeIds.APP_USER_MANAGER, RoleTypeIds.ACCOUNTABILITY_PARTNER, RoleTypeIds.APP_USER];
            break;
          case RoleTypeIds.APP_USER_MANAGER:
            allowedRoles = [RoleTypeIds.ACCOUNTABILITY_PARTNER, RoleTypeIds.APP_USER];
            break;
          default:
            allowedRoles = [
              RoleTypeIds.SUPER_ADMIN,
              RoleTypeIds.ORGANIZATION_ADMIN,
              RoleTypeIds.ORGANIZATION_MANGER,
              RoleTypeIds.APP_USER_MANAGER,
              RoleTypeIds.ACCOUNTABILITY_PARTNER,
              RoleTypeIds.APP_USER,
            ];
            break;
        }
        if (rolesByOrgTypeData && rolesByOrgTypeData.length > 0) {
          if (isUserSystemAdmin()) {
            if (getSystemAdminPermissionsApiSuccess) {
              const managingRoles = getSystemAdminPermissionsApiData[0]?.permission_details?.find((per: any) => per.org_type_id === organizationTypeValue?.value)?.managing_roles;
              const filteredRolesData = rolesByOrgTypeData.filter((role: any) => managingRoles?.some((managingRole: any) => managingRole.roleid === role.RoleId));
              const rolesData = filteredRolesData.map((role: any) => ({
                label: role.Name,
                value: role.RoleId,
                roleTypeId: role.RoleTypeID,
              }));
              setUserRoles(rolesData);
              setSelectedUser([]);
            }
          } else {
            const filteredRolesData = rolesByOrgTypeData.filter((role: any) => allowedRoles.includes(role.RoleTypeID));
            const rolesData = filteredRolesData.map((role: any) => ({
              label: role.Name,
              value: role.RoleId,
              roleTypeId: role.RoleTypeID,
            }));
            setUserRoles(rolesData);
            setSelectedUser([]);
          }
        } else {
          setUserRoles([]);
          setSelectedUser([]);
          setSelectedUserCheck(0);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [rolesByOrgTypeLoading]);

  // Get tags by organization type
  useEffect(() => {
    try {
      if (organizationTypeValue && tagsByOrgTypeData && tagsByOrgTypeData.Tags) {
        const options = tagsByOrgTypeData.Tags.map(
          (tag: string): ISelectOption => ({
            value: tag,
            label: tag.charAt(0).toUpperCase() + tag.slice(1),
          }),
        );
        setTagOptions(options);
      } else {
        setTagOptions([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [tagsByOrgTypeLoading, organizationTypeValue, tagsByOrgTypeData]);

  // Get organization details by organization type
  useEffect(() => {
    try {
      if (organizationTypeValue) {
        if (orgnameByOrgTypeData && orgnameByOrgTypeData.length > 0) {
          const organizationData = orgnameByOrgTypeData.map(
            (organization: any): ISelectOptionsNumber => ({
              label: organization.Name,
              value: organization.ID,
            }),
          );
          setOrgName(organizationData);
          setSelectedOrganization(null);
        } else {
          // If ResponseData is null, update orgName state with null
          setOrgName([]);
          setSelectedOrganization(null);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [orgnameByOrgTypeLoading]);
  // Get category details by organization
  useEffect(() => {
    try {
      if (categoryDetailsByOrgIdData && categoryDetailsByOrgIdData.length > 0) {
        const categoryData = categoryDetailsByOrgIdData.map(
          (category: any): ISelectOptionsNumber => ({
            label: category.CategoryName,
            value: category.CategoryId,
          }),
        );
        setCategory(categoryData);
      } else {
        // If ResponseData is null, update category state with null
        setCategory([]);
        setSelectedCategory({ label: selectText || 'Select', value: '' });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [categoryDetailsByOrgIdLoading, selectText]);
  // Get organization type
  useEffect(() => {
    try {
      if (organizationTypeDropDownSuccess && organizationTypeDropDownData?.length > 0) {
        const orgTypeOptions = organizationTypeDropDownData.map(
          (orgtype: any): ISelectOptionsNumber => ({
            label: orgtype.orgTypeName,
            value: orgtype.orgTypeId,
          }),
        );
        setOrganizationTypeOptions([...orgTypeOptions]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationTypeDropDownLoading]);
  // Add event listener for Enter key press
  useEffect(() => {
    try {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
          // Check if all required fields are filled
          if (isFormValid()) {
            event.preventDefault();
            onSubmit();
          }
        }
      };
      const isFormValid = () => {
        return Object.values(errorFields).every((error) => !error);
      };
      const currentContainer = containerRef?.current;
      if (currentContainer) {
        currentContainer.addEventListener('keydown', handleKeyPress);
      }
      return () => {
        if (currentContainer) {
          currentContainer.removeEventListener('keydown', handleKeyPress);
        }
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [errorFields]);

  // Initial loading - call to list states api
  useEffect(() => {
    try {
      if (listStatesApiSuccess && listStatesApiData?.length > 0) {
        // set data to state dropdown
        const stateOptions = listStatesApiData.map(
          (state: any): ISelectOptionsNumber => ({
            label: state.StateName,
            value: state.Id,
          }),
        );
        setStateOptions(stateOptions);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listStatesApiLoading]);

  // Initial loading - call to list cities api
  // Get cities
  useEffect(() => {
    try {
      if (listCitiesApiSuccess && listCitiesApiData && listCitiesApiData?.length > 0) {
        const options = listCitiesApiData.map(
          (city: any): ISelectOption => ({
            value: city.Id,
            label: city.CityName,
          }),
        );
        setCityOptions(options);
      } else {
        setCityOptions([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listCitiesApiLoading, listCitiesApiData]);
  // limiting user roles based on subscription count
  useEffect(() => {
    if (usersCountForSubscriptionSuccess) {
      const newRoles: ISelectOptionsNumber[] = [];
      userRoles?.forEach((role: any) => {
        if (APP_USER_ROLES.includes(role.roleTypeId) && usersCountForSubscriptionData.NoOfMobileAppUsers > 0) {
          newRoles.push(role);
        } else if (WEB_USER_ROLES.includes(role.roleTypeId) && usersCountForSubscriptionData.NoOfWebUsers > 0) {
          newRoles.push(role);
        }
      });
      setUserRoles(newRoles);
      setDisableFields(false);
    }
    if (usersCountForSubscriptionResponseCode === 4262) {
      toast.toastError('This organization has no active subscription plan.');
      setDisableFields(true);
    }
  }, [usersCountForSubscriptionLoading]);

  // Get user roles and organization name on organization type change
  const handleOrgTypeChange = async (event: any) => {
    try {
      setDisableFields(false);
      const selectedOrgTypeId = parseInt(event.value);
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        OrganizationTypeId: selectedOrgTypeId,
        RoleId: 0,
        OrganizationID: '',
      }));
      setOrganizationTypeValue(event);
      dispatch(
        getUserRolesByOrgType({
          OrganizationTypeId: selectedOrgTypeId,
        }),
      );
      dispatch(
        getOrganizationNameByOrgType({
          OrganizationTypeId: selectedOrgTypeId,
        }),
      );
      dispatch(
        getUserTagsByOrgType({
          OrganizationTypeId: selectedOrgTypeId,
          searchText: '',
        }),
      );
      dispatch(resetSubscriptionCount());
      const validateObj = {
        ...userForm,
        OrganizationTypeId: selectedOrgTypeId,
        RoleId: 0,
        OrganizationID: 0,
      };
      const errorResult = await validateForm(validateObj, USER_SCHEMA, errorFields);
      setErrorFields(errorResult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle selection change
  const handleSelectionChange = async (event: any, type: string) => {
    try {
      const selectedValue = event.value;
      const selectedUser = parseInt(event.value);
      switch (type) {
        case 'role': {
          setSelectedUser(event.value);
          const selectedRole = rolesByOrgTypeData.find((role: any) => role.RoleId === selectedUser);
          // Check if the selected role exists and its RoleType is equal to student
          const selectedUserCheckValue = selectedRole && selectedRole.RoleTypeID === RoleTypeIds.APP_USER ? 2 : 0;
          setSelectedUserCheck(selectedUserCheckValue);
          setUserForm((prevUserForm) => ({
            ...prevUserForm,
            RoleId: selectedValue,
          }));
          setSelectedUser(event);
          const validateRoleObj = {
            ...userForm,
            RoleId: selectedValue,
          };
          const roleErrorResult = await validateForm(validateRoleObj, USER_SCHEMA, errorFields);
          setErrorFields(roleErrorResult);
          break;
        }
        case 'organization': {
          setSelectedOrganization(event.value);
          setDisableFields(false);
          dispatch(userCountForSubscription({ organizationId: event.value }));
          setUserForm((prevUserForm) => ({
            ...prevUserForm,
            OrganizationID: selectedValue,
          }));
          setSelectedOrganization(event);
          dispatch(
            getCategoryDetailsById({
              OrganizationId: selectedValue,
            }),
          );
          const validateOrgObj = {
            ...userForm,
            OrganizationID: selectedValue,
          };
          const orgErrorResult = await validateForm(validateOrgObj, USER_SCHEMA, errorFields);
          setErrorFields(orgErrorResult);
          break;
        }
        default:
          break;
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle input field
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      setUserForm((info: any) => ({
        ...info,
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      const errorResult = await validateForm(validateObj, USER_SCHEMA, errorFields);
      setErrorFields(errorResult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle country code select box
  const handleCountryCodeChange = (event: any) => {
    try {
      setCountryCode(event);
      setErrorFields((prev: any) => ({
        ...prev,
        CountryCode: '',
      }));
      setUserForm((prev: IUserForm) => ({
        ...prev,
        CountryCode: event.value,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle gender select box change
  const handleGenderChange = (event: any) => {
    try {
      setGenderValue(event);
      setUserForm((prevData: IUserForm) => ({
        ...prevData,
        Gender: event.value,
      }));
      setErrorFields((prevErrors: any) => ({
        ...prevErrors,
        Gender: '',
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle dob select box change
  const handleDobChange = (date: any) => {
    try {
      setDob(date);
      setUserForm((prevData: IUserForm) => ({
        ...prevData,
        DOB: date !== null ? `${moment(date).local().format(DATE_FORMAT1)}` : '',
      }));
      setErrorFields((prevErrors: any) => ({
        ...prevErrors,
        DOB: '',
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // subtract years from a date and calculate new
  const subtractYears = (date: any, years: any) => {
    try {
      date.setFullYear(date.getFullYear() - years);
      return date;
    } catch (error) {
      console.log('Error: ', error);
      return null;
    }
  };
  // handle state select box change
  const handleStateChange = (event: any) => {
    try {
      setStateValue(event);
      // setCityValue([]);
      setUserForm((prevData: IUserForm) => ({
        ...prevData,
        StateId: event.value,
      }));
      setErrorFields((prevErrors: any) => ({
        ...prevErrors,
        StateId: '',
      }));
      const reqParams = {
        StateId: event.value,
      };
      dispatch(getCities(reqParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle state change
  const handleCityTagChange = (tag: any) => {
    try {
      // setCityValue(tag);
      setSelectedCity(tag);
      setUserForm((prevData: IUserForm) => ({
        ...prevData,
        CityId: isNaN(Number(tag?.value)) ? 0 : tag?.value,
        CityName: tag?.label,
      }));
      setErrorFields((prevErrors: any) => ({
        ...prevErrors,
        CityId: '',
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle form submit
  const onSubmit = async () => {
    try {
      let errorResult = {};
      errorResult = await validateForm(userForm, USER_SCHEMA, errorFields);
      if (selectedUserCheck === 2) {
        // Check if both category and classes are selected
        if (newCategory.length === 0) {
          toast.toastError(categoryAlert);
          return;
        }
      }
      if (Object.keys(errorResult).length === 0) {
        dispatch(addSingleUser(userForm));
      } else {
        setErrorFields(errorResult);
        // If there are validation errors, focus on the input field
        const firstErrorField = Object.keys(errorResult)[0];
        if (firstErrorField && inputRef?.current) {
          inputRef?.current?.focus();
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Get sub categories based on category
  const handleCategoryChange = (event: any) => {
    try {
      const actualCategoryId = event.value;
      setSelectedCategory(event);
      const category = categoryDetailsByOrgIdData.find((category: any) => category.CategoryId === actualCategoryId);
      if (category) {
        const subcategories = category.Subcategory.map((subcategory: any) => ({
          label: subcategory.classname,
          value: subcategory.classid,
        }));
        setClasses(subcategories);
        setSelectedClasses([]);
      } else {
        setClasses([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle class chanage
  const handleClassChange = (event: any) => {
    try {
      setSelectedClasses(event);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Submit category
  const onSubmitCategory = () => {
    try {
      if (selectedCategory.value === '' || selectedClasses.length === 0) {
        toast.toastError(categoryValidationAlert);
        return;
      }
      const updatedCategory = {
        CategoryId: parseInt(selectedCategory.value),
        CategoryName: selectedCategory.label,
        Subcategory: selectedClasses.map((classItem: any) => ({
          classid: classItem.value,
          classname: classItem.label,
        })),
      };
      const indexToUpdate = newCategory.findIndex((category: any) => category.CategoryId === updatedCategory.CategoryId);
      if (indexToUpdate !== -1) {
        // If the category already exists, update it
        const updatedNewCategory = [...newCategory];
        updatedNewCategory[indexToUpdate] = updatedCategory;
        setUserForm((prevUserForm) => ({
          ...prevUserForm,
          CategoryDetails: updatedNewCategory.map((category: any) => ({
            categoryid: category.CategoryId,
            classid: category.Subcategory.map((subCategory: any) => subCategory.classid),
          })),
        }));
        setNewCategory(updatedNewCategory);
        if (updateButtonRef.current) {
          updateButtonRef.current.textContent = addText;
        }
        const categoryData = categoryDetailsByOrgIdData.map(
          (category: any): ISelectOptionsNumber => ({
            label: category.CategoryName,
            value: category.CategoryId,
          }),
        );
        setCategory(categoryData);
        toast.toastSuccess(categoryUpdateAlert);
      } else {
        // If the category doesn't exist, add it as a new entry
        const updatedNewCategory = [...newCategory, updatedCategory];
        setUserForm((prevUserForm) => ({
          ...prevUserForm,
          CategoryDetails: updatedNewCategory.map((category: any) => ({
            categoryid: category.CategoryId,
            classid: category.Subcategory.map((subCategory: any) => subCategory.classid),
          })),
        }));
        setNewCategory(updatedNewCategory);
        const categoryData = categoryDetailsByOrgIdData.map(
          (category: any): ISelectOptionsNumber => ({
            label: category.CategoryName,
            value: category.CategoryId,
          }),
        );
        setCategory(categoryData);
        toast.toastSuccess(categorySuccessAlert);
      }
      setSelectedCategory({ label: selectText, value: '' });
      setClasses([]);
      setSelectedClasses([]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Delete category
  const onDeleteCategory = (index: number) => {
    try {
      setNewCategory((prevNewCategory: any) => {
        const updatedCategoryDetails = [...prevNewCategory];
        updatedCategoryDetails.splice(index, 1);
        return updatedCategoryDetails;
      });
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        CategoryDetails: prevUserForm.CategoryDetails.filter((_: any, i: any) => i !== index),
      }));
      toast.toastSuccess(categoryDeleteAlert);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Update category
  const onUpdateCategory = (index: number) => {
    try {
      const categoryToUpdate = newCategory[index];
      // Set the selected category and classes
      setSelectedCategory({ label: categoryToUpdate.CategoryName, value: categoryToUpdate.CategoryId });
      const updatedDisabledCategories = [
        {
          label: categoryToUpdate.CategoryName,
          value: categoryToUpdate.CategoryId,
          isDisabled: true, // Disable the selected category
        },
      ];
      setCategory(updatedDisabledCategories);
      setSelectedClasses(
        categoryToUpdate.Subcategory.map((subcategory: any) => ({
          label: subcategory.classname,
          value: subcategory.classid,
        })),
      );
      const categoryDetails = categoryDetailsByOrgIdData.find((category: any) => category.CategoryId.toString() === categoryToUpdate.CategoryId.toString());
      if (categoryDetails) {
        const categoryClasses = categoryDetails.Subcategory || [];
        const otherClasses = categoryClasses.filter((classItem: any) => {
          return !categoryToUpdate.Subcategory.some((selectedClass: any) => selectedClass.classid === classItem.classid);
        });
        const otherClassesOptions = otherClasses.map((classItem: any) => ({
          label: classItem.classname,
          value: classItem.classid,
        }));
        setClasses(otherClassesOptions);
      }
      // Change text of add button to update
      if (updateButtonRef.current) {
        updateButtonRef.current.textContent = updateBtn;
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Function to handle cancel on tab key press
  const handleCancel: React.KeyboardEventHandler<HTMLButtonElement> = (event) => {
    try {
      if (event.key === 'Enter') {
        navigate('/manageuser');
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle tag changes
  const handleTagChange = (tags: any) => {
    try {
      setSelectedTags(tags);
      setUserForm((prevUserForm) => ({
        ...prevUserForm,
        Tags: tags.map((tag: any) => tag.value),
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  const getUsersCount = () => {
    if (usersCountForSubscriptionSuccess && usersCountForSubscriptionData) {
      return `Web users allowed :${usersCountForSubscriptionData.NoOfWebUsers} App users allowed :${usersCountForSubscriptionData.NoOfMobileAppUsers}`;
    }
  };
  const resetUsersCount = () => {
    dispatch(resetSubscriptionCount());
  };

  return (
    <>
      <div className="page-title orgAdd-page-title d-flex justify-content-between align-items-center">
        <h3>{useIntlActionMessages('Button.AddUser')}</h3>
        {location?.state?.parentPage == 'orgview' && (
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>
              <FormattedMessage id="Hd.Organization" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate('/vieworganization', { state: { organizationId: location.state.organizationId } })}>
              <FormattedMessage id="Hd.ViewOrganization" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Button.AddUser" />
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
        {location?.state?.parentPage == 'manageuser' && (
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageuser')}>
              <FormattedMessage id="Hd.ManageUsers" />{' '}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Button.AddUser" />
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
      </div>
      <div className="content-sub" ref={containerRef}>
        <div className="content-area-padding">
          <div className="d-flex justify-content-center">
            <Col xl={6} lg={7} className="tab-secondary">
              <Tabs defaultActiveKey="SingleEntry" transition={false} id="noanim-tab-example" className="mb-5 mt-4" onSelect={(key: string | null) => resetUsersCount()}>
                <Tab eventKey="SingleEntry" title="Single Entry">
                  <h6 className="text-center">{getUsersCount()}</h6>
                  <Select
                    label={useIntlMessages('Label.OrgType')}
                    options={organizationTypeOptions}
                    value={organizationTypeValue}
                    name="OrganizationTypeId"
                    placeholder={useIntlMessages('PH.Select')}
                    onChange={(e: any) => handleOrgTypeChange(e)}
                    error={errorFields?.OrganizationTypeId}
                    isDisabled={isAdminTeacherManager() || disableFields}
                  />
                  <Select
                    label={useIntlMessages('Label.OrgName')}
                    options={orgName}
                    value={selectedOrganization}
                    name="OrganizationID"
                    id="OrganizationID"
                    placeholder={useIntlMessages('PH.Select')}
                    onChange={(e: any) => handleSelectionChange(e, 'organization')}
                    error={errorFields?.OrganizationID}
                    isDisabled={isAdminTeacherManager()}
                  />
                  <Select
                    label={useIntlMessages('Label.Userrole')}
                    options={userRoles}
                    value={selectedUser}
                    placeholder={useIntlMessages('PH.Select')}
                    onChange={(e: any) => handleSelectionChange(e, 'role')}
                    name="RoleId"
                    id="RoleId"
                    error={errorFields?.RoleId}
                    isDisabled={disableFields}
                  />
                  <Input
                    autoFocus
                    ref={inputRef}
                    label={useIntlMessages('Label.EmailAddress')}
                    id="EmailId"
                    name="EmailId"
                    type="text"
                    placeholder={useIntlActionMessages('PH.Enter.Email')}
                    maxLength={NumberValues.NUM_100}
                    value={userForm.EmailId}
                    onChange={onInputHandleChange}
                    errorMessage={errorFields?.EmailId}
                    autoComplete="new-password"
                    status={disableFields}
                  />
                  {selectedUserCheck == 2 && (
                    <>
                      <label className="form-label">{useIntlActionMessages('Text.AddTags')}</label>
                      <CreatableSelect
                        isMulti
                        options={tagOptions}
                        name="Tags"
                        id="Tags"
                        className="tags-selector"
                        onChange={handleTagChange}
                        value={selectedTags}
                        isDisabled={disableFields}
                      />
                      <small>{useIntlActionMessages('Text.HelpTextTags')}</small>
                    </>
                  )}
                  {selectedUserCheck == 2 && (
                    <div className="studentscategoryclass mt-4">
                      <Row>
                        <Col>
                          <Select
                            label={categoryLabel}
                            options={category}
                            value={selectedCategory}
                            placeholder={categoryText}
                            onChange={(e: any) => handleCategoryChange(e)}
                            className="select-category"
                            isDisabled={disableFields}
                          />
                        </Col>
                        <Col>
                          <Select
                            label={subcategoryText}
                            options={classes}
                            value={selectedClasses}
                            placeholder={selectText}
                            onChange={(e: any) => handleClassChange(e)}
                            isMulti
                            isDisabled={disableFields}
                          />
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end btn-container mb-3">
                        <Button variant="dark" onClick={onSubmitCategory} className="icon-add">
                          <AddIcon />{' '}
                          <span className="icon-addtext" ref={updateButtonRef}>
                            {addText}
                          </span>
                        </Button>
                      </div>
                      {newCategory.length > 0 && (
                        <Table striped hover responsive>
                          <thead>
                            <tr>
                              <th>{categoryText}</th>
                              <th>{subcategoryText}</th>
                              <th className="w-100px"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {newCategory.map((category: any, index: number) => (
                              <tr key={index}>
                                <td>{category.CategoryName}</td>
                                <td>
                                  {category.Subcategory &&
                                    category.Subcategory.map((subcategory: any, subIndex: number) => (
                                      <span key={subIndex}>
                                        {subcategory.classname}
                                        {subIndex !== category.Subcategory.length - 1 && ', '}
                                      </span>
                                    ))}
                                </td>
                                <td>
                                  <Button variant="dark" size="sm" className="icon-btn me-2" onClick={() => onUpdateCategory(index)}>
                                    <Edit />
                                  </Button>
                                  <Button variant="dark" size="sm" className="icon-btn" onClick={() => onDeleteCategory(index)}>
                                    <Delete />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                    </div>
                  )}
                  <Input
                    label={useIntlMessages('First Name')}
                    id="fName"
                    name="Firstname"
                    type="text"
                    placeholder={useIntlMessages('First Name')}
                    maxLength={NumberValues.NUM_100}
                    value={userForm.Firstname}
                    onChange={onInputHandleChange}
                    errorMessage={errorFields?.Firstname}
                    autoFocus={true}
                    pattern={FIRST_NAME_REGEX}
                    status={disableFields}
                    // ref={textFirstNameInputRef}
                  />
                  <Input
                    label={useIntlMessages('Last Name')}
                    id="lName"
                    name="Lastname"
                    type="text"
                    placeholder={useIntlMessages('Last Name')}
                    maxLength={NumberValues.NUM_100}
                    value={userForm.Lastname}
                    onChange={onInputHandleChange}
                    errorMessage={errorFields?.Lastname}
                    pattern={FIRST_NAME_REGEX}
                    status={disableFields}
                  />
                  <Select
                    options={countryCodeOptions}
                    label="Country Code"
                    value={countryCode}
                    error={errorFields?.CountryCode}
                    onChange={(e: any) => handleCountryCodeChange(e)}
                    isDisabled={disableFields}
                  />
                  <Input
                    label={useIntlMessages('Label.PhoneNum')}
                    id="PhoneNo"
                    name="Phonenum"
                    type="text"
                    placeholder={useIntlMessages('PH.PhoneNum')}
                    maxLength={NumberValues.NUM_200}
                    value={userForm.Phonenum}
                    onChange={onInputHandleChange}
                    errorMessage={errorFields?.Phonenum}
                    status={disableFields}
                  />
                  <Select
                    label="Gender"
                    options={genderOptions}
                    value={genderValue}
                    placeholder="Select"
                    onChange={(e: any) => handleGenderChange(e)}
                    error={errorFields?.Gender}
                    isDisabled={disableFields}
                  />
                  <div className="custom-calendar mb-4">
                    <label className="form-label">Date of Birth</label>
                    <DatePicker
                      selected={dob}
                      onChange={(date) => handleDobChange(date)}
                      maxDate={subtractYears(new Date(), 10)}
                      placeholderText="MM/DD/YYYY"
                      showYearDropdown={true}
                      scrollableYearDropdown={true}
                      yearDropdownItemNumber={100}
                      disabled={disableFields}
                    />
                    {errorFields?.DOB && errorFields?.DOB.length > 0 && <label className="error">{errorFields?.DOB}</label>}
                  </div>
                  <Input
                    label={useIntlMessages('Label.HighestEducation')}
                    id="HighestEducation"
                    name="HighestEducation"
                    type="text"
                    placeholder={useIntlMessages('PH.HighestEducation')}
                    maxLength={NumberValues.NUM_200}
                    value={userForm.HighestEducation}
                    onChange={onInputHandleChange}
                    errorMessage={errorFields?.HighestEducation}
                    status={disableFields}
                  />
                  <Input
                    label={useIntlMessages('Label.address')}
                    id="Address"
                    name="Address"
                    type="textarea"
                    placeholder={useIntlMessages('PH.addr')}
                    maxLength={NumberValues.NUM_100}
                    value={userForm?.Address}
                    onChange={onInputHandleChange}
                    errorMessage={errorFields?.Address}
                    as="textarea"
                    rows={3}
                    status={disableFields}
                  />
                  <Select
                    label="State"
                    options={stateOptions}
                    placeholder="Select"
                    onChange={(e: any) => handleStateChange(e)}
                    error={errorFields?.StateId}
                    value={stateValue}
                    isDisabled={disableFields}
                  />
                  <div>
                    <label className="form-label">{useIntlMessages('Label.City')}</label>
                    <CreatableSelect
                      options={cityOptions}
                      name="City"
                      id="City"
                      className="tags-selector"
                      onChange={handleCityTagChange}
                      value={selectedCity}
                      isDisabled={disableFields}
                    />
                    <small>{useIntlActionMessages('Text.HelpTextCity')}</small>
                  </div>
                  <Input
                    label={useIntlMessages('Label.Zip')}
                    id="zip"
                    name="Zip"
                    type="text"
                    placeholder={useIntlMessages('PH.ZipC')}
                    maxLength={NumberValues.NUM_10}
                    value={userForm?.Zip}
                    onChange={onInputHandleChange}
                    errorMessage={errorFields?.Zip}
                    status={disableFields}
                  />
                  <Row className="mt-5 mb-5">
                    <Col>
                      <Button variant="outline-primary" className="w-100" onClick={() => navigate('/manageuser')} onKeyDown={handleCancel}>
                        {useIntlActionMessages('Button.Cancel')}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        variant="primary"
                        className="w-100"
                        onClick={onSubmit}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            onSubmit();
                          }
                        }}
                        disabled={disableFields}
                      >
                        {useIntlActionMessages('Button.AddUser')}
                      </Button>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="BulkUpload" title={useIntlActionMessages('Label.BulkUpload')}>
                  <h6 className="text-center">{getUsersCount()}</h6>
                  <UserBulkUpload />
                </Tab>
                {isUserSuperAdmin() && (
                  <Tab eventKey="SystemAdmin" title="System Admin">
                    <SystemAdminAdd />
                  </Tab>
                )}
              </Tabs>
            </Col>
          </div>
        </div>
      </div>
      {(organizationTypeDropDownLoading || rolesByOrgTypeLoading || orgnameByOrgTypeLoading || categoryDetailsByOrgIdLoading || addUserApiLoading || addSystemAdminApiLoading) && (
        <Loader />
      )}
    </>
  );
};
export default UserAdd;
