/**
 * @file   src\containers\Login.jsx
 * @brief  Login page.
 * @date   Nov, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages } from '../utils/helper';
import { Link } from 'react-router-dom';
import { Button, Col } from 'react-bootstrap';
import Input from '../components/MAInput';
import HomeLayout from '../components/HomeLayout';
import { FORGOT_PWD_SCHEMA } from '../validations/authSchema';
import { validateForm } from '../utils/formValidation';
import { IForgotPwdForm } from '../interfaces/AuthInterface';
import { useAppDispatch, useAppSelector } from '../hooks';
import { forgotPassword } from '../store/actions/authActions';
import { MessageToaster } from '../utils/ToastUtil';
import { RootState } from '../store';
import { resetForgotPassword } from '../store/slices/authSlice';
const forgotPasswordDefaultParams = {
  DeviceType: 'web',
  Email: '',
};
const ForgotPassword = () => {
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Toast object creation.
  const toast = new MessageToaster();
  const { forgotPasswordApiLoading, forgotPasswordApiSuccess, forgotPasswordResponseCode, forgotPasswordResponseMessage } = useAppSelector(
    (state: RootState) => state.authentication,
  );
  // Component state variables
  const [forgotPwdForm, setForgotPwdForm] = useState<IForgotPwdForm>(forgotPasswordDefaultParams);
  const [errorFields, setErrorFields] = useState<any>({});
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // works on unmounting.
  useEffect(() => {
    try {
      return () => {
        dispatch(resetForgotPassword());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // Handle forgot password Success/Failure.
  useEffect(() => {
    try {
      if (forgotPasswordResponseCode > 0 && forgotPasswordApiSuccess) {
        toast.toastSuccess(forgotPasswordResponseMessage);
      } else if (forgotPasswordResponseCode > 0 && !forgotPasswordApiSuccess) {
        toast.toastError(forgotPasswordResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [forgotPasswordApiLoading]);

  // handle form fields validation
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      setForgotPwdForm((info) => ({
        ...info,
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      const errorresult = await validateForm(validateObj, FORGOT_PWD_SCHEMA, errorFields);
      setErrorFields({ ...errorresult });
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle reset password button click
  const onSubmit = async () => {
    try {
      let errorresult: any = null;
      errorresult = await validateForm(forgotPwdForm, FORGOT_PWD_SCHEMA, errorFields);
      if (Object.keys(errorresult).length === 0) {
        dispatch(forgotPassword(forgotPwdForm));
        setForgotPwdForm((info) => ({
          ...info,
          Email: '',
        }));
      } else {
        setErrorFields({ ...errorresult });
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <HomeLayout>
      <div className="login-main ForgotP">
        <h2>
          <FormattedMessage id="Hd.ForgotP" />
        </h2>
        <p>
          <FormattedMessage id="tit.ForgotP" />
        </p>
        <Col>
          <Input
            autoFocus
            label={useIntlMessages('Label.Email')}
            id="Email"
            name="Email"
            type="text"
            placeholder={useIntlMessages('PH.Email')}
            maxLength={200}
            value={forgotPwdForm.Email}
            errorMessage={errorFields?.Email}
            onChange={onInputHandleChange}
          />
        </Col>
        <Col className="position-relative"></Col>
        <Button className="w-100 mb-4" variant="primary" onClick={onSubmit}>
          <FormattedMessage id="Button.Reset" />
        </Button>
        <div className="text-center">
          <Link to="/signin" className="text-center">
            <FormattedMessage id="Link.BacktoSignIn" />
          </Link>
        </div>
      </div>
    </HomeLayout>
  );
};
export default ForgotPassword;
