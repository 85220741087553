import React from 'react';
const VideoIcon = () => {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="34.766" height="28.975" viewBox="0 0 34.766 28.975">
  <path id="Subtraction_8" data-name="Subtraction 8" d="M1284.971-20159.025H1261.8a5.8,5.8,0,0,1-5.8-5.795v-17.385a5.8,5.8,0,0,1,5.8-5.795h23.174a5.8,5.8,0,0,1,5.8,5.795v17.385A5.8,5.8,0,0,1,1284.971-20159.025ZM1264.9-20181a2.9,2.9,0,0,0-2.9,2.9v9a2.9,2.9,0,0,0,2.9,2.9h12.9a2.9,2.9,0,0,0,2.9-2.9v-1.2l3.5,2.7a.3.3,0,0,0,.213.07.667.667,0,0,0,.289-.07c.185-.094.3-.193.3-.4v-11.2c0-.207-.105-.3-.3-.4a.65.65,0,0,0-.289-.076.29.29,0,0,0-.213.076l-3.5,2.7v-1.2a2.9,2.9,0,0,0-2.9-2.9Z" transform="translate(-1256 20188)" fill="#8390db"/>
</svg>
    );
}

export default VideoIcon;
