/**
 * @file   src\containers\organizations\SurveySections.tsx
 * @brief  Survey Sections page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import '../assets/css/Survey.scss';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages } from '../utils/helper';
import { Button, Col, Row } from 'react-bootstrap';
import Input from './MAInput';
import RemoveIcon from '../assets/img/icon/Remove';
import AttachmentCard from './AttachmentCard';
import Select from './MASelect';
import Delete from '../assets/img/icon/Delete';
import Plusicon from '../assets/img/plus_icn.svg';
import plusicon from '../assets/img/icon_plus_wt.svg';
import UploadNote from '../components/UploadNote';
import { FILE_TYPES_SUPPORTED } from '../utils/enums';
import Checkbox from '../components/MACheck';
import Image from '../assets/img/icon/Image';
import Audio from '../assets/img/icon/Audio';
import Video from '../assets/img/icon/Video';
import Doc from '../assets/img/icon/Attachment';
import { NumberValues } from '../utils/enums';

import '../assets/css/org.scss';
import '../assets/css/style.scss';

const SurveySections = (props: any) => {

  // Call to get  file Icon
  const getIcon = (fileType: string) => {
    switch (fileType?.toLowerCase()) {
      case 'audio':
        return <Audio />;
      case 'image':
        return <Image />;
      case 'video':
        return <Video />;
      case 'document':
        return <Doc />;
      default:
        return <Image />;
    }
  };
  return (
    <>
      {props.formSections.map((sec: any, sectionIndex: number) => (
        <div className="mb-4" key={sectionIndex}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h4>
              <FormattedMessage id="Label.Section" />
              {sectionIndex + 1}
            </h4>
            {sectionIndex > 0 && (
              <div>
                <Button variant="dark" className="icon-btn" size="sm" onClick={() => props.handleRemoveSection(sectionIndex)}>
                  <Delete />
                </Button>
              </div>
            )}
          </div>
          <div className="surveyformmain">
            <Col>
              <Input
                label={useIntlMessages('Label.Question')}
                id="Question"
                name="Question"
                type="text"
                placeholder={useIntlMessages('PH.SurveyQuestion')}
                maxLength={NumberValues.NUM_100}
                value={sec.FormQuestion}
                onChange={(e: any) => props.handleInputChange(e, sectionIndex, 'Question')}
                errorMessage={sec.errors.find((error: any) => error.key === 'FormQuestion')?.errorMessage}
              />
            </Col>

            <div className={`fileupload-sec mb-3 ${sec.FormAttachmentDisplayName.length > 0 && 'disabled-fileupload'}`}>
              <input
                // ref={fileUploadRef}
                type="file"
                name={`file${sectionIndex}-3[]`}
                id={`file${sectionIndex}-3`}
                className="inputfile inputfile-3"
                data-multiple-caption="{count} files selected"
                multiple={false}
                accept={Object.entries(FILE_TYPES_SUPPORTED)
                  .map(([key, value]) => `${value.map((ext) => `.${ext}`).join(', ')}`)
                  .join(', ')}
                onChange={(e: any) => props.uploadQuestionFile(e, sectionIndex)}
                disabled={sec.FormAttachmentDisplayName.length > 0 ? true : false}
              />
              <label htmlFor={`file${sectionIndex}-3`}>
                <img src={plusicon} alt="" />
                <span>
                  <FormattedMessage id="Button.AddFile" />
                </span>
              </label>
            </div>
            <div className="upload-info mb-3">
              <UploadNote
                fileType={Object.entries(FILE_TYPES_SUPPORTED)
                  .map(([key, value]) => `${value.map((ext) => `.${ext}`).join(', ')}`)
                  .join(', ')}
              />
            </div>
            <div className="attachment-list  my-4">
              {sec.FormAttachmentDisplayName.length > 0 && (
                <AttachmentCard
                  fileType={getIcon(sec.FormAttachmentType)}
                  filename={sec.FormAttachmentDisplayName}
                  category={sec.FormAttachmentSize}
                  deleteOption
                  onDelete={() => props.removeFormAttachment(sectionIndex, sec.FormSectionId)}
                />
              )}
            </div>
            <Col>
              <Select
                label={useIntlMessages('Label.AnswerType')}
                value={sec.SelectedOption.label ? sec.SelectedOption : { label: 'Select', value: '0' }}
                options={props.answerTypeOptions}
                placeholder={useIntlMessages('PH.Select')}
                onChange={(e: any) => props.handleInputChange(e, sectionIndex, 'AnswerType')}
                error={sec.errors.find((error: any) => error.key === 'FormAnswerType')?.errorMessage}
              />
            </Col>
            {(sec.FormAnswerTypeId == '2' || sec.FormAnswerTypeId == '5') && (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <label className="form-label mb-0">{useIntlMessages('Label.Options')}</label>
                  <Button variant="dark" className="icon-btn" onClick={() => props.handleAddOption(sectionIndex)}>
                    <img src={Plusicon} />
                  </Button>
                </div>
                {sec.Options.map((optionDetails: any, optionIndex: number) => (
                  <Row className="mt-3">
                    <Col className="no-margin">
                      <Input
                        id={`Answer_${optionIndex}`}
                        name={`Answer_${optionIndex}`}
                        type="text"
                        placeholder={useIntlMessages('PH.SurveyOption1')}
                        maxLength={NumberValues.NUM_100}
                        value={optionDetails.optionValue}
                        onChange={(e: any) => props.handleOptionChange(sectionIndex, optionIndex, optionDetails.optionId, e.target.value)}
                        errorMessage={sec.errors.find((error: any) => error.key === `Option${optionIndex}`)?.errorMessage}
                      />
                    </Col>
                    <Col xs="auto" className="w-70px">
                      {optionIndex > 0 && (
                        <Button variant="dark" className="icon-btn edit-btn" onClick={() => props.handleRemoveOption(sectionIndex, optionIndex)}>
                          <RemoveIcon />
                        </Button>
                      )}
                    </Col>
                  </Row>
                ))}
              </>
            )}
            <div className="mt-3">
              <Checkbox
                checked={sec.isRequired}
                class="isRequired"
                label={useIntlMessages('Label.required')}
                type="Checkbox"
                name={`checkbox_${sectionIndex}`}
                id={`checkbox_${sectionIndex}`}
                onChange={(e: any) => props.handleInputChange(e, sectionIndex, 'isRequired')}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SurveySections;
