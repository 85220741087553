/**
 * @file   src\validations\resourceSchema.ts
 * @brief  This file is responsible for defining resource validation schemas.
 * @date   Dec, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import * as yup from 'yup';
import { useIntlActionMessages } from '../utils/helper';

// Motivational Resource Schema
export const MOTIVATIONAL_RESOURCE_SCHEMA = yup.object({
  OrganizationTypeId: yup.number().notOneOf([0], useIntlActionMessages('Form.OrgType.Required')).required(useIntlActionMessages('Form.OrgType.Required')),
  OrganizationID: yup.string().required(useIntlActionMessages('Form.SelectOrganizationName.Required')),
  ResourceName: yup.string().trim().max(100, useIntlActionMessages('Form.ResourceName.Max')).required(useIntlActionMessages('Form.ResourceName.Required')),
  Description: yup.string().required(useIntlActionMessages('Form.Description.Required')).max(1000, useIntlActionMessages('Form.Description.Max.Allow')),
  CategoryID: yup.number().notOneOf([0], useIntlActionMessages('Form.Cateory.Required')).required(useIntlActionMessages('Form.Cateory.Required')),
  ClassID: yup.number().notOneOf([0], useIntlActionMessages('Form.Subcategory.Required')).required(useIntlActionMessages('Form.Subcategory.Required')),
});
// Motivational Resource Schema from goal
export const MOTIVATIONAL_RESOURCE_GOAL_SCHEMA = yup.object({
  ResourceName: yup.string().trim().max(150, useIntlActionMessages('Form.ResourceName.Max')).required(useIntlActionMessages('Form.ResourceName.Required')),
  Description: yup.string().required(useIntlActionMessages('Form.Description.Required')).max(1000, useIntlActionMessages('Form.Description.Max.Allow')),
});

export const RESOURCE_REPORT_SCHEMA = yup.object({
  ReportReason: yup.number().notOneOf([0], useIntlActionMessages('Form.ResourceReportReason.Required')).required(useIntlActionMessages('Form.ResourceReportReason.Required')),
  Comments: yup.string().required(useIntlActionMessages('Form.ResourceReportComment.Required')).max(300, useIntlActionMessages('ResourceReport.Comment.Max.Allow')),
});
// Motivational Resource Schema for org users
export const MOTIVATIONAL_RESOURCE_ORG_SCHEMA = yup.object({
  ResourceName: yup.string().trim().max(100, useIntlActionMessages('Form.ResourceName.Max')).required(useIntlActionMessages('Form.ResourceName.Required')),
  Description: yup.string().required(useIntlActionMessages('Form.Description.Required')).max(1000, useIntlActionMessages('Form.Description.Max.Allow')),
  CategoryID: yup.number().notOneOf([0], useIntlActionMessages('Form.Cateory.Required')).required(useIntlActionMessages('Form.Cateory.Required')),
  ClassID: yup.number().notOneOf([0], useIntlActionMessages('Form.Subcategory.Required')).required(useIntlActionMessages('Form.Subcategory.Required')),
});
// Motivational Resource Schema general
export const MOTIVATIONAL_RESOURCE_GENERAL_SCHEMA = yup.object({
  OrganizationTypeId: yup.number().notOneOf([0], useIntlActionMessages('Form.OrgType.Required')).required(useIntlActionMessages('Form.OrgType.Required')),
  OrganizationID: yup.string().required(useIntlActionMessages('Form.SelectOrganizationName.Required')),
  ResourceName: yup.string().trim().max(100, useIntlActionMessages('Form.ResourceName.Max')).required(useIntlActionMessages('Form.ResourceName.Required')),
  Description: yup.string().required(useIntlActionMessages('Form.Description.Required')).max(1000, useIntlActionMessages('Form.Description.Max.Allow')),
});
// Motivational Resource Schema for org users general
export const MOTIVATIONAL_RESOURCE_GENERAL_ORG_SCHEMA = yup.object({
  ResourceName: yup.string().trim().max(100, useIntlActionMessages('Form.ResourceName.Max')).required(useIntlActionMessages('Form.ResourceName.Required')),
  Description: yup.string().required(useIntlActionMessages('Form.Description.Required')).max(1000, useIntlActionMessages('Form.Description.Max.Allow')),
});