/**
 * @file   src\components\ViewAll.tsx
 * @brief  View all motivational resources.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import '../../assets/css/Resource.scss';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntlActionMessages, useIntlMessages } from '../../utils/helper';
import { Col, Row, Breadcrumb } from 'react-bootstrap';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Pagination from '../../components/Pagination';
import { PAGE_SIZE_12, DEFAULT_PAGE_INDEX, BUCKET_URL } from '../../utils/constants';
import Default from '../../assets/img/default_resource.jpg';
import Loader from '../../components/Loader';
import { getGoalResource } from '../../store/actions/organizationActions';
// Declare default params
const defaultApiParams = {
  GoalId: 0,
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE_12,
  TypeID: 3, // 3 For getting both motivational resources and incentive contents under the specified
};

const ResourcesViewall = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  // Location object
  const location = useLocation();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Create route param object to access the route parameters.
  const params = useParams();
  const goalId: number = params.goalId ? Number(params.goalId) : 0;
  // Initialize language variables.
  const NodataText = useIntlActionMessages('Label.Nodata');
  // Access redux state variables.
  const { getGoalResourceApiData, getGoalResourceApiSuccess, getGoalResourceApiLoading } = useAppSelector((state: RootState) => state.organization);
  // Initialize component state variables.
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_INDEX);
  const [apiParams, setApiParams] = useState<any>(defaultApiParams);
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  useEffect(() => {
    try {
      setApiParams((apiParams: any) => ({
        ...apiParams,
        GoalId: goalId,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // Set API params on filter change.
  useEffect(() => {
    try {
      setApiParams((apiParams: any) => ({
        ...apiParams,
        Page: currentPage,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [currentPage]);
  useEffect(() => {
    try {
      if (apiParams.GoalId != 0) dispatch(getGoalResource(apiParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [apiParams]);
  return (
    <>
      <div className="resources-wrap">
        <div className="page-title orgAdd-page-title d-flex justify-content-between align-items-center">
          <h3>
            {location?.state?.title} <FormattedMessage id="Hd.Resources" />
          </h3>
          <Col md="auto">
            <Breadcrumb className="breadcrumb">
              <Breadcrumb.Item onClick={() => navigate(`/goaldetail/${goalId}`)}>
                <FormattedMessage id="Hd.GoalDetails" />
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <FormattedMessage id="label.viewall" />
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </div>
        <div className="content-sub content-sub-thumb mb-3 p-3">
          <Row className="mb-3">
            {getGoalResourceApiSuccess && getGoalResourceApiData?.Resources?.length > 0 ? (
              getGoalResourceApiData?.Resources?.map((resources: any) => (
                <Col md={3} className="mb-3">
                  <div className="thum-box">
                    <img
                      src={resources && resources.ResourceThumbnail ? BUCKET_URL + resources.ResourceThumbnail : Default}
                      alt={resources.ResourceName}
                      onClick={() =>
                        navigate(`/resourceDetails/${resources.ResourceID}`, {
                          state: {
                            tabId: 3,
                            goalId: goalId,
                          },
                        })
                      }
                    />
                    <div className="slid-name">
                      <p>{resources?.FileName}</p>
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div className="content-sub content-area-padding border-top text-center">
                <h6>{NodataText}</h6>
              </div>
            )}
          </Row>
          {getGoalResourceApiSuccess && getGoalResourceApiData?.Resources?.length > 0 && getGoalResourceApiData.TotalCount > PAGE_SIZE_12 && (
            <Pagination
              pageSize={PAGE_SIZE_12}
              totalitems={getGoalResourceApiData.TotalCount}
              pageLimit={PAGE_SIZE_12}
              setCurrentPage={(page: number) => setCurrentPage(page)}
              currentPage={currentPage - 1}
              prevPage={-1}
              itemsDisplayed={getGoalResourceApiData?.Resources?.length}
            />
          )}
        </div>
      </div>
      {getGoalResourceApiLoading && <Loader />}
    </>
  );
};
export default ResourcesViewall;
