/**
 * @file   src/containers/profile/PaymentPopUp.tsx
 * @brief  Subscription payment screen
 * @date   July, 2024
 * @author ZCO
 * @copyright (c) 2024, ZCO */
import { useEffect, useState } from 'react';
import '../../assets/css/TeacherPlanListCard.scss';
import { Button, Modal, Col, Row, Form } from 'react-bootstrap';
import Input from '../../components/MAInput';
import Select from '../../components/MASelect';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import '../../assets/css/stripeElement.scss';
import { IStripeErrors, IPaymentInfo } from '../../interfaces/ProfileInterface';
import { getStates } from '../../store/actions/organizationActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { RootState } from '../../store';
import { ISelectOptionsNumber } from '../../interfaces/GeneralInterface';
import { PAYMENT_INFO_SCHEMA } from '../../validations/profileSchema';
import { validateForm } from '../../utils/formValidation';
import { addSubScriptionPayment, addPaymentMethod } from '../../store/actions/profileActions';
import Loader from '../../components/Loader';
import { useIntlMessages } from '../../utils/helper';
import { PlanTypes } from '../../utils/enums';
import { saveCurrentPlan } from '../../store/slices/profileSlice';

const defaultPaymentInfo = {
  nameOnCard: '',
  firstName: '',
  lastName: '',
  streetAddress: '',
  apartmentNo: '',
  state: 0,
  zip: '',
  billingZip: '',
};

const PaymentPopUp = (props: any) => {
  // Action dispatch object creation
  const dispatch = useAppDispatch();
  // stripe hooks
  const stripe = useStripe();
  const elements = useElements();
  const { listStatesApiData, listStatesApiLoading, listStatesApiSuccess } = useAppSelector((state: RootState) => state.organization);
  const { addSubscriptionPaymentApiLoading } = useAppSelector((state: RootState) => state.profile);
  // loading validation messages from assets file
  const enterCard = useIntlMessages('Payment.EnterCardNumber');
  const enterExpiry = useIntlMessages('Payment.EnterExpiryDate');
  const enterCvc = useIntlMessages('Payment.EnterCvc');
  const enterName = useIntlMessages('Payment.EnterNameCard');
  const enterFname = useIntlMessages('Payment.EnterFirstName');
  const enterLname = useIntlMessages('Payment.EnterLastName');
  const enterStreet = useIntlMessages('Payment.EnterStreetAdress');
  const enterState = useIntlMessages('Payment.SelectState');
  const enterZip = useIntlMessages('Payment.EnterZip');
  const enterAptNo = useIntlMessages('Payment.EnterAptNo');

  const defaultStripeErrors = {
    cardNumber: enterCard,
    expiry: enterExpiry,
    cvc: enterCvc,
  };
  const defaultErrors = {
    nameOnCard: enterName,
    firstName: enterFname,
    lastName: enterLname,
    streetAddress: enterStreet,
    apartmentNo: enterAptNo,
    state: enterState,
    zip: enterZip,
    billingZip: '',
  };
  // component state variables
  const [stripeErrors, setStripeErrors] = useState<IStripeErrors>(defaultStripeErrors);
  const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo>(defaultPaymentInfo);
  const [stateOptions, setStateOptions] = useState<ISelectOptionsNumber[]>([]);
  const [errorFields, setErrorFields] = useState<IPaymentInfo>(defaultErrors);
  const [errorVisible, setErrorVisible] = useState<boolean>(false);

  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // Intial loading call states api
  useEffect(() => {
    try {
      dispatch(getStates({}));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // set state options in select box
  useEffect(() => {
    try {
      if (listStatesApiSuccess && listStatesApiData?.length > 0) {
        // set data to state dropdown
        const stateOptions = listStatesApiData.map(
          (state: any): ISelectOptionsNumber => ({
            label: state.StateName,
            value: state.Id,
          }),
        );
        setStateOptions(stateOptions);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listStatesApiLoading]);
  // Handle stripe elements validation .set errors
  const updateStripeErrors = (event: any, context: any) => {
    try {
      if (context === 'cardNumber') {
        setStripeErrors((prev: any) => ({
          ...prev,
          cardNumber: event.error !== undefined ? event.error.message : '',
        }));
      } else if (context === 'expiry') {
        setStripeErrors((prev: any) => ({
          ...prev,
          expiry: event.error !== undefined ? event.error.message : '',
        }));
      } else {
        setStripeErrors((prev: any) => ({
          ...prev,
          cvc: event.error !== undefined ? event.error.message : '',
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle form fields validation
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      setPaymentInfo((info: any) => ({
        ...info,
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      const errorresult = await validateForm(validateObj, PAYMENT_INFO_SCHEMA, errorFields);
      setErrorFields(errorresult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // call api to subscribe to subscription plan
  const subscribeToPlan = (tokenObj: any) => {
    try {
      const addPlanRequest = {
        CardToken: tokenObj.token.id,
        BillingFname: paymentInfo.firstName,
        BillingLname: paymentInfo.lastName,
        BillingStreet: paymentInfo.streetAddress,
        BillingAptNumber: paymentInfo.apartmentNo,
        BillingState: paymentInfo.state,
        BillingZipCode: paymentInfo.zip,
        SubscriptionPlanID: props.planid,
        CardType: tokenObj.token.card.brand,
        CardLastFour: Number(tokenObj.token.card.last4),
        ExpiryMonth: tokenObj.token.card.exp_month,
        ExpiryYear: tokenObj.token.card.exp_year,
      };
      const addPaymentMethodRequest = {
        BillingFname: paymentInfo.firstName,
        BillingLname: paymentInfo.lastName,
        BillingStreet: paymentInfo.streetAddress,
        BillingAptNumber: paymentInfo.apartmentNo,
        BillingState: paymentInfo.state,
        BillingZipCode: paymentInfo.zip,
        CardType: tokenObj.token.card.brand,
        CardLastFour: Number(tokenObj.token.card.last4),
        ExpiryMonth: tokenObj.token.card.exp_month,
        ExpiryYear: tokenObj.token.card.exp_year,
      };
      if (props.context !== 'add') {
        dispatch(saveCurrentPlan(props.plantype));
        dispatch(addSubScriptionPayment(addPlanRequest));
      } else {
        dispatch(addPaymentMethod(addPaymentMethodRequest));
        resetDataAndErrors();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle payment form submit
  const handleSubmit = async () => {
    try {
      const errorresult = await validateForm(paymentInfo, PAYMENT_INFO_SCHEMA, errorFields);
      if (Object.keys(errorresult).length === 0) {
        if (stripeErrors.cardNumber.length == 0 && stripeErrors.cvc.length == 0 && stripeErrors.expiry.length == 0) {
          if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
          }
          const cardElement: any = elements.getElement(CardNumberElement);
          stripe.createToken(cardElement).then((payload) => subscribeToPlan(payload));
        } else {
          setErrorVisible(true);
        }
      } else {
        setErrorVisible(true);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle state select box change
  const handleStateChange = (event: any) => {
    try {
      setPaymentInfo((prevData: IPaymentInfo) => ({
        ...prevData,
        state: event.label,
      }));
      setErrorFields((prevErrors: any) => ({
        ...prevErrors,
        state: '',
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // customizing stripe elements appearance
  const cardElementOptions = {
    style: {
      base: {
        color: '#CCD3FA', // Base text color
        '::placeholder': {
          color: '#CCD3FA',
          opacity: '0.3',
          fontWeight: 300, // Placeholder color
        },
      },
      invalid: {
        color: '#FF0000', // Color for invalid input
      },
      complete: {
        color: '#CCD3FA', // Color when input is complete
      },
    },
  };
  // for showing recurring payment text as a hint
  const paymentText = (plantype: string) => {
    let plantext = '';
    try {
      switch (plantype) {
        case PlanTypes.MONTHLY:
          plantext = 'every month';
          break;
        case PlanTypes.HALF_YEARLY:
          plantext = 'every 6 months';
          break;
        case PlanTypes.QUARTERLY:
          plantext = 'every 3 months';
          break;
        case PlanTypes.YEARLY:
          plantext = 'every 12 months';
          break;
      }
    } catch (error) {
      console.log('Error: ', error);
    }
    return plantext;
  };
  // reset form data and errors
  const resetDataAndErrors = () => {
    try {
      props.setShow(false);
      setErrorVisible(false);
      setPaymentInfo(defaultPaymentInfo);
      setErrorFields(defaultErrors);
      setStripeErrors(defaultStripeErrors);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <>
      <Modal show={props.show} onHide={() => props.setShow(false)} size="lg" centered className="payment-popup">
        <Modal.Header closeButton>
          <Modal.Title>{props.context === 'add' && 'Add'} Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Enter your card details</h4>
          <Col>
            <Input
              label={useIntlMessages('Label.NameonCard')}
              id="Name"
              name="nameOnCard"
              type="text"
              placeholder={useIntlMessages('Label.NameonCard')}
              maxLength={200}
              value={paymentInfo.nameOnCard}
              errorMessage={errorVisible ? errorFields?.nameOnCard : ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputHandleChange(e)}
              autoFocus
            />
          </Col>
          <Col>
            <div className="form">
              <Form.Group>
                <Form.Label>Card Number</Form.Label>
                <CardNumberElement onChange={(event) => updateStripeErrors(event, 'cardNumber')} options={cardElementOptions} />
                <Form.Text className="error">{errorVisible ? stripeErrors.cardNumber : ''}</Form.Text>
              </Form.Group>
            </div>
          </Col>
          <Row className="mb-3">
            <Col>
              <Input
                label={useIntlMessages('Label.Zipcode')}
                id="Zip"
                name="zip"
                type="text"
                placeholder={useIntlMessages('Label.Zipcode')}
                maxLength={200}
                value={paymentInfo.zip}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputHandleChange(e)}
                errorMessage={errorVisible ? errorFields?.zip : ''}
              />
            </Col>
            <Col>
              <div className="form">
                <Form.Group>
                  <Form.Label>MM / YY</Form.Label>
                  <CardExpiryElement onChange={(event) => updateStripeErrors(event, 'expiry')} options={cardElementOptions} />
                  <Form.Text className="error">{errorVisible ? stripeErrors.expiry : ''}</Form.Text>
                </Form.Group>
              </div>
            </Col>
            <Col>
              <div className="form">
                <Form.Group>
                  <Form.Label>CVC</Form.Label>
                  <CardCvcElement onChange={(event) => updateStripeErrors(event, 'cvc')} options={cardElementOptions} />
                  <Form.Text className="error">{errorVisible ? stripeErrors.cvc : ''}</Form.Text>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <h4>Billing Information</h4>
          <Row>
            <Col>
              <Input
                label={useIntlMessages('Label.FirstName')}
                id="Name"
                name="firstName"
                type="text"
                placeholder={useIntlMessages('Label.FirstName')}
                maxLength={200}
                value={paymentInfo.firstName}
                errorMessage={errorVisible ? errorFields?.firstName : ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputHandleChange(e)}
              />
            </Col>
            <Col>
              <Input
                label={useIntlMessages('Label.LastName')}
                id="Name"
                name="lastName"
                type="text"
                placeholder={useIntlMessages('Label.LastName')}
                maxLength={200}
                value={paymentInfo.lastName}
                errorMessage={errorVisible ? errorFields?.lastName : ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputHandleChange(e)}
              />
            </Col>
          </Row>
          <Col>
            <Input
              label={useIntlMessages('Label.StreetAddress')}
              id="Name"
              name="streetAddress"
              type="text"
              placeholder={useIntlMessages('Label.StreetAddress')}
              maxLength={200}
              value={paymentInfo.streetAddress}
              errorMessage={errorVisible ? errorFields?.streetAddress : ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputHandleChange(e)}
            />
          </Col>
          <Row>
            <Col>
              <Input
                label={useIntlMessages('Label.AptNumber')}
                id="Name"
                name="apartmentNo"
                type="text"
                placeholder="XXXX"
                maxLength={200}
                value={paymentInfo.apartmentNo}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputHandleChange(e)}
                errorMessage={errorVisible ? errorFields?.apartmentNo : ''}
              />
            </Col>
            <Col>
              <Select
                label={useIntlMessages('Label.State')}
                options={stateOptions}
                // value={selectedRole}
                placeholder="Select"
                onChange={(e: any) => handleStateChange(e)}
                error={errorVisible ? errorFields?.state : ''}
              />
            </Col>
          </Row>
          {props.context === 'add' ? null : (
            <div className="plantotalamount d-flex justify-content-between">
              <p className="text-white mb-0">This will be billed {paymentText(props.plantype)}. You can cancel anytime.</p>
              <div className="total-amount">
                Paying Now <span>${props.price}</span>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={resetDataAndErrors}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleSubmit()}>
            {props.context === 'add' ? 'Add' : 'Pay Now'}
          </Button>
        </Modal.Footer>
      </Modal>
      {addSubscriptionPaymentApiLoading && <Loader />}
    </>
  );
};
export default PaymentPopUp;
