/**
 * @file   src/containers/manageusers/AssignOrgs.tsx
 * @brief  Assign organization to org admins
 * @date   Sep, 2024
 * @author ZCO
 * @copyright (c) 2024, ZCO */

import { useEffect, useState } from 'react';
import '../../assets/css/myprof.scss';
import '../../assets/css/org.scss';
import '../../assets/css/mngPermission.scss';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Breadcrumb, Button, Accordion } from 'react-bootstrap';
import Checkbox from '../../components/MACheck';
import { useNavigate, useParams } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import { DEFAULT_PAGE_INDEX, PAGE_SIZE_9, BUCKET_URL } from '../../utils/constants';
import { IListOrganisationForm, IOrganizationMapData, IAssignOrgList } from '../../interfaces/OrganisationInterface';
import { getOrganizationList } from '../../store/actions/organizationActions';
import Loader from '../../components/Loader';
import { RootState } from '../../store';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { assignOrganizations, orgListForAdmins } from '../../store/actions/userActions';
import { MessageToaster } from '../../utils/ToastUtil';
import { resetAssignOrg } from '../../store/slices/userSlice';
import { RoleTypeIds } from '../../utils/enums';
import { getFromLocalStorage, useIntlMessages } from '../../utils/helper';

const organizationDefaultParams: IListOrganisationForm = {
  OrganizationTypeId: 0,
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE_9,
  Search: '',
  Status: '',
  SubscriptionPlanId: 0,
};

const AssignOrganizations = () => {
  // Navigation object
  const navigate = useNavigate();
  // Action dispatch object
  const dispatch = useAppDispatch();
  // Message toast object creation
  const toastObj = new MessageToaster();
  // Route params object
  const params = useParams();
  // Access redux state variables
  const { organizationListApiData, organizationListApiLoading, organizationListApiSuccess } =
    useAppSelector((state: RootState) => state.organization);
  const { assignOrgsApiLoading, assignOrgsApiSuccess, assignOrgsApiResponseMessage, orgListForAdminsApiLoading, orgListForAdminsApiData, orgListForAdminsApiSuccess } =
    useAppSelector((state: RootState) => state.user);
  const defaultOrgData = getFromLocalStorage('org_admin_org_id');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // Component state variables
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [orgList, setOrgList] = useState<IAssignOrgList[]>([]);
  const [orgIds, setOrgIds] = useState<string[]>([]);
  const [orgListParams, setOrgListParams] = useState<IListOrganisationForm>(organizationDefaultParams);
  // call organization list api on initial loading
  useEffect(() => {
    try {
      const apiParams = organizationDefaultParams;
      apiParams.OrganizationTypeId = Number(params.orgTypeId) || 0;
      setOrgListParams(apiParams);
      dispatch(getOrganizationList(apiParams));
      dispatch(
        orgListForAdmins({
          OrgAdminID: params.userId,
        }),
      );
      // reset assign org state data on component unmount
      return () => {
        dispatch(resetAssignOrg());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // call organization list api on page change
  useEffect(() => {
    try {
      dispatch(getOrganizationList(orgListParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [orgListParams.Page]);
  // set organization list for display
  useEffect(() => {
    try {
      if (organizationListApiSuccess) {
        const orgList = organizationListApiData.Organization.map((organization: IOrganizationMapData) => ({
          orgName: organization.OrganizationName,
          orgId: organization.OrganizationID,
          active: organization.ActiveStatus,
          orgLogo: organization.LogoImage,
          checked: false,
        }));
        setOrgList(orgList);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [organizationListApiLoading]);
  // handle success and error message after assign orgs to org admin
  useEffect(() => {
    try {
      if (assignOrgsApiSuccess) {
        toastObj.toastSuccess(assignOrgsApiResponseMessage);
        navigate(`/userDetails/${params.userId}/${RoleTypeIds.ORGANIZATION_ADMIN}`);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [assignOrgsApiLoading]);
  // set already assigned org ids
  useEffect(() => {
    try {
      if (orgListForAdminsApiSuccess && orgListForAdminsApiData) {
        const existingIds: string[] = [];
        orgListForAdminsApiData.forEach((organization: any) => existingIds.push(organization.OrganizationID));
        setOrgIds(existingIds);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [orgListForAdminsApiLoading]);
  // hanlde org checkboxes click
  const handleCheckBoxClick = (index: number, orgId: string) => {
    try {
      if (defaultOrgData === orgId) {
        toastObj.toastError('Could not remove default organization');
      } else {
        const item = orgList[index];
        if (!orgIds.includes(orgId)) {
          setOrgIds([...orgIds, orgId]);
        } else {
          const orgids = [...orgIds];
          const delindex = orgIds.indexOf(orgId);
          orgids.splice(delindex, 1);
          setOrgIds(orgids);
        }
        const currentItems = [...orgList];
        item.checked = !item.checked;
        currentItems[index] = item;
        setOrgList(currentItems);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle pagination page change
  const handlePageChange = (page: number) => {
    try {
      setCurrentPage(page);
      setOrgListParams((prev: IListOrganisationForm) => ({
        ...prev,
        Page: page,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // call api to assign orgs
  const addOrganizationsToAdmin = () => {
    try {
      dispatch(
        assignOrganizations({
          OrgAdminID: params.userId || '0',
          OrgList: orgIds,
        }),
      );
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <>
      {organizationListApiLoading && <Loader />}
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="AssignOrganizations.Label" />
        </h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageuser')}>
              <FormattedMessage id="Hd.ManageUsers" />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate(`/userDetails/${params.userId}/${RoleTypeIds.ORGANIZATION_ADMIN}`)}>
              <FormattedMessage id="Hd.OrgAdmin.Details" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="AssignOrganizations.Label" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="mng-org-per">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h6>
                <FormattedMessage id="AssignOrganizations.Label" />
              </h6>
            </Accordion.Header>
            <Accordion.Body>
              <div className="org-name-sec">
                <Row className="mb-3">
                  {orgList.slice(0, 3).map((organization: IAssignOrgList, index: number) => (
                    <Col lg={4}>
                      <div className="user-wrap d-flex org-name-b">
                        <div className="org-name-icn">
                          <img src={BUCKET_URL + organization.orgLogo} />
                        </div>
                        <div className="usr-name ">
                          <p>{organization.orgName}</p>
                          <span className="usr-status-admin small-text">{organization.active === 1 ? 'Active' : 'Inactive'}</span>
                        </div>
                        <div className="check-sm">
                          <Checkbox
                            type="Checkbox"
                            checked={orgIds.includes(organization.orgId)}
                            onChange={() => handleCheckBoxClick(index, organization.orgId)}
                            disabled={defaultOrgData === organization.orgId}
                          />
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
                <Row className="mb-3">
                  {orgList.slice(3, 6).map((organization: IAssignOrgList, index: number) => (
                    <Col lg={4}>
                      <div className="user-wrap d-flex org-name-b">
                        <div className="org-name-icn">
                          <img src={BUCKET_URL + organization.orgLogo} />
                        </div>
                        <div className="usr-name ">
                          <p>{organization.orgName}</p>
                          <span className="usr-status-admin small-text">{organization.active === 1 ? 'Active' : 'Inactive'}</span>
                        </div>
                        <div className="check-sm">
                          <Checkbox type="Checkbox" checked={orgIds.includes(organization.orgId)} onChange={() => handleCheckBoxClick(index + 3, organization.orgId)} />
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
                <Row className="mb-3">
                  {orgList.slice(6).map((organization: IAssignOrgList, index: number) => (
                    <Col lg={4}>
                      <div className="user-wrap d-flex org-name-b">
                        <div>
                          <img src={BUCKET_URL + organization.orgLogo} className="org-name-icn" />
                        </div>
                        <div className="usr-name ">
                          <p>{organization.orgName}</p>
                          <span className="usr-status-admin small-text">{organization.active === 1 ? 'Active' : 'Inactive'}</span>
                        </div>
                        <div className="check-sm">
                          <Checkbox type="Checkbox" checked={orgIds.includes(organization.orgId)} onChange={() => handleCheckBoxClick(index + 6, organization.orgId)} />
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
              {organizationListApiSuccess && organizationListApiData.TotalCount > PAGE_SIZE_9 && (
                <Pagination
                  pageSize={PAGE_SIZE_9}
                  totalitems={organizationListApiData.TotalCount}
                  pageLimit={PAGE_SIZE_9}
                  setCurrentPage={(page: number) => handlePageChange(page)}
                  currentPage={currentPage - 1}
                  prevPage={-1}
                  itemsDisplayed={organizationListApiData?.Organization?.length}
                />
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <Row>
        <Col lg={12} md={6} className="btn-btm d-flex justify-content-lg-end mt-4">
          <Button className="mb-4 btn-cancel btn-sec" variant="outline-primary" onClick={() => navigate(`/userDetails/${params.userId}/${RoleTypeIds.ORGANIZATION_ADMIN}`)}>
            <FormattedMessage id="Button.Cancel" />
          </Button>
          <Button className="mb-4 btn-Done" variant="primary" onClick={addOrganizationsToAdmin}>
            <FormattedMessage id="Button.save" />
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default AssignOrganizations;
