/**
 * @file   src\containers\competencies\Add.tsx
 * @brief  Competencies Add.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntlMessages, useIntlActionMessages, getFromLocalStorage, getCurrentOrgDetails, isAdminTeacherManager, isUserAdmin } from '../../utils/helper';
import { Button, Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Input from '../../components/MAInput';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Plusicon from '../../assets/img/plus_icn.svg';
import minusicon from '../../assets/img/icn-minus.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { COMPETENCY_SCHEMA } from '../../validations/competencySchema';
import { validateForm } from '../../utils/formValidation';
import { addCompetency, viewCompetency, updateCompetency, listGroupByType, listTags } from '../../store/actions/competencyActions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ICompetencyForm, ICompetencyViewAPIRequest } from '../../interfaces/CompetencyInterface';
import { resetCompetencyAdd, resetCompetenciesUpdate, resetCompetenciesView } from '../../store/slices/competencySlice';
import Loader from '../../components/Loader';
import { NumberValues } from '../../utils/enums';
import { MessageToaster } from '../../utils/ToastUtil';
import Checkbox from '../../components/MACheck';
import CreatableSelect from 'react-select/creatable';
import { ISelectOption, ISelectOptionsNumber } from '../../interfaces/GeneralInterface';
import { IGroupRequestParams } from '../../interfaces/ResourceInterface';
import AddIcon from '../../assets/img/icon/Add';
import RadioGroup from '../../components/RadioGroup';
import Close from '../../assets/img/Close.svg';
import plusicon from '../../assets/img/icon_plus_wt.svg';
import Sample from '../../assets/img/default_resource.jpg';
import Delete from '../../assets/img/icon/Delete';

// Declare default params
const savedefaultRequestParams: ICompetencyForm = {
  Name: '',
  Options: [],
  OrganizationID: '',
  IsGeneral: false,
  Groups: [],
  CompetencyTagID: 1,
};
// Declare default params for group listing
const defaultParamsListGroups: IGroupRequestParams = {
  Page: 0,
  PageSize: 0,
  Type: 1,
  SearchText: '',
  PageSource: '',
};
const CompetenciesAdd = () => {
  const fileUploadRef = useRef<HTMLInputElement>(null);
  // Navigation object
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Location object
  const location = useLocation();
  // Toast object creation.
  const toast = new MessageToaster();

  // Access redux state variables
  const {
    addCompetencyApiLoading,
    addCompetencyApiSuccess,
    addCompetencyApiResponseCode,
    addCompetencyApiResponseMessage,
    competencyDetail,
    competencyViewApiLoading,
    competencyViewApiSuccess,
    competencyUpdateApiLoading,
    competencyUpdateApiSuccess,
    competencyUpdateApiResponseCode,
    competencyUpdateApiResponseMessage,
    listGroupData,
    listGroupApiLoading,
    listTagsApiData,
    listTagsApiLoading,
    listTagsApiSuccess,
  } = useAppSelector((state: RootState) => state.competency);
  // Initialize component state variables.
  const [competencyForm, setCompetencyForm] = useState<ICompetencyForm>(savedefaultRequestParams);
  const [errorFields, setErrorFields] = useState<any>({});
  const [forms, setForms] = useState<number[]>([1]);
  const [options, setOptions] = useState<string[]>(competencyDetail?.Options || ['']);
  const [isGeneralCompetency, setIsGeneralCompetency] = useState(false);
  const [groupOptions, setGroupOptions] = useState<ISelectOption[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<ISelectOption[]>([]);
  const OptionsMin = useIntlActionMessages('Form.Options.Min');
  const Duplicateoptions = useIntlActionMessages('Form.Duplicate.options');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  const [tagsOptions, setTagsOptions] = useState<Array<any>>([]);
  const [tagValue, setTags] = useState<ISelectOptionsNumber>({ label: '', value: 1 });
  // Get organization details based roles
  useEffect(() => {
    try {
      const userData = getFromLocalStorage('MI_USR_DATA');
      const OrganizationId = getCurrentOrgDetails() !== null ? getCurrentOrgDetails().OrganizationID : userData.OrganizationId;
      if (isAdminTeacherManager()) {
        setCompetencyForm((prevForm) => ({
          ...prevForm,
          OrganizationID: OrganizationId,
          IsGeneral: true,
          Groups: [],
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [localStorage.getItem('CURRENT_ORG')]);
  // Function to get competency details
  useEffect(() => {
    try {
      if (location?.state?.CompetencyID) {
        const competencyViewAPIRequest: ICompetencyViewAPIRequest = {
          CompetencyID: location?.state?.CompetencyID,
        };
        dispatch(viewCompetency(competencyViewAPIRequest));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // Show message after form submit, success/failure
  useEffect(() => {
    try {
      if (addCompetencyApiResponseCode > 0 && addCompetencyApiSuccess) {
        toast.toastSuccess(addCompetencyApiResponseMessage);
        navigate('/competencies');
        dispatch(resetCompetencyAdd());
      } else if (addCompetencyApiResponseCode > 0 && addCompetencyApiResponseMessage && !addCompetencyApiSuccess) {
        toast.toastError(addCompetencyApiResponseMessage);
        dispatch(resetCompetencyAdd());
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [addCompetencyApiLoading, addCompetencyApiResponseCode, addCompetencyApiSuccess, addCompetencyApiResponseMessage]);
  // Reset the form after creating competency
  useEffect(() => {
    try {
      dispatch(listGroupByType(defaultParamsListGroups));
      dispatch(listTags({}));
      return () => {
        dispatch(resetCompetencyAdd());
        dispatch(resetCompetenciesUpdate());
        dispatch(resetCompetenciesView());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);
  // set data for competency edit
  useEffect(() => {
    try {
      if (competencyViewApiSuccess && competencyDetail) {
        if (location.pathname === '/editcompetency') {
          setIsGeneralCompetency(competencyDetail?.IsGeneral);
          const groupsForSelection = competencyDetail?.Groups?.map((groups: any) => ({
            label: groups.Groupname,
            value: groups.Groupid,
          }));
          const groupsForEdit = competencyDetail?.Groups?.map((groups: any) => ({
            Name: groups.Groupname,
            Id: groups.Groupid,
          }));
          setSelectedGroups(groupsForSelection);
          setCompetencyForm((competencyForm: any) => ({
            ...competencyForm,
            CompetencyID: competencyDetail?.CompetencyID.toString(),
            Name: competencyDetail?.Title,
            IsGeneral: competencyDetail?.IsGeneral,
            Groups: groupsForEdit,
          }));
          const optionsArray = competencyDetail?.Options.map((opt: any) => opt.competencyoption) || [];
          setOptions(optionsArray);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [competencyViewApiLoading, competencyViewApiSuccess, competencyDetail]);
  // Handle Org Type Update Success/Failure.
  useEffect(() => {
    try {
      if (competencyUpdateApiSuccess && competencyUpdateApiResponseCode > 0) {
        navigate('/competencies');
        toast.toastSuccess(competencyUpdateApiResponseMessage);
      } else if (!competencyUpdateApiSuccess && competencyUpdateApiResponseCode > 0) {
        toast.toastError(competencyUpdateApiResponseMessage);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [competencyUpdateApiLoading]);
  // Add event listener for Enter key press
  useEffect(() => {
    try {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && event.target instanceof HTMLInputElement) {
          // Check if all required fields are filled
          if (isFormValid()) {
            event.preventDefault();
            onSubmit();
          }
        }
      };
      const isFormValid = () => {
        return Object.values(errorFields).every((error) => !error);
      };
      document.addEventListener('keydown', handleKeyPress);
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [errorFields]);
  // Get groups by organization type
  useEffect(() => {
    try {
      if (listGroupData && listGroupData?.Groups) {
        const options = listGroupData.Groups.map(
          (group: any): ISelectOption => ({
            value: group.ID,
            label: group.Name.charAt(0).toUpperCase() + group.Name.slice(1),
          }),
        );
        setGroupOptions(options);
      } else {
        setGroupOptions([]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listGroupApiLoading]);
  // Get tags
  useEffect(() => {
    try {
      if (listTagsApiSuccess && listTagsApiData?.Tags.length > 0) {
        const setTagsOptionsVal = listTagsApiData.Tags.map(
          (tags: any): ISelectOptionsNumber => ({
            label: tags.CompetencyTag,
            value: tags.CompetencyTagID,
          }),
        );
        setTagsOptions([...setTagsOptionsVal]);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [listTagsApiLoading]);
  // Handle options add
  const handleAddOption = () => {
    setOptions([...options, '']);
  };
  // Handle options remove
  const handleRemoveOption = (index: any) => {
    try {
      if (options.length === 1) {
        return; // Do not remove if there's only one option
      }
      const updatedOptions = [...options];
      updatedOptions.splice(index, 1);
      setOptions(updatedOptions);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle input field
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      setCompetencyForm((info: any) => ({
        ...info,
        [name]: value,
      }));
      // Validate other fields
      const validateObj = { [name]: value };
      const errorResult = await validateForm(validateObj, COMPETENCY_SCHEMA, errorFields);
      setErrorFields(errorResult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle form submit
  const onSubmit = async () => {
    try {
      // Validate the Options field
      const optionsErrorResult = validateOptions(options);
      if (isUserAdmin()) {
        if (!isGeneralCompetency && (!selectedGroups || selectedGroups.length === 0)) {
          setErrorFields((prev: any) => ({
            ...prev,
            Groups: useIntlMessages('Validation.Groups.Required'),
          }));
          return;
        }
      }

      // Validate other fields
      const otherFieldsErrorResult = await validateForm(competencyForm, COMPETENCY_SCHEMA, errorFields);
      if (optionsErrorResult) {
        if (optionsErrorResult.Options === Duplicateoptions) {
          toast.toastError(optionsErrorResult.Options);
        } else {
          setErrorFields({ ...otherFieldsErrorResult, ...optionsErrorResult });
        }
      } else if (Object.keys(otherFieldsErrorResult).length !== 0) {
        setErrorFields(otherFieldsErrorResult);
        return;
      } else {
        // If there are no validation errors, proceed with form submission
        const optionValues = options.map((option) => option.trim()).filter((option) => option !== '');
        setCompetencyForm((info: any) => ({
          ...info,
          Options: optionValues,
        }));
        if (location?.state?.CompetencyID) {
          dispatch(
            updateCompetency({
              ...competencyForm,
              Options: optionValues,
            }),
          );
        } else {
          dispatch(
            addCompetency({
              ...competencyForm,
              Options: optionValues,
            }),
          );
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Function to validate the Options field
  const validateOptions = (options: string[]): { Options?: string } | null => {
    const nonEmptyOptions = options.filter((option) => option.trim() !== '');
    if (nonEmptyOptions.length === 0) {
      return { Options: OptionsMin };
    }
    const uniqueOptions = new Set(nonEmptyOptions);
    if (uniqueOptions.size !== nonEmptyOptions.length) {
      return { Options: Duplicateoptions };
    }
    return null; // No validation errors
  };
  // Function to handle the Options field
  const handleOptionChange = (index: number, value: string) => {
    try {
      const updatedOptions = [...options];
      updatedOptions[index] = value;
      setOptions(updatedOptions);
      // Clear options error message if options are modified
      setErrorFields((prevErrorFields: any) => {
        const { Options, ...rest } = prevErrorFields; // Remove the Options error
        return rest;
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    try {
      setIsGeneralCompetency(!isGeneralCompetency);
      // Reset selected options when checkbox is checked
      if (!isGeneralCompetency) {
        setCompetencyForm((prevCompetencyForm) => ({
          ...prevCompetencyForm,
          IsGeneral: true,
          Groups: [],
        }));
      } else {
        setSelectedGroups([]);
        setCompetencyForm((prevCompetencyForm) => ({
          ...prevCompetencyForm,
          IsGeneral: false,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // handle group changes
  const handleGroupChange = (groups: any) => {
    try {
      setSelectedGroups(groups);
      setCompetencyForm((prevCompetencyForm) => ({
        ...prevCompetencyForm,
        Groups: groups.map((group: any) => ({
          Id: isNaN(Number(group?.value)) ? 0 : group?.value,
          Name: group?.label,
        })),
      }));
      if (!isGeneralCompetency && (!groups || groups.length === 0)) {
        setErrorFields((prevErrorFields: any) => ({
          ...prevErrorFields,
          Groups: useIntlMessages('Validation.Groups.Required'),
        }));
      } else {
        // Clear the groups error if valid
        setErrorFields((prevErrorFields: any) => ({ ...prevErrorFields, Groups: undefined }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Function to add a specific section
  const handleAddSubcategory = () => {
    try {
      setForms([...forms, forms.length + 1]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Function to remove a specific section
  const handleRemoveSubcategory = (index: number) => {
    try {
      setForms((prevForms) => prevForms.filter((_, i) => i !== index));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Function to set tags
  const handleTagChange = async (event: any) => {
    try {
      const selectedTags = parseInt(event.target.value, 10);
      setCompetencyForm((prevCompetencyForm) => ({
        ...prevCompetencyForm,
        CompetencyTagID: selectedTags,
      }));
      setTags({
        label: event.target.name,
        value: selectedTags,
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  return (
    <>
      <div className="page-title orgAdd-page-title d-flex justify-content-between align-items-center">
        <h3>{location.pathname === '/editcompetency' ? <FormattedMessage id="Button.EditCompetency" /> : <FormattedMessage id="Button.AddCompetency" />}</h3>
        <Breadcrumb className="breadcrumb">
          <Breadcrumb.Item onClick={() => navigate('/competencies')}>
            <FormattedMessage id="Hd.Competencies" />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {location.pathname === '/editcompetency' ? <FormattedMessage id="Button.EditCompetency" /> : <FormattedMessage id="Button.AddCompetency" />}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-sub py-5">
        <div className="content-area-padding">
          <div className="d-flex justify-content-center">
            <Col xl={6} lg={7} className="tab-secondary">
              <Input
                autoFocus
                label={useIntlMessages('Label.NameofCompetency')}
                id="Name"
                name="Name"
                type="text"
                placeholder={useIntlMessages('PH.NameCompetency')}
                maxLength={NumberValues.NUM_100}
                onChange={onInputHandleChange}
                value={competencyForm.Name}
                errorMessage={errorFields?.Name}
              />
              {forms.map((formId, index) => (
                <div key={formId} className="mb-3">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h4>
                      <FormattedMessage id="Form.Title" defaultMessage={`Subcategory ${index + 1}`} />
                    </h4>
                    {index > 0 && (
                      <Button variant="dark" className="icon-btn" size="sm" onClick={() => handleRemoveSubcategory(index)}>
                        <Delete />
                      </Button>
                    )}
                  </div>
                  <div className="surveyformmain">
                    <Input
                      autoFocus
                      label={useIntlMessages('Label.NameofSubcategory')}
                      id={`SubcategoryName-${index}`}
                      name={`SubcategoryName-${index}`}
                      type="text"
                      placeholder={useIntlMessages('PH.Entersubcategory')}
                      maxLength={NumberValues.NUM_100}
                      onChange={onInputHandleChange}
                      value={competencyForm.Name}
                      errorMessage={errorFields?.Name}
                    />
                    <Row className="d-flex- align-items-center">
                      <Col>
                        <label className="form-label mb-0">
                          <FormattedMessage id="Label.Options" />{' '}
                          <span>
                            (<FormattedMessage id="SubLabel.Options" />)
                          </span>
                        </label>
                      </Col>
                      <Col sm="auto">
                        <Button variant="dark" className="icon-btn" onClick={handleAddOption}>
                          <img src={Plusicon} alt="Add Option" />
                        </Button>
                      </Col>
                    </Row>
                    {options.map((option, optIndex) => (
                      <Row key={optIndex} className="d-flex align-items-center mt-3 mb-2">
                        <Col className="no-margin">
                          <Input
                            id={`Option${optIndex}`}
                            name={`Option${optIndex}`}
                            type="text"
                            onChange={(e: any) => handleOptionChange(optIndex, e.target.value)}
                            value={option}
                            placeholder={`Option${optIndex + 1}`}
                            maxLength={NumberValues.NUM_100}
                            errorMessage={errorFields?.Options}
                          />
                        </Col>
                        {options.length > 1 && (
                          <Col sm="auto">
                            <Button variant="dark" className="icon-btn" onClick={() => handleRemoveOption(optIndex)}>
                              <img src={minusicon} alt="Remove Option" />
                            </Button>
                          </Col>
                        )}
                      </Row>
                    ))}
                    <Col xl={7} className="add-resource-btn">
                      <Row>
                        <Col className="pe-0">
                          <div className="fileupload-sec mb-3">
                            <input
                              ref={fileUploadRef}
                              type="file"
                              name="file-3[]"
                              id="file-3"
                              className="inputfile inputfile-3"
                              data-multiple-caption="{count} files selected"
                              multiple={false}
                              accept="image/*"
                            />
                            <label htmlFor="file-3">
                              <img src={plusicon} alt="" />
                              <span>{useIntlActionMessages('Button.AddImage')}</span>
                            </label>
                          </div>
                        </Col>
                        <Col>
                          <div className="fileupload-sec mb-3">
                            <input
                              ref={fileUploadRef}
                              type="file"
                              name="file-3[]"
                              id="file-3"
                              className="inputfile inputfile-3"
                              data-multiple-caption="{count} files selected"
                              multiple={false}
                              accept="image/*"
                            />
                            <label htmlFor="file-3">
                              <img src={plusicon} alt="" />
                              <span>{useIntlActionMessages('Button.AddVideo')}</span>
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <Col className="d-flex">
                        <div className="img-thumb">
                          <img src={Sample} />
                          <a href="#" className="close-btn">
                            <img src={Close} />
                          </a>
                        </div>
                        <div className="img-thumb">
                          <img src={Sample} />
                          <a href="#" className="close-btn">
                            <img src={Close} />
                          </a>
                        </div>
                      </Col>
                    </Col>
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-end">
                <Button variant="secondary" onClick={handleAddSubcategory}>
                  <AddIcon /> <FormattedMessage id="Button.AddSubcategory" />
                </Button>
              </div>
              {isUserAdmin() && (
                <div className="check-sm mt-4 mb-5">
                  <Checkbox
                    type="Checkbox"
                    id="IsGeneral"
                    name="IsGeneral"
                    label={useIntlActionMessages('Label.Checkbox.GeneralCompetency')}
                    onChange={handleCheckboxChange}
                    value={competencyForm?.IsGeneral}
                    checked={competencyForm.IsGeneral}
                  />
                  {!isGeneralCompetency && (
                    <>
                      <label className="form-label">{useIntlActionMessages('Text.AddToGroup')}</label>
                      <CreatableSelect isMulti options={groupOptions} name="Groups" id="Groups" className="tags-selector" onChange={handleGroupChange} value={selectedGroups} />
                      {errorFields?.Groups && (
                        <div className="error-message">
                          <small className="text-danger">{errorFields.Groups}</small>
                        </div>
                      )}
                      <small>{useIntlActionMessages('Text.HelpTextGroup')}</small>
                    </>
                  )}
                  <div className="mt-3">
                    <RadioGroup options={tagsOptions} name="Tags" label="Select Tag" value={tagValue?.value ?? 0} onChange={(e: any) => handleTagChange(e)}></RadioGroup>
                  </div>
                </div>
              )}
              <Row className="mt-5 mb-5">
                <Col>
                  <Button variant="outline-primary" className="w-100" onClick={() => navigate('/competencies')}>
                    <FormattedMessage id="Button.Cancel" />
                  </Button>
                </Col>
                <Col>
                  <Button variant="primary" className="w-100" onClick={onSubmit} onKeyDown={(e) => e.key === 'Enter' && onSubmit()}>
                    <FormattedMessage id="Button.Save" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </div>
        </div>
      </div>
      {addCompetencyApiLoading || (competencyViewApiLoading && <Loader />)}
    </>
  );
};

export default CompetenciesAdd;
