/**
 * @file   src\store\actions\userActions.ts
 * @brief  This file is responsible for creating asynchronous users based api call.
 * @date   FEB, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../config/axiosConfig';
import { UserApis } from '../../utils/apiUrls';
import {
  IUserForm,
  ISystemAdminForm,
  IAssignOrgsRequest,
  IAddKudosPointsRequest,
  IListKudosPointsRequest,
  IShowKudosPointsRequest,
  ISurveyListRequest,
  IGoalListRequest,
  ITaskEventListRequest,
} from '../../interfaces/UserInterface';
// Api call to get user list
export const fetchUsersList = createAsyncThunk('/listusers', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.LIST_USERS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to add single user
export const addSingleUser = createAsyncThunk('/adduser', async (request: IUserForm, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.ADD_SINGLE_USER}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get user details
export const userdetails = createAsyncThunk('/userdetails/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.VIEW_USERS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to add system admin.
export const addSystemAdmin = createAsyncThunk('/addsystemadmin', async (request: ISystemAdminForm, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.ADD_SYSTEM_ADMIN}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

export const getProfileSettings = createAsyncThunk('/getProfileSettings/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.GET_PROFILE_SETTINGS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to user bulk upload.
export const usersBulkUpload = createAsyncThunk('/usersBulkUpload', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.USER_BULK_UPLOAD}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get accountability partners related to students
export const usersHierarchyStudents = createAsyncThunk('/usershierarchystudents/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.ACC_PARTNERS_FOR_STUDENTS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get list of organizations assigned to admin
export const orgListForAdmins = createAsyncThunk('/orglistforadmins', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApis.ORG_LIST_FOR_ADMINS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to assign organizations to org admins
export const assignOrganizations = createAsyncThunk('/assignorgsforadmin', async (request: IAssignOrgsRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApis.ASSIGN_ORGS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get students related to accountability partners
export const usersHierarchyAcPartners = createAsyncThunk('/usershierarchyaccpartners/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.STUDENTS_FOR_ACPARTNERS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

// Api call to add kudos points to a user
export const addKudosPoints = createAsyncThunk('/addkudospoints/', async (request: IAddKudosPointsRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.ADD_KUDOS_POINTS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to list kudos points of a user
export const listKudosPoints = createAsyncThunk('/listkudospoints/', async (request: IListKudosPointsRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.LIST_KUDOS_POINTS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to show kudos points of a user
export const showKudosPoints = createAsyncThunk('/showkudospoints/', async (request: IShowKudosPointsRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.SHOW_KUDOS_POINTS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to list goals of an app user
export const listGoalsForAppUser = createAsyncThunk('/listgoalsofappuser/', async (request: IGoalListRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.LIST_GOALS_APP_USER}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to list goals of an app user
export const listSurveysForAppUser = createAsyncThunk('/listsurveysofappuser/', async (request: ISurveyListRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.LIST_SURVEYS_APP_USER}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get proofs count
export const getProofsCount = createAsyncThunk('/getproofscount/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.GET_PROOF_COUNT}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get proofs count
export const listTaskEventAppUser = createAsyncThunk('/listtaskeventsofappuser/', async (request: ITaskEventListRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.LIST_TASK_EVENTS_APPUSER}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to get students related to accountability partners
export const getStudentTags = createAsyncThunk('/getStudentTags/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.STUDENT_TAGS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to list completion and in progress proofs
export const listProofs = createAsyncThunk('/listproofs/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.LIST_PROOFS}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// Api call to list kudos levels
export const listKudosLevels = createAsyncThunk('/listkudoslevels/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${UserApis.LIST_KUDOS_LEVELS}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
// get user counts for subscription of an organisation
export const userCountForSubscription = createAsyncThunk('/usercountforsubscription/', async (request: any, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(`${UserApis.USER_COUNT_FOR_SUBSCRIPTION}`, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});
