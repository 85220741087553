import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../src/assets/css/style.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './containers/Login';
import Forgot from './containers/ForgotPassword';
import Reset from './containers/ResetPassword';
import Home from './containers/Home';
import ManageOrg from './containers/organizations/Manage';
import GenericNotFound from './containers/GenericNotFound';
import Layout from './components/Layout';
import OrganizationAdd from './containers/organizations/Add';
import ViewOrg from './containers/organizations/View';
import AddOrganizationType from './containers/organizations/AddType';
import Activities from './containers/organizations/Activities';
import OrgType from './containers/organizations/OrgType';
import OrganizationTypeDetails from './containers/organizations/OrgTypeDetails';
import Myprofile from './containers/profile/Myprofile';
import UserAdd from './containers/manageusers/UserAdd';
import CompetenciesList from './containers/competencies/List';
import AddCompetencies from './containers/competencies/Add';
import Manageuser from './containers/manageusers/ManageUsers';
import UserDetails from './containers/manageusers/UserDetails';
import UserDetailsTab from './containers/manageusers/UserDetailsTab';
import MngPermission from './containers/manageusers/ManagePermission';
import SubscriptionPlans from './containers/subscriptions/Plans';
import AddSubscriptionPlan from './containers/subscriptions/Add';
import SubscriptionDetails from './containers/subscriptions/Details';
import Notification from './containers/notifications/List';
import Overview from './containers/organizations/Overview';
import Resources from './containers/manageresources/Resources';
import ResourceInfoDetail from './containers/manageresources/ResourceInfoDetail';
import AssignGoal from './containers/organizations/AssignGoal';
import ManageappSettings from './containers/manageSettings/manageAppSettings';
import AddMotivationResource from './containers/manageresources/AddMotivationResource';
import AddIncentiveResource from './containers/manageresources/AddIncentiveResource';
import GoalSuccessful from './containers/organizations/AssignGoalSuccess';
import ManageappAccountability from './containers/manageSettings/manageAppAccountabiltyPartner';
import GoalsDetail from './containers/organizations/GoalsDetail';
import ResourcesViewall from './containers/manageresources/ViewAll';
import SurveySuccessful from './containers/organizations/AssignSurveySuccess';
import AssignSurvey from './containers/organizations/AssignSurvey';
import SurveyForm from './containers/organizations/SurveyAddForm';
import TaskDetails from './containers/organizations/TaskDetails';
import SurveyDetails from './containers/organizations/SurveyDetails';
import ChangePassword from './containers/ChangePassword';
import EditProfile from './containers/EditProfile';
import TeacherLandHome from './containers/TeacherLandHome';
import Teacherlog from './containers/TeacherLog';
import ChoosePlan from './containers/profile/ChoosePlan';
import TeacherSubscriptionPlan from './containers/profile/PlanList';
import Profilecreation from './containers/profile/ProfileCreation';
import Studentsdetails from './containers/StudentsDetails';
import MyOrganization from './containers/profile/MyOrganization';
import SuccessfullySubscribed from './containers/organizations/SubscriptionSuccess';
import ReviewProof from './containers/manageusers/ReviewProof';
import UserStudentsdetailstab from './containers/manageusers/StudentsDetailsGoalsSurveysTab';
import ProtectedRoute from './components/ProtectedRoute';
import Createaccount from './containers/profile/CreateAccount';
import Emailverify from './containers/profile/OtpVerification';
import PageNotFound from './containers/404';
import GoalResourcesViewall from './containers/organizations/ViewAll';
import AssignOrganizations from './containers/manageusers/AssignOrgs';
import SetupPassword from './containers/SetupPassword';
import HowItWorks from './containers/HowItWorks';
import GoalStep3NewDesign from './containers/organizations/GoalStep3New';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/adminlogin" element={<Login />} />
        <Route path="/forgotpassword" element={<Forgot />} />
        <Route path="/resetpassword/:token" element={<Reset />} />
        <Route path="/" element={<TeacherLandHome />} />
        <Route path="/signin" element={<Teacherlog />} />
        <Route path="/createaccount/:token" element={<Createaccount />} />
        <Route path="/otpverification" element={<Emailverify />} />
        <Route path="/chooseplan" element={<ChoosePlan />} />
        <Route path="/planlist" element={<TeacherSubscriptionPlan />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="/profilecreate" element={<Profilecreation />} />
        <Route path="/editprofile" element={<Profilecreation />} />
        <Route path="/setuppassword" element={<SetupPassword />} />
        <Route path="/howitworks" element={<HowItWorks />} />
        <Route
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route path="/home" element={<Home />} />
          <Route path="/addorganization" element={<OrganizationAdd />} />
          <Route path="/editorganization" element={<OrganizationAdd />} />
          <Route path="/manageorganization" element={<ManageOrg />} />
          <Route path="/vieworganization" element={<ViewOrg />} />
          <Route path="/addorganizationtype" element={<AddOrganizationType />} />
          <Route path="/editorganizationtype" element={<AddOrganizationType />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/organizationtypedetails" element={<OrganizationTypeDetails />} />
          <Route path="/listorganizationtypes" element={<OrgType />} />
          <Route path="/orgtypedetails/:organizationTypeId" element={<OrganizationTypeDetails />} />
          <Route path="/competencies" element={<CompetenciesList />} />
          <Route path="/addcompetencies" element={<AddCompetencies />} />
          <Route path="/myprofile" element={<Myprofile />} />
          <Route path="/adduser" element={<UserAdd />} />
          <Route path="/manageuser" element={<Manageuser />} />
          <Route path="/managepermission/:userId" element={<MngPermission />} />
          <Route path="/subscriptionplans" element={<SubscriptionPlans />} />
          <Route path="/addsubscriptionplan" element={<AddSubscriptionPlan />} />
          <Route path="/subscriptiondetails" element={<SubscriptionDetails />} />
          <Route path="/subscriptiondetails/:orgId" element={<SubscriptionDetails />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/userDetails/:userId/:roleTypeId" element={<UserDetails />} />
          <Route path="/userDetailsTab/:userId/:roleTypeId" element={<UserDetailsTab />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/resourceDetails/:resourceId" element={<ResourceInfoDetail />} />
          <Route path="/resourceDetails/:isReportedTab/:resourceId" element={<ResourceInfoDetail />} />
          <Route path="/assigngoal" element={<AssignGoal />} />
          <Route path="/addmotivation" element={<AddMotivationResource />} />
          <Route path="/addincentivecontent" element={<AddIncentiveResource />} />
          <Route path="/managesettings" element={<ManageappSettings />} />
          <Route path="/goalsuccessful" element={<GoalSuccessful />} />
          <Route path="/surveysuccessful" element={<SurveySuccessful />} />
          <Route path="/assignsurvey" element={<AssignSurvey />} />
          <Route path="/surveyform/:surveyId" element={<SurveyForm />} />
          <Route path="/surveyform/:surveyId/:formId" element={<SurveyForm />} />
          <Route path="/accountabilityPartner" element={<ManageappAccountability />} />
          <Route path="/goaldetail/:goalId" element={<GoalsDetail />} />
          <Route path="/resourcesViewall" element={<ResourcesViewall />} />
          <Route path="/taskdetails" element={<TaskDetails />} />
          <Route path="/surveydetails/:surveyId" element={<SurveyDetails />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/editprofile" element={<EditProfile />} />
          <Route path="/studentsdetails" element={<Studentsdetails />} />
          <Route path="/userdetailsoverview/:userId/:status" element={<UserStudentsdetailstab />} />
          <Route path="/myorganization" element={<MyOrganization />} />
          <Route path="/successfullysubscribed" element={<SuccessfullySubscribed />} />
          <Route path="/reviewproof/:status" element={<ReviewProof />} />
          <Route path="/editcompetency" element={<AddCompetencies />} />
          <Route path="/goalResourcesViewall/:goalId" element={<GoalResourcesViewall />} />
          <Route path="/editsubscriptionplan" element={<AddSubscriptionPlan />} />
          <Route path="/assignorgs/:orgTypeId/:userId" element={<AssignOrganizations />} />
          <Route path="/step3new" element={<GoalStep3NewDesign />} />
          <Route path="/*" element={<GenericNotFound />} />
        </Route>
        <Route path="/*" element={<GenericNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
