/**
 * @file   src\containers\organizations\AssignSurveySuccess.tsx
 * @brief  Survey added successfully page.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import { FormattedMessage } from 'react-intl';
import { Button, Col, Breadcrumb } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Orgimg from '../../assets/img/Org-image.png';
import SurveySuccess from '../../assets/img/SurveySuccess.svg';

const AssignSurveySuccess = () => {
  // Navigate object creation.
  const navigate = useNavigate();
  return (
    <>
      <div className="page-title d-flex justify-content-between align-items-center">
        <h3>
          <FormattedMessage id="Button.AssignSurvey" />
        </h3>
        <Col md="auto">
          <Breadcrumb className="breadcrumb">
            <Breadcrumb.Item onClick={() => navigate('/manageorganization')}>
              <FormattedMessage id="Hd.Activities" />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              <FormattedMessage id="Button.AssignSurvey" />
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </div>
      <div className="mb-4">
        <div className="org-selected">
          <img className="thumbnail-image" src={Orgimg} alt="user pic" />
          <div>
            <h6 className="mb-1">Modern American School</h6>
            <small>5744 Forest Ln</small>
          </div>
        </div>
      </div>
      <div className="content-sub">
        <div className="content-area-padding text-center py-5">
          <img src={SurveySuccess} />
          <h5>The survey has been assigned successfully.</h5>
          <Button className="mb-4 mt-3" variant="primary" type="submit">
            <FormattedMessage id="Button.ViewActivities" />
          </Button>
        </div>
      </div>
    </>
  );
};
export default AssignSurveySuccess;
