/**
 * @file   src\store\slices\notificationSlice.ts
 * @brief  This file is responsible for creating notification based slices to call actions and state management.
 * @date   JUL, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createSlice } from '@reduxjs/toolkit';
import { getNotificationList, deleteNotifications } from '../actions/notificationActions';
import { INotificationSlice } from '../../interfaces/Notification';

// Initial state
const notificationDefaultState: INotificationSlice = {
  notificationListApiData: {},
  notificationListApiResponseCode: 0,
  notificationListApiLoading: false,
  notificationListApiSuccess: false,
  notificationListApiResponseMessage: '',
  deleteNotificationsApiData: {},
  deleteNotificationsApiResponseCode: 0,
  deleteNotificationsApiLoading: false,
  deleteNotificationsApiSuccess: false,
  deleteNotificationsApiResponseMessage: '',
};
// Authentication slice
const notificationSlice = createSlice({
  name: 'notification',
  initialState: notificationDefaultState,
  reducers: {
    resetNotificationList: (state) => {
      state.notificationListApiData = {};
      state.notificationListApiResponseCode = 0;
      state.notificationListApiLoading = false;
      state.notificationListApiSuccess = false;
      state.notificationListApiResponseMessage = '';
    },
    resetDeleteNotifications: (state) => {
      state.deleteNotificationsApiData = {};
      state.deleteNotificationsApiResponseCode = 0;
      state.deleteNotificationsApiLoading = false;
      state.deleteNotificationsApiSuccess = false;
      state.deleteNotificationsApiResponseMessage = '';
    },
  },
  extraReducers(builder) {
    // listNotificationList api starts
    builder.addCase(getNotificationList.pending, (state) => {
      state.notificationListApiLoading = true;
      state.notificationListApiSuccess = false;
      state.notificationListApiResponseCode = 0;
    });
    // listNotificationList api success
    builder.addCase(getNotificationList.fulfilled, (state, action) => {
      state.notificationListApiLoading = false;
      state.notificationListApiSuccess = true;
      state.notificationListApiResponseCode = action.payload.ResponseCode;
      state.notificationListApiData = action.payload.ResponseData;
    });
    // listNotificationList api failure
    builder.addCase(getNotificationList.rejected, (state, action: any) => {
      state.notificationListApiLoading = false;
      state.notificationListApiSuccess = false;
      state.notificationListApiResponseCode = action.payload.ResponseCode;
      state.notificationListApiResponseMessage = action.payload.ResponseMessage;
    });

    // deleteNotifications api starts
    builder.addCase(deleteNotifications.pending, (state) => {
      state.deleteNotificationsApiLoading = true;
      state.deleteNotificationsApiSuccess = false;
      state.deleteNotificationsApiResponseCode = 0;
      state.deleteNotificationsApiResponseMessage = '';
    });
    // deleteNotifications api success
    builder.addCase(deleteNotifications.fulfilled, (state, action) => {
      state.deleteNotificationsApiLoading = false;
      state.deleteNotificationsApiSuccess = true;
      state.deleteNotificationsApiResponseCode = action.payload.ResponseCode;
      state.deleteNotificationsApiData = action.payload.ResponseData;
      state.deleteNotificationsApiResponseMessage = action.payload.ResponseMessage;
    });
    // deleteNotifications api failure
    builder.addCase(deleteNotifications.rejected, (state, action: any) => {
      state.deleteNotificationsApiLoading = false;
      state.deleteNotificationsApiSuccess = false;
      state.deleteNotificationsApiResponseCode = action.payload.ResponseCode;
      state.deleteNotificationsApiResponseMessage = action.payload.ResponseMessage;
    });
  },
});

// Export actions
export const { resetNotificationList, resetDeleteNotifications } = notificationSlice.actions;
// Export reducer.
export default notificationSlice.reducer;
