/**
 * @file   src\containers\organizations\GoalStep4.tsx
 * @brief  Goal add Fourth step.
 * @date   Jan, 2024
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */

import React, { useEffect, useState, useRef } from 'react';
import '../../assets/css/GoalSurvay.scss';
import { FormattedMessage } from 'react-intl';
import { useIntlActionMessages, useIntlMessages, checkValidFile, getFromLocalStorage, bytesToSize, getFileTypeNumber } from '../../utils/helper';
import { Button, Col } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Input from '../../components/MAInput';
import Select from '../../components/MASelect';
import { useNavigate } from 'react-router-dom';
import { slide as Filter } from 'react-burger-menu';
import Close from '../../assets/img/Close.svg';
import Search from '../../components/MASearch';
import { Scrollbar } from 'react-scrollbars-custom';
import LinkIcon from '../../assets/img/icon/Link';
import AddIcon from '../../assets/img/icon/Add';
import AttachmentCard from '../../components/AttachmentCard';
import Image from '../../assets/img/icon/Image';
import Audio from '../../assets/img/icon/Audio';
import Video from '../../assets/img/icon/Video';
import Doc from '../../assets/img/icon/Attachment';
import { IResourceForm, IFinishGoalForm, IFinishGoalParams, ILinkResource } from '../../interfaces/ResourceInterface';
import { ResourcetypeID, NumberValues, IMAGE_TYPES_SUPPORTED, UploaderProps, FILE_TYPES_SUPPORTED } from '../../utils/enums';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { MessageToaster } from '../../utils/ToastUtil';
import { RootState } from '../../store';
import { resetResourceAdd, resetIncentiveResourceListGoal, resetLinkResource } from '../../store/slices/resourceSlice';
import { IUploadFilePathUpdateParams, IuploadFilePathUpdateMultipleParams } from '../../interfaces/OrganisationInterface';
import { uploadFile, uploadFilePathUpdate, uploadFilePathUpdateMultiple, addGoalFinish, getGoalResource, deleteGoalResource } from '../../store/actions/organizationActions';
import { validateForm } from '../../utils/formValidation';
import { INCENTIVE_RESOURCE_SCHEMA } from '../../validations/IncentiveResourceSchema';
import { FINISH_GOAL_SCHEMA } from '../../validations/addGoalStepFourSchema';
import { addResource, getGoalIncentiveResourceList, linkResource } from '../../store/actions/resourceActions';
import plusicon from '../../assets/img/icon_plus_wt.svg';
import { resetUploadFile, resetaddGoalFinish, resetDeleteGoal, resetUploadFilePathUpdate } from '../../store/slices/organizationSlice';
import ImageUpload from '../../components/ImageUpload';
import Loader from '../../components/Loader';
import UploadNote from '../../components/UploadNote';
import useDebounce from '../../hooks/useDebounce';
import DeleteConfirmPopup from '../../components/DeleteConfirmation';
import InfiniteScroll from 'react-infinite-scroll-component';
import EndMessage from '../../containers/EndMessage';
import { DEFAULT_PAGE_INDEX, PAGE_SIZE_SORT_50 } from '../../utils/constants';
// Declare constants
const fileType = [
  { label: 'Image', value: 'image' },
  { label: 'Video', value: 'video' },
  { label: 'Audio', value: 'audio' },
  { label: 'Document', value: 'document' },
];
// Declare default params
const savedefaultRequestParams: IResourceForm = {
  ResourceName: '',
  ResourcetypeID: ResourcetypeID.INCENTIVE_CONTENT,
  CategoryID: 0,
  ClassID: [],
  CreatedUserRoleTypeID: 0,
  Description: '',
  OrganizationID: '',
  OrganizationTypeId: 0,
  IsGeneral: true,
  IsGeneralGroup: true,
  Visibility: [0],
  GoalID: '',
  Groups: [],
};

// Declare default params for goal finish form
const finshGoalDefaultParams: IFinishGoalForm = {
  GoalID: 0,
  Points: '',
};

// Declare default params for resource list
const defaultListApiParams = {
  OrganizationID: '',
  IncentiveTitle: '',
  Page: DEFAULT_PAGE_INDEX,
  PageSize: PAGE_SIZE_SORT_50,
};

const GoalStep2Incentive = (props: any) => {
  // Navigation object
  const navigate = useNavigate();
  // Create action dispatch object.
  const dispatch = useAppDispatch();
  // Toast object creation.
  const toast = new MessageToaster();
  // Ref for  input element on the page
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const resourceInputRef = useRef<any>();
  const imageRef = useRef<any>();
  // Access redux state variables
  const {
    addResourceApiLoading,
    addResourceApiSuccess,
    addResourceApiResponseCode,
    addResourceApiResponseMessage,
    addResourceApiData,
    incentiveResourceListGoal,
    incentiveResourceListGoalApiLoading,
    linkResourceApiLoading,
    linkResourceApiResponseMessage,
    linkResourceApiSuccess,
  } = useAppSelector((state: RootState) => state.resource);
  const {
    uploadFileApiData,
    uploadFileApiLoading,
    uploadFileApiSuccess,
    uploadFileResponseCode,
    uploadFileResponseMessage,
    uploadFilePathUpdateApiLoading,
    uploadFilePathUpdateApiSuccess,
    uploadFilePathUpdateResponseCode,
    uploadFilePathUpdateResponseMessage,
    uploadFilePathUpdateMultipleApiLoading,
    uploadFilePathUpdateMultipleApiSuccess,
    uploadFilePathUpdateMultipleResponseCode,
    uploadFilePathUpdateMultipleResponseMessage,
    addGoalFinishApiLoading,
    addGoalFinishApiResponseCode,
    addGoalFinishApiResponseMessage,
    addGoalFinishApiSuccess,
    getGoalResourceApiData,
    getGoalResourceApiSuccess,
    getGoalResourceApiLoading,
    deleteGoalResourceApiLoading,
    deleteGoalResourceApiSuccess,
    deleteGoalResourceApiErrorMessage,
    deleteGoalResourceApiErrorCode,
    getGoalDetailAPiData,
  } = useAppSelector((state: RootState) => state.organization);

  // Initialize component state variables.
  const [resourceForm, setResourceForm] = useState<IResourceForm>(savedefaultRequestParams);
  const [apiParams, setApiParams] = useState<any>(defaultListApiParams);
  const [errorFields, setErrorFields] = useState<any>({});
  const [fileTypeValue, setfileTypeValue] = useState<any>(fileType[0]);
  const [fileUploadBegin, setFileUploadBegin] = useState<boolean>(false);
  const [fileUploadError, setFileUploadError] = useState<string>('');
  const [upImageAttachment, setUpImageAttachment] = useState<string | null>(null);
  const [upImageAttachmentDetails, setUpImageAttachmentDetails] = useState<any>({});
  const [upImageThumbnail, setUpImageThumbnail] = useState<string | null>(null);
  const [upImageThumbnailDetails, setUpImageThumbnailDetails] = useState<any>({});
  const [uploadedFileInfo, setUploadedFileInfo] = useState<any>([]);
  const [characterCount, setCharacterCount] = useState<number>(0);
  const [uploadThumbnail, setUploadThumbnail] = useState<boolean>(false);
  const [uploadAttachment, setUploadAttachment] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);
  const [imgError, setImgError] = useState<string>('');
  const [addGoalFinishForm, setAddGoalFinishForm] = useState<IFinishGoalForm>(finshGoalDefaultParams);
  const [goalID, setGoalID] = useState<number>();
  const [finishGoal, setFinishGoal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [startSubmit, setStartSubmit] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isShowDeleteConfirmPopup, setIsShowDeleteConfirmPopup] = useState<boolean>(false);
  const [selectedResourceId, setSelectedResourceId] = useState(null);
  const [items, setItems] = useState<any>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  // Initialize language variables.
  const ImageUploadChooseImage = useIntlActionMessages('Image.Upload.ChooseImage');
  const ImageUploadFileError = useIntlActionMessages('Image.Upload.FileError');
  const ImageUploadChooseAttachment = useIntlActionMessages('Image.Upload.Attachment');
  const GoalResourceError = useIntlActionMessages('Goal.Resource.Error');
  const LoadingText = useIntlActionMessages('Label.Loading');
  // get logged userInfo
  const storageData = getFromLocalStorage('MI_USR_DATA');
  const unexpectedErrorMessage = useIntlMessages('Something.Wentwrong.Error');
  // Invoke debounce component on intervel.
  const debouncedSearch = useDebounce(searchText, 500);

  // initial rendering
  useEffect(() => {
    try {
      setResourceForm((prev) => ({
        ...prev,
        CreatedUserRoleTypeID: storageData?.RoleTypeId,
        Points: getGoalDetailAPiData?.KudosPoint,
        OrganizationID: props.orgId,
      }));
      setAddGoalFinishForm((addGoalFinishForm) => ({
        ...addGoalFinishForm,
        Points: getGoalDetailAPiData?.KudosPoint,
      }));
      setUploadedFileInfo([]);
      setUpImageAttachmentDetails([]);
      setUpImageAttachment('');
      dispatch(resetResourceAdd());
      dispatch(resetDeleteGoal());
      dispatch(resetUploadFile());
      dispatch(resetaddGoalFinish());
      setGoalID(props.activeGoalID);
      getResourceList();
      return () => {
        dispatch(resetDeleteGoal());
      };
    } catch (error) {
      console.log('Error: ', error);
    }
  }, []);

  // Set API params on bebounced search.
  useEffect(() => {
    try {
      setApiParams((apiParams: any) => ({
        ...apiParams,
        IncentiveTitle: searchText,
        Page: DEFAULT_PAGE_INDEX,
        OrganizationID: props.orgId,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [debouncedSearch]);
  // Setting params
  useEffect(() => {
    try {
      if (searchText !== '') {
        setApiParams((prevParams: any) => ({
          ...prevParams,
          IncentiveTitle: searchText,
          Page: DEFAULT_PAGE_INDEX,
        }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [searchText]);
  // Api call to get incentive resource  list
  useEffect(() => {
    try {
      if (apiParams.IncentiveTitle !== '') {
        setItems([]); // Clear items for new search or filter
        setApiParams((prevParams: any) => ({ ...prevParams, Page: 1 }));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [apiParams.IncentiveTitle]);
  // Fetch data when apiParams change
  useEffect(() => {
    try {
      if (apiParams.IncentiveTitle !== '') {
        dispatch(getGoalIncentiveResourceList(apiParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [apiParams, dispatch]);
  // Setting items in overaly
  useEffect(() => {
    try {
      if (incentiveResourceListGoal?.IncentiveContents) {
        const newItems = incentiveResourceListGoal?.IncentiveContents;
        setItems((prevItems: any) => (apiParams.Page === 1 ? newItems : [...prevItems, ...newItems]));
        setHasMore(newItems.length > 0 && newItems.length === apiParams.PageSize);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [incentiveResourceListGoal]);

  // showing link resource  success and error messages
  useEffect(() => {
    try {
      if (linkResourceApiSuccess) {
        toast.toastSuccess(linkResourceApiResponseMessage);
        setOpenFilter(false);
        dispatch(resetLinkResource());
        getResourceList();
        clearResourceDataFilter();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [linkResourceApiLoading]);

  // Handle the upload path api response
  useEffect(() => {
    try {
      const submitUploadsAndSubsequentActions = async () => {
        if (uploadFileResponseCode > 0 && uploadFileApiSuccess && !submit && upImageAttachmentDetails) {
          uploadAttachmentFileToBucket();
          dispatch(resetUploadFile());
        } else if (submit && uploadFileResponseCode > 0 && uploadFileApiSuccess) {
          await uploadThumbnailToBucket();
          dispatch(resetUploadFile());
          dispatch(addResource(resourceForm));
        }
      };
      submitUploadsAndSubsequentActions();
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFileResponseCode, uploadFileResponseMessage]);

  useEffect(() => {
    try {
      if (submit && addResourceApiResponseCode > 0 && addResourceApiResponseMessage && !addResourceApiSuccess) {
        toast.toastError(addResourceApiResponseMessage);
        setStartSubmit(false);
      } else if (submit && addResourceApiResponseCode > 0 && addResourceApiSuccess) {
        setUploadThumbnail(true);
        setSubmit(false);
        // upload thumbnail
        const reqParams: IUploadFilePathUpdateParams = {
          FilePath: upImageThumbnailDetails.name,
          Type: 'resourceimage',
          TypeId: addResourceApiData?.ResourceId,
        };
        // Api request to update file info after bucket upload and save organization info
        dispatch(uploadFilePathUpdate(reqParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [addResourceApiResponseCode, addResourceApiResponseMessage]);

  // Show error messages upon save api failure
  useEffect(() => {
    try {
      if (uploadThumbnail && uploadFilePathUpdateResponseCode > 0 && uploadFilePathUpdateResponseMessage && !uploadFilePathUpdateApiSuccess) {
        toast.toastError(uploadFilePathUpdateResponseMessage);
        setStartSubmit(false);
      } else if (uploadThumbnail && uploadFilePathUpdateResponseCode > 0 && uploadFilePathUpdateApiSuccess) {
        setUploadAttachment(true);
        setUploadThumbnail(false);
        const uplodedFileInfo = uploadedFileInfo.map((file: any) => {
          return {
            FileName: file.name,
            DisplayName: file.displayName,
            FileSize: file.size,
            FileType: file.typeNumber.toString(),
          };
        });

        // upload attachments
        const reqParams: IuploadFilePathUpdateMultipleParams = {
          Files: uplodedFileInfo,
          Type: 'resource_attachments',
          TypeId: addResourceApiData?.ResourceId,
        };
        // Api request to update file info after bucket upload and save organization info
        dispatch(uploadFilePathUpdateMultiple(reqParams));
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFilePathUpdateResponseCode, uploadFilePathUpdateResponseMessage]);
  // Show error messages upon save api failure
  useEffect(() => {
    try {
      if (uploadAttachment && uploadFilePathUpdateMultipleResponseCode > 0 && uploadFilePathUpdateMultipleApiSuccess) {
        setUploadAttachment(false);
        // Redirect to org listing after updating file status
        toast.toastSuccess(addResourceApiResponseMessage);
        dispatch(resetUploadFile());
        dispatch(resetUploadFilePathUpdate());
        clearResourceData();
        setStartSubmit(false);
        getResourceList();
        setSubmit(false);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [uploadFilePathUpdateMultipleResponseCode, uploadFilePathUpdateMultipleResponseMessage]);

  // Show error messages upon save api failure unlink resource from goal
  useEffect(() => {
    try {
      if (deleteGoalResourceApiErrorCode > 0 && deleteGoalResourceApiSuccess) {
        // Unliked resource from goal
        toast.toastSuccess(deleteGoalResourceApiErrorMessage);
        clearResourceData();
        getResourceList();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [deleteGoalResourceApiErrorMessage, deleteGoalResourceApiErrorCode]);

  // handle add kudos api response
  useEffect(() => {
    try {
      if (finishGoal && addGoalFinishApiResponseCode > 0 && addGoalFinishApiSuccess) {
        // Redirect to goal listing
        toast.toastSuccess(addGoalFinishApiResponseMessage);
        navigate('/activities');
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [addGoalFinishApiResponseCode, addGoalFinishApiResponseMessage]);

  // Call to get  incentive resource list API
  const getResourceList = () => {
    try {
      const resourceParams = {
        GoalId: props.activeGoalID,
        Page: NumberValues.NUM_1,
        PageSize: NumberValues.NUM_100,
        TypeID: ResourcetypeID.INCENTIVE_CONTENT,
      };
      dispatch(getGoalResource(resourceParams));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Put file to bucket after getting the path
  const uploadAttachmentFileToBucket = async () => {
    try {
      const uploadURL = uploadFileApiData;
      if (uploadURL) {
        // PUT request: upload file to S3
        const result = await fetch(uploadURL, {
          method: 'PUT',
          body: upImageAttachment,
        });
        if (result.status == 200) {
          const fileSize = bytesToSize(upImageAttachmentDetails.size);
          const fileInfo = { ...upImageAttachmentDetails, bucketURL: uploadURL, size: fileSize };
          setUpImageAttachmentDetails(fileInfo);
          setUploadedFileInfo([...uploadedFileInfo, fileInfo]);
          setFileUploadBegin(false);
        } else {
          setFileUploadError('Failed to Upload.');
          setFileUploadBegin(false);
          if (fileUploadRef.current) {
            fileUploadRef.current.value = '';
          }
          setStartSubmit(false);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Put file to bucket after getting the path
  const uploadThumbnailToBucket = async () => {
    try {
      const uploadURL = uploadFileApiData;
      if (uploadURL) {
        // PUT request: upload file to S3
        const result = await fetch(uploadURL, {
          method: 'PUT',
          body: upImageThumbnail,
        });
        if (result.status == 200) {
          const fileSize = bytesToSize(upImageThumbnailDetails.size);
          const fileInfo = { ...upImageThumbnailDetails, bucketURL: uploadURL, size: fileSize };
          setUpImageThumbnailDetails(fileInfo);
        } else {
          setFileUploadError('Failed to Upload.');
          setStartSubmit(false);
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const fileTypeChange = (event: any) => {
    try {
      setfileTypeValue(event);
      const fileType = event.value.toLowerCase();
      const acceptedFileExtensions = FILE_TYPES_SUPPORTED[fileType as keyof typeof FILE_TYPES_SUPPORTED] || [];
      const acceptString = acceptedFileExtensions.map((ext) => `.${ext}`).join(',');
      if (fileUploadRef?.current) {
        fileUploadRef.current.accept = acceptString;
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const uploadResourceFile = async (e: any) => {
    try {
      setFileUploadBegin(true);
      setFileUploadError('');
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        const lastDotIndex = file?.name?.lastIndexOf('.');
        const fileNameWithoutExtension = file?.name?.slice(0, lastDotIndex);
        const fileExtension = file?.name?.slice(lastDotIndex + 1);
        const modifiedFileName = fileNameWithoutExtension + new Date().getTime() + '.' + fileExtension;
        // replacing special characters with underscores
        const formattedFilename = modifiedFileName.replace(/[^a-zA-Z0-9.\-_]/g, '_');
        const isValid = checkValidFile(modifiedFileName, fileTypeValue.value, file.size);
        if (isValid) {
          setUpImageAttachment(e.target.files[0]);

          const fileInfo = {
            name: formattedFilename,
            displayName: file.name,
            type: fileExtension,
            size: file.size,
            typeNumber: getFileTypeNumber(fileTypeValue),
            fileType: fileTypeValue.value,
          };

          setUpImageAttachmentDetails(fileInfo);
          const formData = new FormData();
          formData.append('UploadExtension', fileInfo.type);
          formData.append('UploadObjectKey', fileInfo.name);
          formData.append('UploadType', 'resource_attachments');
          dispatch(uploadFile(formData));
        } else {
          setFileUploadError(ImageUploadFileError);
          setFileUploadBegin(false);
          if (fileUploadRef.current) {
            fileUploadRef.current.value = '';
          }
          setStartSubmit(false);
        }
      } else {
        setFileUploadBegin(false);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Check validation after form change
  const CheckValidation = async (validateObj: any) => {
    try {
      const errorResult = await validateForm(validateObj, INCENTIVE_RESOURCE_SCHEMA, errorFields);
      setErrorFields(errorResult);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle form fields validtaion
  const onInputHandleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const { name, value } = event.target;
      if (name == 'Description') {
        setCharacterCount(event.target.value.length);
      }
      setResourceForm((info: any) => ({
        ...info,
        GoalID: goalID?.toString(),
        [name]: value,
      }));
      const validateObj = {
        [name]: value,
      };
      CheckValidation(validateObj);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle form submit
  const onSubmit = async () => {
    try {
      const errorResult = await validateForm(resourceForm, INCENTIVE_RESOURCE_SCHEMA, errorFields);
      if (Object.keys(errorResult).length === 0) {
        if (upImageThumbnail && uploadedFileInfo.length > 0) {
          setImgError('');
          setFileUploadError('');
          const formData = new FormData();
          formData.append('UploadExtension', upImageThumbnailDetails.type);
          formData.append('UploadObjectKey', upImageThumbnailDetails.name);
          formData.append('UploadType', 'resourceimage');
          dispatch(uploadFile(formData));
          setSubmit(true);
          setStartSubmit(true);
        } else if (upImageThumbnail && uploadedFileInfo.length == 0) {
          setImgError('');
          setFileUploadError(ImageUploadChooseAttachment);
          window.scrollTo(0, 0);
          if (fileUploadRef?.current) {
            fileUploadRef?.current?.focus();
          }
          setStartSubmit(false);
        } else {
          setFileUploadError('');
          setImgError(ImageUploadChooseImage);
          window.scrollTo(0, 0);
          if (imageRef?.current) {
            imageRef?.current?.focus();
          }
          setStartSubmit(false);
        }
      } else {
        setErrorFields(errorResult);
        // If there are validation errors, focus on the input field
        const firstErrorField = Object.keys(errorResult)[0];
        if (firstErrorField && resourceInputRef?.current) {
          resourceInputRef?.current?.focus();
        }
        setStartSubmit(false);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // set image selected
  const setPicture = (pic: any) => {
    try {
      if (pic) {
        setImgError('');
        setUpImageThumbnail(pic);
        const imgInfo = {
          name: pic.name,
          type: pic?.name?.split('.')[1],
        };
        setUpImageThumbnailDetails(imgInfo);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Remove Attachment from listing
  const deleteAttachment = (deleteIndex: number) => {
    try {
      const newUploadedFileInfo = [...uploadedFileInfo];
      setUploadedFileInfo(newUploadedFileInfo.filter((item: any, index: number) => index != deleteIndex));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle form submit step 4
  const handleFinishGoal = async () => {
    try {
      const errorResult = await validateForm(addGoalFinishForm, FINISH_GOAL_SCHEMA, errorFields);
      if (Object.keys(errorResult).length === 0) {
        setErrorFields([]);
        if (getGoalResourceApiSuccess && getGoalResourceApiData?.TotalCount > 0) {
          setFinishGoal(true);
          const finishParams: IFinishGoalParams = {
            GoalID: props.activeGoalID,
            Points: parseInt(addGoalFinishForm?.Points),
          };
          dispatch(addGoalFinish(finishParams));
        } else {
          toast.toastError(GoalResourceError);
        }
      } else {
        setErrorFields(errorResult);
        // If there are validation errors, focus on the input field
        const firstErrorField = Object.keys(errorResult)[0];
        if (firstErrorField && resourceInputRef?.current) {
          resourceInputRef?.current?.focus();
        }
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle kudos change
  const handleKudosChange = async (event: any) => {
    try {
      if (event.which < 48 || event.which > 57) {
        event.preventDefault();
      } else {
        setAddGoalFinishForm((addGoalFinishForm: any) => ({
          ...addGoalFinishForm,
          GoalID: goalID,
          Points: event.target.value,
        }));

        // check Validation Kudos
        const errorResult = await validateForm(
          {
            GoalID: goalID,
            Points: event.target.value,
          },
          FINISH_GOAL_SCHEMA,
          errorFields,
        );
        setErrorFields(errorResult);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // handle key press
  const handleKeyPress = (event: any) => {
    try {
      if (event.which < 48 || event.which > 57) {
        event.preventDefault();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
    // Handle next button
    const handleNextButton = () => {
      try {
        if (getGoalResourceApiSuccess && getGoalResourceApiData?.TotalCount === 0) {
          toast.toastError(GoalResourceError);
        } else {
          props.onApiResponse(2);
        }
      } catch (error) {
        console.log('Error: ', error);
      }
    };
  // Call to get  file Icon
  const getIcon = (fileType: string) => {
    try {
      switch (fileType?.toLowerCase()) {
        case 'audio':
          return <Audio />;
        case 'image':
          return <Image />;
        case 'video':
          return <Video />;
        case 'document':
          return <Doc />;
        default:
          return <Image />;
      }
    } catch (error) {
      console.log('Error: ', error);
      return <></>;
    }
  };

  // reset form
  const clearResourceData = () => {
    try {
      setOpen(false);
      setResourceForm({ ...savedefaultRequestParams, CreatedUserRoleTypeID: storageData?.RoleTypeId });
      setUploadedFileInfo([]);
      setUpImageAttachmentDetails([]);
      setUpImageThumbnail('');
      setErrorFields([]);
      setUploadAttachment(false);
      setUploadThumbnail(false);
      setImgError('');
      setFileUploadBegin(false);
      if (fileUploadRef.current) {
        fileUploadRef.current.value = '';
      }

      const inputField = document.getElementById('resetUploadedFile');
      if (inputField) {
        inputField.click();
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle delete confirmation
  const ShowDeleteConfirmPopup = (resourceId: any) => {
    try {
      setIsShowDeleteConfirmPopup(true);
      setSelectedResourceId(resourceId);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Api call to delete resource
  const handleDeleteResourcesClick = () => {
    try {
      if (selectedResourceId) {
        const deleteParams = {
          GoalID: props.activeGoalID,
          ResourceID: selectedResourceId,
        };
        dispatch(deleteGoalResource(deleteParams));
        setIsShowDeleteConfirmPopup(false);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Reset filter form
  const clearResourceDataFilter = () => {
    try {
      setOpenFilter(false);
      setApiParams(defaultListApiParams);
      setSearchText('');
      setSelectedItems([]);
      dispatch(resetIncentiveResourceListGoal());
      setHasMore(true);
      setItems([]);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Search field change event.
  const handleSearchChange = (event: any) => {
    try {
      setSearchText(event.target.value);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  // Handle Search Click.
  const handleSearchClick = () => {
    try {
      setApiParams((apiParams: any) => ({
        ...apiParams,
        IncentiveTitle: searchText,
        Page: DEFAULT_PAGE_INDEX,
      }));
      setSearchText(searchText);
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle Search Field input key down.
  const handleSearchKeyDown = (event: any) => {
    try {
      if (event.key === 'Enter') {
        event.preventDefault();
        setApiParams((apiParams: any) => ({
          ...apiParams,
          Search: searchText,
          Page: DEFAULT_PAGE_INDEX,
        }));
      } else {
        setSearchText(event.target.value);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Handle checkbox selection for link resource
  const toggleSelected = (ContentId: number) => {
    try {
      const selectedIndex = selectedItems.indexOf(ContentId);
      if (selectedIndex === -1) {
        setSelectedItems([...selectedItems, ContentId]);
      } else {
        const updatedSelection = selectedItems.filter((item) => item !== ContentId);
        setSelectedItems(updatedSelection);
      }
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Api call to link resource report
  const handleLinkResource = (request: ILinkResource) => {
    try {
      dispatch(linkResource(request));
    } catch (error) {
      console.log('Error: ', error);
    }
  };
  // Fetching next page items
  const fetchMoreData = () => {
    try {
      if (incentiveResourceListGoalApiLoading) return;
      setApiParams((prevParams: any) => ({
        ...prevParams,
        Page: prevParams.Page + 1,
      }));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <>
      <div className="mb-5">
        <div className="text-center">
          <h3>
            <FormattedMessage id="Hd.AddIncentiveContent" />
          </h3>
          <p>
            <FormattedMessage id="SubHd.IncentiveContent" />
          </p>
        </div>
        <div className="d-flex justify-content-center my-5">
          <Col xl={4}>
            <Row>
              <Col className="resource-button">
                <div className="custom-berger-menu">
                  <Filter
                    width={450}
                    right
                    isOpen={openFilter}
                    onOpen={() => {
                      setOpenFilter(true);
                    }}
                    onClose={() => clearResourceDataFilter()}
                    pageWrapId={'filter-wrapper'}
                    outerContainerId={'outer-container'}
                    customCrossIcon={<img src={Close} />}
                    customBurgerIcon={
                      <Button variant="secondary" className="btn-icon-top w-100">
                        <span>
                          <LinkIcon />
                        </span>
                        <FormattedMessage id="Button.LinkContent" />
                      </Button>
                    }
                  >
                    <div id="filter-wrapper" className="filter-main">
                      <h4>
                        <FormattedMessage id="Hd.IncentiveContent" />
                      </h4>
                      <Col className="mt-4 form-sm">
                        <Search
                          placeholder={useIntlMessages('PH.SearchIncentiveContent')}
                          value={searchText}
                          onChange={handleSearchChange}
                          onKeyDown={handleSearchKeyDown}
                          onClick={handleSearchClick}
                          size="sm"
                        />
                      </Col>
                      <InfiniteScroll
                        dataLength={items?.length}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={items?.length > 0 && <h4>{LoadingText}</h4>}
                        height={400}
                        endMessage={<EndMessage />}
                      >
                        {items &&
                          items?.length > 0 &&
                          items.map((resource: any) => {
                            const isSelected =
                              getGoalResourceApiData &&
                              getGoalResourceApiData.Resources &&
                              getGoalResourceApiData.Resources.some((gr: any) => gr.ResourceID === resource.ContentId);

                            return (
                              <div className="file-listing mb-1" key={resource.ContentId}>
                                <AttachmentCard
                                  File={resource?.Image}
                                  filename={resource?.Title}
                                  selected={isSelected || selectedItems.includes(resource?.ContentId)}
                                  handleRowClick={isSelected ? undefined : () => toggleSelected(resource?.ContentId)}
                                />
                              </div>
                            );
                          })}
                      </InfiniteScroll>
                      <Row className="mt-4">
                        <Col>
                          <Button variant="outline-primary" className="w-100" onClick={clearResourceDataFilter}>
                            <FormattedMessage id="Button.Cancel" />
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            variant="primary"
                            className="w-100"
                            onClick={() => {
                              const selectedKeys: string[] = selectedItems.map((item) => String(item));
                              handleLinkResource({
                                GoalID: props.activeGoalID,
                                IncentiveContents: selectedKeys,
                                TypeID: ResourcetypeID.INCENTIVE_CONTENT,
                              });
                            }}
                            disabled={items?.length === 0}
                          >
                            <FormattedMessage id="Button.Select" />
                            {selectedItems?.length > 0 && ` (${selectedItems?.length})`}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Filter>
                </div>
              </Col>
              <Col className="resource-button">
                <div className="custom-berger-menu">
                  <Filter
                    width={450}
                    right
                    pageWrapId={'filter-wrapper'}
                    outerContainerId={'outer-container'}
                    customCrossIcon={<img src={Close} />}
                    isOpen={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => clearResourceData()}
                    customBurgerIcon={
                      <Button variant="secondary" className="btn-icon-top w-100">
                        <span>
                          <AddIcon />
                        </span>
                        <FormattedMessage id="Button.AddNew" />
                      </Button>
                    }
                  >
                    <div id="filter-wrapper" className="filter-main">
                      <Scrollbar className="h-80vh">
                        <h4>
                          <FormattedMessage id="Hd.AddIncentiveContent" />
                        </h4>
                        <p className="text-secondary">
                          <FormattedMessage id="SubHd.AddIncentiveContent" />
                        </p>
                        <Col className="form-sm">
                          <Select
                            label={useIntlActionMessages('Label.SelectFileType')}
                            options={fileType}
                            value={fileTypeValue}
                            placeholder={useIntlMessages('PH.Select')}
                            onChange={(e: any) => fileTypeChange(e)}
                          />
                        </Col>
                        <div className="justify-content-center mb-4">
                          {/* <UploadFile icon={<AddIcon />} uploadLabel={useIntlMessages('Button.AddFile')} /> */}
                          <div className="addMotiv-res">
                            <div className="fileupload-sec  mb-2">
                              <input
                                ref={fileUploadRef}
                                type="file"
                                name="file-3[]"
                                id="file-3"
                                className="inputfile inputfile-3"
                                data-multiple-caption="{count} files selected"
                                multiple={false}
                                // accept="image/png, image/jpeg"
                                accept="image/*"
                                onChange={(e: any) => uploadResourceFile(e)}
                              />
                              <label htmlFor="file-3">
                                <img src={plusicon} alt="" />
                                <span>{useIntlActionMessages('Button.AddFile')}</span>
                              </label>
                            </div>
                          </div>
                          <div className="error text-center pt-2" ref={imageRef}>
                            {' '}
                            {fileUploadError}
                          </div>
                          <div className="upload-info">
                            <UploadNote
                              fileType={Object.entries(FILE_TYPES_SUPPORTED)
                                .map(([key, value]) => `${value.map((ext) => `.${ext}`).join(', ')}`)
                                .join(', ')}
                            />
                          </div>
                        </div>
                        {uploadedFileInfo?.length > 0 && (
                          <h6>
                            <FormattedMessage id="Label.Attachments" /> ({uploadedFileInfo.length})
                          </h6>
                        )}
                        <div className="attachment-list">
                          {uploadedFileInfo.map((filedetails: any, index: number) => (
                            <AttachmentCard
                              fileType={getIcon(filedetails.fileType)}
                              filename={filedetails.displayName}
                              category={filedetails.size}
                              deleteOption
                              onDelete={() => deleteAttachment(index)}
                            />
                          ))}
                        </div>
                        <div className="cover-photo mt-4 mb-2">
                          <Col xl={5}>
                            <ImageUpload
                              setError={(error: string) => setImgError(error)}
                              imageFile={''}
                              setPicture={setPicture}
                              maxSize={Number(UploaderProps.IMAGE_MAX_SIZE)}
                              acceptedFileTypes={IMAGE_TYPES_SUPPORTED}
                              invalidSizeMessage={useIntlActionMessages('Image.Upload.Size.Message')}
                              invalidTypeMessage={useIntlActionMessages('Image.Upload.AcceptedType.Error')}
                              mode="addresource"
                            />
                          </Col>
                        </div>
                        <div className="error text-center pt-2"> {imgError}</div>
                        <div className="upload-info">
                          <UploadNote />
                        </div>
                        <Col className="form-sm">
                          <Input
                            ref={resourceInputRef}
                            label={useIntlActionMessages('Label.NameofResource')}
                            id="ResourceName"
                            name="ResourceName"
                            type="text"
                            placeholder={useIntlActionMessages('Label.IncentiveResource.Name')}
                            maxLength={NumberValues.NUM_100}
                            onChange={onInputHandleChange}
                            errorMessage={errorFields?.ResourceName}
                            value={resourceForm.ResourceName}
                          />
                        </Col>
                        <Col className="form-sm  position-relative">
                          <Input
                            label={useIntlActionMessages('Label.Description')}
                            id="Description"
                            name="Description"
                            type="textarea"
                            placeholder={useIntlActionMessages('Label.IncentiveResource.Description')}
                            maxLength={NumberValues.NUM_1000}
                            as="textarea"
                            rows={3}
                            onChange={onInputHandleChange}
                            errorMessage={errorFields?.Description}
                            value={resourceForm.Description}
                          />
                          <span className="txt-count">
                            {characterCount}/{NumberValues.NUM_1000}
                          </span>
                        </Col>
                      </Scrollbar>
                      <Row className="mt-4">
                        <Col>
                          <Button variant="outline-primary" className="w-100" onClick={clearResourceData}>
                            <FormattedMessage id="Button.Cancel" />
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            variant="primary"
                            className="w-100"
                            onClick={onSubmit}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                onSubmit();
                              }
                            }}
                          >
                            <FormattedMessage id="Button.Save" />
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Filter>
                </div>
              </Col>
            </Row>
          </Col>
        </div>
        {getGoalResourceApiData?.Resources?.length > 0 && (
          <h6 className="text-center">
            <FormattedMessage id="Label.Attachments" /> ({getGoalResourceApiData?.TotalCount})
          </h6>
        )}
        <div className="attachment-list d-flex justify-content-center">
          <Col xl={10}>
            {getGoalResourceApiSuccess &&
              getGoalResourceApiData?.TotalCount > 0 &&
              getGoalResourceApiData?.Resources?.map((filedetails: any) => (
                <Row className="d-flex justify-content-center mb-3" key={filedetails.ResourceID}>
                  <Col xl={6}>
                    <AttachmentCard
                      File={filedetails?.ResourceThumbnail}
                      filename={filedetails.ResourceName}
                      deleteOption
                      onDelete={() => ShowDeleteConfirmPopup(filedetails.ResourceID)}
                    />
                  </Col>
                </Row>
              ))}
          </Col>
        </div>
        {/* <div className="text-center mt-5">
          <h3>
            <FormattedMessage id="Hd.SetKudos" />
          </h3>
          <p>
            <FormattedMessage id="SubHd.SetKudos" />
          </p>
        </div>
        <div className="d-flex justify-content-center">
          <Col xl={3}>
            <Input
              label={useIntlMessages('Label.Points')}
              id="Points"
              name="Points"
              type="text"
              errorMessage={errorFields?.Points}
              onChange={handleKudosChange}
              placeholder={useIntlActionMessages('Form.Goals.kudos.Add')}
              maxLength={NumberValues.NUM_4}
              onKeyPress={handleKeyPress}
              value={addGoalFinishForm?.Points}
            />
          </Col>
        </div> */}
        {/* <div className="d-flex justify-content-center">
          <Col xl={10}>
            <div className="btn-container d-flex justify-content-end my-4">
              <Button variant="outline-primary" onClick={() => navigate('/activities')}>
                <FormattedMessage id="Button.Cancel" />
              </Button>
              <Button variant="primary" onClick={handleFinishGoal}>
                <FormattedMessage id="Button.Done" />
              </Button>
            </div>
          </Col>
        </div> */}
         <div className="btn-container d-flex justify-content-end my-4">
                  <Button variant="outline-primary" onClick={() => navigate('/activities')}>
                    <FormattedMessage id="Button.Cancel" />
                  </Button>
                  <Button variant="primary" onClick={() => handleNextButton()}>
                    <FormattedMessage id="Button.Next" />
                  </Button>
                </div>
        <DeleteConfirmPopup
          show={isShowDeleteConfirmPopup}
          handleClose={() => setIsShowDeleteConfirmPopup(false)}
          onCancel={() => setIsShowDeleteConfirmPopup(false)}
          onOkay={() => handleDeleteResourcesClick()}
          title={useIntlMessages('Text.DeleteConfirmation.Content')}
          content={useIntlMessages('Text.DeleteConfirmation.Content.Desc')}
        />
      </div>
      {(addResourceApiLoading ||
        fileUploadBegin ||
        uploadFileApiLoading ||
        uploadFilePathUpdateApiLoading ||
        uploadFilePathUpdateMultipleApiLoading ||
        addGoalFinishApiLoading ||
        getGoalResourceApiLoading ||
        deleteGoalResourceApiLoading ||
        incentiveResourceListGoalApiLoading ||
        linkResourceApiLoading ||
        startSubmit) && <Loader />}
    </>
  );
};
export default GoalStep2Incentive;
